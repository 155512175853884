/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import ss from '../../modules/ss';

export default function CustomTooltip({
  active,
  payload,
  total,
  setWhatTime,
  duration,
}) {
  if (active && payload && payload.length) {
    let percent;
    percent = payload[0].payload.total;

    useEffect(() => {
      if (payload[0].payload.timed === 0) {
        setWhatTime(0.1);
      } else if (duration === payload[0].payload.timed) {
        setWhatTime(payload[0].payload.timed - 1);
      } else {
        setWhatTime(payload[0].payload.timed);
      }
    }, [payload[0].payload.timed]);

    percent /= total / 100;

    return (
      <div className="bg-slate-800/90 rounded-md text-slate-300 p-3 text-sm leading-4 drop-shadow-2xl">
        <p>
          <span className="font-medium">Tempo: &nbsp;</span>
          {ss(payload[0].payload.timed)}
        </p>
        <p className="mt-2 flex gap-2 items-center">
          <span className="h-2.5 w-2.5 block bg-green-500 rounded-full" />
          <span className="font-medium">Público:</span>
          {' '}
          {payload[0].payload.total}
          /
          {percent.toFixed(2)}
          %
        </p>
      </div>
    );
  }

  return null;
}
