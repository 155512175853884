import React from "react";
import { FaStar } from "react-icons/fa";
import "../../../../styles/builder/ProgressbarRender.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function StarRatingRender({ comp }) {
  const calculateStarRating = () => {
    const maxRating = 10;
    const percentage = (comp.misc.rating / maxRating) * 100;
    return { width: `${percentage}%`, color: "#f0ad4e" };
  };


  return (
    <>
      <div
        className="flex items-center"
        style={{ justifyContent: comp.misc.jusctifyContent }}
      >
        <span className="text-black flex items-center mx-2">
          {comp.ratingText ?? ""}
        </span>
        <div className="stars">
          <div className="star-container">
            <div className="star-container" style={calculateStarRating()}>
              {comp.misc.target ?? "★★★★★"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

