import { ELEMENTSARR, UPDATEELEMENTSARR } from '../constants/types';

export const setElementsArr = (ElArr) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({
    type: ELEMENTSARR,
    payload: ElArr,
  });
  resolve(true);
});

export const updateElementsArr = (ElArr, ELeObj, i) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({
    type: UPDATEELEMENTSARR,
    payload: { index: i, object: ELeObj, org_arr: ElArr },
  });
  resolve(true);
});
