import { ref, uploadBytes, deleteObject } from 'firebase/storage';
import {
  updateDoc,
  arrayUnion,
  arrayRemove,
  doc,
  getDoc,
  onSnapshot,
} from 'firebase/firestore';
import { storage, db, auth } from '../../config/firebase';

import { needContent, needItem } from './helperArrays';
import { apiCall } from '../../service/commonService';

export const getComponentByType = (type, array) => {
  for (const item of array) {
    if (item.type === type) {
      return item.component;
    }
  }
  return null; // Return null if no matching component found
};

export const giveContent = (type) => {
  if (needContent.includes(type)) {
    return true;
  }
  return false;
};

export const giveItem = (type) => {
  if (needItem.includes(type)) {
    return true;
  }
  return false;
};

/*
helperFxns.js:32 Uncaught TypeError: Cannot read properties of null (reading 'uid')
    at ./src/helpers/Builder/helperFxns.js (helperFxns.js:32:1)
*/
const user = auth.currentUser;
const id = user?.uid;

const traverseObjectWithImages = (data, parentPath = ['u', id, 'l']) => {
  const contents = [];

  Object.entries(data.folders).forEach(([name, value]) => {
    const fullPath = [...parentPath, name].join('/');
    const folderObj = {
      name,
      isFolder: true,
      fullPath,
      contentType: 'folder',
      path: fullPath,
    };

    if (value && typeof value === 'object' && Object.keys(value).length > 0) {
      folderObj.children = traverseObjectWithImages(
        { folders: value, images: data.images },
        [...parentPath, name],
      );
    }

    contents.push(folderObj);
  });

  if (data.images) {
    Object.entries(data.images).forEach(([name, imageFiles]) => {
      const parentFolder = contents.find((folder) => folder.name === name);
      if (parentFolder) {
        imageFiles.forEach((imageFile) => {
          const imageObj = {
            name: imageFile,
            isFolder: false,
            fullPath: `${parentFolder.fullPath}/${imageFile}`,
            contentType: 'image',
            path: `https://img.imageboss.me/atm/cdn/${parentFolder.path}/${imageFile}`,
          };
          parentFolder.children = parentFolder.children || [];
          parentFolder.children.push(imageObj);
        });
      }
    });
  }

  return contents;
};

export const handleUploadImage = async (e, folderPath, id) => {
  try {
    const file = e.target.files[0];
    const imagePath = `${folderPath}/${file.name}`;
    const storageRef = ref(storage, imagePath);
    await uploadBytes(storageRef, file);
    console.log(folderPath, 'storageref');

    const folderName = folderPath.substring(folderPath.lastIndexOf('/') + 1);
    const docRef = doc(db, 'imageLibrary', id);
    const updateObject = {
      [`images.${folderName}`]: arrayUnion(file.name),
    };

    return new Promise((resolve, reject) => {
      updateDoc(docRef, updateObject)
        .then(() => {
          onSnapshot(docRef, (snapshot) => {
            const data = snapshot.data();
            const folderContents = traverseObjectWithImages(data);
            resolve(folderContents);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

// export const handleDeleteImage = async (folderPath) => {
//   try {
//     const saveImageUrl = clickedImageURL.split('/cdn')[1]?.substring(1);
//     const imageName = saveImageUrl.split('/').pop();

//     const imageRef = ref(storage, saveImageUrl);
//     await deleteObject(imageRef);
//     setCurrentFolderImages(currentFolderImages.filter((url) => url !== clickedImageURL));
//     const folderName = folderPath.substring(folderPath.lastIndexOf('/') + 1);
//     // console.log(folderName, imageName, 'imaeName');
//     const docRef = doc(db, 'imageLibrary', 'pYOZXY4mNFj9qc1O74E9');

//     const updateObject = {
//       [`images.${folderName}`]: arrayRemove(imageName),
//     };
//     await updateDoc(docRef, updateObject).then(() => {
//       const unsubscribe = onSnapshot(docRef, (snapshot) => {
//         const data = snapshot.data();
//         const folderContents = traverseObjectWithImages(data);
//         setFolderContents(folderContents);
//       });
//     });
//   } catch (error) {
//     console.error('Error:', error);
//   }
// };

export const handleDeleteImage = async (
  folderPath,
  clickedURL,
  setFolderImages,
  folderImages,
  id,
) => {
  try {
    const saveImageUrl = clickedURL.split('/cdn')[1]?.substring(1);
    const imageName = saveImageUrl.split('/').pop();

    const imageRef = ref(storage, saveImageUrl);
    await deleteObject(imageRef);
    setFolderImages(folderImages.filter((url) => url !== clickedURL));
    const folderName = folderPath.substring(folderPath.lastIndexOf('/') + 1);
    const docRef = doc(db, 'imageLibrary', id);

    const updateObject = {
      [`images.${folderName}`]: arrayRemove(imageName),
    };

    return new Promise((resolve, reject) => {
      updateDoc(docRef, updateObject)
        .then(() => {
          const unsubscribe = onSnapshot(docRef, (snapshot) => {
            const data = snapshot.data();
            const folderContents = traverseObjectWithImages(data);
            resolve(folderContents);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const handleButtonClick = async (
  clickedImageURL,
  onButtonClick,
  setClickedImageURL,
  setImagePopup,
  type,
  videoId,
  fetchData,
) => {
  const user = auth.currentUser;

  let userToken;
  try {
    userToken = await user.getIdToken();
  } catch (error) {
    console.log('Authentication error:', error);
    return;
  }
  if (clickedImageURL) {
    // onButtonClick(clickedImageURL);
    const imageName = clickedImageURL.substring(
      clickedImageURL.lastIndexOf('/') + 1,
    );
    console.log(clickedImageURL);

    // setClickedImageURL(null);
    if (type == 'image') {
      const data = {
        smartAutoPlay: imageName,
        uid: user.uid,
      };
      const userData = { videoId, userToken, data };

      const response = await apiCall(
        'post',
        'video/smartauto',
        userData,
        'https://apidopro.atomicat-api.com/',
      );
      fetchData();
      setImagePopup(false);
    } else if (type == 'initalThumb') {
      const data = {
        img: imageName,
        uid: user.uid,
        type: 'default',
      };
      const userData = { videoId, userToken, data };

      await apiCall(
        'post',
        'video/savethumb',
        userData,
        'https://apidopro.atomicat-api.com/',
      );
      fetchData();
      setImagePopup(false);
    } else if (type == 'pauseThumb') {
      const data = {
        img: imageName,
        uid: user.uid,
        type: 'exit',
      };
      const userData = { videoId, userToken, data };

      await apiCall(
        'post',
        'video/savethumb',
        userData,
        'https://apidopro.atomicat-api.com/',
      );
      fetchData();
      setImagePopup(false);
    }
  } else {
    alert('Please select an image');
  }
};
