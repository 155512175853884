import React, {
  useContext, useState, useRef, useCallback,
} from 'react';

import '../../styles/frazerUpload/FrazerUpload.css';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import { useTus } from 'use-tus';
import close from '../../assets/icons/former/close-icon.svg';
import { auth, db } from '../../config/firebase';

const UploadVidios = ({
  closeFun,
  title,
  btn,
  label,
  placeholder,
  fileupload,
  func,
  fetchData,
}) => {
  const [videoTitle, setVideoTitle] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadErr, setUploadErr] = useState(null);
  const [uploadType, setUploadType] = useState('');
  const [videos, setVideos] = useState();
  const [logError, setLogError] = useState();
  const [percent, setPercent] = useState('');
  const [videoStatus, setvideoStatus] = useState('');
  const [displayResumeSummary, setDisplayResumeSummary] = useState();
  const [toast, setToast] = useState('');
  const [displaySummary, setDisplaySummary] = useState(false);
  const [resumeUid, setResumeUid] = useState(null);
  const user = auth.currentUser;
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files.item(0);
    setUploadFile(file);
  };

  const { upload, setUpload } = useTus();

  const validateUpload = async () => {
    if (!videoTitle) {
      setUploadErr('Por favor, escolha um nome para o vídeo.');
      return false;
    }
    console.log(
      videos.filter(
        (v) => v.meta.name?.toLowerCase() === videoTitle.toLowerCase(),
      )?.length > 0,
    );
    if (
      videos.filter(
        (v) => v.meta.name?.toLowerCase() === videoTitle.toLowerCase(),
      )?.length > 0
    ) {
      setUploadErr(
        'Você já possui um vídeo com esse nome. Por favor, escolha outro nome.',
      );
      return false;
    }

    // if (allowedOrigins === null || allowedOrigins === undefined) {
    //   setUploadErr("Please add allowed origins");
    //   return false;
    // }

    setUploadErr(null);

    return { videoTitle };
  };

  const validateType = async (file) => {
    const types = [
      'MP4',
      'MKV',
      'MOV',
      'AVI',
      'FLV',
      'X-FLV',
      'MP2T',
      'MP1S',
      'MP2P',
      'MXF',
      'LXF',
      'GXF',
      '3GP',
      '3GPP',
      'WEBM',
      'MPG',
      'MPEG',
      'MP2P',
      'MP1S',
      'QUICKTIME',
    ];
    return types.includes(file?.type?.split('/')[1]?.toUpperCase());
  };

  const handleSetUpload = useCallback(
    async (event) => {
      const file = event.target.files.item(0);
      setUploadFile(file);
      let isValid = false;
      const validDomains = [];
      let resumeValidDomains;
      let resumeVideo;

      if (!file) {
        return setUploadErr('Por favor, selecione um arquivo de vídeo.');
      }
      if (uploadType === 'new') {
        isValid = await validateUpload();
      }

      const validType = await validateType(file);

      if (validType) {
        if (uploadType !== 'resume') {
          // validDomains = allowedOrigins.split(",").map((item) => item.trim());
          // console.log(validDomains);
        } else {
          // mudei de filter e depois [0] para find
          // resumeVideo = videos.find((item) => item.uid === resumeUid);
          // resumeValidDomains = removeDefaultOrigin(resumeVideo?.allowedOrigins);
          // resumeValidDomains = resumeValidDomains?.trim();
        }

        setUploadFile(file);
        setUploadErr(null);

        const dt = new Date();
        dt.setMinutes(dt.getMinutes() + 10);

        let userToken;
        try {
          userToken = await user.getIdToken(true);
        } catch (err) {
          console.log(err);
        }

        // console.log(allDomains)

        setUpload(file, {
          endpoint: `https://apidopro.atomicat-api.com/stream/initiate/${user.uid}?type=${file.type}`,
          metadata: {
            name: uploadType === 'resume' ? resumeVideo.meta.name : videoTitle,
            creator: user.uid,
            // allowedorigins: uploadType === 'resume' ? resumeValidDomains : [],
            filetype: file.type,
            maxDurationSeconds: 21600,
            userToken,
          },
          chunkSize: 52428800,
          async onError(error) {
            setIsUploading(false);
            await logError({
              error: JSON.stringify(error),
              where: 'video_upload',
              userId: user.uid,
            });
            setUploadErr(
              'Falha ao iniciar o upload. Caso o erro persista, contate o suporte.',
            );
            throw error;
          },
          onProgress(bytesUploaded, bytesTotal) {
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            setPercent(percentage);
            // console.log(percentage)
            if (percentage === '100.00') {
              // setvideoComplete(true);
            }
            console.log(bytesUploaded, bytesTotal, `${percentage}%`);
          },
          onSuccess() {
            setTimeout(() => {
              setDisplaySummary(false);
              setUploadFile(false);
              setPercent(null);
              setVideoTitle('');
              setIsUploading(false);
              closeFun();
              fetchData();
              // setvideoComplete(false);
              setDisplayResumeSummary(false);
              // handleRefreshList();
            }, 2000);
          },
          onAfterResponse(req, res) {
            console.log(res);
            return new Promise((resolve) => {
              const mediaIdHeader = res.getHeader('stream-media-id');
              if (uploadType === 'resume' && res._xhr.status !== 200) {
                setToast({
                  show: true,
                  type: 'error',
                  message:
                    'Já não é possível retomar o upload desse vídeo. Exclua-o e comece novamente clicando no botão `Novo vídeo`.',
                });
                setTimeout(() => {
                  setToast({ show: false, type: '', message: '' });
                  setIsUploading(false);
                  setDisplayResumeSummary(false);
                  setResumeUid(null);
                }, 10500);
                console.log('WHats wrong!');
                return;
              }
              if (mediaIdHeader) {
                console.log('Does what?');
              }
              resolve();
            });
          },
          onShouldRetry(err, retryAttempt, options) {
            console.log(retryAttempt);
            console.log(options);
            const status = err.originalResponse
              ? err.originalResponse.getStatus()
              : 0;
            console.log(status);
            // If the status is a 403, we do not want to retry.
            if (status === 403) {
              setToast({
                show: true,
                type: 'error',
                message:
                  'Acesso negado. Se o erro persistir, por favor contate o suporte.',
              });
              setTimeout(() => {
                setToast({ show: false, type: '', message: '' });
                setIsUploading(false);
                setDisplayResumeSummary(false);
                setResumeUid(null);
              }, 5000);
              return false;
            }

            // For any other status code, tus-js-client should retry.
            return true;
          },
          removeFingerprintOnSuccess: true,
        });
      }
    },
    [
      uploadFile,
      videoTitle,
      // allowedOrigins,
      uploadType,
      // defaultOrigins,
      resumeUid,
      videos,
    ],
  );

  const pauseResume = async () => {
    if (videoStatus === 'resume') {
      upload.abort();
      setvideoStatus('pause');
    } else {
      setvideoStatus('resume');
      upload.start();
    }
  };

  const handleStart = useCallback(
    (e) => {
      e.preventDefault();
      if (!upload) {
        console.log('No Upload.............');
        setUploadErr('Please upload Video');
        if (uploadType === 'resume') {
          console.log('expired.........');
          setToast({
            show: true,
            type: 'error',
            message: 'Por favor, faça upload de um video.',
          });
          setTimeout(
            () => setToast({ show: false, type: '', message: '' }),
            10500,
          );
          return;
        }
        return;
      }
      if (uploadFile || uploadType === 'resume') {
        upload.findPreviousUploads().then((previousUploads) => {
          // Found previous uploads, so we select the first one.
          console.log(previousUploads);
          console.log(upload);
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
            setDisplayResumeSummary(true);
          } else if (uploadType === 'resume') {
            setToast({
              show: true,
              type: 'error',
              message:
                'O link para continuar o upload do vídeo expirou. Por favor, delete este vídeo e comece o upload novamente.',
            });
            setTimeout(() => {
              setToast({ show: false, type: '', message: '' });
              setIsUploading(false);
            }, 10500);
            return;
          }

          upload.start();
          setIsUploading(true);
          // fetchData()
        });
      } else {
        setUploadErr('Please upload Video');
      }
    },
    [upload, uploadFile, videoTitle],
  );
  const handleTextClick = () => {
    inputRef.current.click();
  };

  return (
    <div className="frazer-container">
      <div className="frazer-box">
        <div className="frazer-upload-header">
          <h2>{t('upload')}</h2>
          <img onClick={() => closeFun(false)} src={close} alt="" />
        </div>

        <div className="hr80" />

        <form onSubmit={handleStart} className="w-full">
          <div className="frazer-input">
            <label htmlFor="fileupload">{t('nameOfVideo')}</label>
            <input
              value={videoTitle}
              onChange={(e) => setVideoTitle(e.target.value)}
              type="text"
              placeholder={t('nameTheVideo')}
            />
          </div>
          <input
            ref={inputRef}
            onChange={handleSetUpload}
            onClick={(e) => {
              e.target.value = null;
              // setUploadType("new");
            }}
            type="file"
            accept="video/mp4"
            id="file_upload"
            name="file_upload"
            className="hidden ml-5"
            disabled={isUploading}
          />
          {/* <button onClick={handleTextClick}>Escolher arquivo.</button> */}
          {uploadFile ? (
            <>
              <p className="text-md font-medium ml-6 mb-3">
                Selecionado: &nbsp;
                <b>{uploadFile.name}</b>
                <span className="mt-0.25 text-slate-300 text-xs ml-5">
                  {(parseFloat(uploadFile.size) / (1024 * 1024)).toFixed(2)}
                  {' '}
                  MB
                </span>
              </p>
            </>
          ) : (
            <button
              className="ml-6 mb-2 videoUploadbtn  text-center "

              onClick={handleTextClick}
            >
              Escolher arquivo...
            </button>
            // t("chooseFile")
          )}
          {percent && (
            <>
              <div className="progress">
                <div
                  className="bar text-center bg-green-600"
                  style={{ width: `${percent}%` }}
                >
                  <p className="percent text-center">
                    {percent}
                    %
                  </p>
                </div>
              </div>
              {/* <div
                className="frazer-input ml-5 mt-3 mb-3"
                style={{
                  border: "1px solid #11d167",
                  width: "66%",
                  height: "36px",
                  borderRadius: "5px",
                }}
              > */}
              <button
                style={{
                  color: '#11d167', border: '1px solid #11d167', width: '409px', height: '36px', borderRadius: '6px',
                }}
                className=" ml-6 "
                onClick={pauseResume}
              >
                Pausar
              </button>
              {/* </div> */}
            </>
          )}

          {/* note Section */}

          <div className="hr80" />

          <div className="fazer-btn">
            <p onClick={() => closeFun(false)}>{t('cancel')}</p>
            <button>
              {isUploading ? t('translationInProgress') : t('upload')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadVidios;
