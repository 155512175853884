import { async } from '@firebase/util';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  where,
} from 'firebase/firestore';
import { BASEURL } from '../utility/constants';
import { db } from '../config/firebase';
// const user = useContext(LoginContext);
// useEffect(() => {
//   console.log(auth);
//   onAuthStateChanged(auth, (user) => {
//     if (user) {
//       console.log("UserExist", user);
//       setUser(user);
//     } else {
//       setUser(null);
//     }
//     setIsLoading(false);
//   });
// }, []);

export const firebaseSnapshotCall = async (q) => new Promise((resolve, reject) => {
  onSnapshot(
    q,
    (querySnapshot) => {
      resolve(querySnapshot);
    },
    (error) => {
      reject(error);
    },
  );
});

const BASE = BASEURL;

export const apiCall = async (method, url, body = {}, baseUrl) => {
  const serverUrl = `${baseUrl != undefined ? baseUrl : BASE}${url}`;
  // let token = user?.["accessToken"];
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    Authorization: 'Bearer pAm8Lq1zYnr5sKb6tc0vH7nO0wj4DIiUx381IgggF2bE',
  };

  try {
    let response;
    if (method == 'get') {
      response = await axios.get(serverUrl, { headers });
    } else if (method == 'post') {
      response = await axios.post(serverUrl, body, { headers });
    } else if (method == 'delete') {
      response = await axios.delete(serverUrl, { headers, data: body });
    } else if (method == 'update') {
      response = await axios.put(serverUrl, body, { headers });
    }
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};
