import React, { useCallback, useState, useEffect } from 'react';
import {
  updateDoc,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import FrazerUpload from './FrazerUpload';
import cross from '../../../../assets/icons/builder/Cross.svg';
import '../../../../styles/builder/ImagePopup.css';
import plus from '../../../../assets/icons/landingDashboard/Plus.svg';
import folder from '../../../../assets/icons/builder/folder-open.svg';
import file from '../../../../assets/icons/builder/file.svg';
import uploadFile from '../../../../assets/icons/builder/Group 27648.svg';
import deleteIcon from '../../../../assets/icons/sites/Delete.png';
import {
  handleUploadImage,
  handleDeleteImage,
  handleButtonClick,
} from '../../../../helpers/Builder/helperFxns';
import { useCollection } from '../../../../custom-hooks/useCollection';

import { db, auth } from '../../../../config/firebase';

const ImagePopup = ({
  setImagePopup,
  type,
  videoId,
  fetchData,
  onButtonClick,
}) => {
  const user = auth.currentUser;
  console.log('image popup', videoId, type);
  const [newFolderPopup, setNewFolderPopup] = useState(false);
  const [folderContents, setFolderContents] = useState([]);
  const [currentFolderImages, setCurrentFolderImages] = useState([]);
  const [expandedItems, setExpandedItems] = useState([
    folderContents[0]?.fullPath,
  ]);
  const [clickedImageURL, setClickedImageURL] = useState();
  const [currentFolderPath, setCurrentFolderPath] = useState('');
  const [forceRender, setForceRender] = useState(false);
  const [savedClickedImagesURL, setSavedClickedImagesURL] = useState([]);

  async function fetchData() {
    const { documents } = await useCollection('imageLibrary');
    const folderDocuments = documents || [];

    // Use folderDocuments here after it has a value
    console.log(folderDocuments);
  }

  fetchData();

  const id = user?.uid;
  function filterByFullPath(array, fullPath) {
    // Empty array to store the filtered items
    const filteredArray = [];

    // Empty array to store the paths of children with isFolder false
    const childrenPaths = [];

    // Recursive function to traverse the array and its children
    function traverse(items) {
      for (const item of items) {
        if (item.fullPath === fullPath) {
          filteredArray.push(item);
        } else if (item.children) {
          traverse(item.children);
          if (!item.isFolder) {
            childrenPaths.push(item.path);
          }
        }
      }
    }
    traverse(array);
    if (filteredArray[0]?.children === undefined) {
      // If no item was found, return an empty array
      return [];
    }
    filteredArray[0].children
      .filter((item) => item.isFolder === false)
      .map((item) => {
        childrenPaths.push(item.path);
      });
    return childrenPaths;
  }
  const traverseObjectWithImages = (data, parentPath = ['u', id, 'l']) => {
    const contents = [];

    Object.entries(data.folders).forEach(([name, value]) => {
      const fullPath = [...parentPath, name].join('/');
      const folderObj = {
        name,
        isFolder: true,
        fullPath,
        contentType: 'folder',
        path: fullPath,
      };

      if (value && typeof value === 'object' && Object.keys(value).length > 0) {
        folderObj.children = traverseObjectWithImages(
          { folders: value, images: data.images },
          [...parentPath, name],
        );
      } else {
        folderObj.children = [];
      }

      contents.push(folderObj);
    });

    if (data.images) {
      Object.entries(data.images).forEach(([name, imageFiles]) => {
        const parentFolder = contents.find((folder) => folder.name === name);
        if (parentFolder) {
          imageFiles.forEach((imageFile) => {
            const imageObj = {
              name: imageFile,
              isFolder: false,
              fullPath: `${parentFolder.fullPath}/${imageFile}`,
              contentType: 'image',
              path: `https://img.imageboss.me/atm/cdn/${parentFolder.path}/${imageFile}`,
            };
            parentFolder.children = parentFolder.children || [];
            parentFolder.children.push(imageObj);
          });
        }
      });
    }

    return contents;
  };

  const fetchPage = useCallback(async () => {
    const pageRef = doc(db, 'imageLibrary', id);

    try {
      const docSnap = await getDoc(pageRef);
      if (docSnap.exists()) {
        const imageData = docSnap.data();
        console.log(imageData, 'imageData');
        const folderContents = traverseObjectWithImages(imageData);
        setFolderContents(folderContents);
        setExpandedItems([folderContents[0]?.fullPath]);
        setCurrentFolderPath(folderContents[0]?.fullPath);
        setCurrentFolderImages(
          filterByFullPath(folderContents, folderContents[0]?.fullPath),
        );
        console.log('this is');
      } else {
        const newImageData = {
          ownerId: id,
          folders: {
            Pictures: {},
          },
          images: {
            Pictures: [],
          },
        };

        await setDoc(pageRef, newImageData);

        const folderContents = traverseObjectWithImages(newImageData);
        setFolderContents(folderContents);
        setExpandedItems([folderContents[0]?.fullPath]);
        setCurrentFolderPath(folderContents[0]?.fullPath);
        setCurrentFolderImages(
          filterByFullPath(folderContents, folderContents[0]?.fullPath),
        );
        console.log('this issss');
      }
    } catch (error) {
      console.log(error);
    } finally {
      console.log('done');
      console.log('vkjsv', folderContents);
    }
  }, []);

  const docRef = doc(db, 'imageLibrary', id);

  const handleCreateFolder = async (folderName, parentPath) => {
    try {
      const partsAfterId = parentPath
        .split('u/')[1]
        .split('/')
        .slice(2)
        .join('/');
      console.log(partsAfterId, 'after id');
      const objKeys = partsAfterId.split('/');
      console.log(objKeys, 'obj');
      const updateObject = {
        [`folders.${objKeys.join('.')}.${folderName}`]: {},
      };
      await updateDoc(docRef, updateObject);

      const updateImageLibrary = {
        [`images.${folderName}`]: [],
      };

      await updateDoc(docRef, updateImageLibrary).then(() => {
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
          const data = snapshot.data();
          const folderContents = traverseObjectWithImages(data);
          setFolderContents(folderContents);
          setExpandedItems([folderContents[0]?.fullPath]);
        });
      });

      setFolderContents([...folderContents]);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  const toggleFolder = async (fullPath) => {
    if (expandedItems.includes(fullPath)) {
      setExpandedItems(expandedItems.filter((path) => path !== fullPath));
    } else {
      setExpandedItems([...expandedItems, fullPath]);
    }
  };

  const toggleImages = async (fullPath) => {
    const folderPath = fullPath;
    setCurrentFolderPath(folderPath);
    console.log(fullPath, 'toggle image');

    console.log(folderPath, '>>>>>>>.folder path');
    const imageURLs = filterByFullPath(folderContents, folderPath);
    setCurrentFolderImages(imageURLs);
    const saveImageUrl = {
      folderPath,
      imageURLs,
    };
    setSavedClickedImagesURL([...savedClickedImagesURL, saveImageUrl]);
  };

  const renderImages = () => {
    const imageExtensions = ['png', 'jpeg', 'jpg', 'svg', 'gif'];
    currentFolderImages.sort((a, b) => a.localeCompare(b));
    return currentFolderImages.map(
      (url) => imageExtensions.some((ext) => url.includes(ext)) && (
      <div className="uploadedImage cursor-pointer">
        <img
          key={url}
          src={url}
          onClick={() => setClickedImageURL(url)}
          alt="stuff"
          style={{
            border:
                  clickedImageURL === url
                    ? '5px solid #00A3FF'
                    : '5px solid transparent',
          }}
        />
      </div>
      ),
    );
  };

  const renderItems = (items) => {
    if (!Array.isArray(items)) {
      return null;
    }
    items.sort((a, b) => a.name.localeCompare(b.name));

    return items
      .filter((item) => item.isFolder)
      .map((item) => (
        <div
          key={item.fullPath}
          className="files"
        >
          <span
            onClick={() => {
              setClickedImageURL(null);
            }}
          >
            <span
              onClick={() => {
                toggleFolder(item.fullPath);

                console.log(currentFolderPath, 'toggle folder');
              }}
            >
              {item.isFolder
                ? expandedItems.includes(item.fullPath)
                  ? '▼ '
                  : '▶ '
                : ''}
            </span>
            <span
              onClick={() => {
                toggleImages(item.fullPath);
              }}
              style={{ opacity: currentFolderPath === item.fullPath ? 1 : 0.5 }}
            >
              <img
                className="folderFileImg"
                src={item.isFolder ? folder : file}
                alt="folder.svg"
              />
              {item.name.includes('-') ? item.name.split('-')[1] : item.name}
            </span>
            {' '}
            <br />
          </span>
          {item.isFolder && expandedItems.includes(item.fullPath) && (
            <div style={{ marginLeft: '0px' }}>
              {renderItems(item.children)}
            </div>
          )}
        </div>
      ));
  };

  return (
    <div className="BodyOfPopup">
      <div className="headerOfPopup">
        <p className="DefinitionOfHeader">Galeria de imagens</p>
        <img
          src={cross}
          alt="cross.svg"
          onClick={() => {
            setImagePopup(false);
          }}
        />
      </div>
      <div className="FrameOfPopup">
        <div className="sidebarFramePopup">
          <div className="mainSideBar">
            <div className="sideFrameTop">
              <div className="sideFrameComponent">
                <p className="novapasta">Nova pasta</p>
                <img
                  className="cursor-pointer"
                  src={plus}
                  alt="plus.svg"
                  onClick={() => {
                    setNewFolderPopup(true);
                  }}
                />
              </div>
              {renderItems(folderContents)}
            </div>
            <div className="sideFrameBottom">
              <div className="sideFrameComponent">
                <p className="novapasta">Galeria Atomicat</p>
              </div>
              <div className="toggleFolder">
                <div className="folder">
                  <img src={folder} alt="folder.svg" />
                  <p>Todos arquivos</p>
                </div>
              </div>
              <div className="subFolder">
                <div className="folder">
                  <img src={file} alt="folder.svg" />
                  <p>Ícones</p>
                </div>
              </div>
              <div className="subFolder">
                <div className="folder">
                  <img src={file} alt="folder.svg" />
                  <p>Ilustrações</p>
                </div>
              </div>
              <div className="subFolder">
                <div className="folder">
                  <img src={file} alt="folder.svg" />
                  <p>Selos de garantia</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainframePopup">
          <div className="uploadImage">
            <input
              type="file"
              id="uploadFile"
              onClick={(e) => {
                console.log(currentFolderPath, 'on clikc');
              }}
              onChange={async (e) => {
                if (e.target.files[0].type.split('/')[0] !== 'image') {
                  alert('Please upload an image file');
                } else {
                  const folderContents = await handleUploadImage(
                    e,
                    currentFolderPath,
                    id,
                  );
                  setFolderContents(folderContents);
                  setCurrentFolderPath(currentFolderPath);
                  setCurrentFolderImages(
                    filterByFullPath(folderContents, currentFolderPath),
                  );
                }
              }}
              hidden
            />

            <label
              htmlFor="uploadFile"
              className="uploadFileLabel"
            >
              <img src={uploadFile} alt="uploadFile.svg" />
              <p>
                Solte o arquivo ou
                <span>clique aqui</span>
              </p>
            </label>
            {renderImages()}
          </div>
          <div className="footer">
            <button
              className="deleteButton"
              onClick={async () => {
                const folderContents = await handleDeleteImage(
                  currentFolderPath,
                  clickedImageURL,
                  setCurrentFolderImages,
                  currentFolderImages,
                  id,
                );
                setFolderContents(folderContents);
                setCurrentFolderPath(currentFolderPath);
                setClickedImageURL(null);
              }}
              style={{ opacity: clickedImageURL ? 1 : 0.5 }}
              disabled={!clickedImageURL}
            >
              <img src={deleteIcon} alt="deleteIcon.svg" />
              <p>Excluir imagem</p>
            </button>
            <button
              onClick={() => {
                handleButtonClick(
                  clickedImageURL,
                  onButtonClick,
                  setClickedImageURL,
                  setImagePopup,
                  type,
                  videoId,
                  fetchData,
                  currentFolderImages,
                );
                onButtonClick(clickedImageURL);
                setImagePopup(false);
              }}
              className="selectButton"
              style={{ opacity: clickedImageURL ? 1 : 0.5 }}
              disabled={!clickedImageURL}
            >
              <span>Selectionar</span>
            </button>
          </div>
        </div>
      </div>
      { newFolderPopup && <FrazerUpload handleCreateFolder={handleCreateFolder} setNewFolderPopup={setNewFolderPopup} currentFolderPath={currentFolderPath} />}
    </div>
  );
};

export default ImagePopup;
