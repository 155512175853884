import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { auth, db } from '../../config/firebase';
import close from '../../assets/icons/former/close-icon.svg';

import '../../styles/frazerUpload/FrazerUpload.css';
import { apiCall } from '../../service/commonService';
import { Buffer } from 'buffer';
import {
  collection,
  addDoc,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';

function UnlinkSite({
  siteId, siteName, closeFun, btn, domainName, title,
}) {
  const user = auth.currentUser;

  const [success, setSuccess] = useState(null);
  const [inputVal, setInputVal] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const noteVal = 'Nota: Esta ação é irresistível.';
  const deleteDocument = async (event) => {
    console.log('calling this');
    event.preventDefault();
    try {
      // Get the user token
      let userToken;
      try {
        userToken = await user.getIdToken();
      } catch (error) {
        console.log('Authentication error:', error);
        return;
      }

      // Call your API endpoint to remove the user
      const userData = {
        siteId: Buffer.from(siteId).toString('base64'),
        userToken,
      };
      const response = await apiCall(
        'post',
        'cloudpanel/api/verify/removeuser',
        userData,
      );
      const { data } = response;
      const succeeded = data?.data || data;

      if (succeeded.success) {
        closeFun(false);
        // Delete the document from Firebase Firestore
        await deleteDoc(doc(db, 'sites', siteId));
        setSuccess(succeeded.message);
      } else {
        // Handle failure case
        console.error('Failed to delete the document:', succeeded.error);
      }
    } catch (error) {
      // Handle error
      console.error('Error deleting the document:', error);
    }
  };

  const deleteSiteFirebase = async (event) => {
    // let document = db.collection('todos').doc(`${request.params.todoId}`);
    // document.update(request.body)
  };

  return (
    <div className="frazer-container">
      <div className="frazer-box">
        <div className="frazer-upload-header">
          <h2>{title}</h2>
          <img onClick={() => closeFun(false)} src={close} alt="" />
        </div>

        <div className="hr80" />

        <form onSubmit={deleteDocument}>
          <div className="frazer-input">
            <label htmlFor="siteName">Do you want to unlink the domain?</label>
            {/* <input
              type="text"
              id="siteName"
              placeholder={placeholder}
              value={siteName}
              disabled={true}
              required
            /> */}
          </div>
          <div className="frazer-input">
            <label>
              The domain
              {' '}
              <span style={{ color: '#00A3FF' }}>{domainName}</span>
              {' '}
              will stop working, but the pages will be visible under
              <span style={{ color: '#11D167' }}>
                {' '}
                {siteName}
                .
              </span>
            </label>
          </div>

          {/* <div className='frazer-input'>
                        <label htmlFor="siteName">Site Type:</label>
                        <input type="text" id="siteName" value={siteType} onChange={(e) => setSiteType(e.target.value)} required />

                    </div>
                    <div className='ml-5 mb-5'>

                        <input className='mr-5' type="checkbox" id="useCloaker" checked={useCloaker} onChange={handleUseCloakerChange} />
                        <label htmlFor="useCloaker">Use Cloaker</label>
                    </div> */}

          <div className="hr80" />

          <div className="fazer-btn">
            <p onClick={() => closeFun(false)}>Cancelar</p>
            <button
              style={{
                border: '1px solid #FFE81A',
                color: '#FFE81A',
                background: '#212129',
                padding: '0.1rem 1.2rem',
                borderRadius: '3px',
              }}
              type="submit"
            >
              {btn}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UnlinkSite;
