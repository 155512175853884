import React, { useState } from 'react';
import { SideBarHome } from '..';
import '../../styles/atomicPlayer/AtomicPlayer.css';

import checkActive from '../../assets/icons/menuVideos/check-active.svg';
import check from '../../assets/icons/menuVideos/check.svg';
import fileIcon from '../../assets/icons/former/file-icon.svg';

import Popup2 from '../popup/Popup2';
import NotePopup from '../popup/NotePopup';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const AtoomicPlayerComponent = ({ btn }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [activeBtn, setActiveBtn] = useState('Autoplay');
  const [title, setTitle] = useState('Magic Autoplay');
  const { t } = useTranslation();
  const [desc, setDesc] = useState([
    'Com o Magic Autoplay, você nunca mais vai perder a oportunidade de apresentar seu vídeo para seus leads.',
    'Sua VSL carregará automaticamente em qualquer dispositivo, enquanto uma animação atraente incentiva o espectador a dar o play.',
    'Quando o play é dado, o vídeo volta ao início, garantindo que ele assista o conteúdo completo.',
    'Aproveite os benefícios do Magic Autoplay e veja sua taxa de engajamento e conversão aumentarem como mágica!',
  ]);
  const [noteVal, setNoteVal] = useState(['Nota: Esta ação é irresistível.']);
  const [gif, setGif] = useState(
    'https://img.imageboss.me/atm/cdn/animation:true/u/jZBMpCBanOb4g3SWQQna9kAJOaT2/l/LrxhiA1631246.gif',
  );
  const handleBtnClick = (btnname, title, gif2, descVal, noteVal) => {
    setTitle(title);
    setActiveBtn(btnname);
    setGif(gif2);
    setDesc(descVal);
    setNoteVal(noteVal);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {/* {showPopup && <Popup2 title ={'Fazer upload'}label = {'Nome do vídeo'} placeholder = {'Dê um nome ao vídeo'} btn= {'Fazer upload'} fileupload = 'Escolher arquivo...' closeFun={setShowPopup} />} */}
      {showPopup && (
        <NotePopup
          title="Criar produto"
          label="Criar um novo produto"
          placeholder="Digite o nome do produto"
          btn="Confirmar"
          noteVal="Hello Bro"
          closeFun={setShowPopup}
        />
      )}
      <SideBarHome />
      <div className="atomicplayer-container">
        <div className="atomicplayer-header">
          <p>
            {t('signature')}
            {' '}
            - AtomicPlayer
          </p>

          {btn && (
            <button onClick={() => setShowPopup(true)}>
              <img src={fileIcon} alt="" />
              {' '}
              {t('playerButtonText')}
            </button>
          )}
        </div>

        <div className="atomicplayer-middle">
          <h2>{t('atomicplayerMiddleHead')}</h2>

          <div className="atomicplayer-middle-content">
            <div className="atomicplayer-middle-first">
              <button
                onClick={() => handleBtnClick(
                  'Autoplay',
                  'Magic Autoplay',
                  'https://img.imageboss.me/atm/cdn/animation:true/u/jZBMpCBanOb4g3SWQQna9kAJOaT2/l/LrxhiA1631246.gif',
                  [
                    'Com o Magic Autoplay, você nunca mais vai perder a oportunidade de apresentar seu vídeo para seus leads.',
                    'Sua VSL carregará automaticamente em qualquer dispositivo, enquanto uma animação atraente incentiva o espectador a dar o play.',
                    'Quando o play é dado, o vídeo volta ao início, garantindo que ele assista o conteúdo completo.',
                    'Aproveite os benefícios do Magic Autoplay e veja sua taxa de engajamento e conversão aumentarem como mágica!',
                  ],
                )}
                className={activeBtn === 'Autoplay' && 'activebtn'}
              >
                Magic Autoplay
              </button>
              <button
                className={activeBtn === 'Progress' && 'activebtn'}
                onClick={() => handleBtnClick(
                  'Progress',
                  'Magic Progress',
                  'https://img.imageboss.me/atm/cdn/animation:true/u/jZBMpCBanOb4g3SWQQna9kAJOaT2/l/onDcPp3516687.gif',
                  [
                    'Com o Magic Progress, você pode aumentar significativamente a retenção e conversão em seus vídeos de vendas.',
                    'Pois a barra de progresso avança mais rapidamente no início do vídeo e depois desacelera, dando a impressão de que o conteúdo é mais curto e fácil de consumir.',
                    'Seus leads ficarão mais engajados e mais propensos a assistir até o final, o que aumentará a sua conversão.',
                    'Experimente a mágica do Magic Progress agora mesmo!',
                  ],
                )}
              >
                Magic Progress
              </button>
              <button
                className={activeBtn === 'Sync' && 'activebtn'}
                onClick={() => handleBtnClick(
                  'Sync',
                  'Magic Sync',
                  'https://img.imageboss.me/atm/cdn/animation:true/u/jZBMpCBanOb4g3SWQQna9kAJOaT2/l/aAGBMj0348576.gif',
                  [
                    'O Magic Sync exibe o botão de ação no momento exato do vídeo, aumentando a conversão do seu vídeo de vendas.',
                    'Diferente do delay tradicional, ele é sincronizado com o conteúdo do vídeo, garantindo que o botão seja exibido mesmo se o lead pausar o vídeo.',
                    'Com o Magic Sync, você tem controle total sobre o momento em que seu call-to-action é exibido, aumentando a eficácia de sua estratégia de marketing de vídeo.',
                  ],
                )}
              >
                Magic Sync
              </button>

              <button
                className={activeBtn === 'Thumbnail' && 'activebtn'}
                onClick={() => handleBtnClick(
                  'Thumbnail',
                  'Magic Thumbnail',
                  '	https://img.imageboss.me/atm/cdn/animation:true/u/jZBMpCBanOb4g3SWQQna9kAJOaT2/l/raBbyu4484661.gif',
                  [
                    'Com a Magic Thumbnail, você pode aumentar a chance de seus leads retornarem ao vídeo após uma pausa.',
                    'Essa ferramenta exibe uma imagem personalizada atraente sempre que o vídeo é pausado, incentivando o espectador a retomar a reprodução e continuar assistindo.',
                    'Não deixe seus leads perderem o interesse quando pausarem seu vídeo - mantenha-os engajados com o poder da Magic Thumbnail!',
                  ],
                )}
              >
                Magic Thumbnail
              </button>

              <button
                className={activeBtn === 'Analytics' && 'activebtn'}
                onClick={() => handleBtnClick(
                  'Analytics',
                  'Analytics',
                  'https://img.imageboss.me/atm/cdn/animation:true/u/jZBMpCBanOb4g3SWQQna9kAJOaT2/l/xXEpmC4691909.gif',
                  [
                    'Acompanhe as métricas do seu vídeo: impressões totais, impressões únicas, quantidade de plays, play rate e taxa de engajamento.',
                    'Além disso, veja no gráfico a curva de retenção do seu vídeo de vendas.',
                    'Assim, você sabe exatamente onde o seu lead está abandonando o vídeo, e pode ajudar a copy para aumentar a retenção e consequentemente as suas vendas.',
                  ],
                )}
              >
                Analytics
              </button>
            </div>

            <MiddleCard title={title} gif={gif} desc={desc} />
          </div>
        </div>

        <div className="atomicPlayer-plan">
          <p className="atomicPlayer-plan-heading">{t('atomicPlayerPlan')}</p>

          <div className="atomicPlayer-plan-category">
            <button>{t('monthlyPlan')}</button>

            <button>
              {t('annualPlan')}
              {' '}
              <span>-50%</span>
            </button>
          </div>

          <div className="atomicPlayer-plan-list">
            <PlanCard
              title="STARTER"
              features={[
                '10 mil plays mensais',
                'R$0,02 por play excedente',
                'Magic Autoplay',
                'Magic Progress',
                'Magic Sync',
                'Magic Thumbnail',
                'Powered by Cloudflare ®',
              ]}
            />
            <PlanCard
              features={[
                '10 mil plays mensais',
                'R$0,02 por play excedente',
                'Magic Autoplay',
                'Magic Progress',
                'Magic Sync',
                'Magic Thumbnail',
                'Powered by Cloudflare ®',
              ]}
              btncolor="linear-gradient(315deg, #8672FB 0.94%, #60F0D3 48.36%, #A8D916 121.83%)"
              title="PROFESSIONAL"
            />
            <PlanCard
              features={[
                '10 mil plays mensais',
                'R$0,02 por play excedente',
                'Magic Autoplay',
                'Magic Progress',
                'Magic Sync',
                'Magic Thumbnail',
                'Powered by Cloudflare ®',
              ]}
              title="BUSINESS"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MiddleCard = ({ title, gif, desc }) => (
  <div className="atomicplayer-middle-last">
    <div className="player-banner">
      <img src={gif} alt="" />
    </div>

    <div>
      <h3>{title}</h3>
      <div>
        {desc.map((item, i) => (
          <p key={i}>{item}</p>
        ))}
      </div>
    </div>
  </div>
);

const PlanCard = ({
  title, color, bg, active, btncolor, features,
}) => (
  <div className={`atomicPlayer-plan-list-item ${active}`}>
    <button
      style={{ background: bg }}
      className="atomicPlayer-plan-list-item-title"
    >
      {title}
    </button>

    <div className="hr80" />

    <div className="price">
      <p className="actual-price">
        {t('from')}
        {' '}
        <span>R$1164,00</span>
        {' '}
        {t('forOnly')}
        :
      </p>
      <div className="offerPrice">
        <h1>R$697</h1>
        <p>
          /
          {t('perAnnum')}
        </p>
      </div>

      <p className="price-desc">
        {t('orUntil')}
        {' '}
        12x
        {t('from')}
        {' '}
        <span>R$69,70</span>
        {' '}
      </p>
    </div>
    <div className="hr80" />

    <div className="plan-feature">
      <ul>
        {features?.map((item, i) => (i === 0 ? (
          <li style={{ color: '#11D167' }}>
            <img src={checkActive} alt="" />
            {item}
          </li>
        ) : (
          <li>
            <img src={check} alt="" />
            {' '}
            {item}
          </li>
        )))}
      </ul>
    </div>

    <button
      style={
        btncolor && { background: btncolor, border: 'none', color: '#000000' }
      }
      className="plan-btn"
    >
      {t('subscribeNow')}
    </button>
  </div>
);

export default AtoomicPlayerComponent;
