import './App.css';
import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import { onAuthStateChanged } from "@firebase/auth";
import LoginPage from "./modules/auth/LoginPage";
import SignUpPage from "./modules/auth/SignUpPage";
import RedefineSenha from "./modules/RedefineSenha";
import RedefineEmailSent from "./modules/RedefineEmailSent";
import ConfirmEmail from "./modules/auth/ConfirmEmail";
import LandingDashboard from "./modules/LandingDashboard";
import Confirmed from "./modules/auth/Confirmed";
import Builder from "./modules/Builder";
import Sites from "./modules/Sites";
import "./styles/loginPage/LoginPage.css";
import ParaSitesExternos from "./components/paraSitesExternos/ParaSitesExternos";
import NovaPagina from "./modules/NovaPagina";
import Paginas from "./modules/Paginas";
import Gerenciar from "./modules/Gerenciar";
import Popup from "./components/builder/main/popups/ImagePopup";
import AtomicPlayer from "./modules/AtomicPlayer";
import AtomicPlayerWithBtn from "./modules/AtomicPlayerWithBtn";
import MenuVideos from "./modules/MenuVideos";
import MeusVideos from "./modules/MeusVideos";
import { LoginContext } from "./contexts/ContextProvider";
import { auth } from "./config/firebase";
import { LeftComponentVideo } from "./components";
import NomeDoVideo from "./modules/NomeDoVideo";
import Tutorials from "./modules/Tutorials";
import Tutorial from "./components/tutorial/Tutorial";
function AppRoutes() {
  const { user, setUser } = useContext(LoginContext);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  return (
    <Routes>
      <Route exact path="/" />
      <Route exact path="/builder" Component={Builder} />

      <Route
        exact
        path="/new"
        element={user ? <NovaPagina /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/builder"
        element={user ? <Builder /> : <Navigate to="/login" replace />}
      />
      <Route
        path="/builder/:id"
        element={user ? <Builder /> : <Navigate to="/login" replace />}
      />
      <Route
        exact
        path="/login"
        element={
          user ? (
            <Navigate to="/" replace />
          ) : (
            <VideoLayout>
              <LoginPage />
            </VideoLayout>
          )
        }
        redirectTo="/"
      />
      <Route
        exact
        path="/signup"
        element={
          user ? (
            <Navigate to="/" replace />
          ) : (
            <VideoLayout>
              <SignUpPage />
            </VideoLayout>
          )
        }
        redirectTo="/"
      />
      <Route
        exact
        path="/redefine"
        element={(
          <VideoLayout>
            <RedefineEmailSent />
          </VideoLayout>
        )}
      />
      <Route
        exact
        path="/redefinesenha/:actionCode"
        element={(
          <VideoLayout>
            <RedefineSenha />
          </VideoLayout>
        )}
      />
      <Route exact path="/confirmEmail" element={<ConfirmEmail />} />
      <Route exact path="/reset" element={<Confirmed />} />
      <Route
        exact
        path="/sites"
        element={user ? <Sites /> : <Navigate to="/login" replace />}
      />
      <Route
        exact
        path="/site/:id"
        element={user ? <Paginas /> : <Navigate to="/login" replace />}
      />
      <Route exact path="/atomicPlayer" element={<AtomicPlayer />} />
      <Route
        exact
        path="/atomic-player-withbtn"
        element={<AtomicPlayerWithBtn />}
      />
      <Route
        exact
        path="/video"
        element={user ? <MenuVideos /> : <Navigate to="/login" replace />}
      />
      <Route
        path="*"
        element={
          user ? (
            <Navigate to="/video/:id" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="*"
        element={
          user ? <Navigate to="/" replace /> : <Navigate to="/login" replace />
        }
      />

      <Route
        exact
        path="/videos"
        element={user ? <MeusVideos /> : <Navigate to="/login" replace />}
      />
      <Route
        path="*"
        element={
          user ? (
            <Navigate to="/meus-videos" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />

      {/* ParaSitesExternos */}

      <Route exact path="/ParaSitesExternos" element={<ParaSitesExternos />} />
      <Route
        path="*"
        element={
          user ? (
            <Navigate to="/ParaSitesExternos" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />

      <Route exact path="/nome-do-video" element={<NomeDoVideo />} />
      <Route
        path="*"
        element={
          user ? (
            <Navigate to="/nome-do-video/:id" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        exact
        path="/sites/manage"
        element={user ? <Gerenciar /> : <Navigate to="/login" replace />}
      />
      <Route
        exact
        path="/imageLibrary"
        element={user ? <Popup /> : <Navigate to="/login" replace />}
      />
      <Route 
      exact 
      path= '/tutorial'
      element = {user? <Tutorials/> : <Navigate to="/login" replace/> }
      />
       {/* <Route
        path="*"
        element={
          user ? (
            <Navigate to="/tutorial/:id" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      /> */}
       <Route
        path="/tutorial/:id"
        element={user ? <Tutorial /> : <Navigate to="/login" replace />}
      />
    </Routes>
    
  );
}

function VideoLayout({ children }) {
  return (
    <div className="body-container">
      <LeftComponentVideo />
      {children}
    </div>
  );
}

export default AppRoutes;
