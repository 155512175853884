import React, { useRef } from 'react';
import '../../../../styles/builder/ButtonEdit.css';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsSliders } from 'react-icons/bs';
import {
  fontSizeOptions, targetOptions, alignOptions, nonDigitStyles,
  responsiveIcons,
} from '../../../../helpers/Builder/helperArrays';
import CustomButton from '../../../customButton/CustomButton';
import DropDown from './DropDown';

const ButtonEdit = ({
  setselectedtab, propValue, propChange, propMiscValue, propMiscChange, styleValue, styleChange, device, setDevice, setResponsiveMode, responsiveData, responsiveMode,
}) => {
  const tituloRef = useRef(null);
  const tipografia = useRef(null);
  const changeObjectStyle = (style, position, e) => {
    const positionValue = e.target.value;
    const newStyleObject = {
      target: {
        value: {
          ...styleValue(style),
          [position]: positionValue,
        },
      },
    };
    styleChange(style, newStyleObject);
  };

  return (
    <div className="buttonEdit">
      <span
        className="titulo"
        onClick={(e) => {
          const { nextSibling } = e.target;
          if (nextSibling.style.transform == 'rotate(180deg)') {
            nextSibling.style.transform = 'rotate(0deg)';
          } else {
            nextSibling.style.transform = 'rotate(180deg)';
          }

          e.target.parentNode.children[2].classList.toggle('hidden');
        }}
        ref={tituloRef}
      >
        Titulo:
        {' '}
      </span>
      <MdKeyboardArrowDown
        color="#72728F"
        className="arrowUpIcon"
        onClick={(e) => {
          tituloRef.current.click();
        }}
      />
      <div className="pt-1 pb-6" style={{ borderBottom: '2px solid #ddd' }}>
        <div className="py-1">
          <span className="spanTitle">Campo de texto</span>
          <textarea
            onChange={(e) => {
              propChange('content', e);
            }}
            rows="4"
            value={propValue('content')}
            style={{
              outline: 0,
              border: '1px solid #ddd',
              borderRadius: '5px',
              width: '100%',
              fontSize: '12px',
              color: '#72728F',
              paddingLeft: '5px',
            }}
          >
            {propValue('content')}
          </textarea>
        </div>
        <div className="py-1">
          <span className="spanTitle">Link</span>
          <input
            style={{
              outline: 0,
              border: '1px solid #ddd',
              borderRadius: '5px',
              width: '100%',
              paddingLeft: '5px',
              fontSize: '13px',
              color: '#72728f',
              padding: '5px 5px',
            }}
            type="text"
            value={propMiscValue('href')}
            onChange={(e) => {
              propMiscChange('href', e);
            }}
          />

          <div className="py-1" style={{ marginTop: '0.7rem' }}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              className="spanTitle"
            >
              <label htmlFor="target" className="spanTitle">
                Abrir em nova guia
              </label>
              <select
                style={{
                  fontSize: '13px',
                  border: '1px solid #ddd',
                  textAlign: 'center',
                  outline: 0,
                  color: '#72728F',
                }}
                className="px-2"
                name="target"
                id="target"
                onChange={(e) => {
                  propMiscChange('target', e);
                }}
              >
                {targetOptions.map((target) => (
                  <option
                    value={target.value}
                    selected={propMiscValue('target') == target.value}
                  >
                    {target.response}
                  </option>
                ))}
              </select>
            </span>
          </div>
        </div>
        <div className="py-1">
          <span className="flex justify-between center-align relative" style={{ color: '#72728F', fontSize: '13px' }}>
            <label htmlFor="textType" style={{ color: '#72728F', fontSize: '13px' }}>Alinhamento</label>
            {/* <div className='flex flex-col items-center justify-center'></div> */}
            <DropDown responsiveData={responsiveData} responsiveMode={responsiveMode} setDevice={setDevice} setResponsiveMode={setResponsiveMode} position="40%" />
            <select
              style={{
                fontSize: '13px',
                border: '1px solid #ddd',
                textAlign: 'center',
                outline: 0,
                color: '#72728F',
                width: '5rem',
              }}
              className="px-2"
              name="textType"
              id="textType"
              onChange={(e) => {
                changeObjectStyle('textAlign', device, e);
                styleChange('currentTextAlign', e);
              }}
            >
              {alignOptions.map((align) => <option value={align} selected={styleValue('textAlign')[device] === align}>{align}</option>)}
            </select>
          </span>
        </div>
      </div>

      <div className="py-4 ">
        <span
          className="tipografia"
          onClick={(e) => {
            const { nextSibling } = e.target;
            if (nextSibling.style.transform == 'rotate(180deg)') {
              nextSibling.style.transform = 'rotate(0deg)';
            } else {
              nextSibling.style.transform = 'rotate(180deg)';
            }

            e.target.parentNode.children[2].classList.toggle('hidden');
          }}
          ref={tipografia}
        >
          Tipografia
        </span>
        <MdKeyboardArrowDown
          color="#72728F"
          className="arrowUpIcon"
          onClick={(e) => {
            tipografia.current.click();
          }}
        />
        <div style={{ transition: 'all 0.2s ease-in-out' }}>
          <div className="py-1 flex items-center justify-between">
            <span className="cor">Cor</span>
            <input
              type="color"
              className="w-7 h-7"
              onChange={(e) => {
                styleChange('color', e);
              }}
              value={styleValue('color')}
            />
          </div>
          <div className="py-1 flex items-center justify-between">
            <span className="spanTitle">Cor de fundo</span>
            <input
              type="color"
              className="w-7 h-7"
              onChange={(e) => {
                styleChange('backgroundColor', e);
              }}
              value={styleValue('backgroundColor')}
            />
          </div>
          <div className="py-1 flex items-center justify-between">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Hover Cor</span>
            <input
              type="color"
              className="w-7 h-7"

              onChange={(e) => {
                styleChange('hoverColor', e);
              }}
              value={styleValue('hoverColor')}
            />
          </div>
          <div className="py-1 flex items-center justify-between">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Hover Cor de fundo</span>
            <input
              type="color"
              className="w-7 h-7"

              onChange={(e) => {
                styleChange('hoverBgColor', e);
              }}
              value={styleValue('hoverBgColor')}
            />
          </div>
          <div className="py-1">
            <span className="spanTitle">Contorno</span>
          </div>
          <div className="py-1">
            <span
              className="flex justify-between center-align"
              style={{
                color: '#72728F',
                fontSize: '13px',
                textTransform: 'capitalize',
              }}
            >
              <label htmlFor="borderStyle" className="spanTitle">
                Tamanho
              </label>
              <select
                style={{
                  fontSize: '13px',
                  border: '1px solid #ddd',
                  textAlign: 'center',
                  outline: 0,
                  color: '#72728F',
                }}
                className="px-2"
                name="borderStyle"
                id="borderStyle"
                onChange={(e) => {
                  styleChange('fontSize', e);
                }}
              >
                {fontSizeOptions.map((style) => (
                  <option
                    value={style}
                    selected={styleValue('fontSize') === style}
                  >
                    {style}
                  </option>
                ))}
              </select>
            </span>
          </div>
        </div>
        <div className="customButtonComponent">
          <CustomButton
            loading={false}
            disabled={false}
            textColor="#fff"
            style={{ textAlign: 'center' }}
            Icon={BsSliders}
            buttontext="Editar tipografia"
            backgroundColor="#62627A"
            variant="contained"
            onClick={() => {
              setselectedtab('slider');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ButtonEdit;
