import React, {
  useState, useRef, useContext, useEffect,
} from 'react';

import '../../styles/loginPage/LoginPage.css';
import '../../styles/signUpPage/SignUpPage.css';
import GoogleLogin from 'react-google-login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope, faLock, faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';

import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { faUser } from '@fortawesome/fontawesome-free-solid';
import { IoMdAlert } from 'react-icons/io';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { signup } from '../../service/api';
import { LoginContext } from '../../contexts/ContextProvider.js';

import {
  auth, signInWithPopup, googleProvider, query, collection, where, db, getDocs, addDoc, Timestamp,
} from '../../service/auth/authService.js';
import LeftComponentVideo from '../../components/leftComponentVideo/LeftComponentVideo';
import '../../styles/global.css';

const SignUpPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [ISDcode, setISDcode] = useState(null);
  const [contact, setContact] = useState(null);
  const [validContact, setValidContact] = useState(false);
  const [validISD, setValidISD] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const reCaptchaRef = useRef();
  const navigate = useNavigate();
  const [dispError, setDispError] = useState('none');
  const [errMessage, setErrMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { setUser } = useContext(LoginContext);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    document.body.classList.add('custom-background');
    return () => {
      document.body.classList.remove('custom-background');
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validateEmail(email)) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage('The email is not of required format');
    }
    if (!validISD) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage('Wrong ISD format');
    }
    if (!validateContact) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage('Wrong contact format');
    }
    if (!validPassword) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage('Password should be greater than 8 characters');
    }
    console.log(`${name} ${email} ${password} ${ISDcode} ${contact}`);
    const token = await reCaptchaRef.current.executeAsync();
    // const token =reCaptchaRef.current.getValue();
    try {
      const response = await signup({
        token,
        email,
        password,
        name,
      });
      console.log(`Success${response.data.success} ` + `Message:${response.data.message}`);
      setIsLoading(false);
      setIsLoaded(true);
      setTimeout(() => {
        navigate('/confirmEmail');
      }, 3000);
    } catch (err) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage('An error occurred. Please try again later.');
    }
    reCaptchaRef.current.reset();
    setName('');
    setEmail('');
    setPassword('');
    setISDcode('');
    setContact('');
  };

  async function signInWithGoogle() {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const { user } = res;
      const q = query(collection(db, 'users'), where('email', '==', user.email));
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        const timestamp = Timestamp.now();
        await addDoc(collection(db, 'users'), {
          id: user.uid,
          online: false,
          name: user.displayName,
          plan: {
            name: 'free',
            credits: 0,
            renewalDate: timestamp,
          },
          email: user.email,
          // isReferred: req.body.isReferred,
          // referrer: req.body.referrer,
          // influencer: req.body.influencer,
          createdAt: timestamp,
          // phone: req.body.phone,
          // paidMedia: req.body.paidMedia,
          utm: null,
          products: [],
          customTags: [],
          newSetup: true,
        });
      }
      setUser(user);
      navigate('/');
    } catch (err) {
      setDispError('flex');
      setErrMessage(`Error signing in : ${err}`);
    }
  }

  const formError = () => {
    console.log('error');
  };

  const validateContact = (e) => {
    const num = e.target.value;
    if (num.length == 10) {
      setValidContact(true);
    } else {
      setValidContact(false);
    }
  };

  const validateISD = (e) => {
    const ISD = e.target.value;
    if (ISD.length == 2) {
      setValidISD(true);
    } else {
      setValidISD(false);
    }
  };

  const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const validatePassword = (e) => {
    const pass = e.target.value;
    if (pass.length >= 8) setValidPassword(true);
    else setValidPassword(false);
  };

  const formatPhone = (num) => {
    num = num.replace(' ', '').replace('(', '')
      .replace(')', '').replace('-', '')
      .replace('.', '')
      .replace('+', '')
      .replace(/\D+/g, '');
    if (num.length > 3) {
      num = num[0] !== '(' ? `(${num}` : num;
      num = num[2] !== ')' ? `${num.slice(0, 3)})${num.slice(3)}` : num;
      num = num[4] !== ' ' ? `${num.slice(0, 4)} ${num.slice(4)}` : num;
    }
    if (num.length > 10) {
      num = num[10] !== '-' ? `${num.slice(0, 10)}-${num.slice(10)}` : num;
    }
    return num;
  };
  const buttonClassName = `EnterButton${isLoading ? ' button--loading' : isLoaded ? ' button--loaded' : ''}`;

  return (
  // <div className='body-container'>
  //   {/* <LeftComponentVideo/> */}
    <div className="right-component">
      <form id="signupForm" className="formStyle" onSubmit={handleSubmit} onError={formError}>
        <h1 className="headingStyle">Crie sua conta!</h1>
        <p className="hyperLinkStyle">
          Já tem uma conta?
          <strong onClick={() => { navigate('/login'); }}>Clique aqui</strong>
        </p>
        <div className="flexDisplay">
          <label className="labelInput" htmlFor="name">Nome completo</label>
          <input
            className="inputDetails"
            type="text"
            id="name"
            placeholder="Digite seu nome completo"
            value={name}
            onChange={(e) => { setName(e.target.value); }}
            required
          />
        </div>
        <div className="flexDisplay">
          <label className="labelInput" htmlFor="email">E-mail</label>
          <input
            className="inputDetails"
            type="text"
            id="email"
            placeholder="Digite seu e-mail"
            value={email}
            onChange={(e) => { setEmail(e.target.value); }}
            required
          />
        </div>
        <div className="flexDisplay">
          <label className="labelInput" htmlFor="password">Senha</label>
          <input
            className="inputDetails"
            type={showPassword ? 'text' : 'password'}
            id="password"
            placeholder="Digite sua senha"
            value={password}
            onChange={(e) => { setPassword(e.target.value); validatePassword(e); }}
            required
          />
          {/* <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              className="togglePassword-icon-signup"
              onClick={handleTogglePassword}
            /> */}
        </div>
        <div className="flexDisplay">
          <label className="labelInput" htmlFor="telephone">Telefone</label>
          <div className="TelephoneField">
            <input
              className="inputDetailsTel"
              type="tel"
              id="ISD"
              placeholder="+55"
              maxLength="2"
              value={ISDcode}
              onChange={(e) => { setISDcode(e.target.value); validateISD(e); }}
              required
            />
            <input
              className="inputDetailsTel"
              type="tel"
              id="Contact"
              placeholder="(00)00000-0000"
              maxLength="15"
              value={contact}
              onChange={(e) => { validateContact(e); setContact(formatPhone(e.target.value)); }}
              required
            />
          </div>
        </div>
        <ReCAPTCHA
          ref={reCaptchaRef}
            // sitekey='6LfVo6glAAAAAI4Ab0lUudzd_tIgYYzgeennEN4R'
          sitekey="6LfNkKklAAAAAEidji8V65sG6EogfoK-lW-9qUbW"
          theme="dark"
          size="invisible"
        />
        <div className="checkBox">
          <input type="checkbox" id="checkBox" />
          <label className="lembrar-signup">
            Aceitar
            <span>Termos e condições</span>
            {' '}
            e
            <span>Política de privacidade</span>
          </label>
        </div>
        <button type="submit" className={buttonClassName}>
          <span className="button-text">{isLoaded ? <IoCheckmarkCircleOutline /> : null}</span>
          <span className="button-text">{isLoading ? 'Carregando...' : isLoaded ? 'Conta criada com sucesso!' : 'Criar uma conta'}</span>
        </button>
        <button type="button" className="google-login" onClick={signInWithGoogle}>
          <span className="google-icon" />
          <span className="google-button">Fazer login com o google</span>
        </button>
        <div className="errorBlock" style={{ display: dispError }}>
          <div className="alert">
            <IoMdAlert />
          </div>
          <div>
            <p className="errorMessage">{errMessage}</p>
          </div>
        </div>
      </form>
    </div>
  // </div> */}
  );
};

export default SignUpPage;
