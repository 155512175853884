import React, { useEffect, useContext, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  auth, collection, db, getDocs,
} from '../service/auth/authService.js';
import { LoginContext } from '../contexts/ContextProvider.js';
import '../styles/global.css';
import '../styles/sideBarHome/SideBarHome.css';
import notification from '../assets/icons/landingDashboard/Notification.svg';
import arrow from '../assets/icons/landingDashboard/Arrow.svg';
import SearchComponent from '../assets/icons/paginas/SearchComponent.svg';
import serverLogo from '../assets/icons/landingDashboard/server.svg';
import suggest from '../assets/icons/landingDashboard/📨 Sugestões.svg';
import plusLogo from '../assets/icons/landingDashboard/Plus.svg';
import plusLogoBlack from '../assets/icons/landingDashboard/Add.svg';
import roundPlusLogo from '../assets/icons/landingDashboard/roundAdd.svg';
import avatarLogo from '../assets/icons/landingDashboard/Avatar.svg';
import CrossIcon from '../assets/icons/builder/Cross.svg';
import optionLogo from '../assets/icons/paginas/Options.svg';
import component1 from '../assets/images/landingDashboard/Rectangle 3.png';
import threeDots from '../assets/icons/landingDashboard/ThreeDot.svg';
import attachLogo from '../assets/icons/sites/Attach.svg';
import { StyleContext } from '../contexts/StyleProvider.js';

import { setUserData } from "../redux/action/userAction.js";
import { CreateSite, SideBarHome } from "../components/index.js";
import { useTranslation } from "react-i18next";
import { useCollection } from "../custom-hooks/useCollection.js";
import ModalSite from "../components/site/ModalSite.js";
import { SelectTag } from "../components/tags/SelectTag.js";
import NotifcationBar from "../components/landingDashboard/NotifcationBar.js";

const LandingDashboard = (props) => {
  const { setUser } = useContext(LoginContext);
  const [showHiddenInput, setShowHiddenInput] = useState(false);
  const [search, setSearch] = useState('');
  const [sites, setSites] = useState([]);
  const [tags, setTags] = useState([]);
  const [pages, setPages] = useState([]);
  const [createSitePopup, setCreateSitePopup] = useState(false);
  const [createTagPopup, setCreateTagPopup] = useState(false);
  const navigate = useNavigate();
  const user = useContext(LoginContext);
  const { t } = useTranslation();
  const { isDarkModeEnabled, setIsDarkModeEnabled } = useContext(StyleContext);
  const { documents: sitesDocuments } = useCollection('sites');
  const { documents: tagsDocuments } = useCollection('tags');
  const { documents: pagesDocuments } = useCollection('pages2');

  useEffect(() => {
    setSites([...sitesDocuments]);
    setTags([...tagsDocuments]);
    setPages([...pagesDocuments]);
    console.log('>>>>>.', sitesDocuments);
  }, [sitesDocuments, tagsDocuments, pagesDocuments]);

  const handleToggle = () => {
    setIsDarkModeEnabled(!isDarkModeEnabled);
  };

  const handleSitePopup = () => {
    setCreateSitePopup(true);
  };

  const handleTagPopup = () => {
    // setDocId(id);
    // setSelectedPage(item);
    setCreateTagPopup(!createTagPopup);
    console.log(createTagPopup);
  };

  function handleLogOut() {
    console.log('Logging out...');
    signOut(auth);
    setUser(null);
    navigate('/');
  }

  useEffect(() => {
    console.log(user);
    document.body.classList.add('custom-background');
    return () => {
      document.body.classList.remove('custom-background');
    };
  }, []);

  return (
    <div className="LandingDashboard">
      {createSitePopup && (
        <ModalSite
          // siteId={siteId}
          // siteData={siteData}
          // func={toCreate}
          title="Criar site"
          label="Escolha um nome para o seu novo site."
          placeholder="Digite o nome do site"
          note="em seguida, você poderá conectar um domínio próprio a esse site."
          btn="Confirmar"
          closeFun={setCreateSitePopup}
        />
      )}
      {createTagPopup && (
        <SelectTag
          // selectedPage={selectedPage}
          tags={tags}
          // docId={docId}
          closeFun={handleTagPopup}
          title="Tag"
        />
      )}
      <SideBarHome />
      <div className="RightComponentLanding">
        <div className="headerHome">
          <p className="HeaderText">
            {t('hello')}
            {' '}
            {user?.user.displayName}
          </p>
          {/* <div className="toggleSwitch">
            <input
              type="checkbox"
              className="toggleSwitchCheckbox"
              id="toggleSwitch"
              onChange={handleToggle}
            />
            <label className="toggleSwitchLabel" htmlFor="toggleSwitch">
              <span className="toggleSwitchInner" />
              <span className="toggleSwitchSwitch" />
            </label>
          </div> */}
          <div className="HeaderIcon">
            <button className="buttonHeader">
              <img className="arrowIcon" src={arrow} alt="Arrow.svg" />
              <p>{t('buttonHeader')}</p>
            </button>
            <div className="frameNotificationProfile">
              <div className="notification">
                <img
                  className="notification-icon"
                  src={notification}
                  alt="notification.svg"
                />
              </div>
              <img src={avatarLogo} className="avatar" />
            </div>
          </div>
        </div>
        <div className="DivHome">
          <NotifcationBar
            note={t("notifyBar")}
            bgColor={"rgba(245, 212, 0, 0.1)"}
            leftBorderColor={"#ffe81a"}
          />
          <div className="frameGrid">
            <div className="CreateSite">
              <div className="Heading">
                <p>{t('mySites')}</p>
                <div className="searchComponent">
                  {showHiddenInput && (
                    <input
                      className={`hiddenInput ${
                        showHiddenInput ? ' moveLeft' : ' moveRight'
                      }`}
                      placeholder="Search"
                      value={search}
                      // autoFocus
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  )}
                  <div className="SearchIcon">
                    <img
                      src={!showHiddenInput ? SearchComponent : CrossIcon}
                      onClick={() => setShowHiddenInput(!showHiddenInput)}
                      style={{
                        zIndex: '9',
                        color: '#62627a !important',
                        cursor: 'pointer',
                        height: showHiddenInput ? '11px' : '16px',
                      }}
                      alt="SearchComponent.svg"
                    />
                    <button onClick={handleSitePopup}>
                      <img
                        className="plusIcon"
                        color="#000"
                        src={plusLogoBlack}
                        alt="SearchComponent.svg"
                      />
                      <p>{t('createSite')}</p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="NewPageComponent">
                {props.data.map((obj) => {
                  console.log(obj.email);
                })}

                {sites.map((item) => (
                  <>
                    <div className="NewPageFrame">
                      <div className="NewPageInner">
                        <div className="NewPageHeader">
                          <div className="HeaderComponent">
                            <p className="Site">{item.atomicDomain}</p>
                            <div className="Site-Url">
                              <img src={serverLogo} alt="server.svg" />
                              <p>www.atomicpage.com</p>
                            </div>
                          </div>
                          <div className="NewPageComponents">
                            {pages
                              .filter((i) => i.domain == item.atomicDomain)
                              .filter((i) => i.slug.includes(search))
                              .map((item) => {
                                return (
                                  <div className="NewPage">
                                    <img
                                      className="imageDesign"
                                      src={component1}
                                      alt="Component1.png"
                                    />
                                    <div className="NewPageDescComponent">
                                      <div className="NewPageDesc">
                                        <div className="NewPageTitleComponent">
                                          <div className="NewPageTitle">
                                            <div className="ellpise">
                                              <span>🟢</span>
                                            </div>
                                            <p>{item.slug}</p>
                                          </div>
                                          <img
                                            src={threeDots}
                                            alt="threedot.svg"
                                          />
                                        </div>
                                        <div className="NewPageTagComponent">
                                          {tags
                                            .filter(
                                              (i) => i.ownerId == item.ownerId
                                            )
                                            .map((tag) => {
                                              return (
                                                <div
                                                  style={{
                                                    border: `1px solid ${tag.tags.twColor}`,
                                                  }}
                                                  className="NewPageTag1"
                                                >
                                                  <p
                                                    style={{
                                                      color: `${tag.tags.color}`,
                                                    }}
                                                  >
                                                    {tag.tags.label}
                                                  </p>
                                                </div>
                                              );
                                            })}
                                          <img
                                            style={{ cursor: "pointer" }}
                                            onClick={handleTagPopup}
                                            src={roundPlusLogo}
                                          />
                                        </div>
                                      </div>
                                      <div className="NewPageUrlBody">
                                        <div className="NewPageUrl">
                                          <img
                                            src={attachLogo}
                                            alt="attach.svg"
                                          />
                                          <p>www.atomicpage.com</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="NewPageComponents">
                          {pages
                            .filter((i) => i.domain == item.atomicDomain)
                            .filter((i) => i.slug.includes(search))
                            .map((item) => (
                              <div className="NewPage">
                                <img
                                  className="imageDesign"
                                  src={component1}
                                  alt="Component1.png"
                                />
                                <div className="NewPageDescComponent">
                                  <div className="NewPageDesc">
                                    <div className="NewPageTitleComponent">
                                      <div className="NewPageTitle">
                                        <div className="ellpise">
                                          <span>🟢</span>
                                        </div>
                                        <p>{item.slug}</p>
                                      </div>
                                      <img
                                        src={threeDots}
                                        alt="threedot.svg"
                                      />
                                    </div>
                                    <div className="NewPageTagComponent">
                                      {tags
                                        .filter(
                                          (i) => i.ownerId == item.ownerId,
                                        )
                                        .map((tag) => (
                                          <div
                                            style={{
                                              border: `1px solid ${tag.tags.twColor}`,
                                            }}
                                            className="NewPageTag1"
                                          >
                                            <p
                                              style={{
                                                color: `${tag.tags.color}`,
                                              }}
                                            >
                                              {tag.tags.label}
                                            </p>
                                          </div>
                                        ))}
                                      <img
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleTagPopup}
                                        src={roundPlusLogo}
                                      />
                                    </div>
                                  </div>
                                  <div className="NewPageUrlBody">
                                    <div className="NewPageUrl">
                                      <img
                                        src={attachLogo}
                                        alt="attach.svg"
                                      />
                                      <p>www.atomicpage.com</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    {/* <div className="NewPageFrame">
                        <div className="NewPageInner">
                          <div className="NewPageHeader">
                            <div className="HeaderComponent">
                              <p className="Site">Site 02</p>
                              <div className="Site-Url">
                                <img src={serverLogo} alt="server.svg" />
                                <p>www.atomicpage2.com</p>
                              </div>
                            </div>
                            <div className="ButtonComponent">
                              <button className="NovaPagina">
                                <img src={plusLogo} alt="plus.svg" />
                                <p className="textButton">{t("newPage")}</p>
                              </button>
                              <div className="optionComponent">
                                <img src={optionLogo} alt="option.svg" />
                              </div>
                            </div>
                          </div>
                          <div className="NewPageComponents">
                            {[...new Array(5)].map(() => {
                              return (
                                <div className="NewPage">
                                  <img
                                    className="imageDesign"
                                    src={component1}
                                    alt="Component1.png"
                                  />
                                  <div className="NewPageDescComponent">
                                    <div className="NewPageDesc">
                                      <div className="NewPageTitleComponent">
                                        <div className="NewPageTitle">
                                          <div className="ellpise">
                                            <span>🟢</span>
                                          </div>
                                          <p> {t("newPage")} 01</p>
                                        </div>
                                        <img
                                          src={threeDots}
                                          alt="threedot.svg"
                                        />
                                      </div>
                                      <div className="NewPageTagComponent">
                                        <div className="NewPageTag1">
                                          <p>tag-01</p>
                                        </div>
                                        <div className="NewPageTag2">
                                          <p>tag-02</p>
                                        </div>
                                        <img src={roundPlusLogo} />
                                      </div>
                                    </div>
                                    <div className="NewPageUrlBody">
                                      <div className="NewPageUrl">
                                        <img
                                          src={attachLogo}
                                          alt="attach.svg"
                                        />
                                        <p>www.atomicpage.com</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div> */}
                  </>
                ))}
              </div>
            </div>
            <div className="NewsAndSuggestComponent">
              <div className="NewsAndSuggestFrame">
                <p>
                  🔥
                  {t('frameHeading')}
                </p>
                <div className="NewsContainer">
                  <div className="NewsInnerContainer">
                    <div className="NewsHeading">
                      <p className="NewsSubHeading">{t('frameSubHeading')}</p>
                      <span>
                        <p className="">{t('frameDescPt1')}</p>
                        <p className="">{t('frameDescPt2')}</p>
                        <p className="">{t('frameDescPt3')}</p>
                        <p className="">{t('frameDescPt4')}</p>
                        <p className="">{t('frameDescPt5')}</p>
                      </span>
                    </div>
                    <span className="NewsFooter">{t('postedOn')}</span>
                  </div>
                </div>
                <div className="NewsContainer">
                  <div className="NewsInnerContainer">
                    <div className="NewsHeading">
                      <p className="NewsSubHeading">{t('frameSubHeading')}</p>
                      <span>
                        <p className="">{t('frameDescPt1')}</p>
                        <p className="">{t('frameDescPt2')}</p>
                        <p className="">{t('frameDescPt3')}</p>
                        <p className="">{t('frameDescPt4')}</p>
                        <p className="">{t('frameDescPt5')}</p>
                      </span>
                    </div>
                    <span className="NewsFooter">{t('postedOn')}</span>
                  </div>
                </div>
              </div>
              <div className="NewsAndSuggestFrame">
                <p>
                  <img src={suggest} />
                  {' '}
                </p>
                {/* {t("suggestions")} */}
                <div className="suggestion-Container">
                  <div className="inputAndLabelComponent">
                    <label className="inputLabelSugg">
                      {t('suggestLabel')}
                    </label>
                    <div className="suggestionBox">
                      <p>{t('suggestPlaceholder')}</p>
                    </div>
                  </div>
                  <button className="suggestionButton">
                    {t('suggestionButton')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.userReducer.data,
});
export default connect(mapStateToProps, {
  setUserData,
})(LandingDashboard);
