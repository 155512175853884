import React, { useState, useEffect, useRef } from "react";

import Select from "react-select";
import chroma from "chroma-js";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  where,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { SideBarHome } from "..";

// import '../styles/global.css';
// import '../styles/SideBarHome.css';
import "../../styles/videos/MenuVideosComponent.css";
import Banner from "../../assets/images/banner/LrxhiA1631246 1.svg";
import checkActive from "../../assets/icons/menuVideos/check-active.svg";
import check from "../../assets/icons/menuVideos/check.svg";
import UploadFile from "../../assets/icons/meusVideos/UploadFile.svg";
import speackerImage from "../../assets/icons/menuVideos/speackerImage.svg";
import Ajuda from "../../assets/icons/menuVideos/Ajuda.svg";
import goBackIcon from "../../assets/icons/paginas/chevron-left.svg";
import { auth, db } from "../../config/firebase";
import exlametryMark from "../../assets/icons/nomeDoVideo/exlametryMarkinCircle.png";
import { apiCall, firebaseSnapshotCall } from "../../service/commonService";
import ExternalDomain from "../popup/ExternalDomainPopup";
import Popup from "../builder/main/popups/ImagePopup";
import VideoMetrics from "./VideoMetrics";

const MenuVideosComponent = () => {
  const user = auth.currentUser;
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const inputRef7 = useRef(null);
  const inputRef8 = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const uid = params.get("id");
  const tab = params.get("tab");
  console.log(tab);
  const { t } = useTranslation();
  const [selected, setSelected] = useState(tab || "settings");
  const [videoData, setVideoData] = useState([]);
  const [sites, setSites] = useState([]);
  const [imagePopup, setImagePopup] = useState(false);
  const [initialThumbPopup, setInitialThumbPopup] = useState(false);
  const [pauseThumb, setPauseThumb] = useState(false);
  const [originalSitesData, setOriginalSitesData] = useState([]);
  const [domainModal, setDomainModal] = useState(false);
  const [smartAutoPlayData, setsmartAutoPlayData] = useState([]);
  const [smartAutoPlayDefault, setsmartAutoPlayDefault] = useState(false);
  const [videoDetail, setVideoDetail] = useState([]);
  const [magicCheck, setMagicChecked] = useState(
    !!videoDetail?.details?.smartAutoPlay
  );
  const [videoName, setVideoName] = useState(false);
  const [nameOfVideo, setNameOfVideo] = useState(videoData?.meta?.name);
  const [language, setLanguage] = useState("");
  const [validDomain, setValidDomain] = useState([]);
  const [uploadFile, setUploadFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fakeViewers, setFakeViewers] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [libraryDetail, setlibraryDetail] = useState(null);
  const [allowedOrigins, setAllowedOrigins] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [exitThumbImage, setExitThumbImage] = useState(null);
  const [thumbImage, setThumbImage] = useState(null);
  const [exitThumbnail, setExitThumbnail] = useState("");
  const [poster, setPoster] = useState("");
  const [pauseThumbimg, setPausethumbImg] = useState("");
  const [smartAutoplayImg, setSmartAutoPlayImg] = useState("");
  const [videoStyles, setVideoStyles] = useState([]);
  const [savingEmbedOptions, setSavingEmbedOptions] = useState(false);
  // states for bottom right box

  const [videoStyle, setVideoStyle] = useState("");
  const [videoOrientation, setVideoOrientations] = useState("");
  const [color, setColor] = useState("#60F0D3");
  const [smartProgress, setSmartProgress] = useState(true);
  const [preload, setPreload] = useState(false);
  const [controls, setControls] = useState(true);
  const [autoPlay, setAutoplay] = useState(false);
  const [muted, setMuted] = useState(true);
  const [pauseAllowed, setPauseAllowed] = useState(false);
  const [resumePlay, setResumePlay] = useState(false);
  const [magicLiveStreaming, setMagicLiveStreaming] = useState(false);
  const [loop, setLoop] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  console.log(selected);
  useEffect(() => {
    params.set("tab", selected);
    window.history.replaceState(
      {},
      "",
      `${location.pathname}?${params.toString()}`
    );
  }, [selected, location, params]);
  const handleSelectChange = (selected) => {
    setValidDomain(selected.map((item) => item.value));
    setAllowedOrigins(selected.map((item) => item.value));

    console.log(selected);
  };
  const handleSwitches = (inputName, inputValue, setInputValue) => {
    const newValue = !inputValue;
    setInputValue(newValue);
    console.log(inputName, newValue);
  };
  // const options = allowedOrigins.map((origin) => ({ label: origin, value: origin }));
  // const defaultValue = options[0];
  const handleColorChange = (event) => {
    setColor(event.target.value);
  };
  const fetchData = async () => {
    setLoading(true);
    const user = auth.currentUser;

    let userToken;
    try {
      userToken = await user.getIdToken();
    } catch (error) {
      console.log("Authentication error:", error);
      return;
    }
    const userData = { uid, userToken };
    const response = await apiCall(
      "post",
      "stream/getVideo",
      userData,
      "https://apidopro.atomicat-api.com/"
    );
    if (response.data.success) {
      setVideoData(response.data.result);
      setNameOfVideo(response.data.result?.meta?.name);
      setLanguage(response.data.result?.meta?.language);
      setAllowedOrigins(
        response.data.result.allowedOrigins.length > 0
          ? response.data.result.allowedOrigins
          : ""
      );
      setVideoUrl(response.data.result.preview.replace("/watch", "/iframe"));
    }
    const reqData = { uid: user.uid, videoId: uid, userToken };
    const videoDetails = await apiCall(
      "post",
      "video/getdetails",
      reqData,
      "https://apidopro.atomicat-api.com/"
    );

    if (videoDetails.data) {
      setVideoDetail(videoDetails.data);
      setMagicChecked(!!videoDetails.data.details?.smartAutoPlay);

      // setControls(videoDetails.data.details?.controls || false)
    }
    if (videoDetails) {
      setThumbImage(null);
      setExitThumbImage(null);

      setControls(videoDetails.data?.details?.controls || false);
      setAutoplay(videoDetails.data?.details?.autoplay || false);
      if (videoDetails.data.smartAutoPlay) {
        setsmartAutoPlayData(videoDetails.data.smartAutoPlay);
        setsmartAutoPlayDefault(
          videoDetails.data.smartAutoPlay.includes("atomiSA")
        );
        setUploadFile(null);

        // setDisplaySummary(false);
      }
      setSmartProgress(
        videoDetails.data?.details?.smartProgress
          ? true
          : videoDetails.data?.details?.smartProgress
      );
      let pa = false;
      if (videoDetails.data?.details?.pauseAllowed) {
        pa = true;
      }
      setPauseAllowed(pa);
      setMagicLiveStreaming(
        videoDetails.data?.details?.magicLiveStreaming || false
      );
      setResumePlay(videoDetails.data?.details?.resumePlay || false);
      setFakeViewers(videoDetails.data?.details?.fakeViewers || "");
      setMuted(
        videoDetails.data?.details?.muted
          ? true
          : videoDetails.data?.details?.muted
      );
      setPreload(videoDetails.data?.details?.preload || false);
      setLoop(videoDetails.data?.details.loop || false);
      setColor(videoDetails.data?.details?.primaryColor ?? "#4adede");
      setStartTime(videoDetails.data?.details?.startTime ?? "");
      setWidth(videoDetails.data?.details?.width ?? null);
      setHeight(videoDetails.data?.details?.height ?? null);
      setExitThumbnail(videoDetails.data?.details?.exitThumb);
      setVideoStyles([
        {
          label: "Responsivo",
          value: "responsive",
          selected: !!(
            !videoDetails.data?.details?.height &&
            !videoDetails.data?.details?.width
          ),
        },
        {
          label: "Fixo",
          value: "fixed",
          selected: !!(
            videoDetails.data?.details?.height &&
            videoDetails.data?.details?.width
          ),
        },
      ]);
      setVideoStyle(
        videoDetails.data?.details.height && videoDetails.data?.details.width
          ? "fixed"
          : "responsive"
      );
      setPoster(
        videoDetails.data?.details?.defaultThumb
          ? `https://img.imageboss.me/atm/cdn/u/${user.uid}/l/4717b6089eab431b896ff8f3ca25ff59-Pictures/${videoDetails.data?.details?.defaultThumb}`
          : false
      );
      setPausethumbImg(
        videoDetails.data?.details?.defaultThumb
          ? `https://img.imageboss.me/atm/cdn/u/${user.uid}/l/4717b6089eab431b896ff8f3ca25ff59-Pictures/${videoDetails.data?.details?.exitThumb}`
          : false
      );
      setSmartAutoPlayImg(
        videoDetails.data?.details?.smartAutoPlay
          ? `https://img.imageboss.me/atm/cdn/u/${user.uid}/l/4717b6089eab431b896ff8f3ca25ff59-Pictures/${videoDetails.data?.details?.smartAutoPlay}`
          : false
      );
      if (videoDetails.data?.library) {
        setlibraryDetail(videoDetails.data?.library);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    fetchSites();
  }, []);

  async function fetchSites() {
    const user = auth.currentUser;

    const q = query(collection(db, "sites"), where("ownerId", "==", user?.uid));

    firebaseSnapshotCall(q).then((querySnapshot) => {
      if (querySnapshot != undefined) {
        setSites(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ssl: doc.data().atomicDomain,
          }))
        );
        setOriginalSitesData(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ssl: doc.data().atomicDomain,
            dynamicPath: `https://${doc.data().atomicDomain}/`,
          }))
        );
        // setIsLoading(false);
      }
    });
  }
  const handleSaveEdit = async () => {
    const user = auth.currentUser;

    let userToken;
    try {
      userToken = await user.getIdToken();
    } catch (error) {
      console.log("Authentication error:", error);
      return;
    }
    const data = {
      origins: allowedOrigins,
      meta: videoData?.meta,
      name: nameOfVideo,
      language,
    };
    const userData = { uid, userToken, data };
    const response = await apiCall(
      "post",
      "stream/updateVideo",
      userData,
      "https://apidopro.atomicat-api.com/"
    );
  };

  const handleMagicAutoplay = async () => {
    const user = auth.currentUser;

    let userToken;
    try {
      userToken = await user.getIdToken();
    } catch (error) {
      console.log("Authentication error:", error);
      return;
    }

    const data = {
      smartAutoPlay: "atomiSA",
      uid: user.uid,
      type: "template",
    };

    const userData = { videoId: uid, userToken, data };
    if (!magicCheck) {
      data.method = "DELETE";
    }
    const response = await apiCall(
      magicCheck ? "post" : "delete",
      "video/smartauto",
      userData,
      "https://apidopro.atomicat-api.com/"
    );
  };
  const handleAutoPlay = () => {
    const newValue = !magicCheck;
    setMagicChecked(newValue);
    inputRef.current.checked = newValue;
    console.log("maggic", magicCheck);
  };

  const saveEmbed = async () => {
    const data = {
      autoplay: autoPlay,
      controls,
      loop,
      muted,
      preload,
      primaryColor: color,
      startTime,
      width,
      height,
      smartProgress,
      orientation: videoOrientation,
      pauseAllowed,
      language,
      magicLiveStreaming,
      resumePlay,
      fakeViewers,
    };
    if (videoStyle === "responsive") {
      delete data.width;
      delete data.height;
    } else if (!width || !height) {
      // setCustomizeErr("Width and Height cannot me empty");
      return;
    }
    const user = auth.currentUser;
    let userToken;
    try {
      userToken = await user.getIdToken();
    } catch (error) {
      console.log("Authentication error:", error);
      return;
    }

    const userData = {
      videoId: uid,
      userId: user.uid,
      userToken,
      data,
    };
    setSavingEmbedOptions(true);
    const response = await apiCall(
      "post",
      "video/embed",
      userData,
      "https://apidopro.atomicat-api.com/"
    );
    setSavingEmbedOptions(false);
  };
  const colourStyle2 = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#212129",
      border: "1px solid #62627a",
      maxHeight: "50px",
      overflow: "auto",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma("white");
      return {
        ...styles,
        height: "36px",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "white"
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#212129"
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles) => {
      const color = chroma("#212129");
      return {
        ...styles,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#212129",
      ":hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        color: "white",
      },
    }),
  };
  return (
    <div className="mainvideodiv">
      {domainModal && (
        <ExternalDomain
          btn="Autorizer"
          title="Autorizar um domínio externo"
          closeFun={setDomainModal}
          placeholder="Ex: meudomino.com"
        />
      )}
      {imagePopup && (
        <Popup
          setImagePopup={setImagePopup}
          type="image"
          videoId={uid}
          fetchData={fetchData}
          placeholder="Ex: meudomino.com"
        />
      )}
      {initialThumbPopup && (
        <Popup
          setImagePopup={setInitialThumbPopup}
          type="initalThumb"
          videoId={uid}
          fetchData={fetchData}
          placeholder="Ex: meudomino.com"
        />
      )}
      {pauseThumb && (
        <Popup
          setImagePopup={setPauseThumb}
          type="pauseThumb"
          videoId={uid}
          fetchData={fetchData}
          placeholder="Ex: meudomino.com"
        />
      )}
      {/* {imagePopup && <Popup setShowPopup={setImagePopup} />} */}
      <SideBarHome />

      <div className="RightComponentLanding">
        <div className="HeaderNovaPagina">
          <div className="goBackButton" onClick={() => navigate(-1)}>
            <img src={goBackIcon} alt="goBackIcon.svg" />
          </div>
          <p>
            <span>{t("myVideos")}</span> /{nameOfVideo}
          </p>
          <div className="frameHeader">
            <div
              className={
                selected == "settings"
                  ? "selectedComponent"
                  : "unselectedComponent"
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected("settings");
                }}
              >
                {t("settings")}
              </span>
            </div>
            <div
              className={
                selected == "metrics"
                  ? "selectedComponent"
                  : "unselectedComponent"
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected("metrics");
                }}
              >
                {t("metrics")}
              </span>
            </div>
          </div>
        </div>
        {selected === "settings" && (
          <div className="frameBody">
            <div className="frameGrid">
              <div className="gridBody">
                <div className="frameTopGrid">
                  <div className="videoBox1">
                    <h4>{t("videoData")}</h4>
                    <div className="inputField">
                      <div className="">
                        <div className="inputFieldBox">
                          <div className="leftInputBox">
                            <p className="menuLabelInput" htmlFor="nameOfVideo">
                              {t("nameOfVideo")}
                            </p>
                            <input
                              className="inputVideoName"
                              type="text"
                              value={nameOfVideo}
                              onChange={(e) => setNameOfVideo(e.target.value)}
                              placeholder={t("nameOfVideo")}
                              required
                            />
                          </div>
                          <div className="rightInputBox">
                            <p className="menuLabelInput" htmlFor="email">
                              {t("language")}
                            </p>
                            <select
                              id="dropdown"
                              className="dropdownMenu"
                              placeholder=""
                              value={language}
                              onChange={(e) => setLanguage(e.target.value)}
                            >
                              <option value="pt">Português</option>
                              <option value="en">English</option>
                              <option value="es">Español </option>
                            </select>
                          </div>
                        </div>
                        <div className="authDomain">
                          <div className="leftInputBox">
                            <p className="menuLabelInput ">
                              {t("authDomains")}
                            </p>

                            <Select
                              isMulti
                              className="select-container basis-1/2 w-full"
                              classNamePrefix="select"
                              placeholder="Escolha"
                              options={sites?.map((item) => ({
                                label: item.ssl,
                                value: item.ssl,
                              }))}
                              styles={colourStyle2}
                              onChange={handleSelectChange}
                              value={
                                Array.isArray(allowedOrigins)
                                  ? allowedOrigins
                                      .filter(
                                        (item) => item !== "app.atomicat.com.br"
                                      )
                                      .map((item) => ({
                                        label: item,
                                        value: item,
                                      }))
                                  : []
                              }
                            />
                          </div>

                          <button
                            className="AdicionarBtn relative top-2"
                            onClick={() => setDomainModal(true)}
                          >
                            {t("addExternalDomain")}
                          </button>
                        </div>
                        {/* <div className="authDomain">
                        <div className="authDomainselect">
                          <label className="menuLabelInput" htmlFor="email">
                            {t("authDomains")}
                          </label>
                          <Select
                          isMulti
                          className="select-container basis-1/2"
                          classNamePrefix="select"
                          placeholder='Escolha'
                          options={sites?.map((item) => ({ label: item.ssl, value: item.ssl }))}                          styles={colourStyle2}
                          />
                        </div>
                      </div> */}
                      </div>
                    </div>

                    <button className="SalvarBtn" onClick={handleSaveEdit}>
                      {t("saveEditions")}
                    </button>
                  </div>
                  <div className="videoBox2">
                    <h4>{t("videoData")}</h4>
                    {/* <div className="inputFieldBox">
                    <div className="frameRight1">
                      <div className="file_uploadtitle">
                        <p>{t("initialThumb")}</p>
                        <div >
                          <img src={exlametryMark} alt="Ajuda.svg" />
                        </div>
                      </div>
                      <div className="file_uploadframe">
                        <div className="fileUploadDiv" style={{ backgroundImage: `url(${poster}` }} onClick={() => setInitialThumbPopup(true)}>
                          <div className="uploadFrame">
                            <img src={UploadFile} alt="UploadFile.svg" />
                            <p>
                              {t("dropTheFileOr")}{" "}
                              <a

                              >
                                {t("clickHere")}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="frameRight2">
                      <div className="file_uploadtitle">
                        <p>Thumb de pausa</p>
                        <div>
                          <img src={exlametryMark} alt="Ajuda.svg" />
                        </div>
                      </div>
                      <div className="file_uploadframe">
                        <div className="fileUploadDiv" style={{ backgroundImage: `url(${pauseThumbimg})` }} onClick={() => setPauseThumb(true)} >
                          <div className="uploadFrame">
                            <img src={UploadFile} alt="UploadFile.svg" />
                            <p>
                              {t("dropTheFileOr")}{" "}
                              <a

                              >
                                {t("clickHere")}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    <div className="grid grid-cols-2 gap-4 w-full">
                      <div className="inputFieldBox3">
                        <div className="magicFileBox">
                          <div className="file_uploadtitle">
                            <p>{t("initialThumb")}</p>
                            <img src={exlametryMark} alt="Ajuda.svg" />
                          </div>
                          <div
                            className="file_uploadframe fileBoxSize"
                            onClick={() => setInitialThumbPopup(true)}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="fileUploadDiv fileBoxSize"
                              style={{ backgroundImage: `url(${poster}` }}
                            >
                              <div className="uploadFrame">
                                <img src={UploadFile} alt="UploadFile.svg" />
                                <p>
                                  {t("dropTheFileOr")} <a>{t("clickHere")}</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="inputFieldBox3">
                        <div className="magicFileBox">
                          <div className="file_uploadtitle">
                            <p>Thumb de pausa</p>
                            <img src={exlametryMark} alt="Ajuda.svg" />
                          </div>
                          <div
                            className="file_uploadframe fileBoxSize "
                            onClick={() => setPauseThumb(true)}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="fileUploadDiv fileBoxSize"
                              style={{
                                backgroundImage: `url(${pauseThumbimg})`,
                              }}
                            >
                              <div className="uploadFrame">
                                <img src={UploadFile} alt="UploadFile.svg" />
                                <p>
                                  {t("dropTheFileOr")} <a>{t("clickHere")}</a>
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* <button className="" style={{ cursor: 'pointer' }} disabled={savingEmbedOptions} >Usar como Magic Autoplay</button> */}
                        </div>
                      </div>
                    </div>
                    {/* <button className="SalvarBtn">{t("saveEditions")}</button> */}
                  </div>
                </div>

                <div className="frameBottomGrid">
                  <div className="magicAutoplayBox">
                    <div className="magicAutoplayTitle">
                      <div>
                        <label className="switch">
                          <input
                            ref={inputRef}
                            type="checkbox"
                            onChange={handleMagicAutoplay}
                            checked={magicCheck}
                          />
                          <span
                            className={
                              magicCheck
                                ? " checked slider round"
                                : "unChecked slider round"
                            }
                            onClick={handleAutoPlay}
                          />
                        </label>
                      </div>
                      <h4>Magic Autoplay</h4>
                    </div>

                    <img
                      style={{ width: "100%", height: "auto" }}
                      src={speackerImage}
                      alt="speackerImage.svg"
                    />
                    <div className="inputFieldBox3">
                      <div className="magicFileBox">
                        <div className="file_uploadtitle">
                          <p>{t("orUploadYourOwnImgOrGif")}</p>
                          <img src={exlametryMark} alt="Ajuda.svg" />
                        </div>
                        <div
                          className="file_uploadframe"
                          onClick={() => setImagePopup(true)}
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="fileUploadDiv"
                            style={{
                              backgroundImage: `url(${smartAutoplayImg})`,
                            }}
                          >
                            <div className="uploadFrame">
                              <img src={UploadFile} alt="UploadFile.svg" />
                              <p>
                                {t("dropTheFileOr")} <a>{t("clickHere")}</a>
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* <button className="" style={{ cursor: 'pointer' }} disabled={savingEmbedOptions} >Usar como Magic Autoplay</button> */}
                      </div>
                    </div>
                  </div>
                  <div className="magicAutoplayBox">
                    {/* <div className="frame4"> */}
                    <h4>{t("embedCodeForExtFile")}</h4>
                    {/* </div> */}
                    <div className="grid grid-cols-3 w-full gap-5 gap-y-6">
                      <div className="rightInputBox2">
                        <label className="menuLabelInput" htmlFor="email">
                          {t("videoSize")}
                        </label>
                        <select
                          id="dropdown"
                          className="tamanhoDropdown"
                          placeholder="Escolha..."
                          value={videoStyle}
                          onChange={(e) => setVideoStyle(e.target.value)}
                        >
                          <option value="responsive">Responsivo</option>
                          <option value="fixed">Fixo</option>
                        </select>
                      </div>
                      <div className="rightInputBox2">
                        <label className="menuLabelInput" htmlFor="email">
                          {t("guidance")}
                        </label>
                        <select
                          id="dropdown"
                          className="tamanhoDropdown"
                          placeholder="Escolha..."
                          onChange={(e) => setVideoOrientations(e.value)}
                          value={videoOrientation}
                        >
                          <option value="horizontal">Horizontal</option>
                          <option value="verticle">Verticle</option>
                        </select>
                      </div>
                      <div className="rightInputBox2">
                        <label className="menuLabelInput" htmlFor="email">
                          {t("primaryColor")}
                        </label>
                        <div className="colorInput">
                          <input
                            className="colorText"
                            type="text"
                            placeholder="Cor primáriria"
                            value={color}
                            onChange={handleColorChange}
                            required
                          />
                          <input
                            type="color"
                            value={color}
                            onChange={handleColorChange}
                          />
                        </div>
                      </div>
                      <div className="rightInputBox2 flex items-center">
                        <label className="switch">
                          <input
                            type="checkbox"
                            ref={inputRef8}
                            onChange={() =>
                              handleSwitches(
                                "smartProgress",
                                smartProgress,
                                setSmartProgress
                              )
                            }
                            checked={smartProgress}
                          />
                          <span
                            className={
                              smartProgress
                                ? " checked slider round"
                                : "unChecked slider round"
                            }
                          />
                        </label>
                        <label className="checkBoxLabel">
                          {t("nameOfVideo")}
                        </label>
                      </div>
                      <div className="rightInputBox2 flex items-center">
                        <label className="switch">
                          <input
                            type="checkbox"
                            ref={inputRef7}
                            onChange={() =>
                              handleSwitches("preload", preload, setPreload)
                            }
                            checked={preload}
                          />
                          <span
                            className={
                              preload
                                ? " checked slider round"
                                : "unChecked slider round"
                            }
                          />
                        </label>

                        <label className="checkBoxLabel">Pré-carregar</label>
                      </div>
                      <div className="rightInputBox2 flex items-center">
                        <label className="switch">
                          <input
                            type="checkbox"
                            ref={inputRef1}
                            onChange={() =>
                              handleSwitches("controls", controls, setControls)
                            }
                            checked={controls}
                          />
                          <span
                            className={
                              controls
                                ? " checked slider round"
                                : "unChecked slider round"
                            }
                          />
                        </label>
                        <label className="checkBoxLabel">
                          Mostrar controles
                        </label>
                      </div>
                      <div className="rightInputBox2 flex items-center">
                        <label className="switch">
                          <input
                            type="checkbox"
                            ref={inputRef2}
                            onChange={() =>
                              handleSwitches("autoPlay", autoPlay, setAutoplay)
                            }
                            checked={autoPlay}
                          />
                          <span
                            className={
                              autoPlay
                                ? " checked slider round"
                                : "unChecked slider round"
                            }
                          />
                        </label>
                        <label className="checkBoxLabel">Autoplay</label>
                      </div>
                      <div className="rightInputBox2 flex items-center">
                        <label className="switch">
                          <input
                            type="checkbox"
                            ref={inputRef3}
                            onChange={() =>
                              handleSwitches("muted", muted, setMuted)
                            }
                            checked={muted}
                          />
                          <span
                            className={
                              muted
                                ? " checked slider round"
                                : "unChecked slider round"
                            }
                          />
                        </label>
                        <label className="checkBoxLabel">Mute</label>
                      </div>
                      <div className="rightInputBox2 flex items-center">
                        <label className="switch">
                          <input
                            type="checkbox"
                            ref={inputRef4}
                            onChange={() =>
                              handleSwitches(
                                "pauseAllowed",
                                pauseAllowed,
                                setPauseAllowed
                              )
                            }
                            checked={pauseAllowed}
                          />
                          <span
                            className={
                              pauseAllowed
                                ? " checked slider round"
                                : "unChecked slider round"
                            }
                          />
                        </label>
                        <label className="checkBoxLabel">
                          Permitir pausar o vídeo
                        </label>
                      </div>
                      <div className="rightInputBox2 flex items-center">
                        <label className="switch">
                          <input
                            type="checkbox"
                            ref={inputRef5}
                            onChange={() =>
                              handleSwitches(
                                "resumePlay",
                                resumePlay,
                                setResumePlay
                              )
                            }
                          />
                          <span
                            className={
                              resumePlay
                                ? " checked slider round"
                                : "unChecked slider round"
                            }
                          />
                        </label>
                        <label className="checkBoxLabel">Magic Resume</label>
                      </div>
                      <div className="rightInputBox2 flex items-center">
                        <label className="switch">
                          <input
                            type="checkbox"
                            ref={inputRef6}
                            onChange={() =>
                              handleSwitches(
                                "magicLiveStreaming",
                                magicLiveStreaming,
                                setMagicLiveStreaming
                              )
                            }
                          />
                          <span
                            className={
                              magicLiveStreaming
                                ? " checked slider round"
                                : "unChecked slider round"
                            }
                          />
                        </label>
                        <label className="checkBoxLabel">
                          Magic Live Streaming
                        </label>
                      </div>
                    </div>
                    {/* <div className="toggleDiv"> */}
                    {/* <div className="row1">
                      <div className="toggleColumn1">
                        <label class="switch">
                          <input
                            type="checkbox"
                            ref={inputRef8}
                            onChange={() =>
                              handleSwitches(
                                "smartProgress",
                                smartProgress,
                                setSmartProgress
                              )
                            }
                            checked={smartProgress}
                          />
                          <span
                            className={smartProgress ? ' checked slider round' : 'unChecked slider round'}

                          ></span>
                        </label>
                        <label className="checkBoxLabel">
                          {t("nameOfVideo")}
                        </label>
                      </div>
                      <div className="toggleColumn1">
                        <label class="switch">
                          <input
                            type="checkbox"
                            ref={inputRef7}
                            onChange={() =>
                              handleSwitches("preload", preload, setPreload)
                            }
                            checked={preload}
                          />
                          <span
                            className={preload ? ' checked slider round' : 'unChecked slider round'}

                          ></span>
                        </label>

                        <label className="checkBoxLabel">Pré-carregar</label>
                      </div>
                      <div className="toggleColumn1">
                        <label class="switch">
                          <input
                            type="checkbox"
                            ref={inputRef1}
                            onChange={() =>
                              handleSwitches("controls", controls, setControls)
                            }
                            checked={controls}
                          />
                          <span
                            className={controls ? ' checked slider round' : 'unChecked slider round'}

                          ></span>
                        </label>
                        <label className="checkBoxLabel">
                          Mostrar controles
                        </label>
                      </div>
                    </div> */}
                    {/* <div className="row1" >
                      <div className="toggleColumn1">
                        <label class="switch">
                          <input
                            type="checkbox"
                            ref={inputRef2}
                            onChange={() =>
                              handleSwitches("autoPlay", autoPlay, setAutoplay)
                            }
                            checked={autoPlay}
                          />
                          <span
                            className={autoPlay ? ' checked slider round' : 'unChecked slider round'}

                          ></span>
                        </label>
                        <label className="checkBoxLabel">Autoplay</label>
                      </div>
                      <div className="toggleColumn1">
                        <label class="switch">
                          <input
                            type="checkbox"
                            ref={inputRef3}
                            onChange={() =>
                              handleSwitches("muted", muted, setMuted)
                            }
                            checked={muted}
                          />
                          <span
                            className={muted ? ' checked slider round' : 'unChecked slider round'}

                          ></span>
                        </label>
                        <label className="checkBoxLabel">Mute</label>
                      </div>
                      <div className="toggleColumn1">
                        <label class="switch">
                          <input
                            type="checkbox"
                            ref={inputRef4}
                            onChange={() =>
                              handleSwitches(
                                "pauseAllowed",
                                pauseAllowed,
                                setPauseAllowed
                              )
                            }
                            checked={pauseAllowed}
                          />
                          <span
                            className={pauseAllowed ? ' checked slider round' : 'unChecked slider round'}

                          ></span>
                        </label>
                        <label className="checkBoxLabel">
                          Permitir pausar o vídeo
                        </label>
                      </div>
                    </div> */}
                    {/* <div className="row1" >
                      <div className="toggleColumn1">
                        <label class="switch">
                          <input
                            type="checkbox"
                            ref={inputRef5}
                            onChange={() =>
                              handleSwitches(
                                "resumePlay",
                                resumePlay,
                                setResumePlay
                              )
                            }
                          />
                          <span
                            className={resumePlay ? ' checked slider round' : 'unChecked slider round'}

                          ></span>
                        </label>
                        <label className="checkBoxLabel">Magic Resume</label>
                      </div>
                      <div className="toggleColumn1">
                        <label class="switch">
                          <input
                            type="checkbox"
                            ref={inputRef6}
                            onChange={() =>
                              handleSwitches(
                                "magicLiveStreaming",
                                magicLiveStreaming,
                                setMagicLiveStreaming
                              )
                            }
                          />
                          <span
                            className={magicLiveStreaming ? ' checked slider round' : 'unChecked slider round'}

                          ></span>
                        </label>
                        <label
                          className="checkBoxLabel"
                          style={{ width: "230px" }}
                        >
                          Magic Live Streaming
                        </label>
                      </div>
                      <div className="toggleColumn1" />
                    </div> */}
                    {/* </div> */}
                    <div className="codigoCard">
                      <p>{t("code")}</p>

                      <textarea
                        readOnly
                        id="embedCode"
                        className=" p-4 dark:bg-slate-900  text-slate-200 embedCodePara"
                        value={`<div id="_${uid}" style="position: relative; padding-top:${
                          videoOrientation === "horizontal" ? "56.25" : "177.20"
                        }%;"><iframe src="${videoUrl}?${
                          startTime ? `&startTime=${startTime}` : ""
                        }${resumePlay ? "&rp=1" : ""}${
                          magicLiveStreaming
                            ? `&fv=${fakeViewers || "657"}`
                            : ""
                        }${videoOrientation === "vertical" ? "&o=v" : ""}${
                          color
                            ? `&primaryColor=${encodeURIComponent(color)}`
                            : ""
                        }${preload ? "&preload=auto" : ""}${
                          muted ? `&muted=${muted}` : ""
                        }${
                          loop ? `&loop=${loop}` : ""
                        }${`&controls=${controls}`}${
                          autoPlay ? `&autoplay=${autoPlay}` : ""
                        }${
                          smartAutoPlayData ? `&sA=${smartAutoPlayData}` : ""
                        }${language !== "pt" ? `&lg=${language}` : ""}${
                          smartAutoPlayData && !autoPlay
                            ? `&autoplay=${!autoPlay}`
                            : ""
                        }${pauseAllowed ? "&pA=1" : ""}${
                          smartProgress ? `&sP=${smartProgress}` : ""
                        }${exitThumbnail ? `&eT=${exitThumbnail}` : ""}${
                          poster ? `&poster=${poster}` : ""
                        }${
                          (smartAutoPlayData && !smartAutoPlayDefault) ||
                          (exitThumbnail && !poster)
                            ? `&p=${user.uid}`
                            : ""
                        }" style=${`"border: none;height:${
                          videoStyle === "fixed"
                            ? `${height}%` ?? "100%"
                            : "100%"
                        };width:${
                          videoStyle === "fixed"
                            ? `${width}%` ?? "100%"
                            : "100%"
                        };position: absolute;top: 0;left: 0;"`} allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div><script src="https://embed.cloudflarestream.com/embed/sdk.latest.js"></script><script type="text/javascript">if (typeof atomiVids === 'undefined') {atomiVids = {};} atomiVids['${uid}'] = Stream(document.getElementById('${uid}').querySelector('iframe'));document.head.appendChild(Object.assign(document.createElement("script"), {src: "https://cdn.atomicatpages.com/s1.js?id=_${uid}&cache=" + Math.floor((Math.random() * 100000)),async: true}));</script>`}
                      />
                      {/* </div> */}
                    </div>
                    <div className="EmbedButtons">
                      <button
                        className={
                          savingEmbedOptions
                            ? " loading embedButton1"
                            : " embedButton1"
                        }
                        disabled={savingEmbedOptions}
                        onClick={(e) => saveEmbed()}
                      >
                        Salvar alterações
                      </button>
                      <button className="embedButtonCopy">Copiar embed</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {selected === "metrics" && <VideoMetrics />}
      </div>
    </div>
  );
};

export default MenuVideosComponent;
