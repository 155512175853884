import React, { createContext, useState } from 'react';

export const StyleContext = createContext();

const StyleProvider = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(true);
  return (
    <StyleContext.Provider value={{
      expanded,
      setExpanded,
      isDarkModeEnabled,
      setIsDarkModeEnabled,
    }}
    >
      {children}
    </StyleContext.Provider>
  );
};

export default StyleProvider;
