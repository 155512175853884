import React, { useState, useRef, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router';
import { Stream } from '@cloudflare/stream-react';
import { SideBarHome } from '../components';
import '../styles/videos/meusVideos.css';
import Banner from '../assets/images/banner/LrxhiA1631246 1.svg';
import folderClosed from '../assets/icons/nomeDoVideo/folderClosed.png';
import growth from '../assets/icons/meusVideos/map.png';
import deleteicon from '../assets/icons/sites/Delete.png';
import settingsicon from '../assets/icons/meusVideos/settingsIcon.svg';
import backBtnArrow from '../assets/icons/paginas/chevron-left.svg';
import exlametryMark from '../assets/icons/nomeDoVideo/exlametryMarkinCircle.png';
import clockIcon from '../assets/icons/nomeDoVideo/clock.png';
import { auth, db } from '../config/firebase';
import { apiCall } from '../service/commonService';

import LineRechartComponent from '../components/chart/MetricsChart';

export default function NomeDoVideo() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const uid = params.get('id');
  const videoRef = useRef(null);
  const refStream = useRef();

  const [cTime, setCTime] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [videoData, setVideoData] = useState([]);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [selected, setSelected] = useState('metricas');
  const [analyticsViews, setAnalyticsViews] = useState(0);
  const [analyticsPlays, setAnalyticsPlays] = useState(0);
  const [analyticsPlayRate, setAnalyticsPlayRate] = useState(0);
  const [analyticsEngagement, setAnalyticsEngagement] = useState(0);
  const [analyticsUniqueViews, setAnalyticsUniqueViews] = useState(0);
  const [totalViews, setTotalViews] = useState(null);
  const [totalMinutesPlayed, setTotalMinutesPlayed] = useState(null);
  const [isFetchingAnalytics, setFetchingAnalytics] = useState(false);
  const [data, setData] = useState(null);
  const [whatTime, setWhatTime] = useState(0);
  const [duration, setDuration] = useState('all');
  const [analyticsDuration, setAnalyticsDuration] = useState([
    { label: 'Desde sempre', value: 'all', selected: true },
    { label: 'Hoje', value: '1', selected: false },
    { label: 'Últimos 7 dias', value: 7, selected: false },
    { label: 'Últimos 14 dias', value: 14, selected: false },
    { label: 'Últimos 30 dias', value: 30, selected: false },
    { label: 'Período personalizado', value: 'custom', selected: false },
  ]);

  useEffect(() => {
    if (analyticsData) {
      let realPlays = 0;
      console.log(analyticsData.data);
      for (let s = 0; s < analyticsData?.data?.length; s++) {
        console.log(analyticsData.data[s].timed);
        if (analyticsData.data[s].timed === 0 && analyticsData.data[s].total) {
          if (analyticsData.data[s].total > analyticsData.data[s + 1].total) {
            console.log('s => ', s);
            console.log(analyticsData.data);
            realPlays = analyticsData.data[s].total;
            console.log(analyticsData.data[s]);
            console.log('+ 1 ', analyticsData.data[s + 1]);
            console.log(realPlays);
            break;
          }
        } else if (
          analyticsData.data[s].timed === 1
          && analyticsData.data[s].total
        ) {
          realPlays = analyticsData.data[s].total;
          console.log(realPlays);
          break;
        } else if (
          analyticsData.data[s].timed === 2
          && analyticsData.data[s].total
        ) {
          realPlays = analyticsData.data[s].total;
          break;
        } else if (
          analyticsData.data[s].timed === 3
          && analyticsData.data[s].total
        ) {
          realPlays = analyticsData.data[s].total;
          break;
        } else if (
          analyticsData.data[s].timed === 4
          && analyticsData.data[s].total
        ) {
          realPlays = analyticsData.data[s].total;
          break;
        } else if (
          analyticsData.data[s].timed === 5
          && analyticsData.data[s].total
        ) {
          realPlays = analyticsData.data[s].total;
          break;
        }
      }

      if (
        (analyticsData.visitorsLeft ?? 0)
        + (analyticsData.visitorsCompleted ?? 0)
      ) {
        realPlays = (analyticsData.visitorsLeft ?? 0)
          + (analyticsData.visitorsCompleted ?? 0);
      }

      // const plays = (analyticsData.visitorsLeft ?? 0)+(analyticsData.visitorsCompleted ?? 0);
      const totalImpressions = analyticsData.visitorsviews ?? 0;
      setData(analyticsData);
      setAnalyticsPlays(realPlays);
      setTotalViews(
        (analyticsData.visitorsviews
          || analyticsData.visitorsLeft + analyticsData.visitorsCompleted)
          ?? 0,
      );
      setAnalyticsViews(totalImpressions);
      setTotalMinutesPlayed(analyticsData.visitorstotalminutesplayed ?? 0);
      setAnalyticsUniqueViews(analyticsData.visitorsuniqueViews ?? 0);

      if (
        analyticsData.visitorsviews
        || analyticsData.visitorsLeft
        || analyticsData.visitorsCompleted
      ) {
        const playRate = (realPlays * 100) / analyticsUniqueViews;
        setAnalyticsPlayRate(playRate);
      } else {
        setAnalyticsPlayRate(0);
      }
      setFetchingAnalytics(false);
    }
  }, [analyticsData, totalViews]);

  useEffect(() => {
    if (whatTime) {
      videoRef.current.currentTime = whatTime;
      videoRef.current.play().then(() => {
        videoRef.current.pause();
      });
    }
  }, [whatTime]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
    }
    console.log('sangram', videoRef);
  }, [videoRef.current]);

  const getNextUpdateTime = () => {
    const time = new Date().getUTCHours() - 3;
    if (time >= 5 && time < 11) {
      return 'às 11:00, ';
    }
    if (time >= 11 && time < 17) {
      return 'às 17:00, ';
    }
    if (time >= 17 && time < 23) {
      return 'às 23:00, ';
    }
    if (
      (time === 23 || time >= 0 || time === -1 || time === -2 || time === -3)
      && (time === 23
        || time >= 0
        || time < 5
        || time === -1
        || time === -2
        || time === -3)
    ) {
      return 'às 05:00, ';
    }

    return 'ERRO - Não foi possível localizar a próxima atualização das métricas';
  };

  useEffect(() => {
    if (refStream.current) {
      refStream.current.play();

      setTimeout(() => refStream.current.pause(), 200);
    }
  }, [cTime]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const cards = [
    { title: 'Impressões', number: analyticsViews },
    { title: 'Impressoes unicas', number: analyticsUniqueViews },
    { title: 'Plays', number: analyticsPlays },
    { title: 'Play Rate', number: `${analyticsPlayRate?.toFixed(2) ?? 0}%` },
    {
      title: 'Engajamento',
      number: `${analyticsEngagement?.toFixed(2) || 0}%`,
    },
  ];

  const fetchData = async () => {
    const user = auth.currentUser;

    let userToken;
    try {
      userToken = await user.getIdToken();
    } catch (error) {
      console.log('Authentication error:', error);
      return;
    }
    const userData = { uid, userToken };
    const response = await apiCall(
      'post',
      'stream/getVideo',
      userData,
      'https://apidopro.atomicat-api.com/',
    );
    if (response.data.success) {
      setVideoData(response.data.result);
    }
  };
  const fetchAnalytics = async () => {
    const user = auth.currentUser;

    let userToken;
    const userId = user.uid;
    try {
      userToken = await user.getIdToken(true);
    } catch (err) {
      console.log(err);
    }
    const userData = {
      uid: userId,
      videoId: uid,
      start: '',
      end: '',
      duration,
      userToken,
    };
    const response = await apiCall(
      'post',
      'video/analytics',
      userData,
      'https://apidopro.atomicat-api.com/',
    );
    setAnalyticsData(response);
  };

  useEffect(() => {
    fetchData();
    fetchAnalytics();
  }, []);
  return (
    <div className="disply-flex-justify-space-between">
      <SideBarHome />
      <div className="meusVideos-container">
        <div className="meusVideos-header bg-success">
          <div className="display-align-center">
            <button type="button" className="btn btn-primary back-btn">
              <img src={backBtnArrow} onClick={() => navigate(-1)} />
            </button>
            <p>Meus videos / Nome do video</p>
          </div>
        </div>
        <div
          className="display-flex-justify-align-center "
          style={{ position: 'relative', top: '-60px' }}
        >
          <button
            className={
              selected === 'ajustes' ? ' metricas-btn mr-3' : 'ajustes'
            }
            onClick={() => {
              setSelected('ajustes');
            }}
          >
            AJUSTES
          </button>
          <button
            type="button"
            onClick={() => {
              setSelected('metricas');
            }}
            className={selected === 'metricas' ? ' metricas-btn' : 'ajustes'}
          >
            {' '}
            METRICAS
          </button>
        </div>
        {selected === 'metricas' && (
          <div className="meusVideos-middle" style={{ marginTop: '-35px' }}>
            <div className="disply-flex-justify-start">
              <select
                className="meusVideos-dropdown"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="">Desde sempre</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>
              {selectedOption && (
                <p>
                  You selected:
                  {selectedOption}
                </p>
              )}
              <button type="button" className="btn btn-success ">
                Pesquisar...
              </button>
              <div className=" clock-para">
                <img
                  className="pl-lg-5 pr-lg-0 pr-3 pr-lg-0 pl-lg-5-clock"
                  src={clockIcon}
                />
                <p className="clock-text pl-1">
                  Proxima atualizacao das metricas
                  {' '}
                  {getNextUpdateTime()}
                  {' '}
                  horario
                  de Brasilia.
                </p>
              </div>
            </div>

            {/* Graph Section */}
            <div className="maingraphdiv" style={{ width: '100%' }}>
              <div className="" style={{ width: '99%' }}>
                <Stream
                  streamRef={videoRef}
                  className="rounded-md "
                  src={uid}
                />
                <div
                  style={{
                    // backgroundImage: `url('${thumbnail}')`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    // width: getWidth(),
                    // height: getHeight(),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // marginTop: getHeightVideo(),
                  }}
                >
                  <LineRechartComponent
                    data={analyticsData}
                    vertical={false}
                    horizontal={false}
                    setWhatTime={setWhatTime}
                    duration={videoData.duration}
                  />
                </div>
              </div>
              <div className="cards-area w-100">
                {/* cards  */}
                {cards.map((item, index) => (
                  <div className="card graph-card w-100">
                    <div className="card-body">
                      <div className="display-align-center">
                        <p className="magic-progress">
                          {item.title}
                          {' '}
                        </p>
                        {' '}
                        <img className="ml-3" src={exlametryMark} />
                      </div>

                      <h1 className="magic-progress-numbers">{item.number}</h1>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {selected === 'ajustes' && <p>Hii There</p>}
      </div>
    </div>
  );
}
