import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import '../styles/loginPage/LoginPage.css';
import '../styles/redefine/Redefine.css';
import { IoMdAlert } from 'react-icons/io';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { passwordReset } from '../service/api';
import LeftComponentVideo from '../components/leftComponentVideo/LeftComponentVideo';
import '../styles/global.css';

const RedefineEmailSent = () => {
  const [email, setEmail] = useState('');
  const reCaptchaRef = useRef();
  const navigate = useNavigate();
  const [dispError, setDispError] = useState('none');
  const [errMessage, setErrMessage] = useState('');
  const [dispSuccess, setDispSuccess] = useState('none');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validateEmail(email)) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage('The email is not of required format');
    }
    const token = await reCaptchaRef.current.executeAsync();
    console.log(`${email} ${token}`);
    try {
      const response = await passwordReset({
        token,
        email,
      });
      console.log(`Success${response.data.success} ` + `Message:${response.data.message}`);
      setIsLoading(false);
      setDispSuccess('flex');
      setSuccessMessage('Um link de recuperação foi enviado para o seu e-mail, caso não encontre procure na lixeira, spam ou aguarde alguns minutos!');
    } catch (err) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage(`Error sending password reset mail : ${err}`);
      setTimeout(() => {
        setDispError('none');
      }, 5000);
    }
    reCaptchaRef.current.reset();
    setEmail('');
  };

  const formError = () => {
    console.log('error');
  };

  const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  useEffect(() => {
    document.body.classList.add('custom-background');
    return () => {
      document.body.classList.remove('custom-background');
    };
  }, []);

  const buttonClassName = `EnterButton${isLoading ? ' button--loading' : ''}`;

  return (
  // {/* <div className='body-container'>
  //   <LeftComponentVideo/> */}
    <div className="right-component-redefine">
      <form className="formStyle" onSubmit={handleSubmit} onError={formError}>
        <h1 className="headingStyle">Redefina sua senha!</h1>
        <p className="hyperLinkStyle">
          Quer acessar sua conta?
          <strong onClick={() => { navigate('/login'); }}>Clique aqui</strong>
        </p>
        <div className="flexDisplay">
          <label className="labelInput" htmlFor="email">E-mail</label>
          <input
            className="inputDetails"
            type="text"
            id="email"
            placeholder="Digite seu e-mail"
            value={email}
            onChange={(e) => { setEmail(e.target.value); }}
            required
          />
        </div>
        <ReCAPTCHA
          ref={reCaptchaRef}
          // sitekey='6LfVo6glAAAAAI4Ab0lUudzd_tIgYYzgeennEN4R'
          sitekey="6LfNkKklAAAAAEidji8V65sG6EogfoK-lW-9qUbW"
          theme="dark"
          size="invisible"
        />
        <button type="submit" className={buttonClassName}>
          <span className="button-text">{isLoading ? 'Carregando...' : 'Redefinir senha'}</span>
        </button>
        <div className="errorBlock" style={{ display: dispError }}>
          <div className="alert">
            <IoMdAlert />
          </div>
          <div>
            <p className="errorMessage">{errMessage}</p>
          </div>
        </div>
        <div className="errorBlock" style={{ display: dispSuccess }}>
          <div className="alert">
            <IoCheckmarkCircleSharp color="#2AEB81" />
          </div>
          <div>
            <p className="successMessage">{successMessage}</p>
          </div>
        </div>
      </form>
    </div>
  // </div>
  );
};
export default RedefineEmailSent;
