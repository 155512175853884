import React, { useState } from 'react';

const AccordionRender = ({ comp, responsiveMode }) => {
  const [forceRender, setForceRender] = useState(false);

  // <comp.tag style={{
  //   ...comp.style, width: '100%', borderRadius: `${comp.style.borderTopLeftRadius[responsiveMode.mode]}px ${comp.style.borderTopRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomLeftRadius[responsiveMode.mode]}px`, borderWidth: `${comp.style.border.width}px`, borderColor: `${comp.style.border.color}`, borderStyle: `${comp.style.border.style}`, padding: `${comp.style.paddingTop[responsiveMode.mode]}px ${comp.style.paddingRight[responsiveMode.mode]}px ${comp.style.paddingBottom[responsiveMode.mode]}px ${comp.style.paddingLeft[responsiveMode.mode]}px`, margin: `${comp.style.marginTop[responsiveMode.mode]}px ${comp.style.marginRight[responsiveMode.mode]}px ${comp.style.marginBottom[responsiveMode.mode]}px ${comp.style.marginLeft[responsiveMode.mode]}px`,
  // }}

  return (
    <div
      className="accordionContainer"
      style={{
        ...comp.style, borderRadius: `${comp.style.borderTopLeftRadius[responsiveMode.mode]}px ${comp.style.borderTopRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomLeftRadius[responsiveMode.mode]}px`, borderTop: `${comp.style.border.width}px ${comp.style.border.style} ${comp.style.border.color}`, borderBottom: '0', borderLeft: `${comp.style.border.width}px ${comp.style.border.style} ${comp.style.border.color}`, borderRight: `${comp.style.border.width}px ${comp.style.border.style} ${comp.style.border.color}`,
      }}
    >
      {
            comp.items.map((item, index) => (
              <div className="accordionItem">
                <comp.misc.titleTag
                  className="accordionTitle flex items-center"
                  onClick={() => {
                    comp.items[index].active = !item.active;
                    setForceRender(!forceRender);
                  }}
                  style={{
                    ...comp.style.title, color: item.active ? comp.style.title.activeColor : comp.style.title.color, borderBottom: `${comp.style.border.width}px ${comp.style.border.style} ${comp.style.border.color}`, transition: ' margin-bottom 0.2s ease-in-out', marginBottom: item.active ? '0px' : `-${comp.style.border.width}px`, padding: `${comp.style.paddingTop[responsiveMode.mode]}px ${comp.style.paddingRight[responsiveMode.mode]}px ${comp.style.paddingBottom[responsiveMode.mode]}px ${comp.style.paddingLeft[responsiveMode.mode]}px`, textAlign: comp.style.textAlign, fontWeight: comp.style.fontWeigth, letterSpacing: comp.style.letterSpacing,
                  }}
                >
                  <i className={item.active ? comp.misc.activeIcon : comp.misc.inactiveIcon} style={{ marginRight: '5px', color: item.active ? comp.style.title.activeColor : comp.style.title.color }} />
                  {' '}
                  {item.title}
                </comp.misc.titleTag>
                <comp.misc.contentTag
                  className="accordionContent"
                  style={{
                    ...comp.style.content, maxHeight: item.active ? '1000px' : 0, overflowY: 'hidden', transition: 'max-height 0.3s ease-in-out, margin-bottom 0.3s ease-in-out', borderBottom: `${comp.style.border.width}px ${comp.style.border.style} ${comp.style.border.color}`, padding: item.active ? `${comp.style.paddingTop[responsiveMode.mode]}px ${comp.style.paddingRight[responsiveMode.mode]}px ${comp.style.paddingBottom[responsiveMode.mode]}px ${comp.style.paddingLeft[responsiveMode.mode]}px` : '0px', textAlign: comp.style.textAlign, letterSpacing: comp.style.letterSpacing, wordBreak: 'break-word',
                  }}
                >
                  {item.content}
                </comp.misc.contentTag>
              </div>
            ))
        }
    </div>
  );
};

export default AccordionRender;
