import React, { useState } from 'react';
import '../../styles/frazerUpload/FrazerUpload.css';
import {
  addDoc,
  collection,
  doc,
  setDoc,
  updateDoc,
  remove,
} from 'firebase/firestore';
import close from '../../assets/icons/former/close-icon.svg';
import { db } from '../../config/firebase';

const UnlinkDomain = ({
  closeFun,
  title,
  btn,
  label,
  placeholder,
  fileupload,
  note,
  func,
  siteData,
}) => {
  const [loading, setLoading] = useState(false);
  const handleUnlink = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const docRef = doc(db, 'sites', siteData?.id);

      const addedDocument = await updateDoc(docRef, {
        custDomain: null,
        custDomainCfId: null,
      });
      closeFun(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('failed', error);
    }
    return (
      <div>
        <div>
          <div className="frazer-container">
            <div className="frazer-box">
              <div className="frazer-upload-header">
                <h2>{title}</h2>
                <img onClick={() => closeFun(false)} src={close} alt="" />
              </div>

              <div className="hr80" />

              <form className="">
                <div className="frazer-input">
                  <label htmlFor="fileupload">
                    Deseja desvincular o domínio?
                  </label>
                </div>

                <div className="frazer-input">
                  <label htmlFor="fileupload">
                    O domínio
                    {siteData?.custDomain}
                    {' '}
                    deixará de funcionar, mas as páginas
                    continuarão visíveis em
                    {siteData?.dynamicPath}
                  </label>
                </div>
                <div className="hr80" />

                <div className="fazer-btn">
                  <p onClick={() => closeFun(false)}>Cancelar</p>
                  <button onClick={handleUnlink}>
                    {loading ? 'Carregando...' : btn}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="hr80" />

          <form className="">
            <div className="frazer-input">
              <label htmlFor="fileupload">Deseja desvincular o domínio?</label>
            </div>

            <div className="frazer-input">
              <label htmlFor="fileupload">
                O domínio
                {siteData?.custDomain}
                {' '}
                deixará de funcionar, mas as páginas
                continuarão visíveis em
                {siteData?.dynamicPath}
              </label>
            </div>
            <div className="hr80" />

            <div className="fazer-btn">
              <p onClick={() => closeFun(false)}>Cancelar</p>
              <button onClick={handleUnlink}>
                {loading ? 'Carregando...' : btn}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };
};

export default UnlinkDomain;
