import React from 'react';

const CircularProgress = ({ color, width, size }) => (
  <div>
    <div
      style={{
        borderLeft: '3px solid #E1E2EF', borderRadius: '50%', border: '3px solid #E1E2EF', borderTop: '3px solid transparent', width: size ? `${size}px` : '18px', height: size ? `${size}px` : '18px',
      }}
      className="animate-spin h-5 w-5 mr-2"
    />
  </div>
);

export default CircularProgress;
