import React, { useState, useEffect } from 'react';

const VideoRender = ({ comp, setVideoController, responsiveMode }) => {
  const [forceRender, setForceRender] = useState(false);
  const [url, setUrl] = useState('https://www.youtube.com/watch?v=sXQxhojSdZM');
  const [Video, setVideo] = useState('');

  const extractVideoID = (url) => {
    const youtubeRegex = /^(?:(?:https?:\/\/)?(?:www\.)?)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]+)/;
    const vimeoRegex = /vimeo\.com\/(\d+)/;
    const pandaRegex = /panda\.com\/video\/(\w+)/;
    const vturbRegex = /vturb\.com\/video\/(\w+)/;
    const wistiaRegex = /wistia\.com\/(?:embed\/)?(?:medias\/)?(\w+)/;
    const atomicplayRegex = /atomicplay\.com\/(?:embed\/)?(?:v\/)?(\w+)/;

    let videoID = null;
    let videoLink = null;
    if (youtubeRegex.test(url)) {
      videoID = url.match(youtubeRegex)[1];
      videoLink = `https://www.youtube.com/embed/${videoID}`;
    } else if (vimeoRegex.test(url)) {
      videoID = url.match(vimeoRegex)[1];
      videoLink = `https://player.vimeo.com/video/${videoID}`;
    } else if (pandaRegex.test(url)) {
      videoID = url.match(pandaRegex)[1];
      videoLink = `https://panda.com/video/${videoID}`;
    } else if (vturbRegex.test(url)) {
      videoID = url.match(vturbRegex)[1];
      videoLink = `https://vturb.com/video/${videoID}`;
    } else if (wistiaRegex.test(url)) {
      videoID = url.match(wistiaRegex)[1];
      videoLink = `https://fast.wistia.net/embed/iframe/${videoID}`;
    } else if (atomicplayRegex.test(url)) {
      videoID = url.match(atomicplayRegex)[1];
      videoLink = `https://atomicplay.com/embed/v/${videoID}`;
    }

    const video = `<iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; " src="${videoLink}?&${srcAttribute('controls', comp.misc.controls)}&${srcAttribute('autoplay', comp.misc.autoplay)}&${srcAttribute('loop', comp.misc.loop)}&${srcAttribute('mute', comp.misc.mute)}&${srcAttribute('muted', comp.misc.mute)}&playlist=${videoID}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

    setVideo(video);
  };

  useEffect(() => {
    if (comp.misc.src.includes('iframe')) {
      setVideo(comp.misc.src);
      setVideoController(true);
    } else {
      extractVideoID(comp.misc.src);
      setVideoController(false);
      setForceRender(!forceRender);
    }
  }, [comp.misc.src, forceRender]);

  useEffect(() => {
    extractVideoID('https://vimeo.com/832953441');
  }, [
    url,
  ]);

  // console.log(videoID);

  const srcAttribute = (attrname, attr) => (attr ? `${attrname}=1` : `${attrname}=0`);

  useEffect(() => {
    setForceRender(!forceRender);
  }, [srcAttribute, forceRender]);

  return (
    <div
      style={{
        ...comp.style, display: 'inline-block', width: '100%', borderRadius: `${comp.style.borderTopLeftRadius[responsiveMode.mode]}px ${comp.style.borderTopRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomLeftRadius[responsiveMode.mode]}px`, borderWidth: `${comp.style.border.width}px`, borderStyle: `${comp.style.border.style}`, borderColor: `${comp.style.border.color}`, padding: `${comp.style.paddingTop[responsiveMode.mode]}px ${comp.style.paddingRight[responsiveMode.mode]}px ${100}% ${comp.style.paddingLeft[responsiveMode.mode]}px`, margin: `${comp.style.marginTop[responsiveMode.mode]}px ${comp.style.marginRight[responsiveMode.mode]}px ${comp.style.marginBottom[responsiveMode.mode]}px ${comp.style.marginLeft[responsiveMode.mode]}px`, overflow: 'hidden', position: 'relative',
      }}
      dangerouslySetInnerHTML={{ __html: Video }}
    />

  );
};

export default VideoRender;
