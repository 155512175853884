import React, { useEffect, useRef, useState } from 'react';
import '../styles/global.css';
import '../styles/novaPagina/NovaPagina.css';
import '../styles/sites/Sites.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { deleteDoc, doc } from 'firebase/firestore';
import { format } from 'date-fns';
import chroma from 'chroma-js';
import Select from 'react-select';
import settingsLogo from '../assets/icons/paginas/settingsLogo.svg';
import Add from '../assets/icons/sites/Add.png';
import goBackIcon from '../assets/icons/paginas/chevron-left.svg';
import searchIcon from '../assets/icons/paginas/SearchComponent.svg';
import optionIcon from '../assets/icons/paginas/Options.svg';
import CrossIcon from '../assets/icons/builder/Cross.svg';
import { SideBarHome } from '../components';
import { useCollection } from '../custom-hooks/useCollection';
import { db } from '../config/firebase';
import SitePageSkelton from '../components/skelton/SitePageSkelton';
import { deleteDocument } from '../service/useFirestore';
import { SelectTag } from '../components/tags/SelectTag';
import  SelectProduct from '../components/products/SelectProduct';

const Paginas = (props) => {
  const [showHiddenInput, setShowHiddenInput] = useState(false);
  const [tags, setTags] = useState([]);
  const [docId, setDocId] = useState();
  const [products, setProducts] = useState([]);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [createTagPopup, setCreateTagPopup] = useState(false);
  const [createProductPopup, setCreateProductPopup] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filterPage, setfilterPage] = useState([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();

  const colourStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#212129',
      border: '1px solid #62627a',
    }),
    option: (styles, {
      data, isDisabled, isFocused, isSelected,
    }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.1).css()
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };
  const colourStyle2 = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#212129',
      border: '1px solid #62627a',
    }),
    option: (styles, {
      data, isDisabled, isFocused, isSelected,
    }) => {
      const color = chroma('white');
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? 'white'
            : isFocused
              ? color.alpha(0.1).css()
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? '#212129'
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles) => {
      const color = chroma('#212129');
      return {
        ...styles,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: '#212129',
      ':hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: 'white',
      },
    }),
  };

  const list = [
    {
      name: "domain",
      value: location.state?.ssl,
      operator: "==",
    },
  ];

  const orderBy = [
    {
      name: 'title',
      order: 'asc',
    },
  ];

  const limit = 2;

  const { documents: tagsDocuments } = useCollection('tags');
  const { documents: productsDocuments } = useCollection('sitesProduct');
  const { documents: pagesDocuments } = useCollection(
    'pages2',
    list,
    orderBy,
    limit,
  );

  const howManyPages = localStorage.getItem('page');

  const Skeletons = [];
  for (let i = 0; i < howManyPages; i++) {
    Skeletons.push(<SitePageSkelton key={i} />);
  }

  useEffect(() => {
    const tagFilter = [];
    const productFilter = [];

    for (let i = 0; i < pages?.length; i++) {
      for (let k = 0; k < pages[i].tags?.length; k++) {
        const element = pages[i].tags[k];
        for (let j = 0; j < selectedTags.length; j++) {
          if (element.value == selectedTags[j].value) {
            tagFilter.push(pages[i]);
            break;
          }
        }
      }
    }

    for (let i = 0; i < pages?.length; i++) {
      let found = false;
      for (let k = 0; k < pages[i].products?.length; k++) {
        const element = pages[i].products[k];
        for (let j = 0; j < selectedProducts?.length; j++) {
          if (element.value == selectedProducts[j].value) {
            // productFilter.push(pages[i]);
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
      if (found) {
        productFilter.push(pages[i]);
      }
    }
    // console.log(pages, tagFilter, productFilter);

    let filterPage = [];

    if (selectedTags?.length > 0) {
      filterPage = tagFilter;
    } else {
      filterPage = pagesDocuments;
    }

    if (selectedProducts?.length > 0) {
      filterPage = filterPage.filter((page) => productFilter.includes(page));
    }
    setfilterPage(filterPage);
    setTags([...tagsDocuments]);
    setProducts([...productsDocuments]);
    setIsPageLoading(true);
    setPages([...pagesDocuments]);
    // localStorage.setItem("page", pagesDocuments.length);
    console.log("loading", isPageLoading, pages);
    console.log("ssl", location);

    const timer = setTimeout(() => {
      setIsPageLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [
    tagsDocuments,
    productsDocuments,
    selectedTags,
    selectedProducts,
    pagesDocuments,
  ]);

  useEffect(() => {
    if (pagesDocuments?.length > 0) {
      setPages([...pagesDocuments]);
      setTimeout(() => {
        setIsPageLoading(false);
      }, 4000);
    }
  }, [pagesDocuments]);

  const handlePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleDeleteTag = async (id) => {
    console.log('deleted the Tag');
    await deleteDoc(doc(db, 'tags', id));
  };

  const handleDeleteProduct = async (id) => {
    console.log('Deleted the product');
    await deleteDoc(doc(db, 'sitesProduct', id));
  };

  const handleTagPopup = (id, item) => {
    setDocId(id);
    setSelectedPage(item);
    setCreateTagPopup(!createTagPopup);
    console.log(createTagPopup);
  };

  const handleProductPopup = (item) => {
    setSelectedPage(item);
    console.log(createProductPopup);
    setCreateProductPopup(!createProductPopup);
  };

  useEffect(() => {
    document.body.classList.add('custom-background');
    return () => {
      document.body.classList.remove('custom-background');
    };
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      {createTagPopup && (
        <SelectTag
          selectedPage={selectedPage}
          tags={tags}
          docId={docId}
          closeFun={setCreateTagPopup}
          title="Tag"
        />
      )}
      {createProductPopup && (
        <SelectProduct
          closeFun={handleProductPopup}
          selectedPage={selectedPage}
        />
      )}
      <SideBarHome />
      <div className="meusVideos-container ">
        <div className="meusVideos-header">
          <div className="goBackButton" onClick={() => navigate(-1)}>
            <img src={goBackIcon} alt="goBackIcon.svg" />
          </div>
          <p>
            Sites /
            {t('pages')}
          </p>
          <div className="SitesHeader">
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/sites/manage');
              }}
            >
              <img src={settingsLogo} alt="settingsLogo.svg" />
              <h6 className="settingsInst">{t('manageProductTag')}</h6>
            </div>
            <button>
              <img className="addIcon" src={Add} alt="AddIcon.svg" />
              <span>
                Novo
                {t('pages')}
              </span>
            </button>
          </div>
        </div>
        <div className="meusVideos-middle">
          <div className="paginasBody">
            <div className="stage1">
              <p>{location.state?.ssl}</p>
              <div className="stage1div">
                <div
                  className={`stage1components ${
                    showHiddenInput ? 'show3Input' : 'hide3Input'
                  } ${showHiddenInput ? ' movingLeft' : ' movingRight'}`}
                >
                  <Select
                    isMulti
                    className="select-container basis-1/2"
                    classNamePrefix="select"
                    placeholder={t('filterByTags')}
                    options={tags.map((item) => item.tags)}
                    styles={colourStyle}
                    value={selectedTags}
                    onChange={(e) => setSelectedTags(e)}
                  />
                  <Select
                    isMulti
                    className="select-container basis-1/2"
                    classNamePrefix="select"
                    placeholder={t('filterByProd')}
                    options={products.map((item) => item.products)}
                    styles={colourStyle2}
                    value={selectedProducts}
                    onChange={(e) => setSelectedProducts(e)}
                  />

                  {showHiddenInput && (
                    <input
                      className={`inputThird ${
                        showHiddenInput ? ' movingLeft' : ' movingRight'
                      }`}
                      placeholder="Search"
                      value={search}
                      // autoFocus
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  )}
                </div>
                <img
                  src={!showHiddenInput ? searchIcon : CrossIcon}
                  alt="searchIcon.svg"
                  onClick={() => setShowHiddenInput((prev) => !prev)}
                  style={{
                    zIndex: '9',
                    color: '#62627a !important',
                    cursor: 'pointer',
                    marginTop: '3px',
                  }}
                />
              </div>
            </div>
            <div className="stage2">
              <div className="stage2components">
                <p className="tagComponent">Status</p>
                <p className="tagComponent">{t('pages')}</p>
              </div>
              <div
                style={{
                  display: "grid",
                  // gridTemplateColumns: "30% 30% 10% 25% 5%",
                  gridTemplateColumns: "27% 33% 11% 24% 0%",
                  width: "100%",
                }}
              >
                <p className="tagsotherThanComponent">Tags</p>
                <p className="tagsotherThanComponent">{t('products')}</p>
                <p className="tagsotherThanComponent">Variantes</p>
                <p className="tagsotherThanComponent">{t('publication')}</p>
                <p className="tagsotherThanComponent">{t('actions')}</p>
              </div>
            </div>
            <div className="stage3">
              {!isPageLoading ? (
                <>
                  {filterPage
                    ?.filter((item) => item.slug.includes(search))
                    .map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          width: '100%',
                          marginBottom: '20px',
                        }}
                      >
                        <div
                          className="stage2components"
                          style={{ marginRight: 0 }}
                        >
                          <div className="SwitchFlex">
                            <label class="switch">
                              <input type="checkbox" />
                              <span
                                className={`${item.slug} unChecked slider round`}
                              ></span>
                            </label>
                          </div>
                          <div className="Pagina">
                            <div className="PaginaDiv">
                              <p>{location.state.ssl}</p>
                            </div>
                            <span>{`${location.state.dynamicPath}${item.slug}`}</span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "29% 30% 13.5% 16% 10%",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="stage3components"
                            // style={{ marginLeft: "9rem" }}
                          >
                            {item?.tags?.map((tag) => (
                              <>
                                <div
                                  className="tagsColors"
                                  style={{
                                    border: `1px solid ${tag?.color}`,
                                  }}
                                >
                                  <span style={{ color: tag?.color }}>
                                    {tag?.value}
                                    <span
                                      onClick={() => handleDeleteTag(tag?.value)}
                                      style={{
                                        marginLeft: '5px',
                                        cursor: 'pointer',
                                        fontSize: '15px',
                                      }}
                                    >
                                      ×
                                    </span>
                                  </span>
                                </div>
                              </>
                            ))}
                            <div className="addTags">
                              <span
                                onClick={() => handleTagPopup(item.id, item)}
                                style={{ color: '#9393B8', cursor: 'pointer' }}
                              >
                                +
                              </span>
                            </div>
                          </div>
                          <div
                            className="stage3components"
                            // style={{ marginLeft: "5rem" }}
                          >
                            {item?.products?.map((product) => (
                              <div
                                className="tagsColors"
                                style={{
                                  border: '1px solid #E1E2EB',
                                  background: 'rgba(255, 255, 255, 0.1)',
                                  display: 'flex',
                                }}
                              >
                                <span
                                  style={{
                                    color: '#E1E2EB',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                  }}
                                >
                                  {product?.value}
                                  {' '}
                                  <span
                                    onClick={() => handleDeleteProduct(product.id)}
                                    style={{
                                      marginLeft: '5px',
                                      cursor: 'pointer',
                                      fontSize: '15px',
                                      justifyContent: 'end',
                                    }}
                                  >
                                    ×
                                  </span>
                                </span>
                              </div>
                            ))}
                            <div className="addTags">
                              <span
                                style={{ color: '#9393B8', cursor: 'pointer' }}
                                onClick={() => handleProductPopup(item)}
                              >
                                +
                              </span>
                            </div>
                          </div>
                          <div className="stage3components">
                            <span className="spanElement">0</span>
                          </div>
                          <div className="stage3components">
                            <span className="spanElement dateSpan">
                              {format(
                                new Date(
                                  item.createdAt.seconds * 1000
                                    + Math.floor(item.createdAt.nanoseconds / 1e6),
                                ),
                                'dd/MM/yyyy - HH:mm',
                              )}
                            </span>
                          </div>
                          <div
                            className="stage3components"
                            onClick={handlePopup}
                            style={{ marginLeft: '7rem' }}
                          >
                            <div className="optionPopupMain">
                              <img src={optionIcon} alt="optionIcon.svg" />
                              {showPopup && (
                                <div className="optionPopup">
                                  <li>Create</li>
                                  <li>Edit</li>
                                  <li>Delete</li>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                Skeletons
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paginas;
