import React from 'react';
import '../../styles/loginPage/LoginPage.css';
import leftPaneVideo from '../../assets/images/leftComponentVideo/animation.mp4';
import MobileText from '../text/MobileText';

export const LeftComponentVideo = () => {
  const isMobile = window.innerWidth < 768;
  return (
    <div className="left-component">
      <video
        className="video-phone-view"
        autoPlay
        muted
        loop
      >
        <source src={leftPaneVideo} type="video/mp4" />
      </video>
      {isMobile && <MobileText />}
    </div>
  );
};
