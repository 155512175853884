import React, { useState, useRef } from 'react';

import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsSliders } from 'react-icons/bs';
import {
  alignOptions, nonDigitStyles, fontSizeOptions, responsiveIcons,
} from '../../../../helpers/Builder/helperArrays';
import CustomButton from '../../../customButton/CustomButton';

import DropDown from './DropDown';

const IconEdit = ({
  setselectedtab, setIconPopup, setIconDestination, propMiscValue, propMiscChange, styleValue, styleChange, propMiscCheckedValue, propMiscChecked, device, setDevice, setResponsiveMode, responsiveData, responsiveMode,
}) => {
  const [showImageUpload, setShowImageUpload] = useState(null);

  const changeObjectStyle = (style, position, e) => {
    const positionValue = e.target.value;
    const newStyleObject = {
      target: {
        value: {
          ...styleValue(style),
          [position]: positionValue,
        },
      },
    };
    styleChange(style, newStyleObject);
  };
  const [forceRender, setForceRender] = useState(false);

  const tituloRef = useRef(null);
  const tipografia = useRef(null);

  return (
    <div style={{ padding: '10px 10px', margin: '10px 0' }}>
      <span
        style={{
          color: '#72728F', fontSize: '13px', display: 'inline-block', width: '90%',
        }}
        className="cursor-pointer"
        onClick={(e) => {
          const { nextSibling } = e.target;
          if (nextSibling.style.transform == 'rotate(180deg)') {
            nextSibling.style.transform = 'rotate(0deg)';
          } else {
            nextSibling.style.transform = 'rotate(180deg)';
          }

          e.target.parentNode.children[2].classList.toggle('hidden');
        }}
        ref={tituloRef}
      >
        Imagem:
        {' '}
      </span>
      <MdKeyboardArrowDown
        color="#72728F"
        style={{
          display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'transform 0.2s ease-in-out',
        }}
        className="cursor-pointer"
        onClick={(e) => {
          tituloRef.current.click();
        }}
      />
      <div className="pt-1 pb-6" style={{ borderBottom: '2px solid #ddd' }}>

        <div className="py-1">
          <span style={{ color: '#72728F', fontSize: '13px' }}>Selecionar imagem</span>
          <div
            className="flex flex-col justify-between items-center mt-3 gap-2"
            style={{ cursor: 'pointer', position: 'relative' }}

            onMouseOver={() => {
              setShowImageUpload(true);
            }}
            onMouseLeave={() => {
              setShowImageUpload(false);
            }}
          >
            <i
              style={{ color: 'black' }}
              className={propMiscValue('icon')}
              alt="cloud"
            />
            <span
              style={{
                position: 'absolute', top: '86%', background: 'black', color: 'white', width: '100%', textAlign: 'center', display: showImageUpload ? '' : 'none',
              }}
              onClick={(e) => {
                setIconPopup(true);
                setIconDestination(e.target.innerText);
              }}
            >
              Choose Icon
            </span>
          </div>
          <span style={{ color: '#72728F', fontSize: '13px' }}>Link</span>
          <input
            style={{
              outline: 0, border: '1px solid #ddd', borderRadius: '5px', width: '100%', paddingLeft: '5px', fontSize: '13px', color: '#72728f', padding: '5px 5px',
            }}
            type="text"
            placeholder="Digite ou cole a URL..."
            value={propMiscValue('src')}
            onChange={(e) => {
              propMiscChange('src', e);
            }}
          />
          <div className="flex justify-between items-center mt-3">
            <span className="flex justify-between items-center" style={{ color: '#72728F', fontSize: '13px', width: '100%' }}>
              <span htmlFor="newTab" style={{ color: '#72728F', fontSize: '13px' }}>Abrir em nova guia</span>
              <label id="newTab" className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer hidden"
                  checked={!!propMiscCheckedValue('newTab')}
                  onChange={(e) => {
                    propMiscChecked('newTab', e);
                    setForceRender(!forceRender);
                  }}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white
          after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
          after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-700"
                />
              </label>
            </span>

          </div>
          <div className="flex justify-between items-center mt-3">
            <span className="flex justify-between items-center" style={{ color: '#72728F', fontSize: '13px', width: '100%' }}>
              <span htmlFor="lazyLoad" style={{ color: '#72728F', fontSize: '13px' }}>Lazy Load</span>
              <label id="lazyLoad" className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer hidden"
                  checked={!!propMiscCheckedValue('lazyLoad')}
                  onChange={(e) => {
                    propMiscChecked('lazyLoad', e);
                    setForceRender(!forceRender);
                  }}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white
          after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
          after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-700"
                />
              </label>
            </span>

          </div>
        </div>
        <div className="py-1">
          <span className="flex relative justify-between center-align" style={{ color: '#72728F', fontSize: '13px' }}>
            <label htmlFor="textType" style={{ color: '#72728F', fontSize: '13px' }}>Alinhamento</label>
            <DropDown responsiveData={responsiveData} responsiveMode={responsiveMode} setDevice={setDevice} setResponsiveMode={setResponsiveMode} position="40%" />
            <select
              style={{
                fontSize: '13px', border: '1px solid #ddd', textAlign: 'center', outline: 0, color: '#72728F', width: '5rem',
              }}
              className="px-2"
              name="textType"
              id="textType"
              onChange={(e) => {
                changeObjectStyle('textAlign', device, e);
                styleChange('currentTextAlign', e);
              }}
            >
              {alignOptions.map((align) => <option value={align} selected={styleValue('textAlign')[device] === align}>{align}</option>)}
            </select>
          </span>
        </div>
      </div>
      <div className="py-4 ">
        <span
          style={{
            color: '#72728F', fontSize: '13px', cursor: 'pointer', display: 'inline-block', width: '90%',
          }}
          onClick={(e) => {
            const { nextSibling } = e.target;
            if (nextSibling.style.transform == 'rotate(180deg)') {
              nextSibling.style.transform = 'rotate(0deg)';
            } else {
              nextSibling.style.transform = 'rotate(180deg)';
            }

            e.target.parentNode.children[2].classList.toggle('hidden');
          }}
          ref={tipografia}
        >
          Tipografia
        </span>
        <MdKeyboardArrowDown
          color="#72728F"
          style={{
            display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'transform 0.2s ease-in-out',
          }}
          className="cursor-pointer"
          onClick={(e) => {
            tipografia.current.click();
          }}
        />
        <div style={{ transition: 'all 0.2s ease-in-out' }}>
          <div className="py-1 flex items-center justify-between">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Cor</span>
            <input
              type="color"
              className="w-7 h-7"

              onChange={(e) => {
                styleChange('color', e);
              }}
              value={styleValue('color')}
            />
          </div>
          <div className="py-1 flex items-center justify-between">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Cor de fundo</span>
            <input
              type="color"
              className="w-7 h-7"

              onChange={(e) => {
                styleChange('backgroundColor', e);
              }}
              value={styleValue('backgroundColor')}
            />
          </div>
          <div className="py-1">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Contorno</span>
          </div>
          <div className="py-1">
            <span className="flex flex-col relative" style={{ color: '#72728F', fontSize: '13px', textTransform: 'capitalize' }}>
              <label className="flex items-center" htmlFor="borderStyle" style={{ color: '#72728F', fontSize: '13px' }}>
                Tamanho
                <DropDown responsiveData={responsiveData} responsiveMode={responsiveMode} setDevice={setDevice} setResponsiveMode={setResponsiveMode} position="30%" />
              </label>
              <div className="mt-2 flex gap-2">
                <input
                  type="range"
                  color="#72728F"
                  onChange={(e) => {
                    changeObjectStyle('fontSize', device, e);
                  }}
                  value={styleValue('fontSize')[device]}
                  max="300"
                />
                <input
                  type="number"
                  max={300}
                  onChange={(e) => {
                    changeObjectStyle('fontSize', device, e);
                  }}
                  value={styleValue('fontSize')[device]}
                  style={{ width: '45px', color: 'black' }}
                />
              </div>

            </span>
          </div>
        </div>
        <div style={{ margin: '10px 0' }}>
          <CustomButton
            loading={false}
            disabled={false}
            textColor="#fff"
            style={{ textAlign: 'center' }}
            Icon={BsSliders}
            buttontext="Editar tipografia"
            backgroundColor="#62627A"
            variant="contained"
            onClick={() => {
              setselectedtab('slider');
            }}
          />
        </div>

      </div>
    </div>
  );
};

export default IconEdit;
