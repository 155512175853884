import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FiSave, FiPlus } from 'react-icons/fi';
import VideoRender from './VideoRender';
import AccordionRender from './AccordionRender';
import FacebookRender from './FacebookRender';
import DividerRender from './DividerRender';
import ProgressbarRender from "./ProgressbarRender";
import StarRatingRender from "./StarRatingRender";
import CounterRender from "./CounterRender";
import SocialIconsRender from "./SocialIconsRender";
import { alignableTags } from '../../../../helpers/Builder/helperArrays';
import { giveContent, giveItem } from '../../../../helpers/Builder/helperFxns';
import blocks from '../../../../helpers/Builder/content_copy';

const RenderComponent = ({
  section, sectionArray, setsectionDrop, setSelectedSectionIndex, section_index, setColDrop, setselectedComponent, setStiloShow, row_index, row, setshowDrawer, setElementType, selectedComponent, setVideoController, setIconPopup, setImagePopup, setImageDestination, responsiveMode, setsectionArray, setSelectedColumnIndex, setSelectedContent, setSelectedStyle, setDragOver, setComponentArray, ComponentArray, DragOver, draggedKey, setDraggedKey, formerIndex, setFormerIndex, currentDrag, setcurrentDrag, firstIndex, setFirstIndex, mvBtm, setMvBtm, mvUp, setMvUp,
}) => {
  const [forecRender, setForcerRender] = useState(false);

  const [enter, setEnter] = useState('');
  const [activeComponent, setActiveComponent] = useState(null);
  const [firstkey, setFirstkey] = useState(null);
  const renderPerType = {
    text: (comp) => (
      <comp.tag style={{
        ...comp.style, width: '100%', borderRadius: `${comp.style.borderTopLeftRadius[responsiveMode.mode]}px ${comp.style.borderTopRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomLeftRadius[responsiveMode.mode]}px`, borderWidth: `${comp.style.border.width}px`, borderColor: `${comp.style.border.color}`, borderStyle: `${comp.style.border.style}`, padding: `${comp.style.paddingTop[responsiveMode.mode]}px ${comp.style.paddingRight[responsiveMode.mode]}px ${comp.style.paddingBottom[responsiveMode.mode]}px ${comp.style.paddingLeft[responsiveMode.mode]}px`, margin: `${comp.style.marginTop[responsiveMode.mode]}px ${comp.style.marginRight[responsiveMode.mode]}px ${comp.style.marginBottom[responsiveMode.mode]}px ${comp.style.marginLeft[responsiveMode.mode]}px`,
      }}
      >
        {comp.misc.href ? (
          <a href={comp.misc.href} target={comp.misc.href}>{comp.content}</a>
        ) : (comp.content) }
      </comp.tag>
    ),
    button: (comp) => (
      <comp.tag
        className="bg-violet-500"
        style={{
          display: 'inline-block', width: comp.style.textAlign == 'justify' ? '100%' : 'fit-content', borderRadius: `${comp.style.borderTopLeftRadius[responsiveMode.mode]}px ${comp.style.borderTopRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomLeftRadius[responsiveMode.mode]}px`, borderWidth: `${comp.style.border.width}px`, borderColor: `${comp.style.border.color}`, borderStyle: `${comp.style.border.style}`, padding: `${comp.style.paddingTop[responsiveMode.mode]}px ${comp.style.paddingRight[responsiveMode.mode]}px ${comp.style.paddingBottom[responsiveMode.mode]}px ${comp.style.paddingLeft[responsiveMode.mode]}px`, margin: `${comp.style.marginTop[responsiveMode.mode]}px ${comp.style.marginRight[responsiveMode.mode]}px ${comp.style.marginBottom[responsiveMode.mode]}px ${comp.style.marginLeft[responsiveMode.mode]}px`, color: enter == comp.comp_key ? comp.style.hoverColor : comp.style.color, backgroundColor: enter == comp.comp_key ? comp.style.hoverBgColor : comp.style.backgroundColor, fontSize: `${comp.style.fontSize[responsiveMode.mode]}px`, textAlign: comp.style.textAlign[responsiveMode.mode], cursor: 'pointer',
        }}
        onMouseEnter={() => setEnter(
          comp.comp_key,
        )}
        onMouseLeave={() => setEnter('')}
      >
        {comp.content}
      </comp.tag>
    ),
    video: (comp) => (<VideoRender comp={comp} setVideoController={setVideoController} responsiveMode={responsiveMode} />),
    image: (comp) => (
      <comp.tag
        style={{
          ...comp.style, display: 'inline-block', width: '100%', borderRadius: `${comp.style.borderTopLeftRadius[responsiveMode.mode]}px ${comp.style.borderTopRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomLeftRadius[responsiveMode.mode]}px`, borderWidth: `${comp.style.border.width}px`, borderColor: `${comp.style.border.color}`, borderStyle: `${comp.style.border.style}`, padding: `${comp.style.paddingTop[responsiveMode.mode]}px ${comp.style.paddingRight[responsiveMode.mode]}px ${comp.style.paddingBottom[responsiveMode.mode]}px ${comp.style.paddingLeft[responsiveMode.mode]}px`, margin: `${comp.style.marginTop[responsiveMode.mode]}px ${comp.style.marginRight[responsiveMode.mode]}px ${comp.style.marginBottom[responsiveMode.mode]}px ${comp.style.marginLeft[responsiveMode.mode]}px`, height: '100%',
        }}
        src={comp.misc.href || comp.misc.src}
      />
    ),
    icon: (comp) => (
      <comp.tag
        style={{
          ...comp.style, display: 'inline-block', width: 'fit-content', borderRadius: `${comp.style.borderTopLeftRadius[responsiveMode.mode]}px ${comp.style.borderTopRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomLeftRadius[responsiveMode.mode]}px`, borderWidth: `${comp.style.border.width}px`, borderColor: `${comp.style.border.color}`, borderStyle: `${comp.style.border.style}`, padding: `${comp.style.paddingTop[responsiveMode.mode]}px ${comp.style.paddingRight[responsiveMode.mode]}px ${comp.style.paddingBottom[responsiveMode.mode]}px ${comp.style.paddingLeft[responsiveMode.mode]}px`, margin: `${comp.style.marginTop[responsiveMode.mode]}px ${comp.style.marginRight[responsiveMode.mode]}px ${comp.style.marginBottom[responsiveMode.mode]}px ${comp.style.marginLeft[responsiveMode.mode]}px`, fontSize: `${comp.style.fontSize[responsiveMode.mode]}px`,
        }}
        className={comp.misc.icon}
      />
    ),
    accordion: (comp) => (<AccordionRender comp={comp} setIconPopup={setIconPopup} responsiveMode={responsiveMode} />),
    facebook: (comp) => (<FacebookRender comp={comp} setImagePopup={setImagePopup} setImageDestination={setImageDestination} responsiveMode={responsiveMode} />),
    divider: (comp) => (<DividerRender comp={comp} responsiveMode={responsiveMode} />),
    progressBar: (comp) => (
      <ProgressbarRender comp={comp} />
    ),
    starRating: (comp) => <StarRatingRender comp={comp} />,
    counter: (comp) => <CounterRender comp={comp} />,
    socialIcons: (comp) => <SocialIconsRender comp={comp} />,
  };

  const setAlignment = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
    justify: 'space-between',
  };

  console.log(section, 'sectionnn')

  return (

    <div
      droppable

      onDragOver={(e) => {
        e.preventDefault();
        if (!section.columns[row_index].elements.length) {
          setDragOver(`${section_index}/${row_index}`);
          return;
        }
        if (DragOver?.length && currentDrag?.length) {
          const dragOver = DragOver.split('/');
          // console.log(currentDrag, 'currentDrag');
          const current = currentDrag.split('/');
          const [sec, col, element] = dragOver;
          const [cSec, cCol, cElement] = current;
          console.log(current, dragOver, 'currentDrage');
          if (
            (sec == cSec && col == cCol) && parseInt(element) > parseInt(cElement)) {
            setMvBtm(true);
            setMvUp(false);
          } else if ((sec == cSec && col == cCol) && parseInt(element) < parseInt(cElement)) {
            setMvUp(true);
            setMvBtm(false);
            setFirstIndex(false);
          }
        }
      }}

      onDrop={(e) => {
        if (e.dataTransfer.getData('element').includes('comp')) {
          
          const comp_obj = JSON.parse(e.dataTransfer.getData('element'));
          const dropSection = sectionArray.filter((s) => s.sec_key == section.sec_key);

          const dragOver = DragOver.split('/');
          console.log(currentDrag, 'currentDrag');
          const current = currentDrag.split('/');
          console.log(dragOver, 'dragOver');
          const [sec, col, element] = dragOver;
          const [cSec, cCol, cElement] = current;
          console.log(current, dragOver, 'currentDrage');
          if (!firstIndex && sec == cSec && col == cCol) {
            const former = sectionArray[parseInt(sec)].columns[parseInt(col)].elements.filter((e) => e.comp_key != draggedKey);
            const latter = sectionArray[parseInt(sec)].columns[parseInt(col)].elements.filter((e) => e.comp_key == draggedKey);
            const temp = former.slice(0, parseInt(element)).concat(latter, former.slice(parseInt(element)));
            sectionArray[parseInt(sec)].columns[parseInt(col)].elements = temp;

            setselectedComponent(comp_obj.comp_key);
            const temparr = sectionArray;
            setsectionArray(temparr);
            setColDrop(row);
            setsectionDrop(dropSection[0]);
            setSelectedStyle(comp_obj.style);
            setDragOver(null);
            return;
          }
          console.log(formerIndex, "former");
          const former = sectionArray[formerIndex].columns[parseInt(cCol)].elements.filter((e) => e.comp_key != draggedKey);
          sectionArray[formerIndex].columns[parseInt(cCol)].elements = former;

          console.log('former');

          if (firstIndex) {
            console.log('why why');
            dropSection[0].columns[row].elements.unshift(comp_obj);
            console.log(dropSection[0], 'drop first');
          } else {
            dropSection[0].columns[row].elements.splice(parseInt(element) + 1, 0, comp_obj);
          }

          setselectedComponent(comp_obj.comp_key);

          const temparr = sectionArray;
          setsectionArray(temparr);
          console.log(dropSection[0], 'drop first');

          console.log(sectionArray, 'array');
          setColDrop(row);
          setsectionDrop(dropSection[0]);
          setSelectedStyle(comp_obj.style);
          setDragOver(null);
          setSelectedContent(comp_obj.content);
          setElementType(comp_obj.misc.type);
          setSelectedSectionIndex(section_index);
          setSelectedColumnIndex(row_index);
          setComponentArray([comp_obj, ...ComponentArray]);
          setStiloShow(true);
          setshowDrawer(true);
          return;
        }
        const ele = e.dataTransfer.getData('element');
        console.log(ele, 'ele');
        setDragOver(null);
        setColDrop(row);

        const dropSection = sectionArray.filter((s) => s.sec_key == section.sec_key);
        const comp_key = uuidv4();

        const comp_obj = {
          comp_key,
          tag: blocks[ele].tag,
          ...(giveContent(blocks[ele].misc.type) && { content: blocks[ele].content }),
          misc: blocks[ele].misc,
          style: blocks[ele].style,
          ...(giveItem(blocks[ele].misc.type) && { items: blocks[ele].items.map((item) => ({ ...item })) }),
        };

        if (firstIndex) {
          dropSection[0].columns[row].elements.unshift(comp_obj);
        } else {
          const dragOver = DragOver.split('/');
          console.log(dragOver, 'dragOver');
          const [sec, col, element] = dragOver;
          dropSection[0].columns[col].elements.splice(parseInt(element) + 1, 0, comp_obj);
        }

        setselectedComponent(comp_obj.comp_key);
        const temparr = sectionArray;

        setsectionArray(temparr);
        console.log(sectionArray, 'aarao');
        setselectedComponent(comp_obj.comp_key);
        setsectionArray(temparr);
        console.log(dropSection[0]);
        setsectionDrop(dropSection[0]);
        setSelectedSectionIndex(section_index);
        setSelectedColumnIndex(row_index);
        setComponentArray([comp_obj, ...ComponentArray]);
        setStiloShow(true);
        setSelectedContent(blocks[ele].content);
        setSelectedStyle(blocks[ele].style);
        setElementType(blocks[ele].misc.type);
      }}
      style={{
        opacity: DragOver == `${section_index}/${row_index}` ? 0.5 : 1,
        background: DragOver == `${section_index}/${row_index}` ? '#E1E2EB' : null,
        border: '2px dashed #E1E2EB',
      }}
    >
      <div
        className="setFirstItem"
        style={{
          backgroundColor: firstkey == section.sec_key && firstIndex ? 'blue' : 'white', width: '100%', height: '1rem', cursor: 'pointer',

        }}
        onDragEnter={() => {
          setFirstIndex(true);
          setMvBtm(false);
          setFirstkey(section.sec_key);
        }}
        onDragLeave={() => {
          setFirstIndex(false);
          setMvBtm(true);
          setFirstkey(null);
        }}
        onDrop={() => {
          setFirstIndex(false);
          console.log('why why drop');
          setFirstkey(null);
        }}
      />
      {
       section.columns[row].elements.length > 0
         ? section.columns[row].elements.map((comp, j) => (
           <div
             style={{
               justifyContent: !alignableTags.includes(comp.tag) && setAlignment[comp.style.textAlign[responsiveMode.mode]],
               width: '100%',
             }}

             onMouseEnter={() => {
               setDraggedKey(comp.comp_key);
               setFormerIndex(section_index);
             }}
             onDragOver={(e) => {
               e.preventDefault();
               setDragOver(`${section_index}/${row_index}/${j}`);
               console.log(`${section_index}/${row_index}/${j}`, 'dragover');
             }}
             onDrop={() => {
               setMvBtm(false);
               setMvUp(false);
               setDragOver(null);
               setcurrentDrag(null);
             }}
           >
             <div style={{
               backgroundColor: DragOver == `${section_index}/${row_index}/${j}` && mvUp ? 'blue' : null, width: '100%', height: '1rem', marginTop: '5px', marginBottom: '5px',
             }}
             />

             <div
               className="flex justify-between"
               onClick={() => {
                 const dropSection = sectionArray.filter((s) => s.sec_key == section.sec_key);
                 setsectionDrop(dropSection[0]);
                 setSelectedSectionIndex(section_index);
                 setColDrop(`${row_index}`);
                 setselectedComponent(comp.comp_key);
                 setStiloShow(true);
                 setshowDrawer(true);
                 setElementType(comp.misc.type);
               }}
               style={{
                 border: comp.comp_key == selectedComponent ? '2px solid blue' : '2px solid transparent', cursor: 'pointer', color: 'blue', width: comp.style.width[responsiveMode.mode] === 'fit-content' ? comp.style.width[responsiveMode.mode] : `${comp.style.width[responsiveMode.mode]}%`, textAlign: comp.style.textAlign[responsiveMode.mode], height: comp.tag == 'img' ? `${comp.style.height[responsiveMode.mode]}px` : 'auto',
               }}
               onMouseEnter={() => {
                 setActiveComponent(comp.comp_key);
                 console.log(activeComponent);
               }}
               onMouseLeave={() => {
                 setActiveComponent(null);
               }}
             >
               {renderPerType[comp.misc.type](comp)}
               <div
                 draggable
                 onDragStart={(e) => {
                   e.dataTransfer.setData('element', JSON.stringify(comp));
                   console.log(comp.comp_key);
                   setcurrentDrag(`${section_index}/${row_index}/${j}`);
                   console.log('dragstart', currentDrag);
                   setMvBtm(true);
                 }}
               >
                 <i className="fa-solid fa-edit" style={{ color: 'black', display: comp.comp_key == activeComponent ? 'block' : 'none' }} />
               </div>

             </div>
             <div style={{
               backgroundColor: DragOver == `${section_index}/${row_index}/${j}` && mvBtm ? 'blue' : null, width: '100%', height: '1rem', marginTop: '5px', marginBottom: '5px',
             }}
             />
           </div>

         )) : (
           <div
             className="flex justify-center"
             style={{
               position: 'relative', top: '-29%', color: '#72728F', fontSize: '13px',
             }}
           >
             <FiPlus size={40} className="plus" />
           </div>
         )

}

    </div>

  );
};

export default RenderComponent;