import React from "react";
import TextEdit from "./element-edit/TextEdit";
import ButtonEdit from "./element-edit/ButtonEdit";
import VideoEdit from "./element-edit/VideoEdit";
import ImageEdit from "./element-edit/ImageEdit";
import IconEdit from "./element-edit/IconEdit";
import AccordionEdit from "./element-edit/AccordionEdit";
import FacebookEdit from "./element-edit/FacebookEdit";
import DividerEdit from "./element-edit/DividerEdit";
import ProgressbarEdit from "./element-edit/ProgressbarEdit";
import StarRatingEdit from "./element-edit/StarRatingEdit";
import CounterEdit from "./element-edit/CounterEdit";
import SocialIconsEdit from "./element-edit/SocialIconsEdit";

const DynamicEditInterface = ({
  setselectedtab,
  elementType,
  videoController,
  setImagePopup,
  setIconPopup,
  setIconDestination,
  propValue,
  propChange,
  propMiscValue,
  propMiscChange,
  styleValue,
  styleChange,
  propMiscCheckedValue,
  propMiscChecked,
  device,
  setDevice,
  setResponsiveMode,
  responsiveMode,
  responsiveData,
}) => {
  const interfaceObject = {
    text: (
      <TextEdit
        setselectedtab={setselectedtab}
        propValue={propValue}
        propChange={propChange}
        propMiscValue={propMiscValue}
        propMiscChange={propMiscChange}
        styleValue={styleValue}
        styleChange={styleChange}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveMode={responsiveMode}
        responsiveData={responsiveData}
      />
    ),
    button: (
      <ButtonEdit
        setselectedtab={setselectedtab}
        propValue={propValue}
        propChange={propChange}
        propMiscValue={propMiscValue}
        propMiscChange={propMiscChange}
        styleValue={styleValue}
        styleChange={styleChange}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveData={responsiveData}
        responsiveMode={responsiveMode}
      />
    ),
    video: (
      <VideoEdit
        setselectedtab={setselectedtab}
        videoController={videoController}
        propMiscValue={propMiscValue}
        propMiscChange={propMiscChange}
        styleValue={styleValue}
        styleChange={styleChange}
        propMiscCheckedValue={propMiscCheckedValue}
        propMiscChecked={propMiscChecked}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveData={responsiveData}
        responsiveMode={responsiveMode}
      />
    ),
    image: (
      <ImageEdit
        setselectedtab={setselectedtab}
        setImagePopup={setImagePopup}
        propMiscValue={propMiscValue}
        propMiscChange={propMiscChange}
        styleValue={styleValue}
        styleChange={styleChange}
        propMiscCheckedValue={propMiscCheckedValue}
        propMiscChecked={propMiscChecked}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveData={responsiveData}
        responsiveMode={responsiveMode}
      />
    ),
    icon: (
      <IconEdit
        setselectedtab={setselectedtab}
        setIconPopup={setIconPopup}
        setIconDestination={setIconDestination}
        propMiscValue={propMiscValue}
        propMiscChange={propMiscChange}
        styleValue={styleValue}
        styleChange={styleChange}
        propMiscCheckedValue={propMiscCheckedValue}
        propMiscChecked={propMiscChecked}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveData={responsiveData}
        responsiveMode={responsiveMode}
      />
    ),
    accordion: (
      <AccordionEdit
        setselectedtab={setselectedtab}
        setIconPopup={setIconPopup}
        setIconDestination={setIconDestination}
        propValue={propValue}
        propChange={propChange}
        propMiscValue={propMiscValue}
        propMiscChange={propMiscChange}
        styleValue={styleValue}
        styleChange={styleChange}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveData={responsiveData}
        responsiveMode={responsiveMode}
      />
    ),
    facebook: (
      <FacebookEdit
        setselectedtab={setselectedtab}
        propValue={propValue}
        propChange={propChange}
        styleValue={styleValue}
        styleChange={styleChange}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveData={responsiveData}
        responsiveMode={responsiveMode}
      />
    ),
    divider: (
      <DividerEdit
        setselectedtab={setselectedtab}
        setIconPopup={setIconPopup}
        setIconDestination={setIconDestination}
        propMiscValue={propMiscValue}
        propMiscChange={propMiscChange}
        styleValue={styleValue}
        styleChange={styleChange}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveData={responsiveData}
        responsiveMode={responsiveMode}
      />
    ),
    progressBar: (
      <ProgressbarEdit
        setselectedtab={setselectedtab}
        propValue={propValue}
        propChange={propChange}
        propMiscValue={propMiscValue}
        propMiscChange={propMiscChange}
        styleValue={styleValue}
        styleChange={styleChange}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveData={responsiveData}
        responsiveMode={responsiveMode}
      />
    ),

    starRating: (
      <StarRatingEdit
        setselectedtab={setselectedtab}
        propValue={propValue}
        propChange={propChange}
        propMiscValue={propMiscValue}
        propMiscChange={propMiscChange}
        styleValue={styleValue}
        styleChange={styleChange}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveData={responsiveData}
        responsiveMode={responsiveMode}
      />
    ),
    counter: (
      <CounterEdit
        setselectedtab={setselectedtab}
        propValue={propValue}
        propChange={propChange}
        propMiscValue={propMiscValue}
        propMiscChange={propMiscChange}
        styleValue={styleValue}
        styleChange={styleChange}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveData={responsiveData}
        responsiveMode={responsiveMode}
      />
    ),

    socialIcons: (
      <SocialIconsEdit
        setselectedtab={setselectedtab}
        setIconPopup={setIconPopup}
        setIconDestination={setIconDestination}
        propValue={propValue}
        propChange={propChange}
        propMiscValue={propMiscValue}
        propMiscChange={propMiscChange}
        styleValue={styleValue}
        styleChange={styleChange}
        device={device}
        setDevice={setDevice}
        setResponsiveMode={setResponsiveMode}
        responsiveData={responsiveData}
        responsiveMode={responsiveMode}
      />
    ),
  };
  return (
    <div className="editstuffs" style={{ overflowY: "auto" }}>
      {interfaceObject[elementType]}
    </div>
  );
};

export default DynamicEditInterface;
