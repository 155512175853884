import React, { useState } from 'react';
import '../../../../styles/builder/FacebookRender.css';

const FacebookRender = ({
  comp, setImagePopup, setImageDestination, responsiveMode,
}) => {
  const [edit, setEdit] = useState(null);
  const [forceRender, setForceRender] = useState(false);

  return (
    <div
      style={{
        ...comp.style,
        borderRadius: `${comp.style.borderTopLeftRadius[responsiveMode.mode]}px ${comp.style.borderTopRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomLeftRadius[responsiveMode.mode]}px`,
        borderWidth: `${comp.style.border.width}px`,
        borderStyle: `${comp.style.border.style}`,
        borderColor: `${comp.style.border.color}`,
        padding: `${comp.style.paddingTop[responsiveMode.mode]}px ${comp.style.paddingRight[responsiveMode.mode]}px ${comp.style.paddingBottom[responsiveMode.mode]}px ${comp.style.paddingLeft[responsiveMode.mode]}px`,
        margin: `${comp.style.marginTop[responsiveMode.mode]}px ${comp.style.marginRight[responsiveMode.mode]}px ${comp.style.marginBottom[responsiveMode.mode]}px ${comp.style.marginLeft[responsiveMode.mode]}px`,
      }}
      spellCheck="false"
      className="flexBox"
    >
      {comp.items.map((item, index) => (
        <div key={index} className="flexBox">
          <div className="facebook__parent">
            <div
              className="facebook__thumb"
              onClick={() => {
                setImagePopup(true);
                setImageDestination(index);
              }}
            >
              <img
                src={item.picture}
                alt=""
                className="facebook__thumb_image"
              />
            </div>
            <div className="information">
              <div className="facebook__text">
                <div className="facebook__username">{item.username}</div>
                <div className="facebook__comment">{item.comment}</div>
              </div>
              <div className="miscellanous">
                <div className="miscellanous_left">
                  <div className="miscellanous_text">Curtir</div>
                  <p className="">·</p>
                  <div
                    onClick={() => {
                      const newChild = {
                        picture: 'https://i.pravatar.cc/150?img=3',
                        username: 'John Doe',
                        comment:
                          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.',
                        likes: 15,
                        time: 1,
                        timeUnit: 'h',
                      };
                      // item.children = [...item.children, newChild]
                      item.children.push(newChild);
                      setForceRender(!forceRender);
                    }}
                    className="miscellanous_text"
                  >
                    Responder
                  </div>
                  <p className="">·</p>
                  <div className="miscellanous_text">
                    {item.time}
                    {item.timeUnit}
                  </div>
                </div>
                <div className="likesComponent">
                  <img
                    className="likeImage"
                    src="https://img.imageboss.me/atm/cdn/p/l.png"
                  />
                  <img
                    className="heartImage"
                    src="https://img.imageboss.me/atm/cdn/p/h.png"
                  />
                  <span>{item.likes}</span>
                </div>
              </div>
            </div>
          </div>
          {item.children && item.children.length > 0 && (
            <div className="facebook__children">
              {item.children.map((child, i) => (
                <div className="facebook__child">
                  <div
                    className="facebook__thumb"
                    onClick={() => {
                      setImagePopup(true);
                      // child of item with index
                      setImageDestination([index, i]);
                    }}
                  >
                    <img
                      src={child.picture}
                      alt=""
                      className="facebook__thumb_image"
                    />
                  </div>
                  <div className="information">
                    <div className="facebook__text">
                      <div className="facebook__username">{child.username}</div>
                      <div className="facebook__comment">{child.comment}</div>
                    </div>
                    <div className="miscellanous">
                      <div className="miscellanous_left">
                        <div className="miscellanous_text">Curtir</div>
                        <p className="">·</p>
                        <div
                          onClick={() => {
                            const newChild = {
                              picture: 'https://i.pravatar.cc/150?img=3',
                              username: 'John Doe',
                              comment:
                                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.',
                              likes: 15,
                              time: 1,
                              timeUnit: 'h',
                            };
                            // item.children = [...item.children, newChild]
                            item.children.push(newChild);
                          }}
                          className="miscellanous_text"
                        >
                          Responder
                        </div>
                        <p className="">·</p>
                        <div className="miscellanous_text">
                          {child.time}
                          {child.timeUnit}
                        </div>
                      </div>
                      <div className="likesComponent">
                        <img
                          className="likeImage"
                          src="https://img.imageboss.me/atm/cdn/p/l.png"
                        />
                        <img
                          className="heartImage"
                          src="https://img.imageboss.me/atm/cdn/p/h.png"
                        />
                        <span>{child.likes}</span>
                      </div>
                    </div>
                  </div>
                  {/* <div
                  onClick={() => {
                    item.children.splice(i, 1);
                    setForceRender(!forceRender);
                  }}
                >
                  <i className="
                                text-[red] cursor-pointer fas fa-trash-alt position-absolute right-0 top-0 mt-2 mr-2
                                "
                  />
                </div> */}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FacebookRender;
