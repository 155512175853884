import React from 'react';
import brushIcon from '../../../assets/icons/builder/brushIcon.svg';
import sliderIcon from '../../../assets/icons/builder/slidersIcon.svg';

const DrawerTabHeader = ({ setselectedtab, selectedtab }) => (
  <div className="" style={{ display: 'flex' }}>
    <div
      onClick={() => {
        setselectedtab('brush');
      }}
      style={{
        flex: 6, backgroundColor: selectedtab == 'brush' ? '#D3D4E0' : '#E1E2EB', display: 'flex', justifyContent: 'center', cursor: 'pointer', padding: '8px',
      }}
      className=""
    >
      <img alt="brushicon" src={brushIcon} className="" style={{ width: '24px', height: '24px' }} />
    </div>
    <div
      onClick={() => {
        setselectedtab('slider');
      }}
      style={{
        flex: 6, backgroundColor: selectedtab == 'slider' ? '#D3D4E0' : '#E1E2EB', display: 'flex', justifyContent: 'center', cursor: 'pointer', padding: '8px',
      }}
      className=""
    >
      <img src={sliderIcon} className="" style={{ width: '24px', height: '24px' }} />
    </div>
  </div>
);

export default DrawerTabHeader;
