import React, { useState, useRef, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router';
import { Stream } from '@cloudflare/stream-react';
import '../../styles/videos/meusVideos.css';

import exlametryMark from '../../assets/icons/nomeDoVideo/exlametryMarkinCircle.png';
import clockIcon from '../../assets/icons/nomeDoVideo/clock.png';
import { auth, db } from '../../config/firebase';
import { apiCall } from '../../service/commonService';

import LineRechartComponent from '../../components/chart/MetricsChart';

export default function SelectProduct() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const uid = params.get('id');
    const videoRef = useRef(null);
    const refStream = useRef();

    const [cTime, setCTime] = useState(0);
    const [selectedOption, setSelectedOption] = useState('all');
    const [videoData, setVideoData] = useState([]);
    const [analyticsData, setAnalyticsData] = useState([]);
    const [selected, setSelected] = useState('metricas');
    const [analyticsViews, setAnalyticsViews] = useState(0);
    const [analyticsPlays, setAnalyticsPlays] = useState(0);
    const [analyticsPlayRate, setAnalyticsPlayRate] = useState(0);
    const [analyticsStart, setAnalyticsStart] = useState("");
    const [analyticsEnd, setAnalyticsEnd] = useState("");
    const [analyticsEngagement, setAnalyticsEngagement] = useState(0);
    const [analyticsUniqueViews, setAnalyticsUniqueViews] = useState(0);
    const [totalViews, setTotalViews] = useState(null);
    const [totalMinutesPlayed, setTotalMinutesPlayed] = useState(null);
    const [isFetchingAnalytics, setFetchingAnalytics] = useState(true);
    const [data, setData] = useState(null);
    const [whatTime, setWhatTime] = useState(0);
    const [duration, setDuration] = useState('all');
    const [analyticsDuration, setAnalyticsDuration] = useState([
        { label: 'Desde sempre', value: 'all', selected: true },
        { label: 'Hoje', value: '1', selected: false },
        { label: 'Últimos 7 dias', value: 7, selected: false },
        { label: 'Últimos 14 dias', value: 14, selected: false },
        { label: 'Últimos 30 dias', value: 30, selected: false },
        { label: 'Período personalizado', value: 'custom', selected: false },
    ]);

    useEffect(() => {
        // setFetchingAnalytics(true);

        if (analyticsData) {
            let realPlays = 0;
            console.log(analyticsData.data);
            for (let s = 0; s < analyticsData?.data?.length; s++) {
                console.log(analyticsData.data[s].timed);
                if (analyticsData.data[s].timed === 0 && analyticsData.data[s].total) {
                    if (analyticsData.data[s].total > analyticsData.data[s + 1].total) {
                        console.log('s => ', s);
                        console.log(analyticsData.data);
                        realPlays = analyticsData.data[s].total;
                        console.log(analyticsData.data[s]);
                        console.log('+ 1 ', analyticsData.data[s + 1]);
                        console.log(realPlays);
                        break;
                    }
                } else if (
                    analyticsData.data[s].timed === 1
                    && analyticsData.data[s].total
                ) {
                    realPlays = analyticsData.data[s].total;
                    console.log(realPlays);
                    break;
                } else if (
                    analyticsData.data[s].timed === 2
                    && analyticsData.data[s].total
                ) {
                    realPlays = analyticsData.data[s].total;
                    break;
                } else if (
                    analyticsData.data[s].timed === 3
                    && analyticsData.data[s].total
                ) {
                    realPlays = analyticsData.data[s].total;
                    break;
                } else if (
                    analyticsData.data[s].timed === 4
                    && analyticsData.data[s].total
                ) {
                    realPlays = analyticsData.data[s].total;
                    break;
                } else if (
                    analyticsData.data[s].timed === 5
                    && analyticsData.data[s].total
                ) {
                    realPlays = analyticsData.data[s].total;
                    break;
                }
            }

            if (
                (analyticsData.visitorsLeft ?? 0)
                + (analyticsData.visitorsCompleted ?? 0)
            ) {
                realPlays = (analyticsData.visitorsLeft ?? 0)
                    + (analyticsData.visitorsCompleted ?? 0);
            }

            // const plays = (analyticsData.visitorsLeft ?? 0)+(analyticsData.visitorsCompleted ?? 0);
            const totalImpressions = analyticsData.visitorsviews ?? 0;
            setData(analyticsData);
            setAnalyticsPlays(realPlays);
            setTotalViews(
                (analyticsData.visitorsviews
                    || analyticsData.visitorsLeft + analyticsData.visitorsCompleted)
                ?? 0,
            );
            setAnalyticsViews(totalImpressions);
            setTotalMinutesPlayed(analyticsData.visitorstotalminutesplayed ?? 0);
            setAnalyticsUniqueViews(analyticsData.visitorsuniqueViews ?? 0);

            if (
                analyticsData.visitorsviews
                || analyticsData.visitorsLeft
                || analyticsData.visitorsCompleted
            ) {
                const playRate = (realPlays * 100) / analyticsUniqueViews;
                setAnalyticsPlayRate(playRate);
            } else {
                setAnalyticsPlayRate(0);
            }
            //   setFetchingAnalytics(false);
        }

            // setFetchingAnalytics(false);

    }, [analyticsData, totalViews]);

    useEffect(() => {
        if (whatTime) {
            videoRef.current.currentTime = whatTime;
            videoRef.current.play().then(() => {
                videoRef.current.pause();
            });
        }
    }, [whatTime]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = true;
        }
        console.log('sangram', videoRef);
    }, [videoRef.current]);

    const getNextUpdateTime = () => {
        const time = new Date().getUTCHours() - 3;
        if (time >= 5 && time < 11) {
            return 'às 11:00, ';
        }
        if (time >= 11 && time < 17) {
            return 'às 17:00, ';
        }
        if (time >= 17 && time < 23) {
            return 'às 23:00, ';
        }
        if (
            (time === 23 || time >= 0 || time === -1 || time === -2 || time === -3)
            && (time === 23
                || time >= 0
                || time < 5
                || time === -1
                || time === -2
                || time === -3)
        ) {
            return 'às 05:00, ';
        }

        return 'ERRO - Não foi possível localizar a próxima atualização das métricas';
    };

    useEffect(() => {
        if (refStream.current) {
            refStream.current.play();

            setTimeout(() => refStream.current.pause(), 200);
        }
    }, [cTime]);

    const handleSelectChange = (event) => {
        setDuration(event.target.value);
    };

    const cards = [
        { title: 'Impressões', number: analyticsViews },
        { title: 'Impressoes unicas', number: analyticsUniqueViews },
        { title: 'Plays', number: analyticsPlays },
        { title: 'Play Rate', number: `${analyticsPlayRate?.toFixed(2) ?? 0}%` },
        {
            title: 'Engajamento',
            number: `${analyticsEngagement?.toFixed(2) || 0}%`,
        },
    ];

    const fetchData = async () => {
        const user = auth.currentUser;

        let userToken;
        try {
            userToken = await user.getIdToken();
        } catch (error) {
            console.log('Authentication error:', error);
            return;
        }
        const userData = { uid, userToken };
        const response = await apiCall(
            'post',
            'stream/getVideo',
            userData,
            'https://apidopro.atomicat-api.com/',
        );
        if (response.data.success) {
            setVideoData(response.data.result);
        }
    };
    const fetchAnalytics = async () => {

        const user = auth.currentUser;

        let userToken;
        const userId = user.uid;
        try {
            userToken = await user.getIdToken(true);
        } catch (err) {
            console.log(err);
        }
        const userData = {
            uid: userId,
            videoId: uid,
            start: duration == 'custom' ? analyticsStart : '',
            end: duration == 'custom' ? analyticsEnd : '',
            duration,
            userToken,
        };
        const response = await apiCall(
            'post',
            'video/analytics',
            userData,
            'https://apidopro.atomicat-api.com/',
        );
        // setTimeout(() => {

        //     setFetchingAnalytics(false);
        // },2000)

        setAnalyticsData(response);
        setFetchingAnalytics(false);

    };

    useEffect(() => {
        fetchData();
        fetchAnalytics();
    }, []);
    const getHeight = () => {
        if (window.matchMedia("(max-width: 768px)").matches) {
            return 200;
        } else if (window.matchMedia("(max-width: 1024px)").matches) {
            return 546;
        }
    };
    const getWidth = () => {
        if (window.matchMedia("(max-width: 768px)").matches) {
            return window.innerWidth;
        } else if (window.matchMedia("(max-width: 1024px)").matches) {
            return 992;
        }
    };
    return (
        <div className="">

            <div className="meusVideos-middle w-full" style={{}}>
                <div className="disply-flex-justify-start gap-2 mb-10">
                    <select
                        className="meusVideos-dropdown"
                        value={duration}
                        onChange={handleSelectChange}
                    >
                        {analyticsDuration.map((item) => (

                            <option value={item.value}>{item.label}</option>
                        ))}

                    </select>
                    {duration === 'custom' &&
                        <>
                            <div className='flex justify-center items-center gap-1'>
                                <p>De:</p>

                                <input
                                    type="date"
                                    name="name"
                                    className="analyticDate"

                                    onChange={(e) => {
                                        setAnalyticsStart(e.target.value);
                                    }}
                                    defaultValue={analyticsStart}
                                />
                            </div>
                            <div className='flex justify-center items-center gap-1'>
                                <p>Até:</p>

                                <input
                                    type="date"
                                    name="name"
                                    className="analyticDate"
                                    onChange={(e) => {
                                        setAnalyticsEnd(e.target.value);
                                    }}
                                    defaultValue={analyticsEnd}
                                />
                            </div>
                        </>
                    }
                    <button type="button" className="btn btn-success " onClick={(e) => {
                        fetchAnalytics();
                    }}>
                        Pesquisar...
                    </button>
                    <div className=" clock-para">
                        <img
                            className="pl-lg-5 pr-lg-0 pr-3 pr-lg-0 pl-lg-5-clock"
                            src={clockIcon}
                        />
                        <p className="clock-text pl-1">
                            Proxima atualizacao das metricas
                            {' '}
                            {getNextUpdateTime()}
                            {' '}
                            horario
                            de Brasilia.
                        </p>
                    </div>
                </div>

                {/* Graph Section */}
                <div className="maingraphdiv" style={{ width: '100%', }}>
                    {isFetchingAnalytics &&
                        <div className='skelton-animation' style={{ width: '100%', height: 'auto', backgroundColor: '#ffffff' }}></div>
                    }
                        <>
                    {!isFetchingAnalytics && data &&

                            <div className="stream"  >
                                <Stream
                                    streamRef={videoRef}
                                    className="rounded ml-2"
                                    src={uid}
                                />
                                <div className=''
                                // style={{
                                //     // backgroundImage: `url('${thumbnail}')`,
                                //     backgroundPosition: 'center',
                                //     backgroundSize: 'cover',
                                //     // width: getWidth(),
                                //     // height: getHeight(),
                                //     display: 'flex',
                                //     alignItems: 'center',
                                //     justifyContent: 'center',
                                //     marginTop: '-590px',
                                //     width: '100%',
                                //     marginLeft: '20px'

                                // }}
                                >
                                    <div className='chart'  >

                                        <LineRechartComponent
                                            data={analyticsData}
                                            vertical={false}
                                            horizontal={false}
                                            setWhatTime={setWhatTime}
                                            duration={videoData.duration}
                                        />
                                    </div>
                                </div>
                            </div>
                    }
                            <div className="">
                                {cards.map((item, index) => (
                                    <div className="card graph-card w-100">
                                        <div className="card-body">
                                            <div className="display-align-center">
                                                <p className="magic-progress">
                                                    {item.title}
                                                    {' '}
                                                </p>
                                                {' '}
                                                <img className="ml-3" src={exlametryMark} />
                                            </div>

                                            <h1 className="magic-progress-numbers">{item.number}</h1>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    
                </div>
            </div>
        </div>
    );
}