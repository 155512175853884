import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

import { getFirestore } from 'firebase/firestore';

import { getStorage } from 'firebase/storage';

const environment = 'production';
// ? {
//   apiKey: 'AIzaSyA4k3UvdIdFRFlXDqERZce6Ux7ZfGc7-wA',
//   authDomain: 'furtherdevelopment-1443a.firebaseapp.com',
//   databaseURL:
//         'https://furtherdevelopment-1443a-default-rtdb.firebaseio.com',
//   projectId: 'furtherdevelopment-1443a',
//   storageBucket: 'furtherdevelopment-1443a.appspot.com',
//   messagingSenderId: '37419075264',
//   appId: '1:37419075264:web:58b16987883b6617c28f25',
// }

const firebaseConfig = environment == 'development'
  ? {
    apiKey: 'AIzaSyA4k3UvdIdFRFlXDqERZce6Ux7ZfGc7-wA',
    authDomain: 'furtherdevelopment-1443a.firebaseapp.com',
    databaseURL:
          'https://furtherdevelopment-1443a-default-rtdb.firebaseio.com',
    projectId: 'furtherdevelopment-1443a',
    storageBucket: 'furtherdevelopment-1443a.appspot.com',
    messagingSenderId: '37419075264',
    appId: '1:37419075264:web:58b16987883b6617c28f25',
  }
  : {
    apiKey: 'AIzaSyB6HmwtHzvACOt0q7Sqi0JjLzpGUjjHvAw',
    authDomain: 'atomicat-21a15.firebaseapp.com',
    databaseURL: 'https://atomicat-21a15.firebaseapp.com/',
    projectId: 'atomicat-21a15',
    storageBucket: 'atomicat-21a15.appspot.com',
    messagingSenderId: '262439872624',
    appId: '1:262439872624:web:5125238f1ff40c526c2cb5',
    measurementId: 'G-3J7WH2N1JX',
  };

const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeBgb4gAAAAAAxcfmoa7Ku-2LRPn66xFhz_t9vB'),
  isTokenAutoRefreshEnabled: true,
});

const auth = getAuth(app);

const db = getFirestore(app);

const storage = getStorage(app);

const googleProvider = new GoogleAuthProvider();

export {
  auth, db, googleProvider, storage, environment,
};
