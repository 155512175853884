import React, { useRef } from "react";
import "../../../../styles/builder/ButtonEdit.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsSliders } from "react-icons/bs";
import {
  fontSizeOptions,
  targetOptions,
  alignOptions,
  nonDigitStyles,
  progressBarTypeOptions,
} from "../../../../helpers/Builder/helperArrays";
import CustomButton from "../../../customButton/CustomButton";
import { responsiveIcons ,percentageViewOptions,} from "../../../../helpers/Builder/helperArrays";
import DropDown from "./DropDown";

const ProgressbarEdit = ({
  setselectedtab,
  propValue,
  propChange,
  propMiscValue,
  propMiscChange,
  styleValue,
  styleChange,
  device,
  setDevice,
  setResponsiveMode,
  responsiveData,
  responsiveMode,
}) => {
  const tituloRef = useRef(null);
  const tipografia = useRef(null);
  const changeObjectStyle = (style, position, e) => {
    const positionValue = e.target.value;
    const newStyleObject = {
      target: {
        value: {
          ...styleValue(style),
          [position]: positionValue,
        },
      },
    };
    styleChange(style, newStyleObject);
  };

  return (
    <div className="buttonEdit">
      <span
        className="titulo"
        onClick={(e) => {
          const { nextSibling } = e.target;
          if (nextSibling.style.transform == "rotate(180deg)") {
            nextSibling.style.transform = "rotate(0deg)";
          } else {
            nextSibling.style.transform = "rotate(180deg)";
          }

          e.target.parentNode.children[2].classList.toggle("hidden");
        }}
        ref={tituloRef}
      >
        Title:
      </span>
      <input
        style={{
          outline: 0,
          border: "1px solid #ddd",
          borderRadius: "5px",
          width: "100%",
          paddingLeft: "5px",
          fontSize: "13px",
          color: "#72728f",
          padding: "0px 5px",
        }}
        type="text"
        defaultValue="My Skill"
        value={propValue("content")}
        onChange={(e) => {
          propChange("content", e);
        }}
      />
      <div className="py-4 ">
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="spanTitle"
        >
          <label htmlFor="target" className="spanTitle">
            Type
          </label>
          <select
            style={{
              fontSize: "13px",
              border: "1px solid #ddd",
              textAlign: "center",
              outline: 0,
              color: "#72728F",
              padding: "0",
              width: "7rem",
            }}
            className=""
            name="target"
            id="target"
            onChange={(e) => {
              propMiscChange("target", e);
            }}
          >
            {progressBarTypeOptions.map((target) => (
              <option
                value={target.value}
                selected={propMiscValue("target") == target.value}
              >
                {target.response}
              </option>
            ))}
          </select>
        </span>
      </div>
      <div
        className="py-4"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <label htmlFor="target" className="spanTitle">
          Percentage
        </label>
        <input
          type="range"
          value={propMiscValue("rangeValue")}
          onChange={(e) => propMiscChange("rangeValue", e)}
          style={{ margin: "8px 0", padding: "0" }}
        />
      </div>
      <div className="py-4 ">
        <div className="py-1" style={{ marginTop: "0.7rem" }}>
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="spanTitle"
          >
            <label htmlFor="target" className="spanTitle">
              Display Percentage
            </label>
            <select
              style={{
                fontSize: "13px",
                border: "1px solid #ddd",
                textAlign: "center",
                outline: 0,
                color: "#72728F",
                padding:0,
                width:"7rem"
              }}
              className=""
              name="target"
              id="target"
              onChange={(e) => {
                propMiscChange("target", e);
              }}
            >
              {percentageViewOptions.map((target) => (
                <option
                  value={target.value}
                  selected={propMiscValue("target") == target.value}
                >
                  {target.response}
                </option>
              ))}
            </select>
          </span>
        </div>

        <div className="py-1">
          <span className="spanTitle">Inner Text</span>
          <input
            style={{
              outline: 0,
              border: "1px solid #ddd",
              borderRadius: "5px",
              width: "100%",
              paddingLeft: "5px",
              fontSize: "13px",
              color: "#72728f",
              padding: "0px 5px",
            }}
            type="text"
            defaultValue="Web Designer"
            value={propValue("innerContent")}
            onChange={(e) => {
              propChange("innerContent", e);
            }}
          />
        </div>
        <div className="customButtonComponent">
          <CustomButton
            loading={false}
            disabled={false}
            textColor="#fff"
            style={{ textAlign: "center" }}
            Icon={BsSliders}
            buttontext="Editar tipografia"
            backgroundColor="#62627A"
            variant="contained"
            onClick={() => {
              setselectedtab("slider");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressbarEdit;
