import './App.css';
import React, { useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import { onAuthStateChanged, getPersistence } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import LandingDashboard from './modules/LandingDashboard';
import { LoginContext } from './contexts/ContextProvider';
import { auth } from './config/firebase';
// import Builder2 from './pages/Builder2';
import { StyleContext } from './contexts/StyleProvider';
import './styles/loginPage/LoginPage.css';
// import Builder2 from './pages/Builder2';
import './styles/loginPage/LoginPage.css';
import { LeftComponentVideo, LoadingScreen } from './components';

import AppRoutes from './AppRoutes';

function App() {
  const { user, setUser } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(true);
  const { isDarkModeEnabled } = useContext(StyleContext);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Helmet>
        <html className={isDarkModeEnabled ? 'dark' : 'light'} />
      </Helmet>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Router>
          {/* {console.log(user['accessToken'])} */}
          <Routes>
            {user ? (
              <Route path="/" element={<LandingDashboard />} redirectTo="/" />
            ) : (
              <Route
                path="/"
                element={<Navigate to="/login" replace />}
                redirectTo="/login"
              />
            )}
          </Routes>
          <AppRoutes />
        </Router>
      )}
    </>
  );
}

export default App;
