import React from 'react';
import '../../../styles/builder/BuilderDrawer.css';
import EditDrawer from './EditDrawer';
import ComponentsDrawer from './ComponentsDrawer';
import OpenCloseArrow from '../main/OpenCloseArrow';

const BuilderDrawer = ({
  StiloShow, setselectedtab, selectedtab, sectionDrop, selectedComponent, ColDrop, setSearch, search, SelectedComp, setshowDrawer, showDrawer, elementType, videoController, setImagePopup, setIconPopup, setIconDestination, propValue, propChange, propMiscValue, propMiscChange, styleValue, styleChange, propMiscCheckedValue, propMiscChecked, device, setDevice, setResponsiveMode, responsiveMode, responsiveData, firstIndex, setFirstIndex, mvBtm, setMvBtm, mvUp, setMvUp,
}) => (
  <div
    id="slide"
    className="slide"
    style={{
      position: 'absolute', top: '0', bottom: '0', marginLeft: '99px', marginTop: '4rem', display: 'flex',
    }}
  >
    <div className="shadow">
      {/* {true? */}
      {StiloShow
        ? <EditDrawer setselectedtab={setselectedtab} selectedtab={selectedtab} sectionDrop={sectionDrop} selectedComponent={selectedComponent} ColDrop={ColDrop} elementType={elementType} videoController={videoController} setImagePopup={setImagePopup} setIconPopup={setIconPopup} setIconDestination={setIconDestination} propValue={propValue} propChange={propChange} propMiscValue={propMiscValue} propMiscChange={propMiscChange} styleValue={styleValue} styleChange={styleChange} propMiscCheckedValue={propMiscCheckedValue} propMiscChecked={propMiscChecked} device={device} setDevice={setDevice} setResponsiveMode={setResponsiveMode} responsiveMode={responsiveMode} responsiveData={responsiveData} />
        : <ComponentsDrawer setSearch={setSearch} search={search} SelectedComp={SelectedComp} firstIndex={firstIndex} setFirstIndex={setFirstIndex} mvBtm={mvBtm} setMvBtm={setMvBtm} mvUp={mvUp} setMvUp={setMvUp} />}
    </div>
    <OpenCloseArrow setshowDrawer={setshowDrawer} showDrawer={showDrawer} />
  </div>
);

export default BuilderDrawer;
