import React from 'react';
import '../../../../styles/connect/Connect.css';
import { MdClose } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';

const FrazerUpload = ({
  handleCreateFolder,
  setNewFolderPopup,
  currentFolderPath,
}) => (
  <div className="frazer-container">
    <div className="domain-box">
      <div className="frazer-upload-header">
        <h2>Fazer upload</h2>
        <MdClose
          onClick={() => setNewFolderPopup(false)}
          className="closeIcon"
          size={30}
          color="#000"
        />
      </div>
      <div className="hr80" />

      <form
        className="w-full"
        onSubmit={(e) => {
          e.preventDefault();
          const folderName = `${uuidv4().slice(0, 5)}-${e.target[0].value}`;
          if (!e.target[0].value) {
            return alert('Preencha todos os campos');
          }
          handleCreateFolder(folderName, currentFolderPath);
          setNewFolderPopup(false);
        }}
      >
        <div className="connectInputContainer">
          <div className="frazer-input">
            <label htmlFor="fileupload">Nome do vídeo</label>
            <input type="text" placeholder="Dê um nome ao vídeo" />
          </div>

          {/* <div className="frazer-input">

          <select>
            {folders.map((folder) => (
              <option
                onClick={
                                () => {
                                  setCurrentFolderPath(folder.fullPath);
                                }
                            }
                value={folder.fullPath}
              >
                {folder.name}
              </option>
            ))}
          </select>
        </div> */}
        </div>

        <div className="fazer-btn">
          <p className="" onClick={() => setNewFolderPopup(false)}>
            Cancelar
          </p>
          <button>Fazer upload</button>
        </div>
      </form>
    </div>
  </div>
);

export default FrazerUpload;
