import React, { useState, useRef } from "react";

import { MdKeyboardArrowDown } from "react-icons/md";
import { BsSliders } from "react-icons/bs";
import {
  alignOptions,
  nonDigitStyles,
} from "../../../../helpers/Builder/helperArrays";
import CustomButton from "../../../customButton/CustomButton";
import { responsiveIcons } from "../../../../helpers/Builder/helperArrays";
import DropDown from "./DropDown";
import { widthOptions } from "../../../../helpers/Builder/helperArrays";
import { AiOutlineClose } from "react-icons/ai";

const SocialIconsEdit = ({
  setselectedtab,
  setIconPopup,
  setIconDestination,
  setImagePopup,
  propMiscValue,
  propMiscChange,
  styleValue,
  styleChange,
  propMiscCheckedValue,
  propMiscChecked,
  device,
  setDevice,
  setResponsiveMode,
  responsiveData,
  responsiveMode,
}) => {
  const [showImageUpload, setShowImageUpload] = useState(null);
  const [showFacebookDropdown, setShowFacebookDropdown] = useState(false);
  const [showTwitterDropdown, setShowTwitterDropdown] = useState(false);
  const [showYoutubeDropdown, setShowYoutubeDropdown] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const [customRange, setCustomRange] = useState(false);

  const widthChange = (e) => {
    if (e.target.value === "fit-content" || e.target.value === "100") {
      changeObjectStyle("width", device, e);
      setCustomRange(false);
    } else if (e.target.value === "custom") {
      setCustomRange(true);
    }
  };

  const tituloRef = useRef(null);
  const tipografia = useRef(null);
  const changeObjectStyle = (style, position, e) => {
    const positionValue = e.target.value;
    const newStyleObject = {
      target: {
        value: {
          ...styleValue(style),
          [position]: positionValue,
        },
      },
    };
    styleChange(style, newStyleObject);
  };

  return (
    <div style={{ padding: "10px 10px", margin: "10px 0" }}>
      <span
        style={{
          color: "#72728F",
          fontSize: "13px",
          display: "inline-block",
          width: "90%",
        }}
        className="cursor-pointer"
        onClick={(e) => {
          const { nextSibling } = e.target;
          if (nextSibling.style.transform == "rotate(180deg)") {
            nextSibling.style.transform = "rotate(0deg)";
          } else {
            nextSibling.style.transform = "rotate(180deg)";
          }

          e.target.parentNode.children[2].classList.toggle("hidden");
        }}
        ref={tituloRef}
      >
        Social Icons:
      </span>

      <div
        className={`flex justify-between border border-gray-400 ${
          !showFacebookDropdown && "mb-2"
        }`}
      >
        <div
          className="text-black ml-4 flex items-center bg-gray-100 w-full"
          onClick={() => setShowFacebookDropdown(!showFacebookDropdown)}
        >
          Facebook
        </div>
        <div className="flex justify-center">
          <div className=" flex items-center justify-center border-l p-2 border-gray-400">
            <AiOutlineClose color="#72728F" className="cursor-pointer" />
          </div>
          <div className="flex items-center justify-center border-l p-2 border-gray-400">
            <AiOutlineClose color="black" className="cursor-pointer" />
          </div>
        </div>
      </div>
      {showFacebookDropdown && (
        <>
          <div
            className={`h-full w-full border border-gray-400 py-1 bg-gray-100 ${
              showFacebookDropdown ? "mb-2" : "mb-0"
            }`}
          >
            <span
              style={{ color: "#72728F", fontSize: "13px", margin: "0 10px" }}
            >
              Icon
            </span>
            <div
              className="flex flex-col justify-between items-center mt-3 gap-2"
              style={{ cursor: "pointer", position: "relative" }}
              onMouseOver={() => {
                setShowImageUpload(true);
              }}
              onMouseLeave={() => {
                setShowImageUpload(false);
              }}
            >
              <i
                style={{
                  fontSize: "50px",
                  marginBottom: "10px",
                  color: "black",
                }}
                className={propMiscValue("icon")}
              />
              <span
                style={{
                  position: "absolute",
                  top: "86%",
                  background: "black",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  display: showImageUpload ? "" : "none",
                }}
                onClick={(e) => {
                  setIconPopup(true);
                  setIconDestination(e.target.innerText);
                }}
              >
                Choose Icon
              </span>
            </div>
          </div>
        </>
      )}
      <div
        className={`flex justify-between border border-gray-400 ${
          !showTwitterDropdown && "mb-2"
        }`}
      >
        <div
          className="text-black ml-4 flex items-center bg-gray-100 w-full"
          onClick={() => setShowTwitterDropdown(!showTwitterDropdown)}
        >
          Twitter
        </div>
        <div className="flex justify-center">
          <div className=" flex items-center justify-center border-l p-2 border-gray-400">
            <AiOutlineClose color="#72728F" className="cursor-pointer" />
          </div>
          <div className="flex items-center justify-center border-l p-2 border-gray-400">
            <AiOutlineClose color="black" className="cursor-pointer" />
          </div>
        </div>
      </div>
      {showTwitterDropdown && (
        <>
          <div
            className={`h-full w-full border border-gray-400 py-1 bg-gray-100 ${
              showTwitterDropdown ? "mb-2" : "mb-0"
            }`}
          >
            <span
              style={{ color: "#72728F", fontSize: "13px", margin: "0 10px" }}
            >
              Icon
            </span>
            <div
              className="flex flex-col justify-between items-center mt-3 gap-2"
              style={{ cursor: "pointer", position: "relative" }}
              onMouseOver={() => {
                setShowImageUpload(true);
              }}
              onMouseLeave={() => {
                setShowImageUpload(false);
              }}
            >
              <i
                style={{
                  fontSize: "50px",
                  marginBottom: "10px",
                  color: "black",
                }}
                className={propMiscValue("icon")}
              />
              <span
                style={{
                  position: "absolute",
                  top: "86%",
                  background: "black",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  display: showImageUpload ? "" : "none",
                }}
                onClick={(e) => {
                  setIconPopup(true);
                  setIconDestination(e.target.innerText);
                }}
              >
                Choose Icon
              </span>
            </div>
          </div>
        </>
      )}
      <div className="flex justify-between border border-gray-400">
        <div
          className="text-black ml-4 flex items-center bg-gray-100 w-full"
          onClick={() => setShowYoutubeDropdown(!showYoutubeDropdown)}
        >
          Youtube
        </div>
        <div className="flex justify-center">
          <div className=" flex items-center justify-center border-l p-2 border-gray-400">
            <AiOutlineClose color="#72728F" className="cursor-pointer" />
          </div>
          <div className="flex items-center justify-center border-l p-2 border-gray-400">
            <AiOutlineClose color="black" className="cursor-pointer" />
          </div>
        </div>
      </div>
      {showYoutubeDropdown && (
        <>
          <div className="h-full w-full border border-gray-400 py-1 bg-gray-100">
            <span
              style={{ color: "#72728F", fontSize: "13px", margin: "0 10px" }}
            >
              Icon
            </span>
            <div
              className="flex flex-col justify-between items-center mt-3 gap-2"
              style={{ cursor: "pointer", position: "relative" }}
              onMouseOver={() => {
                setShowImageUpload(true);
              }}
              onMouseLeave={() => {
                setShowImageUpload(false);
              }}
            >
              <i
                style={{
                  fontSize: "50px",
                  marginBottom: "10px",
                  color: "black",
                }}
                className={propMiscValue("icon")}
              />
              <span
                style={{
                  position: "absolute",
                  top: "86%",
                  background: "black",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  display: showImageUpload ? "" : "none",
                }}
                onClick={(e) => {
                  setIconPopup(true);
                  setIconDestination(e.target.innerText);
                }}
              >
                Choose Icon
              </span>
            </div>
          </div>
        </>
      )}
      <div className="mt-6 pb-6" style={{ borderBottom: "2px solid #ddd" }}>
        <div className="py-1">
          <span style={{ color: "#72728F", fontSize: "13px" }}>Link</span>
          <input
            style={{
              outline: 0,
              border: "1px solid #ddd",
              borderRadius: "5px",
              width: "100%",
              paddingLeft: "5px",
              fontSize: "13px",
              color: "#72728f",
              padding: "5px 5px",
            }}
            type="text"
            placeholder="Digite ou cole a URL..."
            value={propMiscValue("href")}
            onChange={(e) => {
              propMiscChange("href", e);
            }}
          />
        </div>

        <div className="py-1 mt-3">
          <span
            className="flex justify-between center-align relative"
            style={{ color: "#72728F", fontSize: "13px" }}
          >
            <label
              htmlFor="textType"
              style={{ color: "#72728F", fontSize: "13px" }}
            >
              Shape
            </label>
            <select
              style={{
                fontSize: "13px",
                border: "1px solid #ddd",
                textAlign: "center",
                outline: 0,
                color: "#72728F",
                width: "5rem",
              }}
              className="px-2"
              name="textType"
              id="textType"
              onChange={(e) => {
                changeObjectStyle("textAlign", device, e);
                styleChange("currentTextAlign", e);
              }}
            >
              {alignOptions.map((align) => (
                <option
                  value={align}
                  selected={styleValue("textAlign")[device] === align}
                >
                  {align}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="py-1 mt-3">
          <span
            className="flex justify-between items-center relative"
            style={{ color: "#72728F", fontSize: "13px" }}
          >
            <label
              htmlFor="textType"
              className="flex items-center"
              style={{ color: "#72728F", fontSize: "13px" }}
            >
              Alignment:{" "}
              <DropDown
                responsiveData={responsiveData}
                responsiveMode={responsiveMode}
                setDevice={setDevice}
                setResponsiveMode={setResponsiveMode}
                position={"32%"}
              />
            </label>
            {/* <div className='flex flex-col items-center justify-center'></div> */}
            <select
              style={{
                fontSize: "13px",
                border: "1px solid #ddd",
                textAlign: "center",
                outline: 0,
                color: "#72728F",
                width: "5rem",
              }}
              className="px-2"
              name="textType"
              id="textType"
              onChange={(e) => {
                changeObjectStyle("textAlign", device, e);
                styleChange("currentTextAlign", e);
              }}
            >
              {alignOptions.map((align) => (
                <option
                  value={align}
                  selected={styleValue("textAlign")[device] === align}
                >
                  {align}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="py-6 ">
          <span
            className="flex justify-between center-align relative"
            style={{ color: "#72728F", fontSize: "13px" }}
          >
            <label
              htmlFor="borderStyle"
              style={{ color: "#72728F", fontSize: "13px" }}
              className="flex items-center"
            >
              Column:{" "}
              <DropDown
                responsiveData={responsiveData}
                responsiveMode={responsiveMode}
                setDevice={setDevice}
                setResponsiveMode={setResponsiveMode}
                position={"30%"}
              />
            </label>
            <select
              style={{
                fontSize: "13px",
                border: "1px solid #ddd",
                textAlign: "center",
                outline: 0,
                color: "#72728F",
                width: "5rem",
              }}
              className="px-2"
              name="textType"
              id="textType"
              onChange={(e) => {
                changeObjectStyle("textAlign", device, e);
                styleChange("currentTextAlign", e);
              }}
            >
              {alignOptions.map((align) => (
                <option
                  value={align}
                  selected={styleValue("textAlign")[device] === align}
                >
                  {align}
                </option>
              ))}
            </select>
          </span>
        </div>
      </div>

      <div className="py-4 ">
        <span
          style={{
            color: "#72728F",
            fontSize: "13px",
            cursor: "pointer",
            display: "inline-block",
            width: "90%",
          }}
          onClick={(e) => {
            const { nextSibling } = e.target;
            if (nextSibling.style.transform == "rotate(180deg)") {
              nextSibling.style.transform = "rotate(0deg)";
            } else {
              nextSibling.style.transform = "rotate(180deg)";
            }

            e.target.parentNode.children[2].classList.toggle("hidden");
          }}
          ref={tipografia}
        >
          Tamanho da imagem
        </span>
        <MdKeyboardArrowDown
          color="#72728F"
          style={{
            display: "inline",
            cursor: "pointer",
            transform: "rotate(0deg)",
            transition: "transform 0.2s ease-in-out",
          }}
          className="cursor-pointer"
          onClick={(e) => {
            tipografia.current.click();
          }}
        />
        <div style={{ margin: "10px 0" }}>
          <CustomButton
            loading={false}
            disabled={false}
            textColor="#fff"
            style={{ textAlign: "center" }}
            Icon={BsSliders}
            buttontext="Editar tipografia"
            backgroundColor="#62627A"
            variant="contained"
            onClick={() => {
              setselectedtab("slider");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialIconsEdit;
