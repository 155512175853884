import React, { useRef } from "react";
import "../../../../styles/builder/ButtonEdit.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsSliders } from "react-icons/bs";
import {
    fontSizeOptions,
    targetOptions,
    alignOptions,
    nonDigitStyles,
    progressBarTypeOptions,
    ratingScaleOptions,
    ratingIconOptions,
} from "../../../../helpers/Builder/helperArrays";
import CustomButton from "../../../customButton/CustomButton";
import {
    responsiveIcons,
    percentageViewOptions,
} from "../../../../helpers/Builder/helperArrays";
import DropDown from "./DropDown";
import { useState } from "react";

export default function Counterdit({
    setselectedtab,
    propValue,
    propChange,
    propMiscValue,
    propMiscChange,
    styleValue,
    styleChange,
    device,
    setDevice,
    setResponsiveMode,
    responsiveData,
    responsiveMode,
}) {
    const tituloRef = useRef(null);
    const tipografia = useRef(null);
    const changeObjectStyle = (style, position, e) => {
        const positionValue = e.target.value;
        const newStyleObject = {
            target: {
                value: {
                    ...styleValue(style),
                    [position]: positionValue,
                },
            },
        };
        styleChange(style, newStyleObject);
    };

    const [isToggle, setIsToggle] = useState(false)

    return (
        <div className="buttonEdit">
            <div className="py-2 "
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <label htmlFor="target" className="spanTitle">
                    Starting Number
                </label>
                <input
                    type="number"
                    value={propValue("startingNumber")}
                    onChange={(e) => {
                        propChange("startingNumber", e);
                    }}
                    style={{
                        margin: "8px 0",
                        padding: "0",
                        fontSize: "13px",
                        border: "1px solid #ddd",
                        textAlign: "center",
                        outline: 0,
                        color: "#72728F",
                        padding: "0",
                        width: "7rem",
                    }}
                />
            </div>
            <div
                className="py-4 border-b"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <label htmlFor="target" className="spanTitle">
                    Ending Number
                </label>
                <input
                    type="number"
                    value={propValue("endingNumber")}
                    onChange={(e) => {
                        propChange("endingNumber", e);
                    }}
                    style={{
                        margin: "8px 0",
                        padding: "0",
                        fontSize: "13px",
                        border: "1px solid #ddd",
                        textAlign: "center",
                        outline: 0,
                        color: "#72728F",
                        padding: "0",
                        width: "7rem",
                    }}
                />
            </div>
            <div
                className="py-2"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <span className="spanTitle">Number Prefix</span>
                <input
                    style={{
                        outline: 0,
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        width: "100%",
                        fontSize: "13px",
                        color: "#72728f",
                        padding: "0px 5px",
                        width: "115px",
                        overflowX: "hidden",
                    }}
                    type="text"
                    defaultValue=""
                    value={propValue("numberPrefix")}
                    onChange={(e) => {
                        propChange("numberPrefix", e);
                    }}
                />
            </div>
            <div
                className="py-2"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <span className="spanTitle">Number Suffix</span>
                <input
                    style={{
                        outline: 0,
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        width: "100%",
                        fontSize: "13px",
                        color: "#72728f",
                        padding: "0px 5px",
                        width: "115px",
                        overflowX: "hidden",
                    }}
                    type="text"
                    defaultValue=""
                    value={propValue("numberSuffix")}
                    onChange={(e) => {
                        propChange("numberSuffix", e);
                    }}
                />
            </div>
            <div className="py-2 "
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <label htmlFor="target" className="spanTitle">
                    Animation Duration
                </label>
                <input
                    type="number"
                    value={propValue("intervalTime")}
                    onChange={(e) => {
                        propChange("intervalTime", e);
                    }}
                    style={{
                        margin: "8px 0",
                        padding: "0",
                        fontSize: "13px",
                        border: "1px solid #ddd",
                        textAlign: "center",
                        outline: 0,
                        color: "#72728F",
                        padding: "0",
                        width: "7rem",
                    }}
                />
            </div>
            <div
                className="py-2"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <span className="spanTitle">Title</span>
                <input
                    style={{
                        outline: 0,
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        width: "100%",
                        fontSize: "13px",
                        color: "#72728f",
                        padding: "0px 5px",
                        width: "115px",
                        overflowX: "hidden",
                    }}
                    type="text"
                    defaultValue=""
                    value={propValue("ratingText")}
                    onChange={(e) => {
                        propChange("ratingText", e);
                    }}
                />
            </div>
            <div className="py-2 border-b"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
            <div className="flex justify-between items-center mt-3">
                <span className="flex justify-between items-center" style={{ color: '#72728F', fontSize: '13px', width: '100%' }}>
                  <span htmlFor="desktop" style={{ color: '#72728F', fontSize: '13px' }}>Esconder no Desktop</span>
                  <label id="desktop" className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      className="sr-only peer hidden"
                      checked={isToggle}
                    onChange={(e) => {
                        setIsToggle(e);
                        console.log("is toggle val : ",isToggle, e)
                    }}
                    />
                  </label>
                </span>

              </div>
            </div>

            <div className="customButtonComponent">
                <CustomButton
                    loading={false}
                    disabled={false}
                    textColor="#fff"
                    style={{ textAlign: "center" }}
                    Icon={BsSliders}
                    buttontext="Editar tipografia"
                    backgroundColor="#62627A"
                    variant="contained"
                    onClick={() => {
                        setselectedtab("slider");
                    }}
                />
            </div>
        </div>
    );
}
