import {
  collection,
  query,
  orderBy,
  onSnapshot,
  where,
  getDoc,
  limitToLast,
} from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';

import { db } from '../config/firebase';
import { LoginContext } from '../contexts/ContextProvider';
import { addDocument } from '../service/useFirestore';

export const useCollection = (
  collectionPath,
  listOfWhereConditions,
  listOfOrderBy,
  limit,
) => {
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null);
  const collectionRef = collection(db, collectionPath);
  let unsubscribe = null;
  const user = useContext(LoginContext);

  const fetchData = async () => {
    try {
      const snapshot = await getDoc(collectionRef);
      const initialDocuments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDocuments(initialDocuments);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const queryConstraints = [];
    if(collectionPath !== 'tutorials'){

      queryConstraints.push(where('ownerId', '==', user?.user?.uid));
    }
    if (listOfWhereConditions != undefined) {
      for (let i = 0; i < listOfWhereConditions.length; i++) {
        const singleElement = listOfWhereConditions[i];
        queryConstraints.push(
          where(singleElement.name, singleElement.operator, singleElement.value),
        );
      }
    }

    if (listOfOrderBy != undefined) {
      for (let i = 0; i < listOfOrderBy.length; i++) {
        const singleElement = listOfOrderBy[i];
        queryConstraints.push(orderBy(singleElement.name, singleElement.order));
      }
    }

    if (limit != undefined) {
      queryConstraints.push(limitToLast(limit));
    }

    console.log('sangram',queryConstraints);
    const q = query(collection(db, collectionPath), ...queryConstraints);
    try {
      unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const doc = {
              id: change.doc.id,
              ...change.doc.data(),
            };

            switch (change.type) {
              case 'added':
                setDocuments((prevDocuments) => [...prevDocuments, doc]);
                break;
              case 'modified':
                setDocuments((prevDocuments) => prevDocuments.map((d) => (d.id === doc.id ? doc : d)));
                break;
              case 'removed':
                setDocuments((prevDocuments) => prevDocuments.filter((d) => d.id !== doc.id));
                break;
              default:
                break;
            }
          });
        },
        (error) => {
          addDocument('errors', {
            error,
            userId: user?.user?.uid,
            where: 'useCollection',
          });
          console.log(error.message);
        },
      );
    } catch (error) {
      console.log(error);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [collectionPath]);

  return { documents };
};
