import { ELEMENTSARR, UPDATEELEMENTSARR } from '../constants/types';

const initialState = {
  EleArr: [],
};

export default function builderReducer(state = initialState, action) {
  switch (action.type) {
    case ELEMENTSARR:
      console.log(action.payload, 'action');
      // let contentobj = {key:action.payload.key,content:action.payload.content}
      // console.log(contentobj,"contentobj");
      return {
        ...state,
        EleArr: action.payload,
        // Content:action.payload
      };
    case UPDATEELEMENTSARR:
      const tempArr = action.payload.org_arr;
      tempArr.splice(action.payload.index, 1);
      tempArr[action.payload.index] = action.payload.object;
      return {
        ...state,
        EleArr: tempArr,
        // Content:action.payload
      };
    default:
      return state;
  }
}
