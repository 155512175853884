import React, { useState, useRef } from 'react';

import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsSliders } from 'react-icons/bs';
import {
  alignOptions, nonDigitStyles,
  responsiveIcons,
} from '../../../../helpers/Builder/helperArrays';
import CustomButton from '../../../customButton/CustomButton';
import DropDown from './DropDown';

const VideoEdit = ({
  setselectedtab, videoController, propMiscValue, propMiscChange, styleValue, styleChange, propMiscCheckedValue, propMiscChecked, device, setDevice, setResponsiveMode, responsiveData, responsiveMode,
}) => {
  const [forceRender, setForceRender] = useState(false);
  const dispositivosRef = useRef(null);
  const tituloRef = useRef(null);
  const tipografia = useRef(null);
  const changeObjectStyle = (style, position, e) => {
    const positionValue = e.target.value;
    const newStyleObject = {
      target: {
        value: {
          ...styleValue(style),
          [position]: positionValue,
        },
      },
    };
    styleChange(style, newStyleObject);
  };

  return (
    <div style={{ padding: '10px 10px', margin: '10px 0' }}>
      <span
        style={{
          color: '#72728F', fontSize: '13px', display: 'inline-block', width: '90%',
        }}
        className="cursor-pointer"
        onClick={(e) => {
          const { nextSibling } = e.target;
          if (nextSibling.style.transform == 'rotate(180deg)') {
            nextSibling.style.transform = 'rotate(0deg)';
          } else {
            nextSibling.style.transform = 'rotate(180deg)';
          }

          e.target.parentNode.children[2].classList.toggle('hidden');
        }}
        ref={tituloRef}
      >
        Video:
        {' '}
      </span>
      <MdKeyboardArrowDown
        color="#72728F"
        style={{
          display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'transform 0.2s ease-in-out',
        }}
        className="cursor-pointer"
        onClick={(e) => {
          tituloRef.current.click();
        }}
      />
      <div className="pt-1 pb-6" style={{ borderBottom: '2px solid #ddd' }}>
        <div className="py-1">
          <span style={{ color: '#72728F', fontSize: '13px' }}>Link</span>
          <textarea
            style={{
              outline: 0, border: '1px solid #ddd', borderRadius: '5px', width: '100%', paddingLeft: '5px', fontSize: '13px', color: '#72728f', padding: '5px 5px',
            }}
            type="text"
            placeholder="Digite ou cole a URL..."
            value={propMiscValue('src')}
            onChange={(e) => {
              propMiscChange('src', e);
            }}
          />
        </div>
        <div className="py-1">
          <span className="flex justify-between center-align relative" style={{ color: '#72728F', fontSize: '13px' }}>
            <label htmlFor="textType" style={{ color: '#72728F', fontSize: '13px' }}>Alinhamento</label>
            {/* <div className='flex flex-col items-center justify-center'></div> */}
            <DropDown responsiveData={responsiveData} responsiveMode={responsiveMode} setDevice={setDevice} setResponsiveMode={setResponsiveMode} position="40%" />
            <select
              style={{
                fontSize: '13px', border: '1px solid #ddd', textAlign: 'center', outline: 0, color: '#72728F', width: '5rem',
              }}
              className="px-2"
              name="textType"
              id="textType"
              onChange={(e) => {
                changeObjectStyle('textAlign', device, e);
                styleChange('currentTextAlign', e);
              }}
            >
              {alignOptions.map((align) => <option value={align} selected={styleValue('textAlign')[device] === align}>{align}</option>)}
            </select>
          </span>
        </div>
      </div>

      <div className="py-4 ">
        <span
          style={{
            color: '#72728F', fontSize: '13px', cursor: 'pointer', display: 'inline-block', width: '90%',
          }}
          onClick={(e) => {
            const { nextSibling } = e.target;
            if (nextSibling.style.transform == 'rotate(180deg)') {
              nextSibling.style.transform = 'rotate(0deg)';
            } else {
              nextSibling.style.transform = 'rotate(180deg)';
            }

            e.target.parentNode.children[2].classList.toggle('hidden');
          }}
          ref={tipografia}
        >
          Largura do video
        </span>
        <MdKeyboardArrowDown
          color="#72728F"
          style={{
            display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'transform 0.2s ease-in-out',
          }}
          className="cursor-pointer"
          onClick={(e) => {
            tipografia.current.click();
          }}
        />
        <div style={{ transition: 'all 0.2s ease-in-out' }}>
          <span className="my-1" style={{ color: '#72728F', fontSize: '13px' }}>Personalizar</span>
          <div className="flex my-1 items-center">
            <input
              min="0"
              max="10"
              type="range"
              style={{ flex: 10, marginRight: '5px' }}
              onChange={(e) => {
                styleChange('borderWidth', e);
              }}
              defaultValue={0}
              value={styleValue('borderWidth')}
            />
            <input
              style={{
                border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px',
              }}
              className="w-7 h-7"
              onChange={(e) => {
                styleChange('borderWidth', e);
              }}
              value={styleValue('borderWidth')}
            />
          </div>
        </div>
        <div className="mx-2 py-5">
          <span
            style={{
              color: '#72728F', fontSize: '13px', cursor: 'pointer', display: 'inline-block', width: '90%',
            }}
            className=""
            ref={dispositivosRef}
            onClick={(e) => {
              const { nextSibling } = e.target;
              if (nextSibling.style.transform == 'rotate(180deg)') {
                nextSibling.style.transform = 'rotate(0deg)';
              } else {
                nextSibling.style.transform = 'rotate(180deg)';
              }

              e.target.parentNode.children[2].classList.toggle('hidden');
            }}
          >
            Opções
          </span>
          <MdKeyboardArrowDown
            color="#72728F"
            style={{
              display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'transform 0.2s ease-in-out',
            }}
            onClick={() => {
              dispositivosRef.current.click();
            }}
          />
          <div>
            <div className="flex justify-between items-center mt-3">
              <span className="flex justify-between items-center" style={{ color: '#72728F', fontSize: '13px', width: '100%' }}>
                <span htmlFor="loop" style={{ color: '#72728F', fontSize: '13px' }}>Repetir</span>
                <label id="loop" className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer hidden"
                    checked={!!propMiscCheckedValue('loop')}
                    disabled={videoController}
                    onChange={(e) => {
                      propMiscChecked('loop', e);
                      setForceRender(!forceRender);
                    }}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white
          after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
          after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-700"
                  />
                </label>
              </span>

            </div>
            <div className="flex justify-between items-center mt-3">

              <span className="flex justify-between items-center" style={{ color: '#72728F', fontSize: '13px', width: '100%' }}>
                <span htmlFor="autoplay" style={{ color: '#72728F', fontSize: '13px' }}>Reproduzir automaticamente</span>
                <label id="autoplay" className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer hidden"
                    checked={!!propMiscCheckedValue('autoplay')}
                    disabled={videoController}
                    onChange={(e) => {
                      propMiscChecked('autoplay', e);
                      setForceRender(!forceRender);
                    }}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white
          after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
          after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-700"
                  />
                </label>
              </span>

            </div>
            <div />
            <div className="flex justify-between items-center mt-3">
              {/* <img src={desktopIcon}/> */}
              <span className="flex justify-between items-center" style={{ color: '#72728F', fontSize: '13px', width: '100%' }}>
                <span htmlFor="Mute" style={{ color: '#72728F', fontSize: '13px' }}>Mudo</span>
                <label id="Mute" className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer hidden"
                    checked={!!propMiscCheckedValue('mute')}
                    disabled={videoController}
                    onChange={(e) => {
                      propMiscChecked('mute', e);
                      setForceRender(!forceRender);
                    }}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white
          after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
          after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-700"
                  />
                </label>
              </span>

            </div>
            <div className="flex justify-between items-center mt-3">
              <span className="flex justify-between items-center" style={{ color: '#72728F', fontSize: '13px', width: '100%' }}>
                <span htmlFor="lazyLoad" style={{ color: '#72728F', fontSize: '13px' }}>Lazy Load</span>
                <label id="lazyLoad" className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer hidden"
                    checked={!!propMiscCheckedValue('lazyLoad')}
                    disabled={videoController}
                    onChange={(e) => {
                      propMiscChecked('lazyLoad', e);
                      setForceRender(!forceRender);
                    }}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white
          after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
          after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-700"
                  />
                </label>
              </span>

            </div>
            <div className="flex justify-between items-center mt-3">
              <span className="flex justify-between items-center" style={{ color: '#72728F', fontSize: '13px', width: '100%' }}>
                <span htmlFor="contols" style={{ color: '#72728F', fontSize: '13px' }}>Controls</span>
                <label id="controls" className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer hidden"
                    checked={!!propMiscCheckedValue('controls')}
                    disabled={videoController}
                    onChange={(e) => {
                      propMiscChecked('controls', e);
                      setForceRender(!forceRender);
                    }}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white
          after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
          after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-700"
                  />
                </label>
              </span>

            </div>
          </div>
        </div>
        <div style={{ margin: '10px 0' }}>
          <CustomButton
            loading={false}
            disabled={false}
            textColor="#fff"
            style={{ textAlign: 'center' }}
            Icon={BsSliders}
            buttontext="Editar tipografia"
            backgroundColor="#62627A"
            variant="contained"
            onClick={() => {
              setselectedtab('slider');
            }}
          />
        </div>

      </div>
    </div>
  );
};

export default VideoEdit;
