import React, { useState } from 'react';

import { SideBarHome } from '..';

import '../../styles/videos/meusVideos.css';
import Banner from '../../assets/images/banner/LrxhiA1631246 1.svg';
import folderClosed from '../../assets/icons/nomeDoVideo/folderClosed.png';
import growth from '../../assets/icons/meusVideos/map.png';
import deleteicon from '../../assets/icons/sites/Delete.png';
import settingsicon from '../../assets/icons/meusVideos/settingsIcon.svg';
import backBtnArrow from '../../assets/icons/paginas/chevron-left.svg';
import exlametryMark from '../../assets/icons/nomeDoVideo/exlametryMarkinCircle.png';
import clockIcon from '../../assets/icons/nomeDoVideo/clock.png';

export default function ParaSitesExternos() {
  const [selectedOption, setSelectedOption] = useState('');
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const cards = [
    { title: 'Magic Progress', number: '38' },
    { title: 'Impressoes unicas', number: '38' },
    { title: 'Plays', number: '38' },
    { title: 'Play Rate', number: '53.85%' },
    { title: 'Engajamento', number: '28.08%' },
  ];
  return (
    <div className="disply-flex-justify-space-between">
      <SideBarHome />
      <div className="meusVideos-container">
        <div className="meusVideos-header bg-success">
          <div className="display-align-center">
            {/* <button type="button" class="btn btn-primary back-btn"><img src={backBtnArrow} /></button> */}
            {/* <p>Meus videos  /  Nome do video</p> */}
          </div>
          {/* <div className="display-flex-justify-align-center">
            <p className='ajustes'>AJUSTES</p>
            <button type="button" class="btn btn-primary metricas-btn"> METRICAS</button>
            </div> */}
        </div>

        <div className="meusVideos-middle">
          {/* Main part Copy from here */}
          <div className="switchboard">
            <div className="heading-section">
              <h1 className="title">
                Codigo de incorporacao para sites externos
              </h1>
            </div>

            {/* div dropdown row */}
            <div className="dropdown-row">
              <div className="dropdown-col">
                <div className="drop-down-title">Tamanho do vídeo</div>
                <div className="drop-down-dropdown">
                  <select
                    className="meusVideos-dropdown"
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value="">Responsivo</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                  {selectedOption && (
                    <p>
                      You selected:
                      {selectedOption}
                    </p>
                  )}
                </div>
              </div>
              <div className="dropdown-col">
                <div className="drop-down-title">Orientacao</div>
                <div className="drop-down-dropdown">
                  <select
                    className="meusVideos-dropdown"
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value="">Horizontal</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                  {selectedOption && (
                    <p>
                      You selected:
                      {selectedOption}
                    </p>
                  )}
                </div>
              </div>
              <div className="dropdown-col">
                <div className="drop-down-title">Cor primariria</div>
                <div className="drop-down-dropdown color-block-flex">
                  <div className="color-block" />
                  <select
                    className="meusVideos-dropdown"
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value="">#60F0D3</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                  {selectedOption && (
                    <p>
                      You selected:
                      {selectedOption}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ----------------------------------------------------------------------------- */}
          <div className="Switches-row">
            <div className="switch-row" />
          </div>
        </div>
      </div>
    </div>
  );
}
