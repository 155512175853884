import React, { createContext, useState } from 'react';

export const LoginContext = createContext();

const ContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  return (
    <LoginContext.Provider value={{
      user,
      setUser,
    }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default ContextProvider;
