import { useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import '../../../../styles/builder/IconPopup.css';
import cross from '../../../../assets/icons/builder/Cross.svg';

library.add(fas, far, fab);

const fabIcons = Object.keys(library.definitions.fab);
const fasIcons = Object.keys(library.definitions.fas);
const farIcons = Object.keys(library.definitions.far);
// console.log(fabIcons);
// console.log(fasIcons);
// console.log(farIcons);
// console.log(library);

const IconPopup = ({
  sectionArray,
  sectionDrop,
  ColDrop,
  selectedComponent,
  SelectedSectionIndex,
  setsectionArray,
  setsectionDrop,
  setSelectedContent,
  setIconPopup,
  iconDestination,
}) => {
  const propMiscChange = (prop, e) => {
    const dropSection = sectionArray.filter(
      (s) => s.sec_key == sectionDrop.sec_key,
    );

    const dropsectionindex = dropSection[0].columns[ColDrop].elements;
    const filtereddropsection = dropsectionindex.filter(
      (c) => c.comp_key == selectedComponent,
    );

    const updatedMiscValues = {
      ...filtereddropsection[0].misc,
      [prop]: e.target.value,
    };

    filtereddropsection[0].misc = updatedMiscValues;

    const temparr = sectionArray;
    temparr.splice(SelectedSectionIndex, 1, dropSection[0]);
    setsectionArray(temparr);
    setsectionDrop(dropSection[0]);
    setSelectedContent(e.target.value);
  };

  const [iconClass, setIconClass] = useState('');

  const activeIconLibrary = [
    'chevron-up',
    'angles-up',
    'angle-double-up',
    'caret-up',
    'caret-square-up',
  ];

  const inactiveIconLibrary = [
    'chevron-down',
    'angles-down',
    'angle-double-down',
    'caret-down',
    'caret-square-down',
  ];

  const iconsType = [
    {
      name: 'Brands',
      iconsPrefix: 'fa-brands fa-',
      iconLibrary: fabIcons,
    },
    {
      name: 'Recommended',
      iconsPrefix: 'fa-solid fa-',
      iconLibrary:
        iconDestination == 'Active Icon'
          ? activeIconLibrary
          : inactiveIconLibrary,
    },
    {
      name: 'Solid',
      iconsPrefix: 'fa-solid fa-',
      iconLibrary: fasIcons,
    },
    {
      name: 'Regular',
      iconsPrefix: 'fa-regular fa-',
      iconLibrary: farIcons,
    },
    {
      name: 'All',
      iconObject: [
        { name: 'Brands', iconsPrefix: 'fa-brands fa-', iconLibrary: fabIcons },
        { name: 'Solid', iconsPrefix: 'fa-solid fa-', iconLibrary: fasIcons },
        {
          name: 'Regular',
          iconsPrefix: 'fa-regular fa-',
          iconLibrary: farIcons,
        },
      ],
    },
  ];
  const [inputText, setInputText] = useState('');
  const [selectedIconType, setSelectedIconType] = useState(
    iconDestination.toLowerCase().includes('active')
      ? iconsType[1]
      : iconsType[4],
  );
  const currentIconsArray = selectedIconType.name === 'All'
    ? selectedIconType.iconObject.map((iconObj) => iconObj.iconLibrary).flat()
    : selectedIconType.iconLibrary;

  // console.log(currentIconsArray);
  const handleSearch = (e) => {
    setInputText(e.target.value);
  };

  function searchByIcon(array, searchTerm) {
    const matchingItems = [];

    array.forEach((item) => {
      const matchingIcons = item.iconLibrary.filter((icon) => icon.replaceAll('-', ' ').startsWith(searchTerm));
      if (matchingIcons.length > 0) {
        matchingItems.push({
          ...item,
          iconLibrary: matchingIcons,
        });
      }
    });

    return matchingItems;
  }
  // iconName.replaceAll('-', ' ').slice(1)
  const searchedIcons = selectedIconType.name === 'All'
    ? searchByIcon(selectedIconType.iconObject, inputText.toLowerCase())
    : selectedIconType.iconLibrary.filter((iconName) => iconName
      .toLowerCase()
      .replaceAll('-', ' ')
      .includes(inputText.toLowerCase()));

  const renderedIcons = inputText.length
    ? searchedIcons.length
      ? searchedIcons
      : selectedIconType.name === 'All'
        ? [
          {
            name: 'Not Found',
            iconsPrefix: 'fa-solid fa-question-circle',
            iconLibrary: [''],
          },
        ]
        : ['fa-solid fa-question-circle']
    : selectedIconType.name === 'All'
      ? selectedIconType.iconObject
      : selectedIconType.iconLibrary;

  // const searchedArray = TodosComponents.filter((comp)=> {
  //   return comp.name.toLowerCase().includes(search.toLowerCase())})

  // const renderedArray = search.length ? searchedArray.length ? searchedArray : [{
  //   name: "Not Found",
  //   icon: notFound
  // }] : SelectedComp.components;

  return (
    <div className="iconPopupContainer">
      <div className="iconPopupContent">
        <div className="iconPopupHeader">
          <nav>
            <img />
            <h3 className="headerText">Icon Library</h3>
          </nav>
          <button
            onClick={() => {
              setIconPopup(false);
            }}
          >
            <img src={cross} />
          </button>
        </div>
        <div className="iconPopupBody">
          <div className="iconPopuBodyNav">
            {iconsType
              .filter(
                (iconType) => iconDestination === 'Active Icon'
                  || iconDestination === 'Inactive Icon'
                  || iconType.name !== 'Recommended',
              )
              .sort((a, b) => {
                if (a.name === 'Recommended') {
                  return -1;
                }
                if (b.name === 'Recommended') {
                  return 1;
                }
                return a.name.localeCompare(b.name);
              })
              .map((iconType, index) => (
                <button
                  className={`${
                    iconType.name === selectedIconType.name
                      ? 'activeNavButton'
                      : ''
                  } navButton`}
                  key={index}
                  onClick={() => setSelectedIconType(iconType)}
                >
                  {iconType.name}
                </button>
              ))}
          </div>
          <div className="searchIconContainer">
            <input
              type="text"
              value={inputText}
              onChange={handleSearch}
              placeholder="Filter by name..."
            />
            <div className="selectedIconComponent">
              {selectedIconType.name === 'All' ? (
                <div>
                  <h1>{selectedIconType.name}</h1>
                  {renderedIcons.map((iconType, index) => (
                    <div key={index}>
                      <div className="flex flex-wrap">
                        {iconType.iconLibrary.map((iconName, index) => (
                          <div
                            key={index}
                            className="selectedIcon"
                            onClick={() => {
                              setIconClass(iconType.iconsPrefix + iconName);
                              console.log(iconType.iconsPrefix + iconName);
                            }}
                            style={{
                              border:
                                iconClass === iconType.iconsPrefix + iconName
                                  ? '1px solid #fff'
                                  : 'none',
                              backgroundColor:
                                iconClass === iconType.iconsPrefix + iconName
                                  ? '#333438'
                                  : '',
                            }}
                            title={
                              iconName.charAt(0).toUpperCase()
                              + iconName.replaceAll('-', ' ').slice(1)
                            }
                          >
                            <i className={iconType.iconsPrefix + iconName} />
                            <span style={{ textTransform: 'capitalize' }}>
                              {iconName.length < 6
                                ? iconName.replace('-', ' ')
                                : `${iconName
                                  .replace('-', ' ')
                                  .slice(0, 6)}...`}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="selectedIconComponent">
                  <h1>{selectedIconType.name}</h1>
                  <div className="flex flex-wrap">
                    {renderedIcons.map((iconName, index) => (
                      <div
                        key={index}
                        className="selectedIcon"
                        onClick={() => {
                          setIconClass(selectedIconType.iconsPrefix + iconName);
                        }}
                        style={{
                          border:
                            iconClass
                            === selectedIconType.iconsPrefix + iconName
                              ? '1px solid #fff'
                              : 'none',
                          backgroundColor:
                            iconClass
                            === selectedIconType.iconsPrefix + iconName
                              ? '#333438'
                              : '',
                        }}
                        title={
                          iconName.charAt(0).toUpperCase()
                          + iconName.replaceAll('-', ' ').slice(1)
                        }
                      >
                        <i
                          className={selectedIconType.iconsPrefix + iconName}
                        />
                        <span style={{ textTransform: 'capitalize' }}>
                          {' '}
                          {iconName.length < 6
                            ? iconName.replace('-', ' ')
                            : `${iconName.replace('-', ' ').slice(0, 6)}...`}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            const e = {
              target: {
                value: iconClass,
              },
            };
            console.log(iconDestination);
            if (iconDestination == 'Choose Icon') {
              propMiscChange('icon', e);
            } else if (iconDestination == 'Active Icon') {
              propMiscChange('activeIcon', e);
            } else if (iconDestination == 'Inactive Icon') {
              propMiscChange('inactiveIcon', e);
            }

            setIconPopup(false);
          }}
          className="AddButton"
        >
          Add Icon
        </button>
      </div>
    </div>
  );
};

export default IconPopup;
