import { USERDATA } from '../constants/types';

const initialState = {
  data: [],

};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USERDATA:
      console.log(action.payload, 'reducer');
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}
