import React, { useRef } from "react";
import "../../../../styles/builder/ButtonEdit.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsSliders } from "react-icons/bs";
import {
  fontSizeOptions,
  targetOptions,
  alignOptions,
  nonDigitStyles,
  progressBarTypeOptions,
  ratingScaleOptions,
  ratingIconOptions,
} from "../../../../helpers/Builder/helperArrays";
import CustomButton from "../../../customButton/CustomButton";
import {
  responsiveIcons,
  percentageViewOptions,
} from "../../../../helpers/Builder/helperArrays";
import DropDown from "./DropDown";

export default function StarRatingEdit({
  setselectedtab,
  propValue,
  propChange,
  propMiscValue,
  propMiscChange,
  styleValue,
  styleChange,
  device,
  setDevice,
  setResponsiveMode,
  responsiveData,
  responsiveMode,
}) {
  const tituloRef = useRef(null);
  const tipografia = useRef(null);
  const changeObjectStyle = (style, position, e) => {
    const positionValue = e.target.value;
    const newStyleObject = {
      target: {
        value: {
          ...styleValue(style),
          [position]: positionValue,
        },
      },
    };
    styleChange(style, newStyleObject);
  };
  return (
    <div className="buttonEdit">
      <div className="py-2 ">
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="spanTitle"
        >
          <label htmlFor="target" className="spanTitle">
            Rating Scale
          </label>
          <select
            style={{
              fontSize: "13px",
              border: "1px solid #ddd",
              textAlign: "center",
              outline: 0,
              color: "#72728F",
              padding: "0",
              width: "7rem",
            }}
            className=""
            name="target"
            id="target"
            onChange={(e) => {
              propMiscChange("target", e);
            }}
          >
            {ratingScaleOptions.map((target) => (
              <option
                value={target.value}
                selected={propMiscValue("target") == target.value}
              >
                {target.response}
              </option>
            ))}
          </select>
        </span>
      </div>
      <div
        className="py-4 border-b"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <label htmlFor="target" className="spanTitle">
          Rating
        </label>
        <input
          type="number"
          min={propMiscValue("min")}
          max={propMiscValue("max")}
          step={propMiscValue("step")}
          defaultValue={propMiscValue("min")}
          value={propMiscValue(parseFloat("rating").toFixed(1))}
          onChange={(e) => propMiscChange("rating", e)}
          style={{
            margin: "8px 0",
            padding: "0",
            fontSize: "13px",
            border: "1px solid #ddd",
            textAlign: "center",
            outline: 0,
            color: "#72728F",
            padding: "0",
            width: "7rem",
          }}
        />
      </div>
      <div className="py-2 ">
        <div className="py-1" style={{ marginTop: "0.7rem" }}>
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="spanTitle"
          >
            <label htmlFor="target" className="spanTitle">
              Icon
            </label>
            <select
              style={{
                fontSize: "13px",
                border: "1px solid #ddd",
                textAlign: "center",
                outline: 0,
                color: "#72728F",
                padding: 0,
                width: "7rem",
              }}
              className=""
              name="target"
              id="target"
              // onChange={(e) => {
              //   propMiscChange("target", e);
              // }}
            >
              {ratingIconOptions.map((target) => (
                <option
                  value={target.value}
                  selected={propMiscValue("target") == target.value}
                >
                  {target.response}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="py-4 border-b-2">
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="spanTitle"
          >
            <label htmlFor="target" className="spanTitle">
              Unmarked Style
            </label>
            <select
              style={{
                fontSize: "13px",
                border: "1px solid #ddd",
                textAlign: "center",
                outline: 0,
                color: "#72728F",
                padding: 0,
                width: "7rem",
              }}
              className=""
              name="target"
              id="target"
              // onChange={(e) => {
              //   propMiscChange("target", e);
              // }}
            >
              {/* {percentageViewOptions.map((target) => (
                <option
                value={target.value}
                selected={propMiscValue("target") == target.value}
                >
                  {target.response}
                </option>
              ))} */}
            </select>
          </span>
        </div>
      </div>
      <div
        className="py-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span className="spanTitle">Title</span>
        <input
          style={{
            outline: 0,
            border: "1px solid #ddd",
            borderRadius: "5px",
            width: "100%",
            fontSize: "13px",
            color: "#72728f",
            padding: "0px 5px",
            width: "115px",
            overflowX: "hidden",
          }}
          type="text"
          defaultValue=""
          value={propValue("ratingText")}
          onChange={(e) => {
            propChange("ratingText", e);
          }}
        />
      </div>
      <div
        className="py-4 border-b-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label htmlFor="target" className="spanTitle">
          Alignment
        </label>
        <DropDown
          responsiveData={responsiveData}
          responsiveMode={responsiveMode}
          setDevice={setDevice}
          setResponsiveMode={setResponsiveMode}
          position={"29%"}
        />
        ={" "}
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="spanTitle"
        >
          <select
            style={{
              fontSize: "13px",
              border: "1px solid #ddd",
              textAlign: "center",
              outline: 0,
              color: "#72728F",
              padding: 0,
              width: "7rem",
            }}
            className=""
            name="textType"
            id="target"
            onChange={(e) => {
              changeObjectStyle("jusctifyContent", device, e);
              styleChange("jusctifyContent", e);
              propMiscChange("jusctifyContent", e);
            }}
          >
            {alignOptions.map((align) => (
              <option value={align} selected={styleValue("jusctifyContent")}>
                {align}
              </option>
            ))}
          </select>
        </span>
      </div>

      <div className="customButtonComponent">
        <CustomButton
          loading={false}
          disabled={false}
          textColor="#fff"
          style={{ textAlign: "center" }}
          Icon={BsSliders}
          buttontext="Editar tipografia"
          backgroundColor="#62627A"
          variant="contained"
          onClick={() => {
            setselectedtab("slider");
          }}
        />
      </div>
    </div>
  );
}
