import React, { useContext, useState, useEffect } from 'react';
import '../styles/global.css';
import '../styles/novaPagina/NovaPagina.css';
import '../styles/sites/Sites.css';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  where,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import greenEncrypt from '../assets/icons/sites/GreenEncrypt.png';
import goToSite from '../assets/icons/sites/GoToSite.png';
import deleteIcon from '../assets/icons/sites/Delete.png';
import attachIcon from '../assets/icons/sites/Attach.svg';
import unlocked from '../assets/icons/sites/unlocked.png';
import { CreateSite, SideBarHome } from '../components';
import Connect from '../components/connect/Connect';
import ModalSite from '../components/site/ModalSite';
import UnlinkDomain from '../components/domain/UnlinkDomain';
import { apiCall, firebaseSnapshotCall } from '../service/commonService';
import SiteSkelton from '../components/skelton/SiteSkelton';
import Add from '../assets/icons/sites/Add.png';
import { db, auth } from '../config/firebase';
import { LoginContext } from '../contexts/ContextProvider.js';
import DeleteAPI from '../components/site/DeleteSite';
import UnlinkSite from '../components/site/UnlinkSite';
import RequestSSL from '../components/ssl/RequestSSL';

const Sites = () => {
  const [inputVal, setInputVal] = useState('');
  const navigate = useNavigate();
  const [sites, setSites] = useState([]);
  const [originalSitesData, setOriginalSitesData] = useState([]);
  const user = useContext(LoginContext);
  const [showPopup, setShowPopup] = useState(false);
  const { i18n, t } = useTranslation();
  const [siteId, setSiteId] = useState('');
  const [siteName, setSiteName] = useState('');
  const [domainName, setDomainName] = useState('');
  const [deletePopup, setDeletePopup] = useState(false);
  const [unlinkPopup, setUnlinkPopup] = useState(false);
  const [requestSSLPopup, setRequestSSLPopup] = useState(false);
  const [connectPopup, setConnectPopup] = useState(false);
  const [modalSite, setModalSite] = useState(false);
  const [unlinkDomain, setUnlinkDomain] = useState(false);
  const [provDomain, setProvDomain] = useState();
  const [siteData, setSiteData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errormsg, setErrorMsg] = useState('');

  const howManySites = localStorage.getItem('sites');
  const Skeletons = [];
  for (let i = 0; i < howManySites; i++) {
    Skeletons.push(<SiteSkelton key={i} />);
  }

  /* function to get all tasks from firestore in realtime */
  useEffect(() => {
    fetchSites();
  }, []);

  const toCreate = async (input) => {
    console.log(input);
  };

  const handleSitePopup = () => {
    setModalSite(true);
  };

  const handleUnlinkModal = (siteId, siteName) => {
    setUnlinkPopup(true);
    setSiteId(siteId);
    setSiteName(siteName);
  };

  const handleDeleteModal = (siteId, siteName) => {
    setDeletePopup(true);
    setSiteId(siteId);
    setSiteName(siteName);
  };
  const handleConnectPopup = async (data) => {
    console.log('ff', data);
    setProvDomain(data.provDomain);
    setSiteData(data);

    if (data.provDomain == null || data.provDomain == undefined) {
      setConnectPopup(true);
    } else if (
      data.provDomain
      && data.custDomain !== null
      && data.provDomain
      && data.custDomain !== undefined
    ) {
      setUnlinkDomain(true);
    } else {
      setModalSite(true);
    }
  };

  const handleRequestModal = async (data) => {
    const user = auth.currentUser;
    console.log(data);
    setSiteData(data);
    if (data?.status?.txtName && data?.status?.txtValue) {
      setRequestSSLPopup(true);
    } else {
      const buffSiteId = Buffer.from(data?.id).toString('base64');

      let userToken;
      try {
        userToken = await user.getIdToken(true);
      } catch (error) {
        console.log('Erro de autenticação => ', error);
      }
      const userData = {
        id: data?.custDomainCfId,
        userToken,
        siteId: buffSiteId,
        sslStatus: data?.status?.status,
      };
      const response = await apiCall(
        'post',
        '/cloudpanel/api/dns/ssl',
        userData,
      );
      const succeeded = response.data;
      if (succeeded.success) {
      }
    }
    setSiteId(data.id);
    setSiteName(data?.ssl);
  };

  async function fetchSites() {
    setIsLoading(true);
    const q = query(
      collection(db, 'sites'),
      where('ownerId', '==', user?.user?.uid),
    );

    firebaseSnapshotCall(q).then((querySnapshot) => {
      if (querySnapshot != undefined) {
        setSites(
          querySnapshot.docs.map((doc) =>
            // console.log(doc.data()),
            ({
              id: doc.id,
              ssl: doc.data().atomicDomain,
              provDomain: doc.data().provDomain,
              status: doc.data().ssl,
              custDomain: doc.data().custDomain,
              custDomainCfId: doc.data().custDomainCfId,
              dynamicPath: `https://${doc.data().atomicDomain}/`,
              pages: doc.data().urls,
            })),
        );
        setOriginalSitesData(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ssl: doc.data().atomicDomain,
            dynamicPath: `https://${doc.data().atomicDomain}/`,
            pages: doc.data().urls,
          })),
        );
        localStorage.setItem('sites', querySnapshot?.docs?.length);

        setIsLoading(false);
      }
    });
  }

  const handleReflect = (e) => {
    const externalURL = e?.dynamicPath; // Replace with your desired external URL
    window.open(externalURL, '_blank');
  };

  useEffect(() => {
    console.log(originalSitesData);
    document.body.classList.add('custom-background');
    return () => {
      document.body.classList.remove('custom-background');
    };
  }, []);

  const handleSearchInputChange = (e) => {
    setIsLoading(true);
    const query = e;
    if (query != '') {
      setInputVal(query);
      // Perform the search
      const results = originalSitesData.filter((item) => item.ssl.includes(query));
      setSites(results);
      setIsLoading(false);
    } else {
      fetchSites();
      setInputVal('');
    }
    setIsLoading(false);
  };

  return (
    <div>
      {requestSSLPopup && (
        <RequestSSL
          siteData={siteData}
          btn={t('tocheck')}
          title="Request SSL"
          closeFun={setRequestSSLPopup}
        />
      )}
      {unlinkPopup && (
        <UnlinkSite
          siteId={siteId}
          siteName={siteName}
          domainName={domainName}
          btn="Unbind"
          title="Unbind"
          closeFun={setUnlinkPopup}
        />
      )}
      {showPopup && (
        <CreateSite
          func={toCreate}
          title={t('createSite')}
          label="Criar um novo produto"
          placeholder="Digite o nome do produto"
          btn={t('confirm')}
          closeFun={setShowPopup}
        />
      )}
      {deletePopup && (
        <DeleteAPI
          siteId={siteId}
          siteName={siteName}
          func={toCreate}
          title="Delete produto"
          label="Criar um novo produto"
          placeholder="www.exexample.com.br"
          btn={t('remove')}
          closeFun={setDeletePopup}
        />
      )}
      {connectPopup && (
        <Connect
          siteId={siteData.id}
          func={toCreate}
          title="Conectar domínio"
          label="Qual domínio deseja conectar a esse site?"
          placeholder="Digite seu domínio"
          note="Nota: Você precisa ter comprado o domínio previamente."
          btn="Conectar"
          closeFun={setConnectPopup}
          modal={setModalSite}
        />
      )}
      {modalSite && (
        <ModalSite
          siteId={siteId}
          siteData={siteData}
          func={toCreate}
          title="Criar site"
          label="Escolha um nome para o seu novo site."
          placeholder="Digite o nome do site"
          note="em seguida, você poderá conectar um domínio próprio a esse site."
          btn="Confirmar"
          closeFun={setModalSite}
        />
      )}
      {unlinkDomain && (
        <UnlinkDomain
          siteData={siteData}
          func={toCreate}
          title="Unlink"
          label="Escolha um nome para o seu novo site."
          placeholder="Digite seu domínio"
          note="Nota: em seguida, você poderá conectar um domínio próprio a esse site."
          btn="Unbind"
          closeFun={setUnlinkDomain}
        />
      )}

      <SideBarHome />
      <div className="meusVideos-container ">
        <div className="meusVideos-header">
          <p className="heading">Sites</p>
          <div className="SitesHeader">
            <input
              type="text"
              placeholder={t('search')}
              value={inputVal}
              onChange={(e) => handleSearchInputChange(e.target.value)}
            />
            <span
              className="close"
              onClick={() => {
                setInputVal('');
                handleSearchInputChange('');
              }}
            >
              ×
            </span>
            <button onClick={handleSitePopup}>
              <img className="addIcon" src={Add} alt="AddIcon.svg" />
              <span>{t('createSite')}</span>
            </button>
          </div>
        </div>
        <div className="meusVideos-middle">
          <div className="SitesBodyHeader">
            <div className="DivisionHeaderBody">
              <p>SSL</p>
              <p>URL</p>
            </div>
            <div className="DivisionHeaderBody1">
              <p>{t('action')}</p>
            </div>
          </div>
          <div className="SitesBodyBody">
            {!isLoading ? (
              <>
                {sites.map((obj, index) => (
                  <div
                    key={obj.id}
                    className="SiteComponent"
                    style={
                      index % 2 != 0
                        ? { background: '#212129' }
                        : { background: '#31313D' }
                    }
                  >
                    <div className="leftSiteComponent">
                      {obj.status?.status == 'pending' ? (
                        <img
                          className="lockIcon"
                          src={unlocked}
                          alt="greenEncrypt.svg"
                          onClick={() => handleRequestModal(obj)}
                        />
                      ) : (
                        <img
                          className="leftSideImg"
                          src={greenEncrypt}
                          alt="greenEncrypt.svg"
                          onClick={() => handleRequestModal(obj)}
                        />
                      )}
                      <p
                        onClick={() => navigate(`/site/${obj.id}`, { state: obj })}
                      >
                        {obj?.ssl}
                      </p>
                      {/* <a href={obj?.dynamicPath} target="_blank" >reflect</a> */}
                      <img
                        onClick={() => handleReflect(obj)}
                        src={goToSite}
                        alt="goToSite.svg"
                      />
                    </div>
                    <div className="rightSiteComponent">
                      <img
                        src={attachIcon}
                        onClick={() => handleConnectPopup(obj)}
                        alt="attachIcon.svg"
                      />
                      <img
                        onClick={() => handleDeleteModal(obj.id, obj.ssl)}
                        src={deleteIcon}
                        alt="deleteIcon.svg"
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : (
              Skeletons
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sites;
