import React, { useState, useEffect } from "react";
import "../styles/novaPagina/NovaPagina.css";
import "../styles/global.css";
import "../styles/tutorial/index.css"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { SideBarHome } from "../components";
import { db, auth } from "../config/firebase";
import {
  doc,
  getDoc,

} from "firebase/firestore";
import { useCollection } from "../custom-hooks/useCollection";

import Files from '../assets/icons/nomeDoVideo/tutorials.png'
const Tutorials = () => {
  const user = auth.currentUser
  const navigate = useNavigate()
  const [selected, setSelected] = useState("");
  const [inputVal, setInputVal] = useState('')
  const [Tutorials, setTutorials] = useState([])
  const [color, setColor] = useState('')
  const { t } = useTranslation();
  console.log(Tutorials)
  useEffect(() => {
    document.body.classList.add("custom-background");
    return () => {
      document.body.classList.remove("custom-background");
    };
  }, []);
  const { documents: tutorial } = useCollection("tutorials");

  const filteredTutorial = Tutorials.filter((item) => item.category.includes(selected));
  console.log(filteredTutorial)
  useEffect(() => {

    setTutorials([...tutorial])
    console.log(tutorial)
  }, [tutorial]);


  const handleTutorial = (e, item) => {
    navigate(`/tutorial/${item.link}`)
  }
  return (
    <div style={{ display: "flex" }}>
      <SideBarHome />

      <div className="RightComponentLanding">
        <div className=" headerTutorial">
          <p>Tutorial</p>
          <div className=" tutorialfilter">
            <div
              className={
                selected == "" ? "selectedComponent" : "unselectedComponent"
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected("");
                }}
              >
                Todos
              </span>
            </div>
            <div
              className={
                selected == "Hospedagem"
                  ? "selectedComponent"
                  : "unselectedComponent"
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected("Hospedagem");
                }}
              >
                Hospedagem
              </span>
            </div>
            <div
              className={
                selected == "Construção de Páginas"
                  ? "selectedComponent"
                  : "unselectedComponent"
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected("Construção de Páginas");
                }}
              >
                Construção de Páginas
              </span>
            </div>
            <div
              className={
                selected == "Domínios"
                  ? "selectedComponent"
                  : "unselectedComponent"
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected("Domínios");
                }}
              >
                Domínios
              </span>
            </div>
            <div
              className={
                selected == "Outros"
                  ? "selectedComponent"
                  : "unselectedComponent"
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected("Outros");
                }}
              >
                Outros
              </span>
            </div>
          </div>
          <div>
            <input
              className="tutorialSearch"
              type="text"
              placeholder={t("search")}
              value={inputVal}
              onChange={(e) => setInputVal(e.target.value)}
            />
          </div>
        </div>
        <div className="tutorialBox">
          <>
            {filteredTutorial?.filter((item)=>item.name.includes(inputVal)).map((item) => (
              <div className=" tutorialList" key={item.id}>
                <div className="sub1-video-list-div ">
                  <>

                    <img src={Files} />
                    <p onClick={(e) => handleTutorial(e, item)} style={{cursor: 'pointer'}}>{item.name}</p>
                    <p className={`categoryName dark:bg-${item.color}-900/20 dark:text-${item.color}-500   p-0.5 px-1.5 rounded-full`} >{item.category}</p>
                  </>

                </div>

              </div>
            ))}

          </>

        </div>
      </div>
    </div>
  );
};

export default Tutorials;
