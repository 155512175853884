import React from "react";
import "../../styles/sideBarHome/SideBarHome.css";

const NotifcationBar = ({ note, bgColor, leftBorderColor }) => {
  return (
    <div
      className="notifyBar"
      style={{
        background: bgColor,
        borderLeft: `4px solid ${leftBorderColor}`,
      }}
    >
      <p>{note}</p>
    </div>
  );
};

export default NotifcationBar;
