import ImageEdit from "../../components/builder/drawer/element-edit/ImageEdit";
import ButtonEdit from "../../components/builder/drawer/element-edit/ButtonEdit";
import TextEdit from "../../components/builder/drawer/element-edit/TextEdit";
import desktopIcon from "../../assets/icons/builder/desktop.svg";
import mobileIcon from "../../assets/icons/builder/cellular.svg";
import tabletIcon from "../../assets/icons/builder/tablet.svg";
import { AiFillStar } from "react-icons/ai";

export const nonDigitStyles = [
  "color",
  "borderColor",
  "borderStyle",
  "textAlign",
  "backgroundColor",
  "fontSize",
];
export const borderStyles = [
  "solid",
  "dotted",
  "dashed",
  "double",
  "groove",
  "ridge",
  "inset",
  "outset",
  "none",
  "hidden",
];
export const tagsOptions = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "div",
  "span",
  "p",
];
export const fontSizeOptions = [
  "xx-large",
  "x-large",
  "larger",
  "large",
  "medium",
  "small",
  "x-small",
  "xx-small",
  "revert",
];
export const widthOptions = [
  {
    name: "Inline (auto)",
    value: "fit-content",
  },
  {
    name: "Full Width",
    value: 100,
  },
  {
    name: "Custom",
    value: "custom",
  },
];

export const dividerElementOptions = ["none", "text", "icon"];

export const targetOptions = [
  { response: "Sim", value: "_blank" },
  { response: "Não", value: "none" },
];

export const progressBarTypeOptions = [
  { response: "Default", value: "Default" },
  { response: "Info", value: "Info" },
  { response: "Success", value: "Success" },
  { response: "Warning", value: "Warning" },
  { response: "Danger", value: "Danger" },
];

export const percentageViewOptions = [
  { response: "Show", value: "Show" },
  { response: "Hide", value: "Hide" },
];

export const ratingScaleOptions = [
  { response: "0-5", value: "★★★★★" },
  { response: "0-10", value: "★★★★★★★★★★" },
];

export const ratingIconOptions = [
  { response: "Font Awesome", value: "<AiFillStar />" },
  { response: "Unicode", value: "<AiFillStar />" },
];

export const timeUnits = [
  { response: "Segundos", value: "s" },
  { response: "Minutos", value: "m" },
  { response: "Horas", value: "h" },
  { response: "Dias", value: "d" },
  { response: "Semanas", value: "w" },
  { response: "Meses", value: "M" },
  { response: "Anos", value: "y" },
];

export const alignOptions = ["left", "center", "right", "justify"];

export const inlineTags = ["a", "span"];

// Components

export const EditTypeArray = [
  {
    type: "text",
    component: <TextEdit />,
    props: {},
  },
  {
    type: "img",
    component: <ImageEdit />,
  },
  {
    type: "button",
    component: <ButtonEdit />,
  },
];

export const videoPlayers = [
  { value: "atomic", label: "AtomicPlay" },
  { value: "panda", label: "Panda" },
  { value: "vturb", label: "VTurb" },
  { value: "vimeo", label: "Vimeo" },
  { value: "wistia", label: "Wistia" },
  { value: "youtube", label: "Youtube" },
  { value: "other", label: "Outros" },
];

export const needContent = ["text", "button"];

export const needItem = ["accordion", "carousel", "list", "table", "facebook"];

export const responsiveIcons = [
  {
    icon: desktopIcon,
    mode: "desktop",
    width: window.innerWidth,
  },
  {
    icon: tabletIcon,
    mode: "tablet",
    width: 768,
    max: 1024,
    min: 768,
  },
  {
    icon: mobileIcon,
    mode: "mobile",
    width: 360,
    max: 767,
    min: 320,
  },
];

export const alignableTags = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "p",
  "span",
  "div",
  "a",
  "button",
];
