import React, {
  useEffect, useState, useRef, useContext,
} from 'react';

import '../../styles/loginPage/LoginPage.css';
import { IoMdAlert } from 'react-icons/io';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { login } from '../../service/api';

import { LoginContext } from '../../contexts/ContextProvider.js';

import {
  auth,
  signInWithPopup,
  googleProvider,
  query,
  collection,
  where,
  db,
  getDocs,
  addDoc,
  Timestamp,
  updateDoc,
} from '../../service/auth/authService.js';
import '../../styles/global.css';

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const captchaRef = useRef(null);
  const { setUser } = useContext(LoginContext);
  const user = useContext(LoginContext);
  const navigate = useNavigate();
  const [dispError, setDispError] = useState('none');
  const [errMessage, setErrMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [userDat, setUserDat] = useState([]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    document.body.classList.add('custom-background');
    return () => {
      document.body.classList.remove('custom-background');
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(rememberMe);
    if (!validateEmail(email)) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage('The email is not of required format');
    }
    if (!validPassword) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage('Password should be greater than 8 characters');
    }
    console.log(`${email} ${password}`);

    // const token = await captchaRef.current.executeAsync();
    try {
      const token = captchaRef.current.getValue();
      const response = await login({
        token,
        email,
        password,
      });
      console.log(
        `Success${response.data.success} ` + `Message:${response.data.message}`,
      );
      if (response.data.success == true) {
        // onAuthStateChanged(auth, (user) => {
        //   if (user) {
        //     console.log("UserExist", user);
        //     setUser(user);
        //   } else {
        //     setUser(null);
        //     navigate("/login");
        //   }
        // });

        try {
          setPersistence(
            auth,
            rememberMe ? browserLocalPersistence : browserSessionPersistence,
          );
          await signInWithEmailAndPassword(auth, email, password);
          console.log("adding lang");
          await updateDoc(doc(db, "users", user?.user?.uid), {
            selectedLang:"en",
          });
          console.log('added lang');
          navigate('/');
        } catch (err) {
          switch (err.code) {
            case 'auth/invalid-email':
              setIsLoading(false);
              setDispError('flex');
              setErrMessage('Please enter a valid email address.');
              break;
            case 'auth/wrong-password':
              setIsLoading(false);
              setDispError('flex');
              setErrMessage('Incorrect password.');
              break;
            default:
              setIsLoading(false);
              setDispError('flex');
              setErrMessage('An error occurred. Please try again later.');
              break;
          }
        }
      } else {
        navigate('/confirmEmail');
      }
    } catch (err) {
      setDispError('flex');
      setIsLoading(false);
      setErrMessage(`Error signing in : ${err}`);
    }

    captchaRef.current.reset();
    setEmail('');
    setPassword('');
  };

  async function signInWithGoogle() {
    try {
      console.log(googleProvider);
      const res = await signInWithPopup(auth, googleProvider);
      const { user } = res;
      console.log(user);
      const q = query(
        collection(db, 'users'),
        where('email', '==', user.email),
      );
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        const timestamp = Timestamp.now();
        await addDoc(collection(db, 'users'), {
          id: user.uid,
          online: false,
          name: user.displayName,
          plan: {
            name: 'free',
            credits: 0,
            renewalDate: timestamp,
          },
          email: user.email,
          // isReferred: req.body.isReferred,
          // referrer: req.body.referrer,
          // influencer: req.body.influencer,
          createdAt: timestamp,
          // phone: req.body.phone,
          // paidMedia: req.body.paidMedia,
          utm: null,
          products: [],
          customTags: [],
          newSetup: true,
        });
        console.log('Success ', user);
      }
      setUser(user);
      navigate('/');
    } catch (err) {
      setDispError('flex');
      setErrMessage(`Error signing in : ${err}`);
    }
  }

  const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const validatePassword = (e) => {
    const pass = e.target.value;
    if (pass.length >= 8) setValidPassword(true);
    else setValidPassword(false);
  };

  const buttonClassName = `EnterButton${isLoading ? ' button--loading' : ''}`;

  return (
    // <div className='body-container'>
    // {/* <LeftComponentVideo/> */}
    <div className="right-component">
      <form className="formStyle" onSubmit={handleSubmit}>
        <h1 className="headingStyle">Faça seu login!</h1>
        <p className="hyperLinkStyle">
          Ainda não tem uma conta?
          {' '}
          <strong
            onClick={() => {
              navigate('/signup');
            }}
          >
            Clique aqui
          </strong>
        </p>
        <div className="flexDisplay">
          <label className="labelInput" htmlFor="email">
            E-mail
          </label>
          <input
            className="inputDetails"
            type="text"
            id="email"
            placeholder="Digite seu e-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
        </div>
        <div className="flexDisplay">
          <label className="labelInput" htmlFor="password">
            Senha
          </label>
          <input
            className="inputDetails"
            type={showPassword ? 'text' : 'password'}
            id="password"
            placeholder="Digite sua senha"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              validatePassword(e);
            }}
            required
          />
          {/* <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="togglePassword-icon"
                onClick={handleTogglePassword}
              /> */}
        </div>
        <div className="form-checkBox">
          <div className="checkBox">
            <input
              type="checkbox"
              className="custom-checkbox"
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
            />
            <label className="lembrar">Lembrar-me</label>
          </div>
          <div>
            <p
              onClick={() => {
                navigate('/redefine');
              }}
              style={{ cursor: 'pointer' }}
              className="lembrar"
            >
              Esqueceu a senha?
            </p>
          </div>
        </div>
        <ReCAPTCHA
          ref={captchaRef}
          // sitekey='6LfVo6glAAAAAI4Ab0lUudzd_tIgYYzgeennEN4R'
          sitekey="6LcU9gkgAAAAALzI75bbxwvJJJC-eQ0p3eYrzaeq"
          theme="dark"
          // size="invisible"
        />
        <button type="submit" className={buttonClassName}>
          <span className="button-text">
            {isLoading ? 'Carregando...' : 'Fazer Login'}
          </span>
        </button>
        <button
          type="button"
          className="google-login"
          onClick={signInWithGoogle}
        >
          <span className="google-icon" />
          <span className="google-button">Fazer login com o google</span>
        </button>

        <div className="errorBlock" style={{ display: dispError }}>
          <div className="alert">
            <IoMdAlert />
          </div>
          <div>
            <p className="errorMessage">{errMessage}</p>
          </div>
        </div>
      </form>
    </div>
    // </div> */}
  );
};

export default LoginPage;
