import React, { useState, useEffect } from 'react';
import '../../styles/frazerUpload/FrazerUpload.css';
import { useTranslation } from 'react-i18next';
import {
  collection,
  doc,
  where,
  updateDoc,
  setDoc,
  getDoc,
  arrayUnion,

} from 'firebase/firestore';
import close from '../../assets/icons/former/close-icon.svg';
import { auth, db } from '../../config/firebase';

const ExternalDomain = ({
  closeFun, title, btn, label, placeholder, fileupload, func,
}) => {
  const user = auth.currentUser;

  const { i18n, t } = useTranslation();
  const [addingExternalDomain, setAddingExternalDomain] = useState(false);
  const [newExternalDomain, setnewExternalDomain] = useState([]);
  const [videoDoc, setVideoDoc] = useState([]);
  const [externalModal, setExternalModal] = useState(false);
  useEffect(() => {
    const docRef = doc(db, 'videos', user.uid);
    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          console.log('Document data:', doc.data());
          setVideoDoc(doc.data());
        } else {
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
      });
  }, []);

  const authorizeExternalDomain = async () => {
    setAddingExternalDomain(true);
    if (user) {
      if (videoDoc.externalDomains.includes(newExternalDomain)) {
        // informUser("alert", "O domínio já foi adicionado previamente.");
        setExternalModal(false);
        return;
      }
      const docref = doc(db, 'videos', user.uid);

      try {
        await setDoc(docref, {
          externalDomains: arrayUnion(newExternalDomain),
        });
        setAddingExternalDomain(false);
        console.log('updateDoc success');
      } catch (error) {
        console.error('updateDoc error:', error);
        setAddingExternalDomain(false);
      }
    } else {
      try {
        await setDoc(doc(db, 'videos', user.uid), {
          externalDomains: [newExternalDomain],
        });
        setAddingExternalDomain(false);

        console.log('setDoc success');
      } catch (error) {
        console.error('setDoc error:', error);
        setAddingExternalDomain(false);
      }
    }

    setAddingExternalDomain(false);
    // informUser("success", "Domínio externo autorizado com sucesso!");
    setExternalModal(false);
  };

  const handleDomain = (e) => {
    e.preventDefault();
    authorizeExternalDomain();
  };
  return (
    <div className="frazer-container">
      <div className=" domain-box">
        <div className="frazer-upload-header">
          <h2>{title}</h2>
          <img onClick={() => closeFun(false)} style={{ cursor: 'pointer' }} src={close} alt="" />
        </div>

        <div className="hr80" />

        <form className="w-full">
          <div className="frazer-input">
            <label htmlFor="fileupload">Qual domínio você deseja autorizar?</label>
            <input value={newExternalDomain} onChange={(e) => setnewExternalDomain(e.target.value)} type="text" placeholder={placeholder} />
          </div>

          <div className="hr80" />

          <div className="fazer-btn">
            <p onClick={() => closeFun(false)}>{t('cancel')}</p>
            <button disabled={addingExternalDomain} onClick={handleDomain}>{addingExternalDomain ? 'Carregando' : btn}</button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default ExternalDomain;
