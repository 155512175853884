import React, { useState } from "react";
import "../../styles/frazerUpload/FrazerUpload.css";
import { useTranslation } from "react-i18next";
import close from "../../assets/icons/former/close-icon.svg";

const PageVisualPopup = ({
  closeFun,
  title,
  btn,
  label,
  placeholder,
  fileupload,
  func,
  templatePreview, 
  setTemplatePreview
}) => {
  const [input, setInput] = useState("");
  const [noteVal, setNoteVal] = useState("");
  const { i18n, t } = useTranslation();

  const blackPage = `
<!DOCTYPE html>
<html lang="pt-BR" dir="ltr">
<head>
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
</head>
<body style="background-color:rgb(15, 23, 42);">
</body>
</html>
`

  return (
    <div className="page-container">
      <div className="page-box">
        <div className="page-header">
          <h2>{title}</h2>
          <img                     onClick={() => setTemplatePreview({show: false, templateObject: null})}
 src={close} alt="" />
        </div>

        <div className="hr80" />

      
      </div>
    </div>
  );
};

export default PageVisualPopup;
