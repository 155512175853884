import React, { useState, useRef } from 'react';
import '../../styles/connect/Connect.css';
import axios from 'axios';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import close from '../../assets/icons/former/close-icon.svg';
import { auth, db } from '../../config/firebase';
// import CommonService from '../service/commonService'
import { apiCall } from '../../service/commonService';

export const CreateSite = ({
  closeFun,
  title,
  btn,
  fetchSites,
  where,
  label,
}) => {
  const ref = useRef();
  const user = auth.currentUser;

  const [siteName, setSiteName] = useState('');
  const [siteType, setSiteType] = useState('static');
  const [isValid, setIsValid] = useState(true);
  const [useCloaker, setUseCloaker] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { i18n, t } = useTranslation();

  const createSite = async () => {
    const rgx = /[^A-Za-z0-9]+/g; // Regex to validate site name
    const rand = String(Math.floor(Math.random() * 10000 + 1));

    try {
      let userToken;
      try {
        userToken = await user.getIdToken();
      } catch (error) {
        console.log('Authentication error:', error);
        return;
      }
      const userData = {
        name: `${siteName}${rand}`,
        siteType,
        userToken,
        server: useCloaker ? 1 : 0,
      };
      setIsLoading(true);

      const response = await apiCall(
        'post',
        'cloudpanel/api/verify/adduser',
        userData,
      );
      const succeeded = response.data;
      if (succeeded.success) {
        setIsLoading(false);

        closeFun(false);

        closeFun(false);
        // const createdAt = Timestamp;
        const doc = {
          cpUser: succeeded.cpUser,
          // createdAt: Timestamp,
          atomicDomain: succeeded.cf_domain,
          atomicDomainCfId: succeeded.atomicDomainCfId,
          ownerId: user.uid,
          siteType,
          server: useCloaker ? 1 : 0,
          pages: {},
          ssl: {
            status: null,
          },
        };
        const addedDocument = await addDoc(collection(db, 'sites'), doc);
        // const addedDocument = await addDoc(ref, { ...doc, createdAt });
        if (addedDocument.id) {
          setSuccess(succeeded.message);
        }
      } else {
      }
    } catch (error) {
      console.error('Error adding document:', error);
      setError('Failed to create the site.');
      setSuccess('');
      setIsLoading(false);
    }
  };
  const validateInput = (value) => {
    const regex = /[^A-Za-z0-9]+/g; // Regular expression for alphanumeric characters only
    return regex.test(value);
  };
  const handleChange = (e) => {
    const value = e.target.value.replace(/[^A-Za-z0-9]+/g, '');
    setSiteName(value);
    setIsValid(validateInput(value));
  };
  const handleKeyDown = (e) => {
    if (e.key === ' ' || /[^\w\s]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    createSite();
  };

  const handleUseCloakerChange = (event) => {
    setUseCloaker(event.target.checked);
  };
  return (
    <div className="frazer-container">
      <div className="frazer-box">
        <div className="frazer-upload-header">
          <h2>{title}</h2>
          <img onClick={() => closeFun(false)} src={close} alt="" />
        </div>
        <form className="minimum-height">
          <div className="connectInputContainer">
            <div className="frazer-input">
              <label htmlFor="siteName">Site Name:</label>
              <input
                type="text"
                id="siteName"
                value={siteName}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                required
              />
            </div>
            {/* {isValid &&
            <div className="ml-5 mb-5">

              <p className="errorMessage">Please Match the Format</p>
            </div>
          } */}

            <div className="note-box">
              <div>
                <p className="popup-note">
                  {t('note')}
                  :
                  {t('connectNote')}
                </p>
              </div>
            </div>

            <div className="ml-6 mb-5">
              <input
                className="mr-5"
                type="checkbox"
                id="useCloaker"
                checked={useCloaker}
                onChange={handleUseCloakerChange}
              />
              <label htmlFor="useCloaker">Use Cloaker</label>
            </div>
          </div>
          <div className="fazer-btn">
            <p onClick={() => closeFun(false)}>{t('cancel')}</p>
            <button type="submit" onClick={handleSubmit}>
              <span className="button-text">
                {isLoading ? 'Carregando...' : btn}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
