import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope, faLock, faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';

import '../styles/loginPage/LoginPage.css';
import '../styles/redefine/Redefine.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, useParams } from 'react-router-dom';

import { confirmPasswordReset } from 'firebase/auth';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { IoMdAlert } from 'react-icons/io';
import LeftComponentVideo from '../components/leftComponentVideo/LeftComponentVideo';
import { auth } from '../config/firebase';
import '../styles/global.css';

const RedefineSenha = () => {
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const reCaptchaRef = useRef();
  const navigate = useNavigate();
  const { actionCode } = useParams();
  const [dispError, setDispError] = useState('none');
  const [errMessage, setErrMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    document.body.classList.add('custom-background');
    return () => {
      document.body.classList.remove('custom-background');
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validPassword) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage('Password should be greater than 8 characters');
    }
    if (password == confirmPassword) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage('Password do not match');
    }
    if (!validateEmail(email)) {
      setIsLoading(false);
      setDispError('flex');
      setErrMessage('The email is not of required format');
    }
    console.log('actionCode: ', actionCode);
    console.log(`${password} ${confirmPassword}`);
    try {
      const res = await confirmPasswordReset(auth, actionCode, password);
      console.log(res);
      setIsLoading(false);
      setIsLoaded(true);
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      switch (error.code) {
        case 'auth/expired-action-code':
          setIsLoading(false);
          setDispError('flex');
          setErrMessage('The password reset link has expired. Please request a new link.');
          break;
        case 'auth/invalid-action-code':
          setIsLoading(false);
          setDispError('flex');
          setErrMessage('The password reset link is invalid. Please request a new link.');
          break;
        case 'auth/user-disabled':
          setIsLoading(false);
          setDispError('flex');
          setErrMessage('Your account has been disabled. Please contact support for assistance.');
          break;
        case 'auth/user-not-found':
          setIsLoading(false);
          setDispError('flex');
          setErrMessage('We could not find a user with that email address. Please check your email and try again.');
          break;
        case 'auth/weak-password':
          setIsLoading(false);
          setDispError('flex');
          setErrMessage('Your new password is too weak. Please choose a stronger password and try again.');
          break;
        default:
          setIsLoading(false);
          setDispError('flex');
          setErrMessage('An error occurred. Please try again later.');
          break;
      }
    }
  };

  const formError = () => {
    console.log('error');
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const validatePassword = (e) => {
    const pass = e.target.value;
    if (pass.length >= 8) setValidPassword(true);
    else setValidPassword(false);
  };

  const buttonClassName = `EnterButton${isLoading ? ' button--loading' : isLoaded ? ' button--loaded' : ''}`;

  return (
  // {/* <div className='body-container'>
  // <LeftComponentVideo/> */}
    <div className="right-component-redefine">
      <form className="formStyle" onSubmit={handleSubmit} onError={formError}>
        <h1 className="headingStyle">Redefina sua senha!</h1>
        <p className="hyperLinkStyle">
          Quer acessar sua conta?
          <strong onClick={() => { navigate('/login'); }}>Clique aqui</strong>
        </p>
        {/* <div className='flexDisplay'>
          <label className='labelInput' htmlFor="email">E-mail</label>
          <FontAwesomeIcon icon={faEnvelope} className="email-icon-redefine" />
          <input className='inputDetails'
          type="text"
          id="email"
          placeholder="Digite seu e-mail"
          value={email}
          onChange={(e)=>{setEmail(e.target.value)}}
          required/>
        </div> */}
        <div className="flexDisplay">
          <label className="labelInput" htmlFor="password">Nova senha</label>
          <FontAwesomeIcon icon={faLock} className="password-icon" />
          <input
            className="inputDetails"
            type={showPassword ? 'text' : 'password'}
            id="password"
            placeholder="Digite sua nova senha"
            value={password}
            onChange={(e) => { setPassword(e.target.value); validatePassword(e); }}
            required
          />
          {/* <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className="togglePassword-icon"
            onClick={handleTogglePassword}
          /> */}
        </div>
        <div className="flexDisplay">
          <label className="labelInput" htmlFor="password">Confirmar senha</label>
          <FontAwesomeIcon icon={faLock} className="password-icon" />
          <input
            className="inputDetails"
            type={showPassword ? 'text' : 'password'}
            id="password"
            placeholder="Confirme sua nova senha"
            value={confirmPassword}
            onChange={(e) => { setConfirmPassword(e.target.value); }}
            required
          />
          {/* <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className="togglePassword-icon"
            onClick={handleTogglePassword}
          /> */}
        </div>
        <ReCAPTCHA
          ref={reCaptchaRef}
          // sitekey='6LfVo6glAAAAAI4Ab0lUudzd_tIgYYzgeennEN4R'
          sitekey="6LfNkKklAAAAAEidji8V65sG6EogfoK-lW-9qUbW"
          theme="dark"
          size="invisible"
        />
        <button type="submit" className={buttonClassName}>
          <span className="button-text">{isLoaded ? <IoCheckmarkCircleOutline /> : null}</span>
          <span className="button-text">{isLoading ? 'Carregando...' : isLoaded ? 'Senha definida com sucesso!' : 'Redefinir senha'}</span>
        </button>
        <div className="errorBlock" style={{ display: dispError }}>
          <div className="alert">
            <IoMdAlert />
          </div>
          <div>
            <p className="errorMessage">{errMessage}</p>
          </div>
        </div>
      </form>
    </div>
  );
};
export default RedefineSenha;
