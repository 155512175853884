import React, { useState } from 'react';
import '../../styles/connect/Connect.css';
import {
  addDoc, collection, doc, setDoc, updateDoc,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import close from '../../assets/icons/former/close-icon.svg';
import { db } from '../../config/firebase';

const Connect = ({
  closeFun,
  title,
  btn,
  label,
  placeholder,
  fileupload,
  func,
  note,
  siteId,
  modal,
}) => {
  const [input, setInput] = useState('');
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const docref = doc(db, 'sites', siteId);
      console.log(doc);
      const addedDocument = await updateDoc(docref, { provDomain: input });
      console.log(addedDocument);
      closeFun(false);
      modal(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('failed', error);
    }
  };
  return (
    <div className="frazer-container">
      <div className="frazer-box">
        <div className="frazer-upload-header">
          <h2>
            {t('connect')}
            {' '}
            {t('domain')}
          </h2>
          <img
            onClick={() => closeFun(false)}
            style={{ cursor: 'pointer' }}
            src={close}
            alt=""
          />
        </div>
        <form className="minimum-height" onSubmit={handleSubmit}>
          <div className="connectInputContainer">
            <div className="frazer-input">
              <label htmlFor="fileupload">{t('connectDomainQues')}</label>
              <input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                type="text"
                placeholder={t('domainPlaceholder')}
              />
            </div>
            {fileupload && (
              <div className="frazer-input">
                <p>{fileupload}</p>
              </div>
            )}

            {/* note Section */}
            <div className="note-box">
              <div>
                <p className="popup-note">
                  {t('note')}
                  :
                  {t('connectNote')}
                </p>
              </div>
            </div>
          </div>
          <div className="fazer-btn">
            <p onClick={() => closeFun(false)}>{t('cancel')}</p>
            <button type="submit">
              {t(loading ? 'Carregando...' : 'connect')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Connect;
