import React from 'react';
import '../../styles/loadingScreen/LoadingScreen.css';
import '../../styles/global.css';
import logoM from '../../assets/images/sideBarHome/LogoM.svg';

export const LoadingScreen = () => (
  <div className="loading-screen">
    <div className="circle">
      <img src={logoM} alt="Logo.svg" className="LoadingLogo" />
      <div className="spinner" />
    </div>
    <div className="redirecting-text">
      <p>Carregando</p>
      <span className="dots" />
      <span className="dots" />
      <span className="dots" />
    </div>
  </div>
);
