import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from './auth/authService';
import { db } from '../config/firebase';

export const addDocument = async (collectionName, data) => {
  try {
    const createdAt = Timestamp.now();
    const docRef = await addDoc(collection(db, collectionName), { ...data, createdAt, id: '' });
    await updateDoc(docRef, { id: docRef.id });
    console.log('Document written with ID: ', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error adding document in usefirestore:', error);
  }
};

export const updateDocument = async (collectionName, id, data) => {
  try {
    const lastEdited = Timestamp.now();
    await updateDoc(doc(db, collectionName, id), { ...data, lastEdited });
  } catch (error) {
    console.error('Error updating document:', error);
  }
};

export const deleteDocument = async (collectionName, id) => {
  try {
    await deleteDoc(doc(db, collectionName, id));
  } catch (error) {
    console.error('Error deleting document:', error);
  }
};
