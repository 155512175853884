import React, { useState, useContext } from 'react';
import '../../styles/connect/Connect.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { Timestamp } from 'firebase/firestore';
import close from '../../assets/icons/former/close-icon.svg';
import { addDocument } from '../../service/useFirestore';
import { LoginContext } from '../../contexts/ContextProvider';

export const CreateTag = ({ closeFun }) => {
  const [tagName, setTagName] = useState('');
  const [currentColor, setCurrentColor] = useState('lightskyblue');
  const [isValid, setIsValid] = useState(true);
  const user = useContext(LoginContext);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const createTag = async () => {
    try {
      if (tagName.length == 0) {
        toast('Enter the name for tag', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        setIsLoading(true);
        const doc = {
          ownerId: user.user.uid,
          siteName: '',
          tags: {
            color: currentColor,
            label: tagName,
            twColor: currentColor,
            value: tagName,
          },
        };
        await addDocument('tags', doc);
        createTagToast();
        setIsLoading(false);
        closeFun();
      }
    } catch (error) {
      const createdAt = Timestamp.now();
      await addDocument('errors', {
        createdAt,
        error: error.message,
        userId: user.user.uid,
        where: 'CreateTag',
      });
      console.error('Error adding document in createTag:', error);
      setError('Failed to create the site.');
      setSuccess('');
    }
  };
  const validateInput = (value) => {
    const regex = /[^A-Za-z0-9]+/g; // Regular expression for alphanumeric characters only
    return regex.test(value);
  };
  const handleChange = (e) => {
    const value = e.target.value.replace(/[^A-Za-z0-9]+/g, '');
    setTagName(value);
    setIsValid(validateInput(value));
  };
  const changeColor = (newColor) => {
    setCurrentColor(newColor);
  };
  const handleKeyDown = (e) => {
    if (e.key === ' ' || /[^\w\s]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    createTag();
  };

  const createTagToast = () => {
    toast('Tag added', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  return (
    <div className="frazer-container">
      <ToastContainer
        className="toast"
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="frazer-box">
        <div className="frazer-upload-header">
          <h2>
            {t('create')}
            {' '}
            tag
          </h2>
          <img onClick={() => closeFun(false)} src={close} alt="" />
        </div>
        <form className="minimum-height">
          <div className="connectInputContainer">
            <div className="frazer-input">
              <label htmlFor="siteName">
                {t('create')}
                {' '}
                {t('custom')}
                {' '}
                tag
              </label>
              <input
                type="text"
                id="siteName"
                value={tagName}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                required
                className="createTagInput"
              />
            </div>
            <div className="frazer-input">
              <label htmlFor="siteName">
                {t('choose')}
                {' '}
                {t('a')}
                {' '}
                {t('tag')}
              </label>
              <select
                id="colorDropdown"
                className="colorDropdown createTagSelect"
                placeholder=""
                onChange={(event) => changeColor(event.target.value)}
                value={currentColor}
                style={{ color: currentColor }}
                defaultValue="lightskyblue"
              >
                <option
                  style={{ color: "lightblue" }}
                  defaultValue="lightskyblue"
                  defaultChecked
                  value="lightskyblue"
                >
                  {t('lightBlue')}
                </option>
                <option value="orange">{t('orange')}</option>
                <option value="green">{t('green')}</option>
                <option value="yellow">{t('yellow')}</option>
                <option value="blue">{t('blue')}</option>
                <option value="violet">{t('violet')}</option>
                <option value="fuchsia">Fuchsia</option>
                <option value="purple">{t('purple')}</option>
                <option value="red">Rose</option>
              </select>
            </div>
          </div>

          <div className="fazer-btn">
            <p onClick={() => closeFun(false)}>{t('cancel')}</p>
            <button type="submit" onClick={handleSubmit}>
              <span className="button-text">
                {isLoading ? t('loading') : `${t('create')} tag`}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
