import React, { useRef } from 'react';

import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsSliders } from 'react-icons/bs';
import {
  dividerElementOptions,
  tagsOptions, fontSizeOptions, targetOptions, alignOptions, nonDigitStyles,
} from '../../../../helpers/Builder/helperArrays';

import { CustomButton } from '../../../customButton';

const setAlignment = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  justify: 'space-between',
};

const DividerRender = ({ comp, responsiveMode }) => (
  <div
    style={{
      ...comp.style, width: '100%', borderRadius: `${comp.style.borderTopLeftRadius[responsiveMode.mode]}px ${comp.style.borderTopRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomRightRadius[responsiveMode.mode]}px ${comp.style.borderBottomLeftRadius[responsiveMode.mode]}px`, borderWidth: `${comp.style.border.width}px`, borderColor: `${comp.style.border.color}`, borderStyle: `${comp.style.border.style}`, padding: `${comp.style.paddingTop[responsiveMode.mode]}px ${comp.style.paddingRight[responsiveMode.mode]}px ${comp.style.paddingBottom[responsiveMode.mode]}px ${comp.style.paddingLeft[responsiveMode.mode]}px`, margin: `${comp.style.marginTop[responsiveMode.mode]}px ${comp.style.marginRight[responsiveMode.mode]}px ${comp.style.marginBottom[responsiveMode.mode]}px ${comp.style.marginLeft[responsiveMode.mode]}px`,
    }}
  >
    {
            comp.misc.withElement === 'none' ? (
              <div style={{
                display: 'flex',
                justifyContent: setAlignment[comp.style.textAlign[responsiveMode.mode]],
              }}
              >
                <div
                  className="divider-line"
                  style={{
                    borderBottomWidth: `${comp.style.lineStyles.border.bottomWidth}px`, borderBottomColor: `${comp.style.lineStyles.border.bottomColor}`, borderBottomStyle: `${comp.style.lineStyles.border.bottomStyle}`, display: 'flex', justifyContent: 'center', width: `${comp.style.lineWidth[responsiveMode.mode]}%`,
                  }}
                />
              </div>
            ) : (
              <div
                className="divider"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: setAlignment[comp.style.textAlign[responsiveMode.mode]],
                }}
              >
                <div
                  className="divider-line"
                  style={{
                    borderBottomWidth: `${comp.style.lineStyles.border.bottomWidth}px`, borderBottomColor: `${comp.style.lineStyles.border.bottomColor}`, borderBottomStyle: `${comp.style.lineStyles.border.bottomStyle}`, width: `${comp.style.lineWidth[responsiveMode.mode] / 2}%`,
                  }}
                />
                <span className="divider-text">
                  {
                    comp.misc.withElement === 'icon' ? (
                      <i
                        className={comp.misc.icon}
                        style={{
                          ...comp.style.elementStyles, fontWeight: null,
                        }}
                      />
                    ) : (
                      <span style={{
                        ...comp.style.elementStyles,
                      }}
                      >
                        {comp.misc.text}
                      </span>

                    )
                  }
                </span>
                <div
                  className="divider-line"
                  style={{
                    borderBottomWidth: `${comp.style.lineStyles.border.bottomWidth}px`, borderBottomColor: `${comp.style.lineStyles.border.bottomColor}`, borderBottomStyle: `${comp.style.lineStyles.border.bottomStyle}`, width: `${comp.style.lineWidth[responsiveMode.mode] / 2}%`,
                  }}
                />
              </div>

            )
        }
  </div>
);

export default DividerRender;

{ /* <div
style={{
    width: '100%',
    height: '1px',
    backgroundColor: 'black',
    margin: '1rem 0',

}}
>

</div>
<span
style={{
    backgroundColor: 'white',
    fontSize: '2rem',
    fontWeight: 'bold',
    position: 'absolute',
    left: '35%',
    bottom: '-1.7rem'
}}
>
    Divider Text
</span>

         <span
    style={{
      position: 'absolute',
      bottom: '50%',
      left: '0',
      content: "''",
      width: '100%',
      height: '1px',
      backgroundColor: 'black',
    }}
  ></span>
  <span
      style={{
        position: 'absolute',
        right: '50%',
        top: '0%',
        bottom: '10%',
        content: "''",
        backgroundColor: 'black',
        height: 'fit-content',
        width: 'fit-content',
        fontSize: '1rem',
      }}
  >
    ll
  </span>

    <span
    style={{
      position: 'absolute',
      right: '0',
      bottom: '50%',
      content: "''",
      width: '100%',
      height: '1px',
      backgroundColor: 'black',
    }}
  ></span>

*/ }
