import React from 'react';
import { CircularProgress } from '../cirularProgress/CircularProgress';

export const CustomButton = ({
  onClick, variant, buttontext, textColor, Icon, loading, disabled, loaderColor, loaderWidth, loaderSize, backgroundColor,
}) => (
  <div onClick={onClick}>
    <span
      className="hover:opacity-80"
      style={{
        backgroundColor: disabled ? '#ddd' : variant == 'contained' ? backgroundColor || 'white' : 'transparent', cursor: 'pointer', fontSize: '13px', display: 'flex', alignItems: 'center', color: disabled ? '#999' : textColor, padding: '0.6rem 1rem', border: variant == 'outlined' ? `1px solid ${textColor}` : null, borderRadius: '4px', justifyContent: 'center',
      }}
    >
      {loading
        ? <CircularProgress color={loaderColor} size={loaderSize} width={loaderWidth} />
        : Icon ? <Icon className="mr-2" size={16} /> : null}
      {buttontext}
    </span>
  </div>
);
