import React, { useEffect } from 'react';
import '../../styles/email/ConfirmEmail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../../styles/global.css';

const ConfirmEmail = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('custom-background');
    return () => {
      document.body.classList.remove('custom-background');
    };
  }, []);
  return (
    <div className="body-confirm-email">
      <span className="formCircle">
        <span className="InnerformCircle">
          <FontAwesomeIcon icon={faEnvelope} className="email-confirm-icon" />
        </span>
      </span>
      <form className="confirm-email-form">
        <div className="confirm-email-container">
          <h2 className="heading">Confirme seu e-mail</h2>
          <p className="heading-description">
            Foi enviado um link de verificação para a caixa de entrada do e-mail
            exemplo@gmail.com
          </p>
        </div>
        <div
          className="list-container"
        >
          <div className="list">
            <div>
              <p className="listNumbers">01.</p>
            </div>
            <div>
              <p className="listDescription">
                Abra a sua caixa de entrada e em seguida clique no link da
                mensagem que enviamos.
              </p>
            </div>
          </div>
          <div className="list">
            <div>
              <p className="listNumbers">02.</p>
            </div>
            <div>
              <p className="listDescription">
                Depois, clique no botão abaixo para concluir seu cadastro.
              </p>
            </div>
          </div>
        </div>
        <div
          className="confirm-email"
        >
          <button
            type="submit"
            className="EnterButtonConfirmEmail"
            onClick={() => {
              navigate('/login');
            }}
          >
            Já verifiquei meu e-mail
          </button>
          <p className="descriptionButton">
            Não recebeu o link? Tente checar a lixeira ou o spam, caso não tenha
            recebido,
            <strong>clique aqui!</strong>
          </p>
        </div>
      </form>
    </div>
  );
};

export default ConfirmEmail;
