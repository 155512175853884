import { useState } from 'react';
import desktop from '../../../../assets/icons/builder/desktop.svg';
import mobile from '../../../../assets/icons/builder/cellular.svg';
import tablet from '../../../../assets/icons/builder/tablet.svg';

const DropDown = ({
  responsiveData, responsiveMode, setDevice, setResponsiveMode, position,
}) => {
  const [selectedOption, setSelectedOption] = useState(desktop);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mouseEnter, setMouseEnter] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="dropdown absolute" style={{ left: position }}>
      <button className="dropdown-toggle h-7 w-7 cursor-pointer bg-gray-200" onClick={toggleDropdown} style={{ display: dropdownOpen ? 'none' : 'block' }}>
        <img className="w-6 h-6" src={responsiveMode.icon} alt="Selected Option" />
      </button>
      {dropdownOpen && (
        <ul className="dropdown-menu">
          {responsiveData.map((item, index) => (
            <li
              className={`dropdown-menu h-7 w-7 cursor-pointer rounded ${responsiveMode.mode === item.mode & !mouseEnter ? 'bg-gray-200' : 'bg-white'} hover:bg-gray-200`}
              key={index}
              onClick={() => {
                handleOptionClick(item.icon);
                setDevice(item.mode);
                setResponsiveMode(item);
              }}
              onMouseEnter={() => setMouseEnter(true)}
              onMouseLeave={() => setMouseEnter(false)}
            >
              <img src={item.icon} className="w-6 h-6" alt={item.mode} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropDown;
