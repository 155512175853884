import React, { useState, useEffect } from "react";
import "../styles/novaPagina/NovaPagina.css";
import "../styles/global.css";
import { useTranslation } from "react-i18next";
import A from "../assets/icons/former/A.svg";
import page2 from "../assets/images/novaPagina/page2.png";
import page3 from "../assets/images/novaPagina/page3.png";
import page4 from "../assets/images/novaPagina/page4.png";
import page5 from "../assets/images/novaPagina/page5.png";
import PageVisualPopup from "../components/popup/PageVisualPopup";
import { SideBarHome } from "../components";

const NovaPagina = () => {
  const [selected, setSelected] = useState("");
  const [pageViewPopup, setPageViewPopup] = useState(false)
  const [templatePreview, setTemplatePreview] = useState({
    show: false,
    templateObject: null
  });
  const { t } = useTranslation();

  const images = [
    {
      src: page2,
      key: 'paginadevendas',
    },
    {
      src: page3,
      key: 'presell',
    },
    {
      src: page4,
      key: 'presell',
    },
    {
      src: page5,
      key: 'paginadevendas',
    },
    {
      src: page3,
      key: 'paginadevendas',
    },
    {
      src: page5,
      key: 'upsell',
    },
    {
      src: page2,
      key: 'paginadevendas',
    },
    {
      src: page2,
      key: 'upsell',
    },
    {
      src: page3,
      key: 'paginadevendas',
    },
    {
      src: page2,
      key: 'paginadevendas',
    },
    {
      src: page4,
      key: 'presell',
    },
    {
      src: page5,
      key: 'paginadevendas',
    },
    {
      src: page2,
      key: 'presell',
    },
    {
      src: page3,
      key: 'paginadevendas',
    },
    {
      src: page4,
      key: 'paginadevendas',
    },
    {
      src: page4,
      key: 'presell',
    },
    {
      src: page2,
      key: 'presell',
    },
  ];
  const filteredImages = images.filter((item) => item.key.includes(selected));

  useEffect(() => {
    document.body.classList.add('custom-background');
    return () => {
      document.body.classList.remove('custom-background');
    };
  }, []);

  return (
    <div style={{ display: "flex" }}>
      {templatePreview.show && (
        <PageVisualPopup
        setTemplatePreview={setTemplatePreview}
          title={'Visualizar'}
          templatePreview={templatePreview}

        />
      )}
      <SideBarHome />

      <div className="RightComponentLanding">
        <div className="HeaderNovaPagina">
          <p>{t('newPage')}</p>
          <div className="frameHeader">
            <div
              className={
                selected == '' ? 'selectedComponent' : 'unselectedComponent'
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected('');
                }}
              >
                {t('all')}
              </span>
            </div>
            <div
              className={
                selected == 'paginadevendas'
                  ? 'selectedComponent'
                  : 'unselectedComponent'
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected('paginadevendas');
                }}
              >
                {t('salesPage')}
              </span>
            </div>
            <div
              className={
                selected == 'presell'
                  ? 'selectedComponent'
                  : 'unselectedComponent'
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected('presell');
                }}
              >
                PRESELL
              </span>
            </div>
            <div
              className={
                selected == 'upsell'
                  ? 'selectedComponent'
                  : 'unselectedComponent'
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected('upsell');
                }}
              >
                UPSELL
              </span>
            </div>
          </div>
        </div>
        <div className="frameBody">
          <div className="componentsPagina">
            <div className="page1">
              <div className="page1div">
                <div>
                  <img src={A} alt="A.svg" />
                </div>
                <p>Atomicat</p>
              </div>
              <div className="page1text">
                <p>Página em branco</p>
                <span>Construa uma página personalizada do zero!</span>
              </div>
              <div className="page1New">
                <p>+</p>
                <div className="page1page" />
                <div className="page1pageOut" />
              </div>
              <div className="ellipse" />
            </div>
            {filteredImages.map((obj) => (
              <div className="pages">
                <img src={obj.src} alt="template.png" />
                <div className="pageButton">
                  <button className="visualizeButton" 
          onClick={() => setTemplatePreview({show: true, templateObject: obj})}
          >
                    Visualizar
                  </button>
                  <button className="selectButton">
                    Selecionar
                  </button>
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NovaPagina;
