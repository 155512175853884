import React from 'react';
import '../../../styles/builder/OpenCloseArrow.css';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const OpenCloseArrow = ({ setshowDrawer, showDrawer }) => (
  <div>
    {showDrawer ? (
      <div
        onClick={() => {
          setshowDrawer(false);
        }}
        className="arrow1"
      >
        <MdChevronLeft className="icon" color="#fff" size={20} />
      </div>
    ) : (
      <div
        onClick={() => {
          setshowDrawer(true);
        }}
        style={{
          zIndex: '100',
        }}
        className="arrow2"
      >
        <MdChevronRight className="icon" color="#72728F" size={20} />
      </div>
    )}
  </div>
);

export default OpenCloseArrow;
