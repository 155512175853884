import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  checkActionCode, applyActionCode, verifyPasswordResetCode, confirmPasswordReset,
} from 'firebase/auth';
import { auth } from '../../config/firebase';
import '../../styles/global.css';

const Confirmed = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const actionCode = searchParams.get('oobCode');
    const action = searchParams.get('mode');
    if (action == 'verifyEmail') {
      CheckAuthentication(actionCode);
    } else if (action == 'resetPassword') {
      CheckPasswordAuthentication(actionCode);
    }
  }, []);

  useEffect(() => {
    document.body.classList.add('custom-background');
    return () => {
      document.body.classList.remove('custom-background');
    };
  }, []);

  const CheckAuthentication = async (actionCode) => {
    const info = await checkActionCode(auth, actionCode);
    await applyActionCode(auth, actionCode);
    navigate('/login');
  };
  const CheckPasswordAuthentication = async (actionCode) => {
    const info = await verifyPasswordResetCode(auth, actionCode);
    navigate(`/redefinesenha/${actionCode}`);
  };
  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh',
    }}
    >
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#60F0D3',
      }}
      >
        <p style={{ color: '#101014' }}>Verification Done Successfully!</p>
      </div>
    </div>
  );
};

export default Confirmed;
