import React, { useState } from "react";
// import "../../styles/sites/ModalSite.css";
import "../../styles/sites/ModalSite.scss";
import { Buffer } from "buffer";
import { doc, updateDoc } from "firebase/firestore";
import close from "../../assets/icons/former/close-icon.svg";
import { auth, db } from "../../config/firebase";
import { apiCall } from "../../service/commonService";
import edit from "../../assets/icons/former/edit.png";
import { useTranslation } from "react-i18next";

const ModalSite = ({
  title,
  btn,
  label,
  placeholder,
  note,
  fileupload,
  func,
  siteData,
  closeFun,
}) => {
  const user = auth.currentUser;

  const [input, setInput] = useState(siteData?.provDomain);
  const [link, setLink] = useState(siteData?.ssl);
  const [noteVal, setNoteVal] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userToken;
    try {
      userToken = await user.getIdToken(true);
    } catch (err) {
      setError("Erro HADK8. Informe ao suporte.");
    }
    console.log(siteData);
    try {
      setIsLoading(true);
      const userData = {
        domain: siteData?.provDomain,
        siteId: Buffer.from(siteData?.id).toString("base64"),
        userToken,
      };

      console.log(userData);
      const response = await apiCall(
        "post",
        "cloudpanel/api/verify/addcusthost",
        userData
      );
      const succeeded = response.data;
      console.log(response);
      if (succeeded.success) {
        setIsLoading(false);
        setSuccess(succeeded.message);
        closeFun(false);
      } else if (
        succeeded.error &&
        succeeded.error.includes("Duplicate custom hostname found")
      ) {
        setError(
          `The domain ${siteData?.provDomain} is already in use please use another domain`
        );
      } else if (succeeded.error) {
        setError(succeeded.error);
      } else {
        setError(
          "Ocorreu um erro. Por favor, informe o código UUUYT ao suporte."
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleEditDomain = async () => {
    try {
      const docref = doc(db, "sites", siteData?.id);
      console.log(docref);
      const addedDocument = await updateDoc(docref, { provDomain: null });
      console.log(addedDocument);
      closeFun(false);
    } catch (error) {
      console.error("failed", error);
    }
  };
  return (
    <div className="frazer-container">
      <div className="frazer-box">
        <div className="frazer-upload-header">
          <h2>{title}</h2>
          <img onClick={() => closeFun(false)} src={close} alt="" />
        </div>
        <form className="minimum-height">
          <div className="connectModalSiteContainer">
            <div className="frazer-input">
              <label htmlFor="fileupload">{label}</label>
              <input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                type="text"
                placeholder={placeholder}
              />
            </div>
            <div className="ml-6 mb-5">
              <input
                className="mr-5"
                type="checkbox"
                id="useCloaker"
                // checked={useCloaker}
                // onChange={handleUseCloakerChange}
              />
              <label htmlFor="useCloaker">Usar o AtomicFilter neste site</label>
            </div>

            {/* note Section */}
            <div className="notes-box">
              <div>
                <p className="popup-note">
                  {t("note")}:{note}
                </p>
              </div>
            </div>
          </div>
          <div className="fazer-btn">
            <p onClick={() => closeFun(false)}>{t("cancel")}</p>
            <button onClick={handleSubmit}>
              {" "}
              {isLoading ? "Carregando..." : title}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalSite;
