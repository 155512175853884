import '../../styles/skelton/sitePageskelton.css';
import '../../styles/skelton/skelton.css';

const SitePageSkelton = () => (

  <div className="pagecontainer">

    <div className="sitebox">
      <div className=" flex flex-row gap-5">
        <div className="switchPage skelton-animation" />
        <div className="urlName skelton-animation" />

      </div>

      <div className="flex flex-row gap-5">
        <div className="tags skelton-animation" />
        <div className="tags skelton-animation" />

      </div>
      <div className="flex flex-row gap-5">
        <div className="tags skelton-animation" />
        <div className="tags skelton-animation" />

      </div>
      <div className="flex flex-row gap-5">
        <div className="skeltonCircle skelton-animation" />

      </div>
      <div className="flex flex-row gap-5">
        <div className="tags skelton-animation" />
      </div>

      <div className="flex flex-row gap-5">
        <div className="skeltonCircle skelton-animation" />
        <div className="skeltonCircle skelton-animation" />

      </div>
    </div>
  </div>
);

export default SitePageSkelton;
