import '../../styles/skelton/skelton.css';
import '../../styles/videos/meusVideos.css';

const VideoSkelton = () => (
  <div className="container">
    <div className="videoBox">
      <div className=" flex flex-row gap-5 justify-center items-center">
        <div className="box  skelton-animation" />
        <div className="flex flex-column gap-2">

          <div className="line skelton-animation" />
          <div className="timeLine skelton-animation" />
        </div>

      </div>
      <div className="flex flex-row gap-5">
        <div className="skeltonCircle skelton-animation" />
        <div className="skeltonCircle skelton-animation" />
        <div className="skeltonCircle skelton-animation" />
      </div>
    </div>
    {/* <Skeleton type="thumbnail" /> */}
    {/* <Skeleton type="text-lg" /> */}
    {/* <Skeleton type="text-md" />
             <Skeleton type="text-sm" /> */}
    <div className="shimmer-wrapper">
      <div className="shimmer" />
    </div>
  </div>
);
export default VideoSkelton;
