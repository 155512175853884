import imageIcon from "../../assets/icons/builder/helpers/ImageIcon.svg";
import videoIcon from "../../assets/icons/builder/helpers/videoIcon.svg";
import smileyIcon from "../../assets/icons/builder/helpers/smileyIcon.svg";
import carouselIcon from "../../assets/icons/builder/helpers/carouselIcon.svg";
import progressbarIcon from "../../assets/icons/builder/helpers/progressbarIcon.svg";
import buttonIcon from "../../assets/icons/builder/helpers/ButtonIcon.svg";
import texttoIcon from "../../assets/icons/builder/helpers/texttoIcon.svg";
import listIconsIcon from "../../assets/icons/builder/helpers/listIconsIcons.svg";
import suspensaIcon from "../../assets/icons/builder/helpers/suspensaIcons.svg";
import formularioIcon from "../../assets/icons/builder/helpers/formularioIcon.svg";
import rodapeIcon from "../../assets/icons/builder/helpers/rodapeIcon.svg";
import espacamentoIcon from "../../assets/icons/builder/helpers/espacamentoIcon.svg";
import messageIcon from "../../assets/icons/builder/helpers/messageIcon.svg";
import alarmIcon from "../../assets/icons/builder/helpers/alarmIcon.svg";
import tabelaIcon from "../../assets/icons/builder/helpers/tabelaIcon.svg";
import todoIcon from "../../assets/icons/builder/helpers/TodoIcon.svg";
import tIcon from "../../assets/icons/builder/helpers/TIcon.svg";
import catIcon from "../../assets/icons/builder/helpers/CatIcon.svg";
import estiloIcon from "../../assets/icons/builder/helpers/IstiloIcon.svg";
import gap from "../../assets/icons/builder/helpers/gap.png";
import star3 from "../../assets/icons/builder/helpers/star3.png";

export const TodosComponents = [
  {
    name: "Texto",
    icon: texttoIcon,
    type: "text",
  },
  {
    name: "Image",
    icon: imageIcon,
    type: "image",
  },
  {
    name: "Video",
    icon: videoIcon,
    type: "video",
  },
  {
    name: "Icon",
    icon: smileyIcon,
    type: "icon",
  },
  {
    name: "Accordion",
    icon: carouselIcon,
    type: "accordion",
  },
  {
    name: "Divisor",
    icon: gap,
    type: "divider",
  },
  {
    name: "Facebook",
    icon: carouselIcon,
    type: "facebook",
  },
  {
    name: "Progress Bar",
    icon: progressbarIcon,
    type: "progressBar",
  },
  {
    name: "Star Rating",
    icon: star3,
    type: "starRating",
  },
  {
    name: "Counter",
    icon: star3,
    type: "counter",
  },
  {
    name: "Social Icons",
    icon: star3,
    type: "socialIcons",
  },
  {
    name: "Button",
    icon: buttonIcon,
    type: "button",
  },
  {
    name: "Divisor",
    icon: gap,
    type: "divider",
  },
];

export const TextoComponents = [
  {
    name: "Texto",
    icon: texttoIcon,
    type: "text",
  },
];

export const MidiaComponents = [
  {
    name: "Image",
    icon: imageIcon,
    type: "image",
  },
  {
    name: "Video",
    icon: videoIcon,
    type: "video",
  },
  {
    name: "Icon",
    icon: smileyIcon,
    type: "icon",
  },
  {
    name: "Accordion",
    icon: carouselIcon,
    type: "accordion",
  },
  {
    name: "Divisor",
    icon: gap,
    type: "divider",
  },
  {
    name: "Facebook",
    icon: carouselIcon,
    type: "facebook",
  },
  {
    name: "Progress Bar",
    icon: progressbarIcon,
    type: "progressBar",
  },
  {
    name: "Star Rating",
    icon: star3,
    type: "starRating",
  },
  {
    name: "Counter",
    icon: star3,
    type: "counter",
  },
  {
    name: "Social Icons",
    icon: star3,
    type: "socialIcons",
  },
];

export const BotaoComponents = [
  {
    name: "Button",
    icon: buttonIcon,
    type: "button",
  },
];

export const CatComponents = [
  {
    name: "Divisor",
    icon: gap,
    type: "divider",
  },
];

export const sidebar_components = [
  {
    icon: todoIcon,
    name: "Todos",
    value: 1,
    components: TodosComponents,
  },
  {
    icon: tIcon,
    name: "Texto",
    value: 2,
    components: TextoComponents,
  },
  {
    icon: imageIcon,
    name: "Media",
    value: 3,
    components: MidiaComponents,
  },
  {
    icon: buttonIcon,
    name: "Button",
    value: 4,
    components: BotaoComponents,
  },
  {
    icon: catIcon,
    name: "Catblocs",
    value: 5,
    components: CatComponents,
  },
];
