import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { SideBarHome } from '../components';
import '../styles/videos/meusVideos.css';
import Banner from '../assets/images/banner/LrxhiA1631246 1.svg';
import fileManagement from '../assets/icons/meusVideos/file-management.png';
import UploadFile from '../assets/icons/meusVideos/UploadFile.svg';
import growth from '../assets/icons/meusVideos/map.png';
import deleteicon from '../assets/icons/sites/Delete.png';
import settingsicon from '../assets/icons/meusVideos/settingsIcon.svg';
import { apiCall } from '../service/commonService';
import { auth, db } from '../config/firebase';
import UploadVidios from '../components/video/UploadVideos';
import { LoginContext } from '../contexts/ContextProvider.js';
import VideoSkelton from '../components/skelton/VideoSkelton';

// import { useTus } from 'use-tus';

export default function MeusVideos() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const howManyVideos = localStorage.getItem('videos');
  const Skeletons = [];
  for (let i = 0; i < howManyVideos; i++) {
    Skeletons.push(<VideoSkelton key={i} />);
  }
  const [videoListData, setVideoListData] = useState([]);
  const [uploadPopup, setUploadPopup] = useState(false);
  const [deleteVideo, setDeleteVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userid = useContext(LoginContext);

  const fetchData = async () => {
    setIsLoading(true);
    const user = auth.currentUser;

    let userToken;
    let creator;
    try {
      userToken = await user.getIdToken();
      creator = await userid?.user?.uid;
    } catch (error) {
      console.log('Authentication error:', error);
      return;
    }

    const userData = { userToken, creator };
    const response = await apiCall(
      'post',
      'stream/list',
      userData,
      'https://apidopro.atomicat-api.com/',
    );
    if (response?.data?.success) {
      setVideoListData(response.data.result);
      console.log('videos', videoListData);
      localStorage.setItem('videos', response.data.result.length);
    }
    setIsLoading(false);
  };

  const handleUploadVidiosPopup = () => {
    setUploadPopup(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteVideos = async (item) => {
    setDeleteVideo(true);
    const user = auth.currentUser;

    let userToken;
    let creator;
    try {
      userToken = await user.getIdToken();
      creator = await userid?.user?.uid;
    } catch (error) {
      console.log('Authentication error:', error);
      return;
    }
    const userData = { uid: item.uid, userId: creator, userToken };
    const response = await apiCall(
      'post',
      'stream/deleteVideo',
      userData,
      'https://apidopro.atomicat-api.com/',
    );
    if (response.data.acknowledged) {
      fetchData();
      setDeleteVideo(false);
    }
  };
  const onClickMetrics = (item) => {
    console.log(item);
    navigate(`/video/?id=${item.uid}&tab=metrics`);
  };
  const handleVideoSetting = (item) => {
    navigate(`/video/?id=${item.uid}&tab=settings`);
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {uploadPopup && (
        <UploadVidios
          fetchData={fetchData}
          btn="Confirmar"
          closeFun={setUploadPopup}
        />
      )}
      <SideBarHome />
      <div className="meusVideos-container">
        <div className="meusVideos-header bg-success">
          <div>
            {' '}
            <h3 className="videoHeading">
              {t('my')}
              {' '}
              videos
            </h3>
          </div>
          <div style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn btn-primary gerenciarMeus-btn mr-5"
            >
              <img className="mr-2" src={fileManagement} />
              {' '}
              {t('manageMyVideos')}
            </button>
            <button
              type="button"
              style={{
                color: '#000000',
                backgroundColor: '#ffffff',
                height: '42px',
                width: '141px',
              }}
              className="btn btn-primary gerenciarMeus-btn"
              onClick={handleUploadVidiosPopup}
            >
              +
              {' '}
              {t('new')}
              {' '}
              Video
            </button>
          </div>
        </div>

        <div className="meusVideos-middle">
          <form>
            <input
              className="meusVideos-input-box"
              type="text"
              name="name"
              placeholder={t('toLookFor')}
            />
          </form>

          <div className="videoList">
            {!isLoading ? (
              <>
                {videoListData?.length > 0
                  ? videoListData?.map((item, index) => (
                    <div className="main-video-list-div" key={index}>
                      <div className="sub1-video-list-div">
                        <div
                          className="listImg"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleVideoSetting(item)}
                        >
                          <img
                            src={item.thumbnail}
                            style={{
                              borderRadius: '8px',
                              height: '99.5px',
                              width: '180px',
                            }}
                          />
                        </div>
                        <div className="listHeader">
                          <h1 className="list-title">
                            {' '}
                            {item.meta.name}
                          </h1>
                          <h1

                            className="listtime"
                          >
                            {' '}
                            {new Date(item.duration * 1000)
                              .toISOString()
                              .substr(11, 8)}
                            {' '}
                          </h1>
                        </div>
                      </div>
                      <div className="sub2-video-list-div">
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => onClickMetrics(item)}
                        >
                          <img className="actionimg" src={growth} />
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => handleVideoSetting(item)}>
                          <img className="actionimg" src={settingsicon} />
                        </div>
                        <div style={{ cursor: 'pointer' }}>
                          <button disabled={deleteVideo}>
                            <img
                              style={{
                                cursor: deleteVideo
                                  ? 'not-allowed'
                                  : 'pointer',
                              }}
                              className="actionimg"
                              onClick={() => handleDeleteVideos(item)}
                              src={deleteicon}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                  : ''}
              </>
            ) : (
              Skeletons
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
