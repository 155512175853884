import React, { useState, useEffect, useRef } from 'react';
import {
	MdClose,
	MdArrowUpward,
	MdContentCopy,
	MdArrowDownward,
	MdDeleteOutline,
} from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { RiLayoutMasonryFill } from 'react-icons/ri';
import { v4 as uuidv4 } from 'uuid';
import { FiSave, FiPlus } from 'react-icons/fi';
import { IoSettingsOutline } from 'react-icons/io5';
import { t, use } from 'i18next';
import CustomButton from '../../customButton/CustomButton';

import blocks from '../../../helpers/Builder/content_copy';

// import { blocks } from '../../../helpers/Builder/content_copy';
import {
	EditTypeArray,
	inlineTags,
} from '../../../helpers/Builder/helperArrays';
import {
	getComponentByType,
	giveContent,
	giveItem,
} from '../../../helpers/Builder/helperFxns';
import RenderComponent from './render_components/RenderComponent';

const DropContainer = ({
	showDrawer,
	sectionArray,
	layoutEle,
	setLayoutEle,
	layoutViewEle,
	setLayoutViewEle,
	setsectionArray,
	setShowBlueBorder,
	ShowBlueBorder,
	setDragOver,
	setLayoutCreator,
	setselectedComponent,
	setsectionDrop,
	setSelectedSectionIndex,
	setSelectedColumnIndex,
	setComponentArray,
	setStiloShow,
	setSelectedContent,
	setSelectedStyle,
	setColDrop,
	ComponentArray,
	DragOver,
	LayoutCreator,
	LayoutView,
	selectedComponent,
	setshowDrawer,
	setLayoutView,
	setElementType,
	setVideoController,
	setIconPopup,
	scale,
	responsiveMode,
	setImagePopup,
	setImageDestination,
	width,
	setWidth,
	height,
	setHeight,
	setResponsiveMode,
	responsiveData,
	sectionDrop,
	draggedKey,
	setDraggedKey,
	formerIndex,
	setFormerIndex,
	currentDrag,
	setcurrentDrag,
	firstIndex,
	setFirstIndex,
	mvBtm,
	setMvBtm,
	mvUp,
	setMvUp,
}) => {
	const generateSection = (num) => {
		const sec_key = uuidv4();
		const rowsArray = Array(num).fill(0);
		const secObj = { sec_key: `${num}/${sec_key}`, columns: [] };

		for (let i = 0; i < num; i++) {
			secObj.columns.push({ row_key: `${i}/${sec_key}`, elements: [] });
		}

		return secObj;
	};

	const [resizableWidth, setResizableWidth] = useState(null);
	// Function to handle click event for section creation
	const handleSectionClick = (num) => {
		const secObj = generateSection(num);
		setsectionArray([...sectionArray, secObj]);
		setLayoutView(false);
		setLayoutCreator(true);
	};

	const resizableDivRef = useRef(null);
	const refTop = useRef(null);
	const refRight = useRef(null);
	// const refBottom = useRef(null);
	const refLeft = useRef(null);

	const handleDropBetweenColumns = (e) => {
		e.preventDefault();
		const ele = e.dataTransfer.getData('element');
		const dropSection = sectionArray.filter(
			(s) => s.sec_key == sectionDrop.sec_key
		);
		const comp_key = uuidv4();
		const comp_obj = {
			comp_key,
			tag: blocks[ele].tag,
			...(giveContent(blocks[ele].misc.type) && {
				content: blocks[ele].content,
			}),
			misc: blocks[ele].misc,
			style: blocks[ele].style,
		};
		dropSection[0].columns[colDrop].elements = [
			...dropSection[0].columns[colDrop].elements,
			comp_obj,
		];
		setselectedComponent(comp_obj.comp_key);
		const temparr = sectionArray;
		setsectionArray(temparr);
		setsectionDrop(dropSection[0]);
		setSelectedStyle(comp_obj.style);
		setDragOver(null);
		setSelectedContent(comp_obj.content);
		setElementType(comp_obj.misc.type);
		setSelectedSectionIndex(section_index);
		setSelectedColumnIndex(row_index);
		setComponentArray([comp_obj, ...ComponentArray]);
		setStiloShow(true);
		setshowDrawer(true);
	};

	useEffect(() => {
		const resizableElement = resizableDivRef.current;
		const styles = window.getComputedStyle(resizableElement);
		let width = parseInt(styles.width, 10);
		let height = parseInt(styles.height, 10);

		let xCord = 0;
		let yCord = 0;

		// resizableElement.style.top = `${yCord}px`;
		// resizableElement.style.top = '150px';
		// resizableElement.style.left = '150px';

		// TOP RESIZE LOGIC
		const onMouseMoveTopResize = (e) => {
			const dy = e.clientY - yCord;
			height -= dy;
			yCord = e.clientY;
			resizableElement.style.height = `${height}px`;
		};

		const onMouseUpTopResize = (e) => {
			document.removeEventListener('mousemove', onMouseMoveTopResize);
		};

		const onMouseDownTopResize = (e) => {
			yCord = e.clientY;
			const styles = window.getComputedStyle(resizableElement);
			resizableElement.style.bottom = styles.bottom;
			resizableElement.style.top = null;
			document.addEventListener('mousemove', onMouseMoveTopResize);
			document.addEventListener('mouseup', onMouseUpTopResize);
		};

		// RIGHT RESIZE LOGIC
		const onMouseMoveRightResize = (e) => {
			const dx = e.clientX - xCord;
			xCord = e.clientX;
			width += dx;
			// resizableElement.style.width = `${width}px`;
			setResponsiveMode({
				...responsiveMode,
				width,
			});
		};

		const onMouseUpRightResize = (e) => {
			document.removeEventListener('mousemove', onMouseMoveRightResize);
		};

		const onMouseDownRightResize = (e) => {
			xCord = e.clientX;
			resizableElement.style.left = styles.left;
			resizableElement.style.right = null;
			// const styles = window.getComputedStyle(resizableElement);
			document.addEventListener('mousemove', onMouseMoveRightResize);
			document.addEventListener('mouseup', onMouseUpRightResize);
		};

		// BOTTOM RESIZE LOGIC
		const onMouseMoveBottomResize = (e) => {
			const dy = e.clientY - yCord;
			height += dy;
			yCord = e.clientY;
			resizableElement.style.height = `${height}px`;
		};

		const onMouseUpBottomResize = (e) => {
			document.removeEventListener('mousemove', onMouseMoveBottomResize);
		};

		const onMouseDownBottomResize = (e) => {
			yCord = e.clientY;
			const styles = window.getComputedStyle(resizableElement);
			resizableElement.style.top = styles.top;
			resizableElement.style.bottom = null;
			document.addEventListener('mousemove', onMouseMoveBottomResize);
			document.addEventListener('mouseup', onMouseUpBottomResize);
		};

		// LEFT RESIZE LOGIC
		const onMouseMoveLeftResize = (e) => {
			const dx = e.clientX - xCord;
			xCord = e.clientX;
			width -= dx;
			// resizableElement.style.width = `${width}px`;
			setResponsiveMode({
				...responsiveMode,
				width,
			});
		};

		const onMouseUpLeftResize = (e) => {
			document.removeEventListener('mousemove', onMouseMoveLeftResize);
		};

		const onMouseDownLeftResize = (e) => {
			xCord = e.clientX;
			resizableElement.style.right = styles.right;
			resizableElement.style.left = null;
			document.addEventListener('mousemove', onMouseMoveLeftResize);
			document.addEventListener('mouseup', onMouseUpLeftResize);
		};

		const resizerTop = refTop.current;
		resizerTop.addEventListener('mousedown', onMouseDownTopResize);

		const resizerRight = refRight.current;
		resizerRight.addEventListener('mousedown', onMouseDownRightResize);

		// const resizerBottom = refBottom.current;
		// resizerBottom.addEventListener('mousedown', onMouseDownBottomResize);

		const resizerLeft = refLeft.current;
		resizerLeft.addEventListener('mousedown', onMouseDownLeftResize);

		return () => {
			resizerTop.removeEventListener('mousedown', onMouseDownTopResize);
			resizerRight.removeEventListener('mousedown', onMouseDownRightResize);
			// resizerBottom.removeEventListener('mousedown', onMouseDownBottomResize);
			resizerLeft.removeEventListener('mousedown', onMouseDownLeftResize);
		};
	}, []);

	return (
		<div
			style={{
				width: showDrawer
					? 'calc(100vw - 16.5rem - 80px)'
					: 'calc(100vw - 99px)',
				overflowX: scale > 1 ? 'scroll' : 'hidden',
				marginLeft: showDrawer ? 'calc(16.5rem + 80px)' : '99px',
				position: 'absolute',
				top: '4rem',
				bottom: '0',
				backgroundColor: '#333333',
				transition: 'all 0.2s ease-in-out',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div
				className='resizable-div'
				ref={resizableDivRef}
				style={{
					transform: `scale(${scale})`,
					transformOrigin: '0 0',
					height: '100%',
					position: 'relative',
					transition: 'scale 0.2s ease-in-out',
					backgroundColor: 'white',
					width: `${responsiveMode.width}px`,
					minWidth: '100px',
				}}
			>
				<div
					ref={refLeft}
					className={`resizer rl ${
						responsiveMode.mode !== 'desktop' && 'rlstyles'
					}`}
				>
					<div className='resizer-child' />
				</div>
				<div
					ref={refTop}
					className={`resizer rt ${
						responsiveMode.mode !== 'desktop' && 'rtstyles'
					}`}
				>
					<div className='resizer-child' />
				</div>
				<div
					ref={refRight}
					className={`resizer rr ${
						responsiveMode.mode !== 'desktop' && 'rrstyles'
					}`}
				>
					<div className='resizer-child' />
				</div>
				{/* <div ref={refBottom} className={`resizer rb ${responsiveMode.mode !== 'desktop' ? 'rbstyles' : ''}`}><div className='resizer-child'></div></div> */}
				{sectionArray?.length > 0
					? sectionArray.map((section, section_index) => (
							<div
								style={{
									position: 'relative',
									zIndex: '1000',
									margin: '0 1rem',
								}}
							>
								{layoutEle.some((item) =>
									item.hasOwnProperty(section_index)
								) && (
									<div
										className='flex flex-col items-center justify-center'
										style={{
											border: '2px dashed #E1E2EB',
											position: 'relative',
											height: '176px',
											margin: '30px',
											borderRadius: '8px',
											width: 'calc(100% - 60px)',
										}}
									>
										<div>
											<MdClose
												className='hover:opacity-80'
												onClick={() => {
													const newLayoutEle = layoutEle.filter(
														(ele) => !ele.hasOwnProperty(section_index)
													);
													setLayoutEle(newLayoutEle);
												}}
												color='black'
												size={25}
												style={{
													position: 'absolute',
													top: '1%',
													right: '5px',
													cursor: 'pointer',
												}}
											/>
										</div>
										<div
											style={{
												height: 'auto',
												width: '100%',
												position: 'relative',
											}}
											className='grid grid-cols-1 items-center gap-6'
										>
											<div
												style={{}}
												className='rounded flex justify-center items-center '
											>
												{
													// for each element

													layoutEle.some(
														(item) =>
															item.hasOwnProperty(section_index) &&
															item[section_index].layoutCreator
													) ? (
														<div
															className='flex items-center gap-2'
															style={{ position: 'relative' }}
														>
															<CustomButton
																onClick={() => {
																	if (!layoutViewEle.includes(section_index)) {
																		const newLayoutViewEle = [
																			...layoutViewEle,
																			section_index,
																		];
																		setLayoutViewEle(newLayoutViewEle);
																	}
																	const newLayoutEle = layoutEle.map((ele) => {
																		if (ele.hasOwnProperty(section_index)) {
																			return {
																				...ele,
																				[section_index]: {
																					...ele[section_index],
																					layoutCreator: false,
																					layoutView: true,
																				},
																			};
																		}
																		return ele;
																	});
																	setLayoutEle(newLayoutEle);
																}}
																loading={false}
																variant='outlined'
																disabled={false}
																Icon={BsPlus}
																buttontext={t('blankBlock')}
																textColor='#72728F'
															/>
															<CustomButton
																loading={false}
																backgroundColor='#101014'
																textColor='#fff'
																disabled={false}
																Icon={RiLayoutMasonryFill}
																buttontext={t('Templates')}
																variant='contained'
															/>
														</div>
													) : layoutEle.some(
															(item) =>
																item.hasOwnProperty(section_index) &&
																item[section_index].layoutView
													  ) ? (
														<div className='flex items-center'>
															<div
																className='bg-[#E1E2EB] hover:opacity-80 rounded-sm mx-3'
																onClick={() => {
																	const sec_key = uuidv4();
																	const section_obj = generateSection(1);
																	setsectionArray([
																		...sectionArray.slice(0, section_index),
																		section_obj,
																		...sectionArray.slice(section_index),
																	]);
																	const newLayoutEle = layoutEle.filter(
																		(ele) => !ele.hasOwnProperty(section_index)
																	);
																	setLayoutEle(newLayoutEle);
																}}
																style={{
																	cursor: 'pointer',
																	width: '9rem',
																	height: '5.1rem',
																}}
															/>
															<div
																onClick={() => {
																	const sec_key = uuidv4();
																	const section_obj = generateSection(2);
																	setsectionArray([
																		...sectionArray.slice(0, section_index),
																		section_obj,
																		...sectionArray.slice(section_index),
																	]);
																	const newLayoutEle = layoutEle.filter(
																		(ele) => !ele.hasOwnProperty(section_index)
																	);
																	setLayoutEle(newLayoutEle);
																}}
																className='flex items-center mx-3 hover:opacity-80'
																style={{
																	cursor: 'pointer',
																	width: '9rem',
																	height: '5.1rem',
																}}
															>
																<div
																	className='bg-[#E1E2EB] rounded-sm mx-1'
																	style={{ width: '4.3rem', height: '5.1rem' }}
																/>
																<div
																	className='bg-[#E1E2EB] rounded-sm mx-1'
																	style={{ width: '4.3rem', height: '5.1rem' }}
																/>
															</div>

															<div
																onClick={() => {
																	const sec_key = uuidv4();
																	const section_obj = generateSection(3);
																	setsectionArray([
																		...sectionArray.slice(0, section_index),
																		section_obj,
																		...sectionArray.slice(section_index),
																	]);
																	const newLayoutEle = layoutEle.filter(
																		(ele) => !ele.hasOwnProperty(section_index)
																	);
																	setLayoutEle(newLayoutEle);
																}}
																className='flex items-center mx-3 hover:opacity-80'
																style={{
																	cursor: 'pointer',
																	width: '9rem',
																	height: '5.1rem',
																}}
															>
																<div
																	className='bg-[#E1E2EB] rounded-sm mx-1'
																	style={{ width: '2.75rem', height: '5.1rem' }}
																/>
																<div
																	className='bg-[#E1E2EB] rounded-sm mx-1'
																	style={{ width: '2.75rem', height: '5.1rem' }}
																/>
																<div
																	className='bg-[#E1E2EB] rounded-sm mx-1'
																	style={{ width: '2.75rem', height: '5.1rem' }}
																/>
															</div>

															<div
																onClick={() => {
																	const sec_key = uuidv4();
																	const section_obj = generateSection(4);
																	setsectionArray([
																		...sectionArray.slice(0, section_index),
																		section_obj,
																		...sectionArray.slice(section_index),
																	]);
																	const newLayoutEle = layoutEle.filter(
																		(ele) => !ele.hasOwnProperty(section_index)
																	);
																	setLayoutEle(newLayoutEle);
																}}
																className='flex items-center mx-3 hover:opacity-80'
																style={{
																	cursor: 'pointer',
																	width: '9rem',
																	height: '5.1rem',
																}}
															>
																<div
																	className='bg-[#E1E2EB] rounded-sm mx-1'
																	style={{ width: '2.1rem', height: '5.1rem' }}
																/>
																<div
																	className='bg-[#E1E2EB] rounded-sm mx-1'
																	style={{ width: '2.1rem', height: '5.1rem' }}
																/>
																<div
																	className='bg-[#E1E2EB] rounded-sm mx-1'
																	style={{ width: '2.1rem', height: '5.1rem' }}
																/>
																<div
																	className='bg-[#E1E2EB] rounded-sm mx-1'
																	style={{ width: '2.1rem', height: '5.1rem' }}
																/>
															</div>
														</div>
													) : null
												}
											</div>
										</div>
									</div>
								)}
								<div
									onMouseOver={() => {
										setShowBlueBorder(section_index);
									}}
									onMouseLeave={() => {
										setShowBlueBorder(null);
									}}
									style={{
										position: 'relative',
										marginTop: '3px',
										marginRight: '17px',
									}}
								>
									{ShowBlueBorder == section_index && (
										<div
											className='flex items-center justify-center'
											style={{
												justifySelf: 'flex-start',
												alignSelf: 'center',
												backgroundColor: '#0066FF',
												height: '30px',
												borderBottomLeftRadius: '16px',
												borderBottomRightRadius: '16px',
												border: '2px solid #0066FF',
												position: 'absolute',
												left: '50%',
												top: '15px',
												transform: 'translate(-50%, -50%)',
												zIndex: '1',
												padding: '0 7px',
											}}
										>
											<div className='flex items-center mr-5 cursor-pointer'>
												<div
													className='p-1 buttonHover'
													style={{ borderBottomLeftRadius: '4.5px' }}
													onClick={() => {
														const array = sectionArray;
														if (section_index > 0) {
															[array[section_index], array[section_index - 1]] =
																[
																	array[section_index - 1],
																	array[section_index],
																];
															setShowBlueBorder(null);
														}
														setsectionArray(array);
													}}
												>
													<MdArrowUpward size={21} height='100%' color='#fff' />
												</div>
												<div
													className='p-1 cursor-pointer buttonHover'
													onClick={() => {
														const array = sectionArray;
														if (section_index < sectionArray.length - 1) {
															[array[section_index], array[section_index + 1]] =
																[
																	array[section_index + 1],
																	array[section_index],
																];
															setShowBlueBorder(null);
														}

														setsectionArray(array);
													}}
												>
													<MdArrowDownward
														size={21}
														color='#fff'
														title='Move Element Downward'
													/>
												</div>
											</div>
											<div className='flex items-center'>
												<div
													className='p-1 cursor-pointer buttonHover'
													onClick={() => {
														const clonedSecKey = uuidv4();
														const clonedColumns = sectionArray[
															section_index
														].columns.map((column) => {
															const clonedElements = column.elements.map(
																(element) => cloneData(element)
															);
															return {
																row_key: `${
																	column.row_key.split('/')[0]
																}/${uuidv4()}`,
																elements: clonedElements,
															};
														});
														const clonedSectionArray = {
															sec_key: `${
																sectionArray[section_index].sec_key.split(
																	'/'
																)[0]
															}/${uuidv4()}`,
															columns: clonedColumns,
														};

														function cloneData(data) {
															if (Array.isArray(data)) {
																return data.map((item) => cloneData(item));
															}
															if (typeof data === 'object' && data !== null) {
																const clonedData = { ...data };
																for (const key in clonedData) {
																	if (
																		typeof clonedData[key] === 'object' &&
																		clonedData[key] !== null
																	) {
																		clonedData[key] = cloneData(
																			clonedData[key]
																		);
																	}
																}
																clonedData.comp_key = `${
																	clonedData.tag
																}/${uuidv4()}`;
																return clonedData;
															}
															return data;
														}

														setsectionArray([
															...sectionArray.slice(0, section_index),
															clonedSectionArray,
															...sectionArray.slice(section_index),
														]);

														setSelectedSectionIndex(section_index + 1);
														console.log(
															'clonedSectionArray',
															clonedSectionArray
														);
													}}
												>
													<MdContentCopy size={21} color='#fff' title='Clone' />
												</div>
												<div className='p-1 cursor-pointer buttonHover'>
													<IoSettingsOutline size={21} color='#fff' />
												</div>
												<div className='p-1 cursor-pointer buttonHover'>
													<FiSave size={21} color='#fff' />
												</div>
												<div
													className='p-1 cursor-pointer buttonHover'
													style={{ borderBottomRightRadius: '4px' }}
													onClick={() => {
														if (
															!layoutEle.some((item) =>
																item.hasOwnProperty(section_index)
															)
														) {
															const newLayout = [
																...layoutEle,
																{
																	[section_index]: {
																		layoutCreator: 'true',
																		layoutView: 'false',
																	},
																},
															];
															setLayoutEle(newLayout);
														}
													}}
												>
													<FiPlus size={21} color='#fff' title='Add' />
												</div>
												<div
													className='p-1 cursor-pointer buttonHover'
													style={{ borderBottomRightRadius: '4px' }}
													onClick={() => {
														const array = sectionArray;
														array.splice(section_index, 1);
														setShowBlueBorder(null);
														if (array.length == 0) {
															setLayoutCreator(true);
														}
														setsectionArray(array);
													}}
												>
													<MdDeleteOutline
														size={21}
														color='#fff'
														title='Delete'
													/>
												</div>
											</div>
										</div>
									)}
									<div
										style={{
											border:
												ShowBlueBorder == section_index
													? '1.5px solid #0066FF'
													: '1.5px solid transparent',
											width: '100%',
											padding: '0px 1px',
											display: 'flex',
											flexDirection: 'column',
											height: 'auto',
											margin: '0px 0',
										}}
									>
										<div
											style={{ padding: '2px 0px' }}
											className={`grid grid-cols-${section.columns.length} items-center gap-6`}
										>
											{Object.keys(section.columns)
												.sort((a, b) => {
													const numA = parseInt(a.replace('sec_', ''));
													const numB = parseInt(b.replace('sec_', ''));
													return numA - numB;
												})
												.map((row, row_index) => (
													<div>
														{/* {
                 section.columns[row].elements.length > 0
				 section section_index row_index row
                    ?  */}

														<RenderComponent
															section={section}
															sectionArray={sectionArray}
															setsectionDrop={setsectionDrop}
															setSelectedSectionIndex={setSelectedSectionIndex}
															section_index={section_index}
															setColDrop={setColDrop}
															setselectedComponent={setselectedComponent}
															setStiloShow={setStiloShow}
															row_index={row_index}
															row={row}
															setshowDrawer={setshowDrawer}
															setElementType={setElementType}
															selectedComponent={selectedComponent}
															setVideoController={setVideoController}
															setIconPopup={setIconPopup}
															setImagePopup={setImagePopup}
															setImageDestination={setImageDestination}
															responsiveMode={responsiveMode}
															setsectionArray={setsectionArray}
															setSelectedColumnIndex={setSelectedColumnIndex}
															setSelectedContent={setSelectedContent}
															setSelectedStyle={setSelectedStyle}
															setDragOver={setDragOver}
															setComponentArray={setComponentArray}
															ComponentArray={ComponentArray}
															DragOver={DragOver}
															draggedKey={draggedKey}
															setDraggedKey={setDraggedKey}
															formerIndex={formerIndex}
															setFormerIndex={setFormerIndex}
															currentDrag={currentDrag}
															setcurrentDrag={setcurrentDrag}
															firstIndex={firstIndex}
															setFirstIndex={setFirstIndex}
															mvBtm={mvBtm}
															setMvBtm={setMvBtm}
															mvUp={mvUp}
															setMvUp={setMvUp}
														/>
														{/* //                     : (
//                       <div className="flex justify-center" style={{ position: 'relative', top: '-29%', color: '#72728F', fontSize: '13px' }}>
//                         <FiPlus size={40} className='plus' />
//                       </div>
//                     )
// } */}
													</div>
												))}
										</div>
									</div>
								</div>
							</div>
					  ))
					: null}
				{/* Layout Creator- 2 buttons Bloco and Templates
          Layout View- Selecting number of sections
          */}{' '}
				<div
					style={{
						border: '2px dashed #E1E2EB',
						height: '176px',
						margin: '30px',
						borderRadius: '8px',
					}}
					className='flex justify-center items-center'
				>
					{LayoutCreator ? (
						<div className='flex items-center gap-2'>
							<CustomButton
								onClick={() => {
									setLayoutCreator(false);
									setLayoutView(true);
								}}
								loading={false}
								variant='outlined'
								disabled={false}
								Icon={BsPlus}
								buttontext={t('blankBlock')}
								textColor='#72728F'
							/>
							<CustomButton
								loading={false}
								backgroundColor='#101014'
								textColor='#fff'
								disabled={false}
								Icon={RiLayoutMasonryFill}
								buttontext={t('Templates')}
								variant='contained'
							/>
						</div>
					) : LayoutView ? (
						<div className='flex items-center' style={{ position: 'relative' }}>
							<div>
								<MdClose
									className='hover:opacity-80'
									onClick={() => {
										setLayoutCreator(true);
										setLayoutView(false);
									}}
									color='black'
									size={25}
									style={{
										position: 'absolute',
										bottom: '100%',
										right: '-50%',
										cursor: 'pointer',
									}}
								/>
							</div>

							{[1, 2, 3, 4].map((num) => (
								<div
									key={num}
									onClick={() => {
										handleSectionClick(num);
										console.log(sectionArray);
									}}
									className='flex items-center mx-3 hover:opacity-80'
									style={{ cursor: 'pointer', width: '9rem', height: '5.1rem' }}
								>
									{Array(num)
										.fill(0)
										.map((_, index) => (
											<div
												key={index}
												className='bg-[#E1E2EB] rounded-sm mx-1'
												style={{
													width: `${(11 - num) / num}rem`,
													height: '5.1rem',
												}}
											/>
										))}
								</div>
							))}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default DropContainer;
