import React from 'react';
import { FiSave } from 'react-icons/fi';
import { BsSliders } from 'react-icons/bs';
import { connect } from 'react-redux';
import { CustomButton } from '../customButton';

import { setElementsArr, updateElementsArr } from '../../redux/action/builderAction';

const TextEdit = (props) => (
  <div>
    <div style={{
      display: 'flex', flexDirection: 'column', padding: '10px 10px', margin: '10px 0',
    }}
    >
      <span style={{ color: '#72728F', fontSize: '13px' }}>Titulo</span>
      <div className="pt-1 pb-6 " style={{ borderBottom: '2px solid #ddd' }}>
        <div className="py-1">
          <span style={{ color: '#72728F', fontSize: '13px' }}>Campo de texto</span>
          {props.EleArr.map((el, i) => (
            el.key == props.SelectedElement
              ? (
                <textarea
                  onChange={(e) => {
                    const temparr = props.EleArr;

                    const eleobj = {
                      element: el.element, content: e.target.value, position: el.position, key: `${el.element.tag}/${el.position}/${props.EleArr.length - 1}`,
                    };
                    temparr[i] = eleobj;
                    console.log(temparr[i], 'index');
                    props.setElementsArr(temparr);
                    // props.updateElementsArr(props.EleArr,eleobj,i)
                  }}
                  rows="4"
                  style={{
                    outline: 0, border: '1px solid #ddd', borderRadius: '5px', width: '100%', fontSize: '12px', color: '#72728F', paddingLeft: '5px',
                  }}
                >
                  {el.content}
                </textarea>
              ) : null
          ))}
        </div>
        <div className="py-1">
          <span style={{ color: '#72728F', fontSize: '13px' }}>Link</span>
          <input style={{
            outline: 0, border: '1px solid #ddd', borderRadius: '5px', width: '100%', paddingLeft: '5px', fontSize: '13px', color: '#72728f', padding: '5px 5px',
          }}
          />
        </div>
        <div className="py-1">
          <span style={{ color: '#72728F', fontSize: '13px' }}>Abrir em nova guia</span>
        </div>
        <div className="py-1">
          <span style={{ color: '#72728F', fontSize: '13px' }}>Alinhamento</span>

        </div>

      </div>

      <div className="py-4 ">
        <span style={{ color: '#72728F', fontSize: '13px' }}>Tipografia</span>

        <div className="py-1 flex items-center justify-between">
          <span style={{ color: '#72728F', fontSize: '13px' }}>Cor</span>
          <input type="color" defaultValue="red" className="w-7 h-7" />
        </div>
        <div className="py-1">
          <span style={{ color: '#72728F', fontSize: '13px' }}>Cor de fundo</span>
        </div>
        <div className="py-1">
          <span style={{ color: '#72728F', fontSize: '13px' }}>Contorno</span>
        </div>
        <div style={{ margin: '10px 0' }}>
          <CustomButton loading={false} disabled={false} textColor="#fff" style={{ textAlign: 'center' }} Icon={BsSliders} buttontext="Editar tipografia" backgroundColor="#62627A" variant="contained" />
        </div>

        <div className="py-1 flex items-center justify-between">
          <span style={{ color: '#72728F', fontSize: '13px' }}>Tamanho</span>
          <div style={{ border: '1px solid #ddd', borderRadius: '5px' }} className="flex items-center">
            <span style={{ color: '#72728F', fontSize: '13px' }} className="mx-1">P</span>
            <span style={{ color: '#72728F', fontSize: '13px' }} className="mx-1">M</span>
            <span style={{ color: '#72728F', fontSize: '13px' }} className="mx-1">M</span>
            <span style={{ color: '#72728F', fontSize: '13px' }} className="mx-1">GG</span>

          </div>
        </div>

      </div>
    </div>
  </div>
);
const mapStateToProps = (state) => ({
  EleArr: state.builderReducer.EleArr,
});
export default connect(mapStateToProps, {
  setElementsArr, updateElementsArr,
})(TextEdit);
