import React, { useState, useRef } from 'react';

import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsSliders } from 'react-icons/bs';
import {
  alignOptions, nonDigitStyles, fontSizeOptions, tagsOptions,
  responsiveIcons,
} from '../../../../helpers/Builder/helperArrays';
import CustomButton from '../../../customButton/CustomButton';
import DropDown from './DropDown';

const AccordionEdit = ({
  setselectedtab, setIconPopup, setIconDestination, propValue, propChange, propMiscValue, propMiscChange, styleValue, styleChange, device, setDevice, setResponsiveMode, responsiveData, responsiveMode,
}) => {
  const [currentItem, setCurrentItem] = useState(null);

  const changeObjectStyle = (style, position, e) => {
    if (style.includes('.')) {
      const styleArray = style.split('.');
      const styleObject = styleArray[0];
      const stylePosition = styleArray[1];
      const newStyleObject = {
        target: {
          value: {
            ...styleValue(styleObject),
            [stylePosition]: {
              ...styleValue(styleObject)[stylePosition],
              [position]: e.target.value,
            },
          },
        },
      };
      styleChange(styleObject, newStyleObject);
      return;
    }

    const positionValue = e.target.value;
    const newStyleObject = {
      target: {
        value: {
          ...styleValue(style),
          [position]: positionValue,
        },
      },
    };
    styleChange(style, newStyleObject);
  };

  const items = propValue('items').map((item) => ({ ...item }));

  const tituloRef = useRef(null);
  const tipografia = useRef(null);

  return (
    <div style={{ padding: '10px 10px', margin: '10px 0' }}>
      <span
        style={{
          color: '#72728F', fontSize: '13px', display: 'inline-block', width: '90%',
        }}
        className="cursor-pointer"
        onClick={(e) => {
          const { nextSibling } = e.target;
          if (nextSibling.style.transform == 'rotate(180deg)') {
            nextSibling.style.transform = 'rotate(0deg)';
          } else {
            nextSibling.style.transform = 'rotate(180deg)';
          }

          e.target.parentNode.children[2].classList.toggle('hidden');
        }}
        ref={tituloRef}
      >
        Accordion Info:
        {' '}
      </span>
      <MdKeyboardArrowDown
        color="#72728F"
        style={{
          display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'transform 0.2s ease-in-out',
        }}
        className="cursor-pointer"
        onClick={(e) => {
          tituloRef.current.click();
        }}
      />
      <div className="pt-1 pb-6" style={{ borderBottom: '2px solid #ddd' }}>

        <div className="py-1">
          <div className="flex gap-4">
            <div
              className="flex flex-col justify-between items-center mt-3 gap-2"
              style={{ cursor: 'pointer', position: 'relative' }}
            >
              <i
                className={propMiscValue('activeIcon')}
                alt="cloud"
                style={{ color: '#72728F', fontSize: '13px' }}
              />
              <span
                style={{
                  background: 'black', color: 'white', width: '100%', textAlign: 'center',
                }}
                onClick={(e) => {
                  setIconPopup(true);
                  setIconDestination(e.target.innerText);
                }}
              >
                Active Icon
              </span>
            </div>
            <div
              className="flex flex-col justify-between items-center mt-3 gap-2"
              style={{ cursor: 'pointer', position: 'relative' }}
            >
              <i
                className={propMiscValue('inactiveIcon')}
                alt="cloud"
                style={{ color: '#72728F', fontSize: '13px' }}
              />
              <span
                style={{
                  background: 'black', color: 'white', width: '100%', textAlign: 'center',
                }}
                onClick={(e) => {
                  setIconPopup(true);
                  setIconDestination(e.target.innerText);
                }}
              >
                Inactive Icon
              </span>
            </div>

          </div>

          <div>
            <div className="flex justify-between items-center">
              <h4>Accordion List</h4>
              <button
                onClick={() => {
                  const newItems = [...items];
                  newItems.push({ title: 'New Item', content: 'New Content', active: false });
                  const change = { target: { value: newItems } };
                  propChange('items', change);
                }}
              >
                <i className="fas fa-plus" />
              </button>
            </div>

            {
                    items.map((item, index) => (
                      <div key={index} className="flex flex-col justify-between items-center mt-3" style={{ color: '#72728F' }}>
                        <div className="flex">
                          <button
                            onClick={() => {
                              const newItems = [...items];
                              newItems.splice(index, 0, { ...item });
                              const change = { target: { value: newItems } };
                              propChange('items', change);
                            }}
                          >
                            <i className="fas fa-clone" style={{ color: '#72728F' }} />
                          </button>
                          <input
                            value={propValue('items')[index].title}
                            onChange={(e) => {
                              const newItems = [...items];
                              newItems[index].title = e.target.value;
                              const change = { target: { value: newItems } };
                              propChange('items', change);
                            }}
                            onClick={() => {
                              currentItem === index ? setCurrentItem(null) : setCurrentItem(index);
                            }}
                            style={{ color: '#72728F' }}
                          />
                          <button
                            onClick={() => {
                              const newItems = [...items];
                              newItems.splice(index, 1);
                              const change = { target: { value: newItems } };
                              propChange('items', change);
                            }}
                          >
                            <i className="fas fa-trash" style={{ color: '#72728F' }} />
                          </button>
                        </div>

                        <textarea
                          value={propValue('items')[index].content}
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[index].content = e.target.value;
                            // setItemsArray(newItems);
                            const change = { target: { value: newItems } };
                            propChange('items', change);
                          // propChange('items'[index].title, e);
                          }}
                          style={{ display: currentItem === index ? '' : 'none' }}
                        />

                      </div>
                    ))
                }
          </div>
        </div>
      </div>
      <div className="py-4 ">
        <span
          style={{
            color: '#72728F', fontSize: '13px', cursor: 'pointer', display: 'inline-block', width: '90%',
          }}
          onClick={(e) => {
            const { nextSibling } = e.target;
            if (nextSibling.style.transform == 'rotate(180deg)') {
              nextSibling.style.transform = 'rotate(0deg)';
            } else {
              nextSibling.style.transform = 'rotate(180deg)';
            }

            e.target.parentNode.children[2].classList.toggle('hidden');
          }}
          ref={tipografia}
        >
          Tipografia
        </span>
        <MdKeyboardArrowDown
          color="#72728F"
          style={{
            display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'transform 0.2s ease-in-out',
          }}
          className="cursor-pointer"
          onClick={(e) => {
            tipografia.current.click();
          }}
        />
        <div style={{ transition: 'all 0.2s ease-in-out' }}>
          <span style={{ color: '#72728F', fontSize: '13px' }}> Title Properties</span>
          <div className="py-1">
            <span className="flex justify-between center-align" style={{ color: '#72728F', fontSize: '13px' }}>
              <label htmlFor="textType" style={{ color: '#72728F', fontSize: '13px' }}>Tipo</label>
              <select
                style={{
                  fontSize: '13px', border: '1px solid #ddd', textAlign: 'center', outline: 0, color: '#72728F', width: '70px',
                }}
                className="px-2"
                name="textType"
                id="textType"
                onChange={(e) => {
                  propMiscChange('titleTag', e);
                }}
              >
                {tagsOptions.map((tag) => <option value={tag} selected={propMiscValue('titleTag') == tag}>{tag}</option>)}
              </select>
            </span>
          </div>
          <div className="py-1 flex items-center justify-between">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Title Cor</span>
            <input
              type="color"
              className="w-7 h-7"

              onChange={(e) => {
                changeObjectStyle('title', 'color', e);
              }}
              value={styleValue('title').color}
            />
          </div>
          <div className="py-1 flex items-center justify-between">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Title active Cor</span>
            <input
              type="color"
              className="w-7 h-7"
              onChange={(e) => {
                changeObjectStyle('title', 'activeColor', e);
              }}
              value={styleValue('title').activeColor}
            />
          </div>
          <div className="py-1 flex items-center justify-between">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Title Cor de fundo</span>
            <input
              type="color"
              className="w-7 h-7"

              onChange={(e) => {
                changeObjectStyle('title', 'backgroundColor', e);
              }}
              value={styleValue('title').backgroundColor}
            />
          </div>
          <div className="py-1">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Contorno</span>
          </div>
          <div className="py-1">
            <span className="flex justify-between center-align" style={{ color: '#72728F', fontSize: '13px', textTransform: 'capitalize' }}>
              <label htmlFor="borderStyle" style={{ color: '#72728F', fontSize: '13px' }}>Tamanho</label>
              <select
                style={{
                  fontSize: '13px', border: '1px solid #ddd', textAlign: 'center', outline: 0, color: '#72728F',
                }}
                className="px-2"
                name="borderStyle"
                id="borderStyle"
                onChange={(e) => {
                  changeObjectStyle('title', 'fontSize', e);
                }}
              >
                {fontSizeOptions.map((style) => <option value={style} selected={styleValue('title').fontSize == style}>{style}</option>)}
              </select>
            </span>
          </div>
          <span>Content Properties</span>
          <div className="py-1">
            <span className="flex justify-between center-align" style={{ color: '#72728F', fontSize: '13px' }}>
              <label htmlFor="textType" style={{ color: '#72728F', fontSize: '13px' }}>Tipo</label>
              <select
                style={{
                  fontSize: '13px', border: '1px solid #ddd', textAlign: 'center', outline: 0, color: '#72728F', width: '70px',
                }}
                className="px-2"
                name="textType"
                id="textType"
                onChange={(e) => {
                  propMiscChange('contentTag', e);
                }}
              >
                {tagsOptions.map((tag) => <option value={tag} selected={propMiscValue('contentTag') === tag}>{tag}</option>)}
              </select>
            </span>
          </div>
          <div className="py-1 flex items-center justify-between">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Cor</span>
            <input
              type="color"
              className="w-7 h-7"

              onChange={(e) => {
                changeObjectStyle('content', 'color', e);
              }}
              value={styleValue('content').color}
            />
          </div>
          <div className="py-1 flex items-center justify-between">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Cor de fundo</span>
            <input
              type="color"
              className="w-7 h-7"

              onChange={(e) => {
                changeObjectStyle('content', 'backgroundColor', e);
              }}
              value={styleValue('content').backgroundColor}
            />
          </div>
          <div className="py-1">
            <span style={{ color: '#72728F', fontSize: '13px' }}>Contorno</span>
          </div>
          <div className="py-1">
            <span className="flex justify-between center-align" style={{ color: '#72728F', fontSize: '13px', textTransform: 'capitalize' }}>
              <label htmlFor="borderStyle" style={{ color: '#72728F', fontSize: '13px' }}>Tamanho</label>
              <select
                style={{
                  fontSize: '13px', border: '1px solid #ddd', textAlign: 'center', outline: 0, color: '#72728F',
                }}
                className="px-2"
                name="borderStyle"
                id="borderStyle"
                onChange={(e) => {
                  changeObjectStyle('content', 'fontSize', e);
                }}
              >
                {fontSizeOptions.map((style) => <option value={style} selected={styleValue('content').fontSize === style}>{style}</option>)}
              </select>
            </span>
          </div>
        </div>
        <div style={{ margin: '10px 0' }}>
          <CustomButton
            loading={false}
            disabled={false}
            textColor="#fff"
            style={{ textAlign: 'center' }}
            Icon={BsSliders}
            buttontext="Editar tipografia"
            backgroundColor="#62627A"
            variant="contained"
            onClick={() => {
              setselectedtab('slider');
            }}
          />
        </div>

      </div>
    </div>
  );
};

export default AccordionEdit;
