import React, { useState, useRef } from 'react';

import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsSliders } from 'react-icons/bs';
import DrawerTabHeader from './DrawerTabHeader';
import {
  nonDigitStyles, borderStyles, tagsOptions, fontSizeOptions, widthOptions, targetOptions, alignOptions,
  responsiveIcons,
} from '../../../helpers/Builder/helperArrays';
import { CustomButton } from '../../customButton';
import desktop from '../../../assets/icons/builder/desktop.svg';
import cellular from '../../../assets/icons/builder/cellular.svg';
import tablet from '../../../assets/icons/builder/tablet.svg';
import TextEdit from './element-edit/TextEdit';
import DynamicEditInterface from './DynamicEditInterface';
import DropDown from './element-edit/DropDown';

const EditDrawer = ({
  setselectedtab, selectedtab, sectionDrop, selectedComponent, ColDrop, elementType, videoController, setImagePopup, setIconPopup, setIconDestination, propValue, propChange, propMiscValue, propMiscChange, styleValue, styleChange, propMiscCheckedValue, propMiscChecked, device, setDevice, setResponsiveMode, responsiveMode, responsiveData,
}) => {
  const espacamento = useRef(null);
  const bordaRef = useRef(null);
  const dispositivosRef = useRef(null);

  const changeObjectStyle = (style, position, e) => {
    const positionValue = e.target.value;
    const newStyleObject = {
      target: {
        value: {
          ...styleValue(style),
          [position]: positionValue,
        },
      },
    };
    styleChange(style, newStyleObject);
  };

  const [forceRender, setForceRender] = useState(false);

  const [customRange, setCustomRange] = useState(false);

  const widthChange = (e) => {
    if (e.target.value === 'fit-content' || e.target.value === '100') {
      changeObjectStyle('width', device, e);
      setCustomRange(false);
    } else if (e.target.value === 'custom') {
      setCustomRange(true);
    }
  };

  return (
    <div>
      <DrawerTabHeader setselectedtab={setselectedtab} selectedtab={selectedtab} />
      {selectedtab == 'slider' ? (
        <div
          className="hideScrollBar"
          style={{
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: 'transparent transparent',
          }}
        >
          <div className="mx-2 py-6 " style={{ borderBottom: '2px solid #ddd' }}>
            <span
              style={{
                color: '#72728F', fontSize: '13px', display: 'inline-block', width: '90%',
              }}
              className="cursor-pointer"
              onClick={(e) => {
                const { nextSibling } = e.target;
                if (nextSibling.style.transform == 'rotate(180deg)') {
                  nextSibling.style.transform = 'rotate(0deg)';
                } else {
                  nextSibling.style.transform = 'rotate(180deg)';
                }

                e.target.parentNode.children[2].classList.toggle('hidden');
              }}
              ref={espacamento}
            >
              Espacamentos:
              {' '}
            </span>
            <MdKeyboardArrowDown
              color="#72728F"
              style={{
                display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'tranform 0.2s ease-in-out',
              }}
              onClick={(e) => {
                espacamento.current.click();
              }}
            />
            <div>
              <div className="flex flex-column mt-3">
                <span style={{ color: '#72728F', fontSize: '13px' }} className="flex relative items-center">
                  Externo:
                  <DropDown responsiveData={responsiveData} responsiveMode={responsiveMode} setDevice={setDevice} setResponsiveMode={setResponsiveMode} position="30%" />
                </span>
                <span style={{ color: '#72728F', fontSize: '13px' }}>
          &nbsp;&nbsp;&nbsp;top &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;right &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bottom &nbsp;&nbsp;left
                </span>
                <div className="flex gap-2">
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    onChange={(e) => {
                      changeObjectStyle('marginTop', device, e);
                      changeObjectStyle('currentMargin', 'top', e);
                    }}
                    value={styleValue('marginTop')[device]}
                  />
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    onChange={(e) => {
                      changeObjectStyle('marginRight', device, e);
                      changeObjectStyle('currentMargin', 'right', e);
                    }}
                    value={styleValue('marginRight')[device]}
                  />
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    onChange={(e) => {
                      changeObjectStyle('marginBottom', device, e);
                      changeObjectStyle('currentMargin', 'bottom', e);
                    }}
                    value={styleValue('marginBottom')[device]}
                  />
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    onChange={(e) => {
                      changeObjectStyle('marginLeft', device, e);
                      changeObjectStyle('currentMargin', 'left', e);
                    }}
                    value={styleValue('marginLeft')[device]}
                  />
                </div>

              </div>
              <div className="flex flex-column mt-3">
                <span style={{ color: '#72728F', fontSize: '13px' }} className="flex relative items-center">
                  Interno:

                  <DropDown responsiveData={responsiveData} responsiveMode={responsiveMode} setDevice={setDevice} setResponsiveMode={setResponsiveMode} position="30%" />
                </span>
                <span style={{ color: '#72728F', fontSize: '13px' }} className="">
          &nbsp;&nbsp;&nbsp;top &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;right &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bottom &nbsp;&nbsp;left
                </span>
                <div className="flex gap-2">
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    onChange={(e) => {
                      changeObjectStyle('paddingTop', device, e);
                      changeObjectStyle('currentPadding', 'top', e);
                    }}
                    value={styleValue('paddingTop')[device]}
                  />
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    onChange={(e) => {
                      changeObjectStyle('paddingRight', device, e);
                      changeObjectStyle('currentPadding', 'right', e);
                    }}
                    value={styleValue('paddingRight')[device]}
                  />
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    onChange={(e) => {
                      changeObjectStyle('paddingBottom', device, e);
                      changeObjectStyle('currentPadding', 'bottom', e);
                    }}
                    value={styleValue('paddingBottom')[device]}
                  />
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    onChange={(e) => {
                      changeObjectStyle('paddingLeft', device, e);
                      changeObjectStyle('currentPadding', 'left', e);
                    }}
                    value={styleValue('paddingLeft')[device]}
                  />
                </div>

              </div>
            </div>
          </div>
          <div className="mx-2 py-6 " style={{ borderBottom: '2px solid #ddd' }}>
            <span className="flex justify-between center-align relative" style={{ color: '#72728F', fontSize: '13px' }}>
              <label htmlFor="borderStyle" style={{ color: '#72728F', fontSize: '13px' }} className="flex items-center">
                Largura:
                <DropDown responsiveData={responsiveData} responsiveMode={responsiveMode} setDevice={setDevice} setResponsiveMode={setResponsiveMode} position="30%" />
              </label>
              <select
                name="width"
                id="width"
                onChange={(e) => {
                  widthChange(e);
                  styleChange('currentWidth', e);
                }}

                style={{
                  fontSize: '13px', border: '1px solid #ddd', textAlign: 'center', outline: 0, color: '#72728F', width: '8rem',
                }}
                className="px-2"
              >
                {widthOptions.map((width) => <option style={{ color: '#72728F', fontSize: '13px' }} value={width.value} selected={styleValue('width')[device] === width.value}>{width.name}</option>)}
              </select>
            </span>
            {customRange && (
            <div className="flex flex-column">
              <label style={{ color: '#72728F', fontSize: '13px', marginTop: '10px' }}>Custom value</label>
              <input
                type="range"
                color="#72728F"
                onChange={(e) => {
                  changeObjectStyle('width', device, e);
                  styleChange('currentWidth', e);
                }}
              />
            </div>
            )}
          </div>
          <div className="mx-2 py-5" style={{ borderBottom: '2px solid #ddd' }}>
            <span
              style={{
                color: '#72728F', fontSize: '13px', display: 'inline-block', width: '90%',
              }}
              className="cursor-pointer"
              onClick={(e) => {
                const { nextSibling } = e.target;
                if (nextSibling.style.transform == 'rotate(180deg)') {
                  nextSibling.style.transform = 'rotate(0deg)';
                } else {
                  nextSibling.style.transform = 'rotate(180deg)';
                }

                e.target.parentNode.children[2].classList.toggle('hidden');
              }}
              ref={bordaRef}
            >
              Borda:
              {' '}
            </span>
            <MdKeyboardArrowDown
              color="#72728F"
              style={{
                display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'tranform 0.2s ease-in-out',
              }}
              onClick={(e) => {
                bordaRef.current.click();
              }}
            />
            <div>
              <div className="py-1">
                <span className="flex justify-between center-align" style={{ color: '#72728F', fontSize: '13px', textTransform: 'capitalize' }}>
                  <label htmlFor="borderStyle" style={{ color: '#72728F', fontSize: '13px' }}>Borda</label>
                  <select
                    style={{
                      fontSize: '13px', border: '1px solid #ddd', textAlign: 'center', outline: 0, color: '#72728F',
                    }}
                    className="px-2"
                    name="borderStyle"
                    id="borderStyle"
                    onChange={(e) => {
                      changeObjectStyle('border', 'style', e);
                    }}
                  >
                    {borderStyles.map((style) => <option value={style} selected={styleValue('border').style === style}>{style}</option>)}
                  </select>
                </span>
              </div>
              <div className="flex justify-between mt-3">
                <div className="flex flex-col" style={{ width: '100%' }}>
                  <div className="py-1 flex items-center justify-between">
                    <span style={{ color: '#72728F', fontSize: '13px' }}>Cor</span>
                    <input
                      type="color"
                      className="w-7 h-7"

                      onChange={(e) => {
                        changeObjectStyle('border', 'color', e);
                      }}
                      value={styleValue('border').color}
                    />
                  </div>
                  <span className="my-1" style={{ color: '#72728F', fontSize: '13px' }}>Largura da borda</span>
                  <div className="flex my-1 items-center">
                    <input
                      min="0"
                      max="10"
                      type="range"
                      style={{ flex: 10, marginRight: '5px' }}
                      onChange={(e) => {
                        changeObjectStyle('border', 'width', e);
                      }}
                      value={styleValue('border').width}
                    />
                    <input
                      style={{
                        border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px',
                      }}
                      className="w-7 h-7"
                      onChange={(e) => {
                        changeObjectStyle('border', 'width', e);
                      }}
                      value={styleValue('border').width}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col mt-3 ">
                <span style={{ color: '#72728F', fontSize: '13px' }} className="flex items-center relative">
                  Arredondar:

                  <DropDown responsiveData={responsiveData} responsiveMode={responsiveMode} setDevice={setDevice} setResponsiveMode={setResponsiveMode} position="40%" />
                </span>
                <span style={{ color: '#72728F', fontSize: '13px' }} className="">
          &nbsp;&nbsp;&nbsp;TL &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TR &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BL &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BR
                </span>
                <div className="flex gap-2">
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    value={styleValue('borderTopLeftRadius')[device]}
                    onChange={(e) => {
                      changeObjectStyle('borderTopLeftRadius', device, e);
                      changeObjectStyle('currentBorderRadius', 'topLeft', e);
                    }}
                  />
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    value={styleValue('borderTopRightRadius')[device]}
                    onChange={(e) => {
                      changeObjectStyle('borderTopRightRadius', device, e);
                      changeObjectStyle('currentBorderRadius', 'topRight', e);
                    }}
                  />
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    value={styleValue('borderBottomLeftRadius')[device]}
                    onChange={(e) => {
                      changeObjectStyle('borderBottomLeftRadius', device, e);
                      changeObjectStyle('currentBorderRadius', 'bottomLeft', e);
                    }}
                  />
                  <input
                    style={{
                      border: '1px solid #ddd', textAlign: 'center', color: '#72728F', fontSize: '13px', width: '50px',
                    }}
                    className="w-7 h-7"
                    type="number"
                    value={styleValue('borderBottomRightRadius')[device]}
                    onChange={(e) => {
                      changeObjectStyle('borderBottomRightRadius', device, e);
                      changeObjectStyle('currentBorderRadius', 'bottomRight', e);
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
          <div className="mx-2 py-5">
            <span
              style={{
                color: '#72728F', fontSize: '13px', cursor: 'pointer', display: 'inline-block', width: '90%',
              }}
              className=""
              ref={dispositivosRef}
              onClick={(e) => {
                const { nextSibling } = e.target;
                if (nextSibling.style.transform == 'rotate(180deg)') {
                  nextSibling.style.transform = 'rotate(0deg)';
                } else {
                  nextSibling.style.transform = 'rotate(180deg)';
                }

                e.target.parentNode.children[2].classList.toggle('hidden');
              }}
            >
              Dispositivos
            </span>
            <MdKeyboardArrowDown
              color="#72728F"
              style={{
                display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'transform 0.2s ease-in-out',
              }}
              onClick={() => {
                dispositivosRef.current.click();
              }}
            />
            <div>
              <div className="flex justify-between items-center mt-3">
                <span className="flex justify-between items-center" style={{ color: '#72728F', fontSize: '13px', width: '100%' }}>
                  <img
                    src={desktop}
                  />
                  <span htmlFor="desktop" style={{ color: '#72728F', fontSize: '13px' }}>Esconder no Desktop</span>
                  <label id="desktop" className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      className="sr-only peer hidden"
                      checked={!!propMiscCheckedValue('hideOnDesktop')}
                      onChange={(e) => {
                        propMiscChecked('hideOnDesktop', e);
                        setForceRender(!forceRender);
                      }}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white
          after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
          after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-700"
                    />
                  </label>
                </span>

              </div>
              <div className="flex justify-between items-center mt-3">

                <span className="flex justify-between items-center" style={{ color: '#72728F', fontSize: '13px', width: '100%' }}>
                  <img
                    src={tablet}
                  />
                  <span htmlFor="tablet" style={{ color: '#72728F', fontSize: '13px' }}>Esconder no Tablet&nbsp;&nbsp; </span>
                  <label id="tablet" className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      className="sr-only peer hidden"
                      checked={!!propMiscCheckedValue('hideOnTablet')}
                      onChange={(e) => {
                        propMiscChecked('hideOnTablet', e);
                        setForceRender(!forceRender);
                      }}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white
          after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
          after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-700"
                    />
                  </label>
                </span>

              </div>
              <div />
              <div className="flex justify-between items-center mt-3">
                {/* <img src={desktopIcon}/> */}
                <span className="flex justify-between items-center" style={{ color: '#72728F', fontSize: '13px', width: '100%' }}>
                  <img
                    src={cellular}
                  />
                  <span htmlFor="cellular" style={{ color: '#72728F', fontSize: '13px' }}>Esconder no Celular</span>
                  <label id="cellular" className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      className="sr-only peer hidden"
                      checked={!!propMiscCheckedValue('hideOnCellular')}
                      onChange={(e) => {
                        propMiscChecked('hideOnCellular', e);
                        setForceRender(!forceRender);
                      }}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white
          after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
          after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-700"
                    />
                  </label>
                </span>

              </div>
            </div>
          </div>
        </div>
      )
        : <DynamicEditInterface setselectedtab={setselectedtab} elementType={elementType} videoController={videoController} setImagePopup={setImagePopup} setIconPopup={setIconPopup} setIconDestination={setIconDestination} propValue={propValue} propChange={propChange} propMiscValue={propMiscValue} propMiscChange={propMiscChange} styleValue={styleValue} styleChange={styleChange} propMiscCheckedValue={propMiscCheckedValue} propMiscChecked={propMiscChecked} device={device} setDevice={setDevice} setResponsiveMode={setResponsiveMode} responsiveMode={responsiveMode} responsiveData={responsiveData} />}
      {/* <ImageEdit/> */}
    </div>
  );
};

export default EditDrawer;
