import React, { useContext, useState, useEffect } from 'react';
import '../styles/global.css';
import '../styles/novaPagina/NovaPagina.css';
import '../styles/sites/Sites.css';
import '../styles/gerenciar/Gerenciar.css';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import {
  collection, doc, addDoc, deleteDoc,
} from 'firebase/firestore';
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import goBackIcon from '../assets/icons/paginas/chevron-left.svg';
import deleteIcon from '../assets/icons/sites/Delete.png';
import { SideBarHome } from '../components';
import { db } from '../config/firebase';
import { deleteDocument } from '../service/useFirestore';
import { LoginContext } from '../contexts/ContextProvider.js';
import { useCollection } from '../custom-hooks/useCollection';

const Gerenciar = () => {
  const [selected, setSelected] = useState('tags');
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [products, setProducts] = useState([]);
  const user = useContext(LoginContext);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [currentColor, setCurrentColor] = useState('lightskyblue');
  const [tagName, setTagName] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [productName, setProductName] = useState('');
  const navigate = useNavigate();

  const list = [
    {
      name: 'status',
      value: 'Active',
      operator: '==',
    },
  ];

  const orderBy = [
    {
      name: 'name',
      order: 'asc',
    },
  ];

  const limit = 2;

  const { documents: tagsDocuments } = useCollection('tags');
  const { documents: productsDocuments } = useCollection('sitesProduct');
  // const { documents: sub } = useCollection("products","0B1zizlSngfFNS7TlZ858AKj77o1","domains");

  const validateInput = (value) => {
    const regex = /[^A-Za-z0-9]+/g; // Regular expression for alphanumeric characters only
    return regex.test(value);
  };

  useEffect(() => {
    setTags([...tagsDocuments]);
    console.log(tagsDocuments);
    setProducts([...productsDocuments]);
  }, [tagsDocuments, productsDocuments]);

  useEffect(() => {
    document.body.classList.add('custom-background');
    return () => {
      document.body.classList.remove('custom-background');
    };
  }, []);

  const createTag = async () => {
    try {
      if (tagName.length == 0) {
        toast('Enter the name for tag', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        setIsLoading(true);
        const doc = {
          ownerId: user.user.uid,
          siteName: '',
          tags: {
            color: currentColor,
            label: tagName,
            twColor: currentColor,
            value: tagName,
          },
        };
        await addDoc(collection(db, 'tags'), doc);
        addTagToast();
      }
    } catch (error) {
      console.error('Error adding document:', error);
      setError('Failed to create the site.');
      setSuccess('');
      setIsLoading(false);
    }
  };

  const createProduct = async () => {
    try {
      setIsLoading(true);
      if (productName.length == 0) {
        toast('Enter the name of product', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        const doc = {
          ownerId: user.user.uid,
          products: {
            label: productName,
            value: productName,
          },
        };

        await addDoc(collection(db, 'sitesProduct'), doc);
        addProductToast();
      }
    } catch (error) {
      console.error('Error adding document:', error);
      setError('Failed to create the site.');
      setSuccess('');
      setIsLoading(false);
    }
  };

  const handleProductSubmit = (event) => {
    event.preventDefault();
    createProduct();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createTag();
  };

  const addTagToast = () => {
    toast('Tag Added', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  const addProductToast = () => {
    toast('Product Added', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  const changeColor = (newColor) => {
    setCurrentColor(newColor);
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/[^A-Za-z0-9]+/g, '');
    setTagName(value);
    setIsValid(validateInput(value));
  };
  const handleKeyDown = (e) => {
    if (e.key === ' ' || /[^\w\s]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleProductName = (e) => {
    const value = e.target.value.replace(/[^A-Za-z0-9]+/g, '');
    setProductName(value);
    setIsValid(validateInput(value));
  };

  const handleProductKeyDown = (e) => {
    if (e.key === ' ' || /[^\w\s]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleDeleteTag = async (id) => {
    console.log('deleted the Tag');
    await deleteDoc(doc(db, 'tags', id));
  };

  const handleDeleteProduct = async (id) => {
    console.log('Deleted the product');
    await deleteDoc(doc(db, 'sitesProduct', id));
  };

  return (
    <div className="Gerenciar">
      <SideBarHome />
      <ToastContainer
        className="toast"
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="RightComponentLanding">
        <div className="HeaderNovaPagina">
          <div className="goBackButton" onClick={() => navigate(-1)}>
            <img src={goBackIcon} alt="goBackIcon.svg" />
          </div>
          <p>{t('manageProductTag')}</p>
          <div className="frameHeader">
            <div
              className={
                selected == 'tags' ? 'selectedComponent' : 'unselectedComponent'
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected('tags');
                }}
              >
                TAGS
              </span>
            </div>
            <div
              className={
                selected == 'productos'
                  ? 'selectedComponent'
                  : 'unselectedComponent'
              }
            >
              <span
                className="componentName"
                onClick={() => {
                  setSelected('productos');
                }}
              >
                {t('products')}
              </span>
            </div>
          </div>
        </div>
        <div className="SitesBody">
          <div className="GerenciarBody">
            <div className="SubBody">
              {selected == 'tags' ? (
                <>
                  <p onClick={addTagToast}>{t('myTags')}</p>
                  <div className="tagsContainer">
                    {tags?.map((item, index) => (
                      <div
                        key={index}
                        className="tagsColors"
                        style={{
                          border: `1px solid ${item?.tags?.color}`,
                        }}
                      >
                        <span style={{ color: item?.tags?.color }}>
                          {item?.tags?.value}
                          {' '}
                          <span
                            onClick={() => handleDeleteTag(item.id)}
                            className="cross"
                          >
                            ×
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <p>{t('myProducts')}</p>
                  <div className="productsContainer">
                    {products.map((item, index) => (
                      <div key={index} className="productosList">
                        <p>{item?.products?.value}</p>
                        <img
                          src={deleteIcon}
                          onClick={() => handleDeleteProduct(item.id)}
                          className="deleteIcon"
                          alt="deleteIcon.png"
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="SubBody">
              {selected == 'tags' ? (
                <>
                  <p>
                    {t('create')}
                    {' '}
                    Tag
                  </p>
                  <div className="formContainer">
                    <div className="formInputs">
                      <div className="createTagAndColor">
                        <p>
                          {t('create')}
                          {' '}
                          tag
                          {t('custom')}
                        </p>
                        <input
                          type="text"
                          className="dropdownGerenciar"
                          placeholder={t('nameATage')}
                          value={tagName}
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}
                          required
                        />
                      </div>
                      <div className="createTagAndColor">
                        <p>{t('chooseAColor')}</p>
                        <select
                          id="colorDropdown"
                          className="colorDropdown"
                          placeholder=""
                          onChange={(event) => changeColor(event.target.value)}
                          value={currentColor}
                          style={{color:currentColor}}
                          defaultValue="lightskyblue"
                        >
                          <option
                          style={{color:"lightblue"}}
                            defaultValue="lightskyblue"
                            defaultChecked
                            value="lightskyblue"
                          >
                            {t('lightBlue')}
                          </option>
                          <option style={{color:"orange"}} value="orange">{t("orange")}</option>
                          <option style={{color:"green"}} value="green">{t("green")}</option>
                          <option style={{color:"yellow"}} value="yellow">{t("yellow")}</option>
                          <option style={{color:"blue"}} value="blue">{t("blue")}</option>
                          <option style={{color:"violet"}} value="violet">{t("violet")}</option>
                          <option style={{color:"fuchsia"}} value="fuchsia">Fuchsia</option>
                          <option style={{color:"purple"}} value="purple">{t("purple")}</option>
                          <option style={{color:"red"}} value="red">Rose</option>
                        </select>
                      </div>
                      <button onClick={handleSubmit} className="criarTagButton">
                        <span>{t('createTag')}</span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p>{t('addProduct')}</p>
                  <form>
                    <div className="createProduct">
                      <p>{t('enterNameOfProduct')}</p>
                      <input
                        type="text"
                        className="dropdownGerenciar productInput"
                        placeholder={t('enterProductName')}
                        value={productName}
                        onChange={handleProductName}
                        onKeyDown={handleProductKeyDown}
                        required
                      />
                    </div>
                    <button
                      onClick={handleProductSubmit}
                      className="criarTagButton productButton"
                    >
                      <span>{t('addProduct')}</span>
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gerenciar;
