import React, { useState } from "react";
import "../../../../styles/builder/ProgressbarRender.css";

export default function ProgressbarRender({ comp }) {
  return (
    <>
      <div className="skillHeader">
        <span className="headerText">{comp.content ?? "My Skill"}</span>
      </div>
      <div className="horizontal-liquid-container">
        <input
          type="range"
          min="0"
          max="100"
          value={comp.misc.rangeValue}
          className="horizontal-liquid-range"
        />
        <div
          className="horizontal-liquid-level"
          style={{
            width: `${comp.misc.rangeValue}%`,
            backgroundColor: "#6EC1E4",
          }}
        >
          <span className="percentage">
            {comp.misc.target == "Show" ? `${comp.misc.rangeValue}%` : ""}
          </span>
        </div>
        <span className="skillText">{comp.innerContent ?? "Web Designer"}</span>
      </div>
    </>
  );
}
