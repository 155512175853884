import React, { useState, useEffect, useRef } from "react";
import { FaStar } from "react-icons/fa";
import "../../../../styles/builder/ProgressbarRender.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CounterRender({ comp }) {
    const [title, setTitle] = useState("Cool Number");

  const [count, setCount] = useState(0);

  const [startingPoint, setStartingPoint] = useState(Number);

  const [previousValue, setPreviousValue] = useState(Number);

  const [endingPoint, setEndingPoint] = useState(60);

  const [prefixValue, setPrefixValue] = useState(null);

  const [suffixValue, setSuffixValue] = useState(null);

  const [intervalTIme, setIntervalTime] = useState(200);

  const [isToggled, setToggle] = useState(false);

  const [selectedSeparator, setSelectedSeparator] = useState("");

  const options = [

    { value: "apostrophe", label: "Apostrophe" },

    { value: "dot", label: "Dot" },

    { value: "space", label: "Space" },

    { value: "underline", label: "Underline" },

    // Add more options here if needed

  ];
  const calculateStarRating = () => {
    const maxRating = 10;
    const percentage = (comp.misc.rating / maxRating) * 100;
    return { width: `${percentage}%`, color: "#f0ad4e" };
  };

  useEffect(() => {
    console.log('Thousand : ', comp.thousandSeparator)
    if (comp.startingNumber != previousValue || previousValue != 0) {
      setPreviousValue(comp.startingNumber ?? 0);
      setCount(comp.startingNumber ?? 0);
    }
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < comp.endingNumber || prevCount < 100) {
          return Number(prevCount) + 1;
        } else {
          return prevCount;
        }

      });

    }, comp.intervalTime ?? intervalTIme); // Interval of 1000ms (1 second)
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [comp.startingNumber, comp.endingNumber, comp.intervalTime, comp.count]); // Empty dependency array ensures the effect runs only once on mount




  const numberWithSeparator = () => {

    switch (selectedSeparator) {

      case 'apostrophe':

        return count.toLocaleString("en-US").replace(/,/g, "'");

      case 'dot':

        return count.toLocaleString("en-US").replace(/,/g, ".");

      case 'space':

        return count.toLocaleString("en-US").replace(/,/g, " ");

      case 'underline':

          return count.toLocaleString("en-US").replace(/,/g, "_");

      default:

        return count.toLocaleString("en-US");

      }




  };


  return (
    <>
      <div
        className="flex items-center flex-col"
        style={{ justifyContent: comp.misc.jusctifyContent }}
      >
        <span className="text-black flex items-center mx-2">
          {comp.numberPrefix ?? ''}{comp.count ?? count}{comp.numberSuffix ?? ''}
        </span>
        <span className="text-black flex items-center mx-2">
          {comp.ratingText ?? "Cool Number"}
        </span>
      </div>
    </>
  );
}

