import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import OpenCloseArrow from '../components/builder/main/OpenCloseArrow';
import BuilderHeader from '../components/builder/header/BuilderHeader';
import BuilderSidebar from '../components/builder/sidebar/BuilderSidebar';
import { LoadingScreen } from '../components/loading/LoadingScreen';
import { sidebar_components } from '../helpers/Builder/components';
import BuilderDrawer from '../components/builder/drawer/BuilderDrawer';
import DropContainer from '../components/builder/main/DropContainer';
import ImagePopup from '../components/builder/main/popups/ImagePopup';
import IconPopup from '../components/builder/main/popups/IconPopup';
import desktopIcon from '../assets/icons/builder/desktop.svg';
import mobileIcon from '../assets/icons/builder/cellular.svg';
import tabletIcon from '../assets/icons/builder/tablet.svg';
import { useCollection } from '../custom-hooks/useCollection';

const Builder = (props) => {
  const [SelectedComp, setSelectedComp] = useState(sidebar_components[0]);
  const [ColDrop, setColDrop] = useState(null);
  const [ShowBlueBorder, setShowBlueBorder] = useState(null);
  const [ComponentArray, setComponentArray] = useState([]);
  const [SelectedColumnIndex, setSelectedColumnIndex] = useState(null);
  const [SelectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [SelectedContent, setSelectedContent] = useState('');
  const [showDrawer, setshowDrawer] = useState(false);
  const [StiloShow, setStiloShow] = useState(false);
  const [selectedtab, setselectedtab] = useState('brush');
  const [sectionArray, setsectionArray] = useState([]);
  const [selectedComponent, setselectedComponent] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [sectionDrop, setsectionDrop] = useState({});
  const [LayoutCreator, setLayoutCreator] = useState(true);
  const [LayoutView, setLayoutView] = useState(false);
  const [DragOver, setDragOver] = useState(null);
  const [search, setSearch] = useState('');
  const [isBuilderDataLoaded, setIsBuilderDataLoaded] = useState(false);
  const [imagePopup, setImagePopup] = useState(false);
  const [iconPopup, setIconPopup] = useState(false);
  const [iconDestination, setIconDestination] = useState('');
  const [imageDestination, setImageDestination] = useState('');
  const [scale, setScale] = useState(1);

  const [page, setPage] = useState('');
  const [layoutEle, setLayoutEle] = useState([]);
  const [layoutViewEle, setLayoutViewEle] = useState([]);
  const [elementType, setElementType] = useState('text');
  const [videoController, setVideoController] = useState(false);
  const [responsiveMode, setResponsiveMode] = useState({ icon: desktopIcon, mode: 'desktop', width: window.innerWidth });
  const [firstIndex, setFirstIndex] = useState(false);
  const [mvBtm, setMvBtm] = useState(false);
  const [mvUp, setMvUp] = useState(false);
  const [draggedKey, setDraggedKey] = useState(null);
  const [formerIndex, setFormerIndex] = useState(null);
  const [currentDrag, setcurrentDrag] = useState(null);
  const { id } = useParams();

  const [width, setWidth] = useState(responsiveMode.width);
  const [height, setHeight] = useState(0);

  console.log(ColDrop, "colo")
  const [breakPoints, setBreakPoints] = useState({
    mobile: {
      min: 0,
      max: 767,
    },
    tablet: {
      min: 768,
      max: 1024,
    },
  });

  const [responsiveData, setResponsiveData] = useState([
    {
      icon: desktopIcon,
      mode: 'desktop',
      width: window.innerWidth,
    },
    {
      icon: tabletIcon,
      mode: 'tablet',
      width: 768,
      max: 1024,
      min: 768,
    },
    {
      icon: mobileIcon,
      mode: 'mobile',
      width: 360,
      max: 767,
      min: 320,
    },
  ]);

  const docId = [
    {
      name: 'id',
      operator: '==',
      value: id,
    },
  ];

  const { documents: pages } = id?.length ? useCollection('pagesnew', docId) : { documents: [] };

  useEffect(() => {
    if (id?.length && pages.length) {
      const pageData = pages[0];
      const { sections } = pageData;
      const { page } = pageData;
      if (sections.length) {
        setsectionArray(sections);
        setPage(page);
        setIsBuilderDataLoaded(true);
      }
    } else if (!id?.length && !pages.length) {
      setIsBuilderDataLoaded(true);
    }
  }, [pages]);

  const [device, setDevice] = useState('desktop');
  const propMiscCheckedValue = (prop) => {
    const dropSection = sectionDrop.columns[ColDrop].elements;
    const filterDropSection = dropSection.filter(((c) => c.comp_key == selectedComponent
    ));
    return filterDropSection[0].misc[prop];
  };

  const propMiscChecked = (prop, e) => {
    const dropSection = sectionArray.filter((s) => s.sec_key == sectionDrop.sec_key);

    const dropsectionindex = dropSection[0].columns[ColDrop].elements;
    const filtereddropsection = dropsectionindex.filter(((c) => c.comp_key == selectedComponent));

    const updatedMiscValues = {
      ...filtereddropsection[0].misc,
      [prop]: e.target.checked,
    };

    filtereddropsection[0].misc = updatedMiscValues;
    const temparr = sectionArray;
    temparr.splice(SelectedSectionIndex, 1, dropSection[0]);
    setsectionArray(temparr);
    setsectionDrop(dropSection[0]);
    setSelectedContent(e.target.value);
  };

  const propValue = (prop) => {
    const dropSection = sectionDrop.columns[ColDrop].elements;
    const filterDropSection = dropSection.filter(((c) => c.comp_key == selectedComponent
    ));
    return filterDropSection[0][prop];
  };

  const propMiscChange = (prop, e) => {
    console.log('In Builder : ', prop, e)
    const dropSection = sectionArray.filter((s) => s.sec_key == sectionDrop.sec_key);
    console.log('Selected Comp : ',selectedComponent)
    const dropsectionindex = dropSection[0].columns[ColDrop].elements;
    const filtereddropsection = dropsectionindex.filter(((c) => c.comp_key == selectedComponent));

    const updatedMiscValues = {
      ...filtereddropsection[0].misc,
      [prop]: e.target.value,
    };

    filtereddropsection[0].misc = updatedMiscValues;
    const temparr = sectionArray;
    temparr.splice(SelectedSectionIndex, 1, dropSection[0]);
    setsectionArray(temparr);
    setsectionDrop(dropSection[0]);
    setSelectedContent(e.target.value);
  };

  const propMiscValue = (prop) => {
    const dropSection = sectionDrop.columns[ColDrop].elements;
    const filterDropSection = dropSection.filter(((c) => c.comp_key == selectedComponent
    ));
    return filterDropSection[0].misc[prop];
  };

  const styleChange = (newStyle, e) => {
    const dropSection = sectionArray.filter((s) => s.sec_key == sectionDrop.sec_key);

    const dropsectionindex = dropSection[0].columns[ColDrop].elements;
    const filtereddropsection = dropsectionindex.filter(((c) => c.comp_key == selectedComponent));

    const updatedStyles = {
      ...filtereddropsection[0].style,
      [newStyle]: e.target.value,
    };
    filtereddropsection[0].style = updatedStyles;

    const temparr = sectionArray;
    temparr.splice(SelectedSectionIndex, 1, dropSection[0]);
    setsectionArray(temparr);
    setsectionDrop(dropSection[0]);
    setSelectedContent(e.target.value);
  };

  const styleValue = (style) => {
    const dropSection = sectionDrop.columns[ColDrop].elements;
    const filterDropSection = dropSection.filter(((c) => c.comp_key == selectedComponent
    ));

    return filterDropSection[0].style[style];
  };

  const propChange = (prop, e) => {
    const dropSection = sectionArray.filter((s) => s.sec_key == sectionDrop.sec_key);

    dropSection[0].columns[ColDrop].elements.filter(((c) => c.comp_key == selectedComponent))[0][prop] = e.target.value;
    const temparr = sectionArray;
    temparr.splice(SelectedSectionIndex, 1, dropSection[0]);
    setsectionArray(temparr);
    setsectionDrop(dropSection[0]);
    setSelectedContent(e.target.value);
  };

  const resetStyles = (newDevice) => {
    const newMargin = {
      target: {
        value: {
          top: styleValue('marginTop')[newDevice],
          right: styleValue('marginRight')[newDevice],
          bottom: styleValue('marginBottom')[newDevice],
          left: styleValue('marginLeft')[newDevice],
        },
      },
    };
    const newPadding = {
      target: {
        value: {
          top: styleValue('paddingTop')[newDevice],
          right: styleValue('paddingRight')[newDevice],
          bottom: styleValue('paddingBottom')[newDevice],
          left: styleValue('paddingLeft')[newDevice],
        },
      },
    };
    const newBorderRadius = {
      target: {
        value: {
          topLeft: styleValue('borderTopLeftRadius')[newDevice],
          topRight: styleValue('borderTopRightRadius')[newDevice],
          bottomRight: styleValue('borderBottomRightRadius')[newDevice],
          bottomLeft: styleValue('borderBottomLeftRadius')[newDevice],
        },
      },
    };
    const newWidth = {
      target: {
        value: styleValue('width')[newDevice],
      },
    };
    const newTextAlign = {
      target: {
        value: styleValue('textAlign')[newDevice],
      },
    };

    styleChange('currentTextAlign', newTextAlign);
    styleChange('currentPadding', newPadding);
    styleChange('currentMargin', newMargin);
    styleChange('currentBorderRadius', newBorderRadius);
    styleChange('currentWidth', newWidth);
    styleChange('currentTextAlign', newTextAlign);
  };

  const handleImageSelect = (imageURL) => {
    // Handle image selection logic specific to Builder component
    console.log('Builder - Image selected:', imageURL);
    const e = {
      target: {
        value: imageURL,
      },
    };
    const dropSection = sectionArray.filter((s) => s.sec_key == sectionDrop.sec_key);
    const component = dropSection[0].columns[ColDrop].elements.filter(((c) => c.comp_key == selectedComponent))[0];

    if (component.tag === 'facebook') {
      if (Array.isArray(imageDestination)) {
        const [index, childIndex] = imageDestination;
        const updatedChildren = dropSection[0].columns[ColDrop]
          .filter(((c) => c.comp_key == selectedComponent))[0]
          .items[index].children.map((child, i) => {
            if (i === childIndex) {
              return {
                ...child,
                picture: imageURL,
              };
            }
            return child;
          });

        dropSection[0].columns[ColDrop]
          .elements
          .filter(((c) => c.comp_key == selectedComponent))[0]
          .items[index].children = updatedChildren;
      } else {
        dropSection[0].columns[ColDrop]
          .elements
          .filter(((c) => c.comp_key == selectedComponent))[0]
          .items[imageDestination].picture = imageURL;
        setImagePopup(false);
      }
    } else {
      const nullifyHref = {
        target: {
          value: '',
        },
      };
      propMiscChange('src', e);
      propMiscChange('href', nullifyHref);
    }

    setImagePopup(false);
  };

  return (
    <div>
      { isBuilderDataLoaded ? (
        <div style={{ position: 'relative', height: '100vh', overflowY: 'visible' }}>

          <BuilderHeader savedPage={sectionArray} pageName={page} currentId={id} setScale={setScale} scale={scale} responsiveMode={responsiveMode} setResponsiveMode={setResponsiveMode} width={width} setWidth={setWidth} height={height} setHeight={setHeight} breakPoints={breakPoints} setBreakPoints={setBreakPoints} responsiveData={responsiveData} setResponsiveData={setResponsiveData} resetStyles={resetStyles} setDevice={setDevice} />
          <div className="flex">
            <div style={{ display: 'flex' }}>

              {/* Builder Sidebar Start */}
              <BuilderSidebar ColDrop={ColDrop} selectedComponent={selectedComponent} sectionDrop={sectionDrop} sectionArray={sectionArray} sidebar_components={sidebar_components} setshowDrawer={setshowDrawer} setStiloShow={setStiloShow} SelectedComp={SelectedComp} setSelectedComp={setSelectedComp} />

              {/* Builder Sidebar End */}

              {/* Builder Drawer Start */}
              {!showDrawer
    && (
    <OpenCloseArrow
      setshowDrawer={setshowDrawer}
      showDrawer={showDrawer}
      style={{
        position: 'relative',
      }}
    />
    )}

              {showDrawer
        && <BuilderDrawer StiloShow={StiloShow} setselectedtab={setselectedtab} selectedtab={selectedtab} sectionDrop={sectionDrop} selectedComponent={selectedComponent} ColDrop={ColDrop} setSearch={setSearch} search={search} SelectedComp={SelectedComp} setshowDrawer={setshowDrawer} showDrawer={showDrawer} elementType={elementType} videoController={videoController} setImagePopup={setImagePopup} setIconPopup={setIconPopup} setIconDestination={setIconDestination} propValue={propValue} propChange={propChange} propMiscValue={propMiscValue} propMiscChange={propMiscChange} styleValue={styleValue} styleChange={styleChange} propMiscCheckedValue={propMiscCheckedValue} propMiscChecked={propMiscChecked} device={device} setDevice={setDevice} resetStyles={resetStyles} responsiveMode={responsiveMode} setResponsiveMode={setResponsiveMode} responsiveData={responsiveData} firstIndex={firstIndex} setFirstIndex={setFirstIndex} mvBtm={mvBtm} setMvBtm={setMvBtm} mvUp={mvUp} setMvUp={setMvUp} />}
              {/* Builder Drawer End */}
            </div>
            <DropContainer showDrawer={showDrawer} sectionArray={sectionArray} layoutEle={layoutEle} setLayoutEle={setLayoutEle} layoutViewEle={layoutViewEle} setLayoutViewEle={setLayoutViewEle} setsectionArray={setsectionArray} setShowBlueBorder={setShowBlueBorder} ShowBlueBorder={ShowBlueBorder} setDragOver={setDragOver} setLayoutCreator={setLayoutCreator} setselectedComponent={setselectedComponent} setsectionDrop={setsectionDrop} setSelectedSectionIndex={setSelectedSectionIndex} setSelectedColumnIndex={setSelectedColumnIndex} setComponentArray={setComponentArray} setStiloShow={setStiloShow} setSelectedContent={setSelectedContent} setSelectedStyle={setSelectedStyle} setColDrop={setColDrop} ComponentArray={ComponentArray} DragOver={DragOver} LayoutCreator={LayoutCreator} LayoutView={LayoutView} selectedComponent={selectedComponent} setshowDrawer={setshowDrawer} setLayoutView={setLayoutView} elementType={elementType} setElementType={setElementType} setVideoController={setVideoController} setIconPopup={setIconPopup} setImagePopup={setImagePopup} setImageDestination={setImageDestination} scale={scale} responsiveMode={responsiveMode} width={width} setWidth={setWidth} height={height} setHeight={setHeight} setResponsiveMode={setResponsiveMode} responsiveData={responsiveData} sectionDrop={sectionDrop} draggedKey={draggedKey} setDraggedKey={setDraggedKey} formerIndex={formerIndex} setFormerIndex={setFormerIndex} currentDrag={currentDrag} setcurrentDrag={setcurrentDrag} firstIndex={firstIndex} setFirstIndex={setFirstIndex} mvBtm={mvBtm} setMvBtm={setMvBtm} mvUp={mvUp} setMvUp={setMvUp} />
          </div>
          <style>
            {`
    #slide {
    position: relative;
    left: -16.5rem;
    width: 16.5rem;
    z-index: -1;
    transition: 1s;
    -webkit-animation: ${!showDrawer ? 'slideright 0.3s' : 'slideleft 0.3s forwards'};
    animation: ${!showDrawer ? 'slideright 0.3s' : 'slideleft 0.3s forwards'};
}

@-webkit-keyframes slideleft {
    90%{
        z-index: -1;
    }
    100% { left: 0rem; 
        z-index: 1;
    
    }
}

@keyframes slideleft {
    90%{
        z-index: -1;
    }
    100% { left: 0rem; 
        z-index: 1;
    
    }
}
@-webkit-keyframes slideright {
    100% { 
        left: -16.5rem; 
        z-index: -1;
    }
}

@keyframes slideright {
    100% { left: -16.5rem; 
        z-index: -1;
    }
}
    `}

          </style>
        </div>
      ) : <LoadingScreen />}
      {imagePopup && <ImagePopup sectionArray={sectionArray} sectionDrop={sectionDrop} ColDrop={ColDrop} selectedComponent={selectedComponent} SelectedSectionIndex={SelectedSectionIndex} setsectionArray={setsectionArray} setsectionDrop={setsectionDrop} setSelectedContent={setSelectedContent} setImagePopup={setImagePopup} onButtonClick={handleImageSelect} />}
      {iconPopup && <IconPopup sectionArray={sectionArray} sectionDrop={sectionDrop} ColDrop={ColDrop} selectedComponent={selectedComponent} SelectedSectionIndex={SelectedSectionIndex} setsectionArray={setsectionArray} setsectionDrop={setsectionDrop} setSelectedContent={setSelectedContent} setIconPopup={setIconPopup} iconDestination={iconDestination} />}
    </div>
  );
};

export default Builder;
