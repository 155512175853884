import React, { useRef } from 'react';

import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsSliders } from 'react-icons/bs';
import {
  dividerElementOptions,
  tagsOptions,
  fontSizeOptions,
  targetOptions,
  alignOptions,
  nonDigitStyles,
  borderStyles,
  responsiveIcons,
} from '../../../../helpers/Builder/helperArrays';
import { CustomButton } from '../../../customButton';
import DropDown from './DropDown';

const DividerEdit = ({
  setselectedtab, setIconPopup, setIconDestination, propMiscValue, propMiscChange, styleValue, styleChange, device, setDevice, setResponsiveMode, responsiveData, responsiveMode,
}) => {
  const tituloRef = useRef(null);
  const tipografia = useRef(null);
  const bordaRef = useRef(null);

  const changeObjectStyle = (style, position, e) => {
    if (style.includes('.')) {
      const styleArray = style.split('.');
      const styleObject = styleArray[0];
      const stylePosition = styleArray[1];
      const newStyleObject = {
        target: {
          value: {
            ...styleValue(styleObject),
            [stylePosition]: {
              ...styleValue(styleObject)[stylePosition],
              [position]: e.target.value,
            },
          },
        },
      };
      styleChange(styleObject, newStyleObject);
      return;
    }

    const positionValue = e.target.value;
    const newStyleObject = {
      target: {
        value: {
          ...styleValue(style),
          [position]: positionValue,
        },
      },
    };
    styleChange(style, newStyleObject);
  };

  const withElement = propMiscValue('withElement');

  return (
    <div>
      <div style={{ padding: '10px 10px', margin: '10px 0' }}>
        <span
          style={{
            color: '#72728F',
            fontSize: '13px',
            display: 'inline-block',
            width: '90%',
          }}
          className="cursor-pointer"
          onClick={(e) => {
            const { nextSibling } = e.target;
            if (nextSibling.style.transform == 'rotate(180deg)') {
              nextSibling.style.transform = 'rotate(0deg)';
            } else {
              nextSibling.style.transform = 'rotate(180deg)';
            }

            e.target.parentNode.children[2].classList.toggle('hidden');
          }}
          ref={tituloRef}
        >
          Titulo:
          {' '}
        </span>
        <MdKeyboardArrowDown
          color="#72728F"
          style={{
            display: 'inline',
            cursor: 'pointer',
            transform: 'rotate(0deg)',
            transition: 'transform 0.2s ease-in-out',
          }}
          className="cursor-pointer"
          onClick={(e) => {
            tituloRef.current.click();
          }}
        />
        <div className="pt-1 pb-6" style={{ borderBottom: '2px solid #ddd' }}>
          {withElement === 'text' ? (
            <div className="py-1">
              <span style={{ color: '#72728F', fontSize: '13px' }}>
                Campo de texto
              </span>
              <textarea
                onChange={(e) => {
                  propMiscChange('text', e);
                }}
                rows="1"
                value={propMiscValue('text')}
                style={{
                  outline: 0,
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  width: '100%',
                  fontSize: '12px',
                  color: '#72728F',
                  paddingLeft: '5px',
                }}
              />
            </div>
          ) : withElement === 'icon' ? (
            <div
              className="flex flex-col justify-between items-center mt-3 gap-2"
              style={{ cursor: 'pointer', position: 'relative' }}
            >
              <i style={{ color: 'black' }} className={propMiscValue('icon')} alt="cloud" />
              {/* <span style={{color:"#72728F",fontSize:"13px",}}>Solte o arquivo ou <span id='addImage'>clique aqui</span></span> */}
              <span
                style={{
                  background: 'black',
                  color: 'white',
                  width: '100%',
                  textAlign: 'center',
                }}
                onClick={(e) => {
                  setIconPopup(true);
                  setIconDestination(e.target.innerText);
                }}
              >
                Choose Icon
              </span>
            </div>
          ) : null}
          <div className="py-1">
            <span
              className="flex justify-between center-align"
              style={{ color: '#72728F', fontSize: '13px' }}
            >
              <label htmlFor="textType" style={{ color: '#72728F', fontSize: '13px' }}>Tipo</label>
              <select
                style={{
                  fontSize: '13px',
                  border: '1px solid #ddd',
                  textAlign: 'center',
                  outline: 0,
                  color: '#72728F',
                }}
                className="px-2"
                name="textType"
                id="textType"
                onChange={(e) => {
                  propMiscChange('withElement', e);
                }}
              >
                {dividerElementOptions.map((ele) => (
                  <option
                    value={ele}
                    selected={propMiscValue('withElement') === ele}
                  >
                    {ele}
                  </option>
                ))}
              </select>
            </span>
          </div>
        </div>

        <div className="py-4 ">
          <span
            style={{
              color: '#72728F',
              fontSize: '13px',
              cursor: 'pointer',
              display: 'inline-block',
              width: '90%',
            }}
            onClick={(e) => {
              const { nextSibling } = e.target;
              if (nextSibling.style.transform == 'rotate(180deg)') {
                nextSibling.style.transform = 'rotate(0deg)';
              } else {
                nextSibling.style.transform = 'rotate(180deg)';
              }

              e.target.parentNode.children[2].classList.toggle('hidden');
            }}
            ref={tipografia}
          >
            Tipografia
          </span>
          <MdKeyboardArrowDown
            color="#72728F"
            style={{
              display: 'inline',
              cursor: 'pointer',
              transform: 'rotate(0deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
            className="cursor-pointer"
            onClick={(e) => {
              tipografia.current.click();
            }}
          />
          <div style={{ transition: 'all 0.2s ease-in-out' }}>
            <div className="py-1">
              <span
                className="flex justify-between center-align"
                style={{ color: '#72728F', fontSize: '13px' }}
              >
                <label htmlFor="textType" style={{ color: '#72728F', fontSize: '13px' }}>Alinhamento</label>
                <select
                  style={{
                    fontSize: '13px',
                    border: '1px solid #ddd',
                    textAlign: 'center',
                    outline: 0,
                    color: '#72728F',
                    width: '5rem',
                  }}
                  className="px-2"
                  name="textType"
                  id="textType"
                  onChange={(e) => {
                    changeObjectStyle('textAlign', responsiveMode.mode, e);
                  }}
                >
                  {alignOptions.map((align) => (
                    <option
                      value={align}
                      selected={styleValue('textAlign')[responsiveMode.mode] === align}
                    >
                      {align}
                    </option>
                  ))}
                </select>
              </span>
              <label htmlFor="width" className="flex relative text-[#72728F] text-[13px]">
                Width
                <DropDown responsiveData={responsiveData} responsiveMode={responsiveMode} setDevice={setDevice} setResponsiveMode={setResponsiveMode} position="30%" />
              </label>
              <div className="flex items-center">
                <span
                  className="flex justify-between w-[100%] center-align"
                  style={{ color: '#72728F', fontSize: '13px' }}
                >
                  <input
                    min="0"
                    max="100"
                    id="width"
                    type="range"
                    style={{ flex: 10, marginRight: '5px', marginTop: '5px' }}
                    onChange={(e) => {
                      changeObjectStyle('lineWidth', responsiveMode.mode, e);
                    }}
                    defaultValue={0}
                    value={styleValue('lineWidth')[responsiveMode.mode]}
                  />
                </span>
                <input
                  style={{
                    border: '1px solid #ddd',
                    textAlign: 'center',
                    color: '#72728F',
                    fontSize: '13px',
                  }}
                  className="w-7 h-7"
                  onChange={(e) => {
                    changeObjectStyle('lineWidth', responsiveMode, e);
                  }}
                  defaultValue={0}
                  value={styleValue('lineWidth')[responsiveMode.mode]}
                />
              </div>
            </div>
            {withElement === 'none' ? null : (
              <div>
                <div className="py-1">
                  <span
                    className="flex justify-between center-align"
                    style={{
                      color: '#72728F',
                      fontSize: '13px',
                      textTransform: 'capitalize',
                    }}
                  >
                    <label htmlFor="borderStyle">Tamanho</label>
                    <select
                      style={{
                        fontSize: '13px',
                        border: '1px solid #ddd',
                        textAlign: 'center',
                        outline: 0,
                        color: '#72728F',
                      }}
                      className="px-2"
                      name="borderStyle"
                      id="borderStyle"
                      onChange={(e) => {
                        changeObjectStyle('elementStyles', 'fontSize', e);
                      }}
                    >
                      {fontSizeOptions.map((style) => (
                        <option
                          value={style}
                          selected={
                            styleValue('elementStyles').fontSize === style
                          }
                        >
                          {style}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
                <div className="py-1 flex items-center justify-between">
                  <span style={{ color: '#72728F', fontSize: '13px' }}>
                    Element Cor
                  </span>
                  <input
                    type="color"
                    className="w-7 h-7"
                    onChange={(e) => {
                      changeObjectStyle('elementStyles', 'color', e);
                    }}
                    value={styleValue('elementStyles').color}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="" style={{ borderBottom: '2px solid #ddd' }}>
          <span
            style={{
              color: '#72728F',
              fontSize: '13px',
              display: 'inline-block',
              width: '90%',
            }}
            className="cursor-pointer"
            onClick={(e) => {
              const { nextSibling } = e.target;
              if (nextSibling.style.transform == 'rotate(180deg)') {
                nextSibling.style.transform = 'rotate(0deg)';
              } else {
                nextSibling.style.transform = 'rotate(180deg)';
              }

              e.target.parentNode.children[2].classList.toggle('hidden');
            }}
            ref={bordaRef}
          >
            Borda:
            {' '}
          </span>
          <MdKeyboardArrowDown
            color="#72728F"
            style={{
              display: 'inline',
              cursor: 'pointer',
              transform: 'rotate(0deg)',
              transition: 'tranform 0.2s ease-in-out',
            }}
            onClick={(e) => {
              bordaRef.current.click();
            }}
          />
          <div>
            <div className="py-1">
              <span
                className="flex justify-between center-align"
                style={{
                  color: '#72728F',
                  fontSize: '13px',
                  textTransform: 'capitalize',
                }}
              >
                <label htmlFor="borderStyle" style={{ color: '#72728F', fontSize: '13px' }}>Borda</label>
                <select
                  style={{
                    fontSize: '13px',
                    border: '1px solid #ddd',
                    textAlign: 'center',
                    outline: 0,
                    color: '#72728F',
                  }}
                  className="px-2"
                  name="borderStyle"
                  id="borderStyle"
                  onChange={(e) => {
                    changeObjectStyle('lineStyles.border', 'bottomStyle', e);
                  }}
                >
                  {borderStyles.map((style) => (
                    <option
                      value={style}
                      selected={
                        styleValue('lineStyles').border.bottomStyle === style
                      }
                    >
                      {style}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <div className="flex justify-between mt-3">
              <div className="flex flex-col" style={{ width: '100%' }}>
                <div className="py-1 flex items-center justify-between">
                  <span style={{ color: '#72728F', fontSize: '13px' }}>
                    Cor
                  </span>
                  <input
                    type="color"
                    className="w-7 h-7"
                    onChange={(e) => {
                      changeObjectStyle('lineStyles.border', 'bottomColor', e);
                    }}
                    value={styleValue('lineStyles').border.bottomColor}
                  />
                </div>
                <span
                  className="my-1"
                  style={{ color: '#72728F', fontSize: '13px' }}
                >
                  Largura da borda
                </span>
                <div className="flex my-1 items-center">
                  <input
                    min="0"
                    max="10"
                    type="range"
                    style={{ flex: 10, marginRight: '5px' }}
                    onChange={(e) => {
                      changeObjectStyle('lineStyles.border', 'bottomWidth', e);
                    }}
                    defaultValue={0}
                    value={styleValue('lineStyles').border.bottomWidth}
                  />
                  <input
                    style={{
                      border: '1px solid #ddd',
                      textAlign: 'center',
                      color: '#72728F',
                      fontSize: '13px',
                    }}
                    className="w-7 h-7"
                    onChange={(e) => {
                      changeObjectStyle('lineStyles.border', 'bottomWidth', e);
                    }}
                    value={styleValue('lineStyles').border.bottomWidth}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: '10px 0' }}>
          <CustomButton
            loading={false}
            disabled={false}
            textColor="#fff"
            style={{ textAlign: 'center' }}
            Icon={BsSliders}
            buttontext="Editar tipografia"
            backgroundColor="#62627A"
            variant="contained"
            onClick={() => {
              setselectedtab('slider');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DividerEdit;
