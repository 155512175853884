import React, { useState, useEffect } from 'react';
import '../../../styles/builder/BuilderHeader.css';
import { MdChevronLeft, MdOutlineCheckCircleOutline, MdOutlineRemoveRedEye } from 'react-icons/md';
import { FiSave } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { addDocument, updateDocument } from '../../../service/useFirestore';
import { auth } from '../../../config/firebase';
import reload_invertIcon from '../../../assets/icons/builder/reload_invertIcon.svg';
import CustomButton from '../../customButton/CustomButton';
import { responsiveIcons } from '../../../helpers/Builder/helperArrays';
import save from '../../../assets/icons/builder/save.svg';

const BuilderHeader = ({
  savedPage, pageName, currentId, setScale, scale, responsiveMode, setResponsiveMode, width, setWidth, setHeight, breakPoints, setBreakPoints, responsiveData, setResponsiveData, setDevice,
}) => {
  const { t } = useTranslation();
  const [saveNotify, setSaveNotify] = useState(false);
  const [page, setPage] = useState('');
  const [id, setId] = useState(currentId);
  const ownerId = auth.currentUser.uid;
  const data = {
    ownerId,
    page,
    sections: savedPage,
  };

  const mobileData = responsiveData.filter((item) => item.mode === 'mobile')[0];
  const tabletData = responsiveData.filter((item) => item.mode === 'tablet')[0];

  useEffect(() => {
    setPage(pageName);
    setId(currentId);
  }, [pageName, currentId]);

  const getCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();

    const amOrPm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hours}:${minutes} ${amOrPm}`;
  };

  const currentTime = getCurrentTime();

  let timer;

  return (
    <div className="header">
      <div className="leftBox">
        <div className="leftIcon"><MdChevronLeft color="#fff" size={22} /></div>
        <input
          placeholder="Untitled"
          value={page}

          style={{
            borderBottom: '1px solid #72728F', outline: 0, background: '#31313D', color: 'white', paddingLeft: '4px',
          }}
          onChange={(e) => {
            setPage(e.target.value);
          }}
        />
        <div className="flex gap-2">
          <div className="flex items-center gap-1">
            <label htmlFor="mobileBreakpoint" style={{ fontSize: '11px' }}>Mobile BP</label>
            <input
              type="number"
              id="mobileBreakpoint"
              style={{
                width: '2.5rem', color: 'black', padding: '2px', fontSize: '11px', borderRadius: '8px',
              }}
              placeholder={767}
              max={767}
              min={320}
              onChange={(e) => {
                clearTimeout(timer); // Clear the previous timer if it exists
                const value = parseInt(e.target.value); // Parse the input value to an integer
                timer = setTimeout(() => {
                  const value = parseInt(e.target.value); // Parse the input value to an integer
                  if (value < 320 || value > 767) {
                    alert('Value must be between 320 and 767');
                  } else {
                    const newResponsiveData = responsiveData.map((item) => {
                      if (item.mode === 'mobile') {
                        return {
                          ...item,
                          max: value,
                        };
                      }
                      return item;
                    });
                    console.log(newResponsiveData);
                    setResponsiveData(newResponsiveData);
                  }
                }, 1300);
              }}
            />
          </div>
          <div className="flex items-center gap-1">
            <label htmlFor="tabletBreakpoint" style={{ fontSize: '11px' }}>Tablet BP</label>
            <input
              type="number"
              id="tabletBreakpoint"
              style={{
                width: '2.7rem', color: 'black', padding: '2px', fontSize: '11px', borderRadius: '8px',
              }}
              placeholder={1024}
              max={1024}
              min={768}
              onChange={(e) => {
                clearTimeout(timer); // Clear the previous timer if it exists
                const value = parseInt(e.target.value); // Parse the input value to an integer
                console.log(value);
                timer = setTimeout(() => {
                  const value = parseInt(e.target.value); // Parse the input value to an integer

                  if (value < 768 || value > 1024) {
                    alert('Value must be between 768 and 1024');
                  } else {
                    const newResponsiveData = responsiveData.map((item) => {
                      if (item.mode === 'tablet') {
                        return {
                          ...item,
                          max: value,
                        };
                      }
                      return item;
                    });
                    setResponsiveData(newResponsiveData);
                  }
                }, 1300);
              }}
            />
          </div>
        </div>
      </div>
      <div className="middleBox">
        <div className="resolution">
          <div className="icons">
            {
              responsiveData.map((item, index) => (
                <div
                  className={`h-7 w-7 cursor-pointer flex items-center justify-center rounded ${responsiveMode.mode === item.mode ? 'bg-[grey]' : ''}`}
                  onClick={() => {
                    setResponsiveMode(item);
                    setWidth(item.width);
                    setDevice(item.mode);
                  }}
                >
                  <img src={item.icon} className="w-6 h-6" key={index} />
                </div>
              ))
           }
          </div>
          <div className="resolutionRange">
            <span>
              {responsiveMode.width}
              {' '}
              <span className="textColor">px</span>
            </span>
            <span>
              <i
                className="fa-solid fa-plus cursor-pointer"
                onClick={() => {
                  scale < 2 && setScale(scale + 0.1);
                }}
              />
              {' '}
              <span className="textColor">%</span>
              {Math.round(scale * 100)}
              <span className="text-[#72728f]">%</span>
              {' '}
              <i
                className="fa-solid fa-minus cursor-pointer"
                onClick={() => {
                  scale > 0.6 && setScale(scale - 0.1);
                }}
              />
            </span>
          </div>

        </div>
      </div>
      <div className="rightBox">
        <div className="reload">
          <img color="white" src={reload_invertIcon} style={{ filter: savedPage?.length ? 'brightness(0) invert(1)' : '', cursor: 'pointer' }} />
          <img color="white" src={reload_invertIcon} style={{ transform: 'scaleX(-1)', cursor: 'pointer' }} />
        </div>

        <div className="checkCircle">
          <div
            className="currentTime"
            style={{ visibility: saveNotify ? 'visible' : 'hidden' }}
          >
            <MdOutlineCheckCircleOutline color="#11D167" />
            <span className="text-[#E1E2EB] ml-1">
              {saveNotify && `Salvo às ${currentTime}`}
            </span>
          </div>
          <div className="Buttons">
            <CustomButton loading={false} variant="outlined" disabled={false} Icon={MdOutlineRemoveRedEye} buttontext={t('visualiser')} textColor="#E1E2EB" />
            {/* <CustomButton
              loading={false}
              disabled={false}
              Icon={FiSave}
              buttontext="Salvar"
              variant="contained"

              onClick={() => {
                if (!page.length) {
                  alert('Please enter a filename');
                } else if (id) {

                  updateDocument('pagesnew', id, data);
                } else {
                  addDocument('pagesnew', data);
                }
              }}
            /> */}
            <div
              onClick={() => {
                if (!page.length) {
                  alert('Please enter a filename');
                } else if (id) {
                  updateDocument('pagesnew', id, data);
                  console.log('Documen updated successfully with', id);
                  setSaveNotify(true);
                } else {
                  addDocument('pagesnew', data).then((res) => {
                    setId(res);
                    setSaveNotify(true);
                  });
                }
              }}
              className="flex items-center justify-center cursor-pointer gap-2 bg-[#FFFFFF] rounded-md px-3 py-1 "
            >
              <img src={save} />
              <span className="text-[#000000]">{t('Save')}</span>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};

export default BuilderHeader;
