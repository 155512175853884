import React from 'react';
import '../../../styles/builder/BuilderSidebar.css';
import { useTranslation } from 'react-i18next';
import rocketIcon from '../../../assets/icons/former/RocketIcon.svg';
import files from '../../../assets/icons/builder/files.svg';
import settings from '../../../assets/icons/builder/settings.svg';
import i18n from '../../../translations/i18n';

const BuilderSidebar = ({
  sidebar_components,
  setStiloShow,
  setshowDrawer,
  setSelectedComp,
  SelectedComp,
}) => {
  const { t } = useTranslation();

  return (
    <div className="sideBar">
      <div className="logo">
        <span>
          LO
          <br />
          GO
        </span>
      </div>
      {sidebar_components.map((comp) => (
        <div
          onClick={() => {
            setshowDrawer(true);
            setStiloShow(false);
            setSelectedComp(comp);
          }}
          className="icons"
          style={{
            background:
              comp.value == SelectedComp.value ? '#727285' : '#414152',
          }}
        >
          <img src={comp.icon} className="icon" />
          <span>{t(comp.name)}</span>
        </div>
      ))}

      <div className="publishButton">
        <img src={rocketIcon} className="icon" />
        <span>{t('publish')}</span>
      </div>
      <div className="settingsContainer">
        <img src={files} alt="filesIcon" className="settingsIcon" />
        <img src={settings} alt="settingsIcon" className="settingsIcon" />
      </div>
      <br />
      <div>
        <select
          className=""
          placeholder="en"
          onChange={(e) => {
            if (e.target.value == 'en') {
              i18n.changeLanguage('en');
            } else if (e.target.value == 'pt') {
              i18n.changeLanguage('pt');
            } else if (e.target.value == 'es') {
              i18n.changeLanguage('es');
            }
          }}
        >
          <option defaultChecked value="en">
            en
          </option>
          <option value="pt">pt</option>
          <option value="es">es</option>
        </select>
      </div>
    </div>
  );
};

export default BuilderSidebar;
