import axios from 'axios';
import { BASEURL, FIREBASEURL } from '../utility/constants';

// const url='http://localhost:3003';
const url = FIREBASEURL;

export const signup = async (user) => {
  try {
    console.log(url);
    return await axios.post(`${url}/auth/signup`, user);
  } catch (error) {
    console.log('Error while calling SignUp API', error);
  }
};

export const login = async (user) => {
  try {
    return await axios.post(`${BASEURL}auth/signin`, user);
  } catch (error) {
    console.log('Error while calling Login API', error);
  }
};

export const emailVerification = async (user) => {
  try {
    return await axios.post(`${url}/auth/email`, user);
  } catch (error) {
    console.log('Error while calling resend email verification api', error);
  }
};

export const passwordReset = async (user) => {
  try {
    console.log(user);
    return await axios.post(`${url}/auth/password/reset`, user);
  } catch (error) {
    console.log('Error while calling password reset api', error);
  }
};
