import React, { useContext, useEffect, useState } from "react";
import "../../styles/sideBarHome/SideBarHome.css";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { LoginContext } from "../../contexts/ContextProvider.js";

import { auth, db } from "../../config/firebase.js";
import "../../styles/global.css";

import logo from "../../assets/images/sideBarHome/Vector.svg";
import logoM from "../../assets/images/sideBarHome/LogoM.svg";
import Home from "../../assets/icons/sideBarHome/Home.svg";
import NovaPagina from "../../assets/icons/sideBarHome/NovaPagina.svg";
import Sites from "../../assets/icons/sideBarHome/Sites.svg";
import AtomicPlayer from "../../assets/icons/sideBarHome/AtomicPlayer.svg";
import GerenciarPlanos from "../../assets/icons/sideBarHome/GerenciarPlanos.svg";
import amigos from "../../assets/icons/sideBarHome/Amigos.svg";
import Ajuda from "../../assets/icons/menuVideos/Ajuda.svg";
import language from "../../assets/icons/former/language.svg";
import Sair from "../../assets/icons/sideBarHome/Sair.svg";
import { useTranslation } from "react-i18next";
import { StyleContext } from "../../contexts/StyleProvider";
import { doc, updateDoc } from "firebase/firestore";

export const SideBarHome = () => {
  const { setUser } = useContext(LoginContext);
  const user = useContext(LoginContext);
  const { expanded, setExpanded } = useContext(StyleContext);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(() => {
    const savedState = localStorage.getItem("myState");
    return savedState ? JSON.parse(savedState) : "home";
  });

  function handleLogOut() {
    console.log("Logging out...");
    localStorage.removeItem("myState");
    signOut(auth);
    setUser(null);
    navigate("/");
  }
  const handleToggleSidebar = () => {
    setExpanded(!expanded);
  };

  const handleMouseEnter = () => {
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    setExpanded(false);
  };

  const handleLanguageChange = async (selectedLang) => {
    await updateDoc(doc(db, "users", user.user.uid), {
      selectedLang,
    });
    localStorage.setItem("selectedLang", selectedLang);
    console.log("changed lang", selectedLang);
  };

  useEffect(() => {
    const selectedLang = localStorage.getItem("selectedLang");
    if (selectedLang) {
      i18n.changeLanguage(selectedLang);
    }
  }, []);

  return (
    <div
      className={expanded ? "sideBarContainer" : "sideBarContainerClosed"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="Frame1">
        {expanded ? (
          <div className="LogoContainer" onClick={handleToggleSidebar}>
            <img src={logo} alt="Atomicat Logo" className="atomicatLogo" />
            <img src={logoM} alt="LogoM" className="MfromLogo" />
          </div>
        ) : (
          <div className="LogoContainer" onClick={handleToggleSidebar}>
            <div className="LogoBorder">
              <img src={logoM} alt="LogoM" className="MfromLogoClosed" />
            </div>
          </div>
        )}
        <div className="RedirectsIcon">
          <div
            style={
              selected == "home"
                ? { borderLeft: "4px solid #60F0D3", padding: "0 24px" }
                : { borderLeft: "none" }
            }
            className="EachRedirectcomponent"
          >
            <div>
              <img
                src={Home}
                alt="Home.svg"
                onClick={() => {
                  navigate("/");
                  localStorage.setItem("myState", JSON.stringify("home"));
                }}
              />
            </div>
            {expanded ? (
              <p
                className="textforIcon"
                onClick={() => {
                  navigate("/");
                  localStorage.setItem("myState", JSON.stringify("home"));
                }}
              >
                Home
              </p>
            ) : (
              ""
            )}
          </div>
          <div
            style={
              selected == "new"
                ? { borderLeft: "4px solid #60F0D3", padding: "0 24px" }
                : { borderLeft: "none" }
            }
            className="EachRedirectcomponent"
          >
            <div>
              <img
                src={NovaPagina}
                alt="NovaPagina.svg"
                onClick={() => {
                  navigate("/new");
                  localStorage.setItem("myState", JSON.stringify("new"));
                }}
              />
            </div>
            {expanded ? (
              <p
                className="textforIcon"
                onClick={() => {
                  navigate("/new");
                  localStorage.setItem("myState", JSON.stringify("new"));
                }}
              >
                {t("newPage")}
              </p>
            ) : (
              ""
            )}
          </div>
          <div
            style={
              selected == "sites"
                ? { borderLeft: "4px solid #60F0D3", padding: "0 24px" }
                : { borderLeft: "none" }
            }
            className="EachRedirectcomponent"
          >
            <div>
              <img
                src={Sites}
                alt="Sites.svg"
                onClick={() => {
                  navigate("/sites");
                  localStorage.setItem("myState", JSON.stringify("sites"));
                }}
              />
            </div>
            {expanded ? (
              <p
                className="textforIcon"
                onClick={() => {
                  navigate("/sites");
                  localStorage.setItem("myState", JSON.stringify("sites"));
                }}
              >
                Sites
              </p>
            ) : (
              ""
            )}
          </div>
          <div
            style={
              selected === "videos"
                ? { borderLeft: "4px solid #60F0D3", padding: "0 24px" }
                : { borderLeft: "none" }
            }
            className="EachRedirectcomponent"
          >
            <div>
              <img
                onClick={() => {
                  navigate("/atomicPlayer");
                  localStorage.setItem("myState", JSON.stringify("videos"));
                }}
                src={AtomicPlayer}
                alt="AtomicPlayer.svg"
              />
            </div>
            {expanded ? (
              <p
                onClick={() => {
                  navigate("/atomicPlayer");
                  localStorage.setItem("myState", JSON.stringify("videos"));
                }}
                className="textforIcon"
              >
                AtomicPlayer
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="EachRedirectcomponent">
            <div>
              <img src={GerenciarPlanos} alt="GerenciarPlanos.svg" />
            </div>
            {expanded ? <p className="textforIcon">{t("managePlans")}</p> : ""}
          </div>
          <div className="EachRedirectcomponent">
            <div>
              <img src={amigos} alt="amigos.svg" />
            </div>
            {expanded ? <p className="textforIcon">{t("friends")}</p> : ""}
          </div>
        </div>
      </div>
      <div className="Frame2">
        <div className="EachRedirectcomponent">
          <div>
            <img src={Ajuda} alt="Ajuda.svg" />
          </div>
          {expanded ? (
            <p
              onClick={() => {
                navigate("/tutorial");
                localStorage.setItem("myState", JSON.stringify("new"));
              }}
              className="textforIcon"
            >
              {t("help")}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="EachRedirectcomponent">
          <div>
            <img src={language} alt="language.svg" />
          </div>
          {expanded ? (
            <div>
              <select
                className="selectDropDown"
                placeholder="Choose Language"
                onChange={(e) => {
                  if (e.target.value == "en") {
                    i18n.changeLanguage("en"), handleLanguageChange("en");
                  } else if (e.target.value == "pt") {
                    i18n.changeLanguage("pt"), handleLanguageChange("pt");
                  } else if (e.target.value == "es") {
                    i18n.changeLanguage("es"), handleLanguageChange("es");
                  }
                }}
              >
                <option value="en" defaultChecked hidden>
                  Lang(en)
                </option>
                <option value="en">Lang(en)</option>
                <option value="pt">Lang(pt)</option>
                <option value="es">Lang(es)</option>
              </select>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="EachRedirectcomponent" onClick={() => handleLogOut()}>
          <div>
            <img src={Sair} alt="Sair.svg" />
          </div>
          {expanded ? <p className="textforIcon">{t("logOut")}</p> : ""}
        </div>
      </div>
    </div>
  );
};