import React, { useState, useRef } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsSliders } from 'react-icons/bs';
import {
  alignOptions,
  timeUnits,
  responsiveIcons,
} from '../../../../helpers/Builder/helperArrays';
import { CustomButton } from '../../../customButton';
import DropDown from './DropDown';

const FacebookEdit = ({
  setselectedtab, propValue, propChange, styleValue, styleChange, setDevice, setResponsiveMode, responsiveData, responsiveMode,
}) => {
  const [currentItem, setCurrentItem] = useState(null);
  const [currentChild, setCurrentChild] = useState([null, null]);
  const changeObjectStyle = (style, position, e) => {
    const positionValue = e.target.value;
    const newStyleObject = {
      target: {
        value: {
          ...styleValue(style),
          [position]: positionValue,
        },
      },
    };
    styleChange(style, newStyleObject);
  };

  const items = propValue('items').map((item) => ({ ...item }));

  const tituloRef = useRef(null);
  const tipografia = useRef(null);

  return (
    <div className="edit" style={{ padding: '10px 10px', margin: '10px 0', color: '#72728F' }}>
      <span
        style={{
          color: '#72728F', fontSize: '13px', display: 'inline-block', width: '90%',
        }}
        className="cursor-pointer"
        onClick={(e) => {
          const { nextSibling } = e.target;
          if (nextSibling.style.transform == 'rotate(180deg)') {
            nextSibling.style.transform = 'rotate(0deg)';
          } else {
            nextSibling.style.transform = 'rotate(180deg)';
          }

          e.target.parentNode.children[2].classList.toggle('hidden');
        }}
        ref={tituloRef}
      >
        Facebook Info:
        {' '}
      </span>
      <MdKeyboardArrowDown
        color="#72728F"
        style={{
          display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'transform 0.2s ease-in-out',
        }}
        className="cursor-pointer"
        onClick={(e) => {
          tituloRef.current.click();
        }}
      />
      <div className="pt-1 pb-6" style={{ borderBottom: '2px solid #ddd' }}>

        <div className="py-1">
          <div>
            <div className="flex justify-between items-center">
              <h4 style={{ color: '#72728F', fontSize: '13px' }}>Comment List</h4>
              <button
                onClick={() => {
                  const newItems = [...items];
                  newItems.push({
                    picture: 'https://brsc.sa.edu.au/wp-content/uploads/2018/09/placeholder-profile-sq.jpg',
                    username: 'Parent Username',
                    comment: 'Comentário',
                    children: [
                      {
                        picture: 'https://brsc.sa.edu.au/wp-content/uploads/2018/09/placeholder-profile-sq.jpg',
                        username: 'Child',
                        comment: 'Comentário Child',
                        likes: 1,
                        time: 1,
                        timeUnit: 'h',
                      },
                    ],
                  });
                  const change = { target: { value: newItems } };
                  propChange('items', change);
                }}
              >
                <i className="fas fa-plus" />
              </button>
            </div>

            {
                    items.map((item, index) => (
                      <div key={index} className="flex flex-col justify-between items-center mt-3">
                        <div className="flex items-center mb-1">
                          <MdKeyboardArrowDown
                            color="#72728F"
                            style={{
                              display: 'inline', cursor: 'pointer', transform: item.showChild ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.2s ease-in-out',
                            }}
                            className="cursor-pointer"
                            onClick={() => {
                              const newItems = [...items];
                              newItems[index].showChild = !newItems[index].showChild;
                              const change = { target: { value: newItems } };
                              propChange('items', change);
                            }}
                            size={30}
                          />
                          <button
                            onClick={() => {
                              const children = item.children.map((child) => ({ ...child }));
                              const newItems = [...items];
                              newItems.splice(index, 0, { ...item, children });
                              const change = { target: { value: newItems } };
                              propChange('items', change);
                            }}
                          >
                            <i className="fas fa-clone" style={{ color: '#72728F' }} />
                          </button>
                          <input
                            value={propValue('items')[index].username}
                            onChange={(e) => {
                              const newItems = [...items];
                              newItems[index].username = e.target.value;
                              const change = { target: { value: newItems } };
                              propChange('items', change);
                            }}
                            onClick={() => {
                              currentItem === index ? setCurrentItem(null) : setCurrentItem(index);
                            }}
                            style={{ width: '70px', color: '#72728F' }}
                          />
                          <input
                            value={propValue('items')[index].likes}
                            onChange={(e) => {
                              const newItems = [...items];
                              newItems[index].likes = e.target.value;
                              const change = { target: { value: newItems } };
                              propChange('items', change);
                            }}
                            style={{ width: '30px' }}
                          />
                          <input
                            value={propValue('items')[index].time}
                            onChange={(e) => {
                              const newItems = [...items];
                              newItems[index].time = e.target.value;
                              const change = { target: { value: newItems } };
                              propChange('items', change);
                            }}
                            style={{ width: '30px' }}
                          />
                          <select
                            value={propValue('items')[index].timeUnit}
                            onChange={(e) => {
                              const newItems = [...items];
                              newItems[index].timeUnit = e.target.value;
                              const change = { target: { value: newItems } };
                              propChange('items', change);
                            }}
                            style={{ width: '50px', paddingRight: '0' }}
                          >
                            {timeUnits.map((unit) => (
                              <option key={unit} value={unit.value}>{unit.value}</option>
                            ))}
                          </select>
                          <button
                            onClick={() => {
                              const newItems = [...items];
                              newItems.splice(index, 1);
                              const change = { target: { value: newItems } };
                              propChange('items', change);
                            }}
                          >
                            <i className="fas fa-trash" style={{ color: '#72728F' }} />
                          </button>
                        </div>
                        <textarea
                          value={propValue('items')[index].comment}
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[index].comment = e.target.value;
                            const change = { target: { value: newItems } };
                            propChange('items', change);
                          }}
                          style={{ display: currentItem === index ? '' : 'none', color: '#72728F' }}

                        />
                        <div style={{ display: item.showChild ? '' : 'none' }}>
                          {
                          item.children.map((child, childIndex) => (
                            <div>
                              <div className="flex mb-1">
                                <button
                                  onClick={() => {
                                    const newItems = [...items];
                                    newItems[index].children.splice(childIndex, 0, { ...child });
                                    const change = { target: { value: newItems } };
                                    propChange('items', change);
                                  }}
                                >
                                  <i className="fas fa-clone" />
                                </button>
                                <input
                                  value={propValue('items')[index].children[childIndex].username}
                                  onChange={(e) => {
                                    const newItems = [...items];
                                    newItems[index].children[childIndex].username = e.target.value;
                                    const change = { target: { value: newItems } };
                                    propChange('items', change);
                                  }}
                                  onClick={() => {
                                    currentChild[0] === index && currentChild[1] === childIndex ? setCurrentChild([
                                      null, null,
                                    ]) : setCurrentChild([index, childIndex]);
                                  }}
                                  style={{ width: '70px' }}
                                />
                                <input
                                  value={propValue('items')[index].children[childIndex].likes}
                                  onChange={(e) => {
                                    const newItems = [...items];
                                    newItems[index].children[childIndex].likes = e.target.value;
                                    const change = { target: { value: newItems } };
                                    propChange('items', change);
                                  }}
                                  style={{ width: '30px' }}
                                />
                                <input
                                  value={propValue('items')[index].children[childIndex].time}
                                  onChange={(e) => {
                                    const newItems = [...items];
                                    newItems[index].children[childIndex].time = e.target.value;
                                    const change = { target: { value: newItems } };
                                    propChange('items', change);
                                  }}
                                  style={{ width: '30px', color: '#72728F' }}
                                />
                                <select
                                  value={propValue('items')[index].children[childIndex].timeUnit}
                                  onChange={(e) => {
                                    const newItems = [...items];
                                    newItems[index].children[childIndex].timeUnit = e.target.value;
                                    const change = { target: { value: newItems } };
                                    propChange('items', change);
                                  }}
                                  style={{ width: '50px', paddingRight: '0' }}
                                >
                                  {timeUnits.map((unit) => (
                                    <option key={unit} value={unit.value}>{unit.value}</option>
                                  ))}
                                </select>
                                <button
                                  onClick={() => {
                                    const newItems = [...items];
                                    newItems[index].children.splice(childIndex, 1);
                                    const change = { target: { value: newItems } };
                                    propChange('items', change);
                                  }}
                                >
                                  <i className="fas fa-trash" style={{ color: '#72728F' }} />
                                </button>
                              </div>

                              <textarea
                                value={propValue('items')[index].children[childIndex].comment}
                                onChange={(e) => {
                                  const newItems = [...items];
                                  newItems[index].children[childIndex].comment = e.target.value;
                                  const change = { target: { value: newItems } };
                                  propChange('items', change);
                                }}

                                style={{ display: currentChild[0] === index && currentChild[1] === childIndex ? '' : 'none' }}
                              />
                            </div>
                          ))
                        }
                        </div>
                      </div>
                    ))
                }
          </div>
        </div>
        <div className="py-1">
          <span className="flex justify-between center-align relative" style={{ color: '#72728F', fontSize: '13px' }}>
            <label htmlFor="textType" style={{ color: '#72728F', fontSize: '13px' }}>Alinhamento</label>
            {/* <div className='flex flex-col items-center justify-center'></div> */}
            <DropDown responsiveData={responsiveData} responsiveMode={responsiveMode} setDevice={setDevice} setResponsiveMode={setResponsiveMode} position="40%" />
            <select
              style={{
                fontSize: '13px', border: '1px solid #ddd', textAlign: 'center', outline: 0, color: '#72728F', width: '5rem',
              }}
              className="px-2"
              name="textType"
              id="textType"
              onChange={(e) => {
                changeObjectStyle('textAlign', responsiveMode.mode, e);
              }}
            >
              {alignOptions.map((align) => <option value={align} selected={styleValue('textAlign')[responsiveMode.mode] === align}>{align}</option>)}
            </select>
          </span>
        </div>
      </div>
      <div className="py-4 ">
        <span
          style={{
            color: '#72728F', fontSize: '13px', cursor: 'pointer', display: 'inline-block', width: '90%',
          }}
          onClick={(e) => {
            const { nextSibling } = e.target;
            if (nextSibling.style.transform == 'rotate(180deg)') {
              nextSibling.style.transform = 'rotate(0deg)';
            } else {
              nextSibling.style.transform = 'rotate(180deg)';
            }

            e.target.parentNode.children[2].classList.toggle('hidden');
          }}
          ref={tipografia}
        >
          Tipografia
        </span>
        <MdKeyboardArrowDown
          color="#72728F"
          style={{
            display: 'inline', cursor: 'pointer', transform: 'rotate(0deg)', transition: 'transform 0.2s ease-in-out',
          }}
          className="cursor-pointer"
          onClick={(e) => {
            tipografia.current.click();
          }}
        />
        <div style={{ margin: '10px 0' }}>
          <CustomButton
            loading={false}
            disabled={false}
            textColor="#fff"
            style={{ textAlign: 'center' }}
            Icon={BsSliders}
            buttontext="Editar tipografia"
            backgroundColor="#62627A"
            variant="contained"
            onClick={() => {
              setselectedtab('slider');
            }}
          />
        </div>

      </div>
    </div>
  );
};

export default FacebookEdit;
