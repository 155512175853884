import React, { useState } from 'react';
import '../../styles/frazerUpload/FrazerUpload.css';
import { useTranslation } from 'react-i18next';
import close from '../../assets/icons/former/close-icon.svg';

const NotePopup = ({
  closeFun,
  title,
  btn,
  label,
  placeholder,
  fileupload,
  func,
  noteVal,
}) => {
  const [input, setInput] = useState('');
  const { i18n, t } = useTranslation();

  return (
    <div className="frazer-container">
      <div className="frazer-box">
        <div className="frazer-upload-header">
          <h2>{title}</h2>
          <img onClick={() => closeFun(false)} src={close} alt="" />
        </div>

        <div className="hr80" />

        <from className="minimum-height pb-5">
          <div className="frazer-input">
            <label htmlFor="fileupload">{label}</label>
            <input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              type="text"
              placeholder={placeholder}
            />
          </div>
          {fileupload && (
            <div className="frazer-input">
              <p>{fileupload}</p>
            </div>
          )}

          {/* note Section */}
          <div className="note-box ">
            <div>
              <p className="popup-note">{noteVal}</p>
            </div>
          </div>

          <div className="hr80" />

          <div className="fazer-btn">
            <p onClick={() => closeFun(false)}>{t('cancel')}</p>
            <button onClick={() => func(input)}>{btn}</button>
          </div>
        </from>
      </div>
    </div>
  );
};

export default NotePopup;
