import React, { useState, useRef, useEffect } from 'react';
import { MdSearch, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import '../../../styles/builder/ComponentsDrawer.css';
import { BsStar } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { TodosComponents } from '../../../helpers/Builder/components';
import notFound from '../../../assets/icons/builder/not-found.png';

const ComponentsDrawer = ({
  setSearch, search, SelectedComp, firstIndex, setFirstIndex, mvBtm, setMvBtm, mvUp, setMvUp,
}) => {
  const todosRef = useRef(null);
  const { t } = useTranslation();
  const searchedArray = TodosComponents.filter((comp) => comp.name.toLowerCase().includes(search.toLowerCase()));

  const renderedArray = search.length ? searchedArray.length ? searchedArray : [{
    name: 'Not Found',
    icon: notFound,
  }] : SelectedComp.components;

  return (
    <div style={{ overflowY: 'scroll' }}>
      <div className="inputComponent">
        <MdSearch size={20} color="#72728F" />
        {' '}
        <input
          className="input"
          placeholder={t('Search')}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <div className="listOfComponents">
        <div className="favourite" style={{ display: search.length ? 'none' : '' }}>
          <span>{t('favorite')}</span>
          <MdKeyboardArrowDown className="arrowDownIcon" color="#72728F" />
        </div>
        <div className="selectedComponent" style={{ display: search.length ? 'none' : '' }}>
          <span
            onClick={(e) => {
              const { nextSibling } = e.target;
              if (nextSibling.style.transform == 'rotate(180deg)') {
                nextSibling.style.transform = 'rotate(0deg)';
              } else {
                nextSibling.style.transform = 'rotate(180deg)';
              }
              e.target.parentNode.nextSibling.classList.toggle('hidden');
            }}
            ref={todosRef}
          >
            {t(SelectedComp.name)}
          </span>
          <MdKeyboardArrowDown
            className="arrowDownIcon"
            color="#72728f"
            onClick={() => {
              todosRef.current.click();
            }}
          />
        </div>
        <div>
          {
                renderedArray.map((comp) => (
                  <div
                    draggable
                    onDragStart={(e) => {
                      e.dataTransfer.setData('element', (comp.type));
                      setMvBtm(true);
                      setMvUp(false);
                    }}

                    className="builderComponent"
                  >
                    <BsStar className="starIcon" color="#72728F" size={12} />
                    <div className="builderItem">
                      <img className="" src={comp.icon} />
                      <span>{t(comp.name)}</span>
                    </div>
                  </div>
                ))
}
        </div>
      </div>
    </div>
  );
};

export default ComponentsDrawer;
