import React, { useState, useEffect } from 'react';
import {
  AreaChart,
  Area,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';
// import "..styles/line.rechart.css";
import CustomTooltip from '../custom/CustomTooltip';
import ss from '../../modules/ss';

export default function LineRechartComponent({
  isMobile,
  isDesktop,
  data,
  vertical,
  horizontal,
  duration,
  setWhatTime,
}) {
  const [datas, setData] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const tempDatas = [];
    const visitorData = data.data;
    const total = data.visitorsCount;

    const tot = (data.visitorsLeft ?? 0) + (data.visitorsCompleted ?? 0);

    setTotal(tot);

    if (visitorData?.length > 0) {
      let tempTotal = total;
      let hasEnd = false;

      for (let s = 0; s < visitorData?.length; s++) {
        const temp = visitorData[s];
        if (temp.timed !== 0) {
          if (temp.timed === Number(duration)) {
            hasEnd = true;
            tempDatas.push(temp);
          } else if (temp.timed) {
            // temp['timed'] = ss(temp['timed'])
            temp.total = tempTotal - temp.total;
            tempDatas.push(temp);
            tempTotal = temp.total;
          }
        } else {
          tempTotal -= temp.total;
        }
      }
      if (!hasEnd) {
        tempDatas.push({
          timed: duration,
          total: 0,
        });
      }
    }

    tempDatas.unshift({ timed: 0, total: tot });

    console.log(tempDatas);

    console.log(tempDatas.sort((a, b) => a.timed - b.timed));

    setData(tempDatas.sort((a, b) => a.timed - b.timed));
  }, []);

  const getWidth = () => {
    if (isDesktop) return 992;
    if (isMobile) return window.innerWidth;
    return window.innerWidth * 0.85;
  };

  const getHeight = () => {
    if (isDesktop) return 546;
    if (isMobile) return 200;
    return 300;
  };

  return (
    <ResponsiveContainer width="100%">
      <AreaChart
        style={{ marginLeft: -81 }}
        width="100%"
        height={700}
        data={datas}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#4adede" stopOpacity={0.6} />
            <stop offset="95%" stopColor="#4adede" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis
          style={{ fontSize: 12 }}
          type="number"
          domain={[0, duration]}
          tickFormatter={(tick) => ss(tick)}
          ticks={[0, duration / 4, duration / 2, duration * 0.75, duration]}
          dataKey="timed"
        />
        <YAxis style={{ fontSize: 12 }} dataKey="total" />
        <CartesianGrid vertical={vertical} horizontal={horizontal} />
        <Tooltip
          content={(
            <CustomTooltip
              total={total}
              setWhatTime={setWhatTime}
              duration={duration}
            />
          )}
        />
        <Area
          type="stepAfter"
          dataKey="total"
          stroke="#4adede"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
