import React, { useState, useEffect } from "react";
import "../../styles/novaPagina/NovaPagina.css";
import "../../styles/global.css";
import "../../styles/tutorial/index.css"
import { useTranslation } from "react-i18next";
import goBackIcon from "../../assets/icons/paginas/chevron-left.svg";
import { SideBarHome } from "../../components";
import { useNavigate } from "react-router";
import { useParams } from 'react-router-dom';
import { useCollection } from "../../custom-hooks/useCollection";
const Tutorial = () => {
    const { id } = useParams();
    const list = [
        {
            name: "link",
            value: id,
            operator: "==",
        },
    ];
    const { documents: tutorial } = useCollection("tutorials", list);

    console.log({ tutorial })
    const navigate = useNavigate()

    const { t } = useTranslation();

    useEffect(() => {
        document.body.classList.add("custom-background");
        return () => {
            document.body.classList.remove("custom-background");
        };
    }, []);
    return (
        <div style={{ display: "flex" }}>

            <SideBarHome />

            <div className="RightComponentLanding">
                <div className=" headerTitle">
                    {/* <div className="flex flex-row"> */}
                    <div className="goBackButton" onClick={() => navigate(-1)}>
                        <img src={goBackIcon} alt="goBackIcon.svg" />
                    </div>
                    <p>Voltar aos tutoriais</p>
                    {/* </div> */}

                </div>
                <div className="tutorialBox">
                    <>
                        <div className="px-36 py-5">
                            <h1 className="text-slate-50 text-xl font-semibold">
                                {tutorial?.name}
                            </h1>
                            <div className="mt-2 atm-shadow atm-border dark:border-slate-600 bg-stone-50 dark:bg-slate-800 rounded-sm p-2">
                                <div style={{ position: 'relative', paddingBottom: 'calc(55% + 41px)', height: 0 }}>
                                    <iframe src={`https://secondz.io/play/${id}/embed`} allow="fullscreen" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }}>
                                    </iframe>
                                </div>
                            </div>
                        </div>

                    </>

                </div>
            </div>
        </div>
    );
};

export default Tutorial;
