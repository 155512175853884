import React from "react";

export default function SocialIconsRender({ comp }) {
  return (
    <>
    <i
      style={{
        color: "black",
        width: " fit-content",
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: "50px",
        padding: "12px 24px",
      }}
      className={comp.misc.icon}
      />
      </>
  );
}
