import '../../styles/skelton/siteskelton.css';
import '../../styles/skelton/skelton.css';

const SiteSkelton = () => (

  <div className="sitecontainer">

    <div className="sitebox">
      <div className=" flex flex-row gap-5">
        <div className="line1 skelton-animation" />
        <div className="line2 skelton-animation" />
        <div className="line1 skelton-animation" />

      </div>
      <div className="flex flex-row gap-5">
        <div className="skeltonCircle skelton-animation" />
        <div className="skeltonCircle skelton-animation" />

      </div>
    </div>
  </div>
);

export default SiteSkelton;
