import React, { useState, useCallback } from "react";
import axios from "axios";
import { auth, db } from "../../config/firebase";
import close from "../../assets/icons/former/close-icon.svg";
// import "../../styles/connect/Connect.css";
import "../../styles/connect/connect.scss";
import { apiCall } from "../../service/commonService";
import { Buffer } from "buffer";
import {
  collection,
  addDoc,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";

function DeleteAPI({
  siteId,
  siteName,
  closeFun,
  title,
  btn,
  label,
  placeholder,
  fileupload,
  func,
  databaseURL,
}) {
  const user = auth.currentUser;

  const [success, setSuccess] = useState(null);
  const [inputVal, setInputVal] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const { i18n, t } = useTranslation();
  const noteVal = t("deleteNote");
  const deleteDocument = async (event) => {
    console.log("calling this");
    event.preventDefault();
    try {
      // Get the user token
      let userToken;
      try {
        userToken = await user.getIdToken();
      } catch (error) {
        console.log("Authentication error:", error);
        return;
      }

      // Call your API endpoint to remove the user
      const userData = {
        siteId: Buffer.from(siteId).toString("base64"),
        userToken,
      };
      const response = await apiCall(
        "post",
        "cloudpanel/api/verify/removeuser",
        userData
      );
      const { data } = response;
      const succeeded = data?.data || data;

      if (succeeded.success) {
        closeFun(false);
        // Delete the document from Firebase Firestore
        await deleteDoc(doc(db, "sites", siteId));
        setSuccess(succeeded.message);
      } else {
        // Handle failure case
        console.error("Failed to delete the document:", succeeded.error);
      }
    } catch (error) {
      // Handle error
      console.error("Error deleting the document:", error);
    }
  };

  const deleteSiteFirebase = async (event) => {
    // let document = db.collection('todos').doc(`${request.params.todoId}`);
    // document.update(request.body)
  };

  return (
    <div className="frazer-container">
      <div className="frazer-box">
        <div className="frazer-upload-header">
          <h2>{t("remove")}</h2>
          <img onClick={() => closeFun(false)} src={close} alt="" />
        </div>
        <form className="minimum-height" onSubmit={deleteDocument}>
          <div className="connectInputContainer">
            <div className="frazer-input">
              <label htmlFor="siteName">{t("wantToRemoveSiteBelow")}</label>
              <input
                type="text"
                id="siteName"
                placeholder={placeholder}
                value={siteName}
                disabled
                required
              />
            </div>
            <div className="note-box ">
              <div>
                <p className="popup-note">{noteVal}</p>
              </div>
            </div>
          </div>

          {/* <div className='frazer-input'>
                        <label htmlFor="siteName">Site Type:</label>
                        <input type="text" id="siteName" value={siteType} onChange={(e) => setSiteType(e.target.value)} required />

                    </div>
                    <div className='ml-5 mb-5'>

                        <input className='mr-5' type="checkbox" id="useCloaker" checked={useCloaker} onChange={handleUseCloakerChange} />
                        <label htmlFor="useCloaker">Use Cloaker</label>
                    </div> */}

          <div className="fazer-btn">
            <p onClick={() => closeFun(false)}>{t("cancel")}</p>
            <button
              style={{ backgroundColor: "#FF1F53", color: "#ffffff" }}
              type="submit"
            >
              {btn}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DeleteAPI;
