import React, { useState, useEffect } from 'react';
import '../../styles/connect/Connect.css';
import { arrayUnion } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import close from '../../assets/icons/former/close-icon.svg';
import { updateDocument } from '../../service/useFirestore';
import { CreateTag } from './CreateTag';
import { useCollection } from '../../custom-hooks/useCollection';

export const SelectTag = ({
  selectedPage, tags, docId, closeFun, title,
}) => {
  const [siteName, setSiteName] = useState('');
  const [pages, setPages] = useState([]);
  const [addTag, setAddTag] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const { t } = useTranslation();
  const { documents: pagesDocuments } = useCollection('pages2');

  useEffect(() => {
    console.log('selectedPage : ', selectedPage);
    setPages([...pagesDocuments]);
  }, [pagesDocuments]);

  const selectTag = async () => {
    try {
      // if (!addTag) {
      //   toast("Tag already added", {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "colored",
      //   });
      // } else {
      setIsLoading(true);
      await updateDocument('pages2', selectedPage.id, {
        tags: arrayUnion(...selectedPage.tags, addTag),
      });
      selectTagToast();
      setIsLoading(false);
      // }
    } catch (error) {
      console.error('Error adding document:', error);
      setError('Failed to create the site.');
      setSuccess('');
    }
  };

  const validateInput = (value) => {
    const regex = /[^A-Za-z0-9]+/g; // Regular expression for alphanumeric characters only
    return regex.test(value);
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/[^A-Za-z0-9]+/g, '');
    setSiteName(value);
    setIsValid(validateInput(value));
  };
  const handleKeyDown = (e) => {
    if (e.key === ' ' || /[^\w\s]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    selectTag();
  };

  const handleAddTag = (newTag) => {
    setAddTag(JSON.parse(newTag));
  };

  const handleCloseCreatePopup = () => {
    setShowCreatePopup(false);
  };

  const selectTagToast = () => {
    toast('Tag selected and added', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  return (
    <div className="frazer-container">
      {showCreatePopup && <CreateTag closeFun={handleCloseCreatePopup} />}
      <ToastContainer
        className="toast"
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="frazer-box">
        <div className="frazer-upload-header">
          <h2>{title}</h2>
          <img onClick={() => closeFun(false)} src={close} alt="" />
        </div>
        <form className="minimum-height">
          <div className="connectInputContainer" style={{ justifyContent: 'center' }}>
            <div className="frazer-input" style={{ padding: '0 24px' }}>
              <label htmlFor="siteName">
                {t('select')}
                {' '}
                tag
              </label>
              <select
                id="dropdown"
                className="dropdownMenu selectTagDropdown"
                placeholder=""
                onChange={(e) => handleAddTag(e.target.value)}
              >
                {tags?.map((item) => (
                  <option value={JSON.stringify(item.tags)}>
                    {item?.tags.value}
                  </option>
                ))}
              </select>
            </div>
            <div className="frazer-input" style={{ padding: '0 24px' }}>
              <button
                className="newTagBtn"
                onClick={() => setShowCreatePopup(true)}
              >
                <span>
                  {t('new')}
                  {' '}
                  tag
                </span>
              </button>
            </div>
          </div>

          <div className="fazer-btn">
            <p onClick={() => closeFun(false)}>{t('cancel')}</p>
            <button type="submit" onClick={handleSubmit}>
              <span className="button-text">
                {isLoading ? t('loading') : `${t('confirm')} tag`}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
